<template>
  <div>
    <TopManager />
    <div class="content">
      <div class="btn-above-table">
        <a href="#" @click.prevent="show()" class="btn-green"><i class="ico-11"></i> {{$t('Додати новий запис')}}</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold doctors-table">
          <thead>
            <tr>
              <th>
                <div class="caption">Від ...</div>
              </th>
              <th>
                <div class="caption">... до</div>
              </th>
              <th>
                <div class="caption">Коментар</div>
              </th>
              <th style="width: 40px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(day) of days" :key="day.id">
              <td class="text-overflow-ellipsis">{{ day.date_from }}</td>
              <td class="text-overflow-ellipsis">{{ day.date_to }}</td>
              <td class="text-overflow-ellipsis">{{ day.comment }}</td>
              <td><a v-show="day.allowDrop" href="#" @click.prevent="remove(day.id)" class="btn-table-change"><i class="ico-20"></i></a></td>
            </tr>
          </tbody>
        </table>
<!--        <ul class="pagination">-->
<!--          <li class="disabled">Назад</li>-->
<!--          <li><a href="#" class="active">1</a></li>-->
<!--          <li><a href="#">2</a></li>-->
<!--          <li><a href="#">3</a></li>-->
<!--          <li><a href="#">4</a></li>-->
<!--          <li>...</li>-->
<!--          <li><a href="#">201</a></li>-->
<!--          <li><a href="#">Далі</a></li>-->
<!--        </ul>-->
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.addFreeDay"
        width="960px"
        :title="$t('Додавання нового запису')"
        class="modal-custom"
    >
      <form @submit.prevent="save">
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">Дата</div>
            <div class="input-wr">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                range-separator="-"
                start-placeholder="Від..."
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                end-placeholder="...до"
                style="width: 240px;flex-shrink: 0"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Примітка</div>
            <div class="input-wr"><input type="text" v-model="form.comment" class="custom-input"></div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hide()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  data () {
    return {
      url: '/doctor',
      form: {
        date: new Date(),
        comment: ''
      },
      pickerOptions: {
        firstDayOfWeek: 1
      },
      days: []
    }
  },
  created () {
    this.$http.get(this.url + '/free-days')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    hide () {
      this.$modal.hide('addFreeDay')
    },
    show () {
      this.$modal.show('addFreeDay')
    },
    setData (data) {
      this.days = data.days
    },
    remove (id) {
      if (confirm('Видали запис?')) {
        this.$http.delete(this.url + '/delete-free-days', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.setData(result.data)
          })
          .catch(() => {
            ElMessage({
              message: $t('Помилка видалення'),
              type: 'error',
            })
          })
      }
    },
    save () {
      this.$http.post('/doctor/add-free-days', this.form)
        .then(result => {
          this.setData(result.data)
          this.hide()
          this.setFormData()
          ElMessage({
            message: $t('Запис додано'),
            type: 'error',
          })
        })
        .catch(() => {
          ElMessage({
            message: $t('Помилка додавання запису'),
            type: 'error',
          })
        })
    },
    setFormData () {
      this.form = {
        date: new Date(),
        comment: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
