<template>
  <div>
    <top-director></top-director>
    <div class="content with-marge-bottom">
      <div class="paydesk-wr">
        <div class="one-pay" v-for="person in articles" :class="{'new' : person.statusPaydesk}" :key="person.idPay">
          <div class="number-count before-border">
            <div class="new-pay-t bold hide-old">{{ person.statusText }}</div>
            <div class="caption-grey black bold hide-new">Рахунок</div>
            <div class="counter hide-new">{{ person.counterPay }}</div>
            <div class="caption-grey hide-new">Нал.</div>
            <div class="date-time black hide-new">{{ person.dateTimePay }}</div>
          </div>
          <div class="client-wr before-border">
            <div class="name-client">
              <div class="surname bold">{{ person.surnameClient }}</div>
              <div class="name">{{ person.nameClient }}</div>
            </div>
            <div class="buttons-client">
              <div class="top-btn">
                <div class="date-time black hide-old">{{ person.dateClient }}</div>
                <span class="bold hide-new">{{ person.job }}</span>
                <ul class="pay-box-btn hide-new">
                  <li>
                    <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-17"></i></a>
                  </li>
                </ul>
              </div>
              <ul class="bottom-btn pay-box-btn">
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-21"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-22"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-23"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="sum-count before-border">
            <div class="caption-grey">Сума</div>
            <div class="sum-counter black bold">{{ person.sumCounter }}</div>
            <div class="caption-grey">Знижка</div>
            <div class="discount black bold">{{ person.discount }}%</div>
          </div>
          <div class="discount-wr">
            <div class="caption-grey">Сума зі знижкою</div>
            <div class="counter">{{ person.sumCounter - ((person.sumCounter / 100) * person.discount) }}</div>
          </div>
          <div class="last-block-pay hide-new">
            <div class="btn-2 before-border">
              <ul class="pay-box-btn">
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-24"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-25"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
              </ul>
            </div>
            <div class="balance">
              <div class="caption-grey">Баланс</div>
              <div class="sum-counter black bold">{{ person.balance }}</div>
            </div>
            <div class="wrap-money-btn">
              <a href="#" class="take-money-btn brand-hover with-border">Вивести гроші2</a>
            </div>
          </div>
          <div class="last-block-new hide-old">
            <a href="#" class="btn-create-bill bold brand-hover with-border">Створити рахунок</a>
            <div class="right-b">
              <div class="caption-grey">Сума</div>
              <div class="sum-counter black bold">{{ person.sumCounter - ((person.sumCounter / 100) * person.discount) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-marge-small">
        <span>Всього без знижок:</span>
        <div class="number">160005.00</div>
        <span>Всього зі знижками: </span>
        <div class="number brand-color">160005.00</div>
        <span>Всього незакритих процедур:</span>
        <div class="number red-color">160005.00</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Invoice-Page',
  data () {
    return {
      date: new Date([2019, 4, 12]),
      articles: [
        {
          idPay: 1,
          statusPaydesk: false,
          statusText: 'Новий',
          counterPay: '51917',
          dateTimePay: '04.10.2019',
          surnameClient: 'Руцька',
          nameClient: 'Олена Валеріївна',
          dateClient: '04.10.2019',
          job: 'Дополюк В.М.',
          sumCounter: '100',
          discount: '2',
          balance: '21570.00'
        },
        {
          idPay: 2,
          statusPaydesk: false,
          statusText: 'Новий',
          counterPay: '51917',
          dateTimePay: '04.10.2019',
          surnameClient: 'Головій',
          nameClient: 'Володимир Олександрович',
          dateClient: '04.10.2091',
          job: 'Дополюк В.М.',
          sumCounter: '100',
          discount: '0',
          balance: '21570.00'
        },
        {
          idPay: 3,
          statusPaydesk: false,
          statusText: 'Новий',
          counterPay: '51917',
          dateTimePay: '04.10.2019',
          surnameClient: 'Пасан',
          nameClient: 'Олена Петрівна',
          dateClient: '04.10.2091',
          job: 'Дополюк В.М.',
          sumCounter: '100',
          discount: '0',
          balance: '21570.00'
        },
        {
          idPay: 4,
          statusPaydesk: false,
          statusText: 'Новий',
          counterPay: '51917',
          dateTimePay: '04.10.2019',
          surnameClient: 'Терешковіч',
          nameClient: 'Богдан Сербовіч',
          dateClient: '04.10.2091',
          job: 'Дополюк В.М.',
          sumCounter: '100',
          discountStatus: true,
          discount: '2',
          balance: '21570.00'
        }
      ]
    }
  }
}
</script>
