<template>
  <el-dialog
      title="Tips"
      width="50%"
  >
    <form>
      <div class="caption-modal">
        {{$t('Перегляд запису')}}
        <a href="#" class="close-modal" @click.prevent="$modal.hide('watchOrderModal')"><i class="ico-17"></i></a>
      </div>
      <div class="box-blue-modal modal-radio-blue">
        <div class="date-time-1">Понеділок, 16 Вересня</div>
        <ul class="radio-list">
          <li class="custom-long-radio">
            <label>
              <input type="checkbox" name="successCheckbox">
              <span>{{$t('Увімкнути СМС')}}</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="body-modal form-long">
        <div class="line-modal-form flex-wrap-order">
          <div class="description">{{$t('Коментар')}}</div>
          <div class="input-wr">
            <textarea class="mailing-textarea"></textarea>
          </div>
        </div>
      </div>
      <div class="footer-modal flex-footer">
        <div class="btn-left-footer">
        </div>
        <div class="btn-right-footer">
          <button class="btn-green">{{$t('Зберегти')}}</button>
          <button type="button" class="btn-red" @click="cancel">{{$t('Скасувати')}}</button>
        </div>
      </div>
    </form>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    clear () {},
    cancel () {
      this.$modal.hide('watchOrderModal')
      this.clear()
    }
  }
}
</script>

<style scoped>

</style>
