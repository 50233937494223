// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import {createApp} from "vue";
import router from "./router";
import Axios from "axios";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from "element-plus/lib/locale/lang/uk";
import Vue3Storage from "vue3-storage";
//import VueHtmlToPaper from "vue-html-to-paper";
import {VTooltip} from "v-tooltip";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import App from './App.vue'
import {createI18n} from 'vue-i18n'
import Maska from 'maska';
import {store} from "./store";

import Navbar from "@/components/modules/Navbar";
import TopInfo from "@/components/modules/TopInfo";
import TopManager from "@/components/modules/TopManager";
import TopPacient from "@/components/modules/TopPacient";
import TopDirector from "./components/modules/TopDirector";
import ModalPatient from "@/components/modules/ModalPatient";
import Login from "@/components/modules/Login";
import en from './utilits/messages/en';
import uk from './utilits/messages/uk';
import 'vue3-timepicker/dist/VueTimepicker.css'
import Toaster from '@meforma/vue-toaster';
import mitt from "mitt";
const moment = require("moment");
require("moment/locale/uk");
const emitter = mitt()

const i18n = createI18n({
  locale: 'uk',
  fallbackLocale: 'uk',
  globalInjection: true,
  messages: {
    en: en,
    uk: uk
  }
})

/* eslint-disable no-new */
const app = createApp(App);
app.config.globalProperties.alert = {
  type: "success",
  message: "",
  show: false,
}
app.use(i18n)
app.config.globalProperties.serverUrl = process.env.APP_URL
if (localStorage.getItem("user") !== undefined) {
  app.config.globalProperties.user = JSON.parse(localStorage.getItem("user"));
  store.commit('setUser', JSON.parse(localStorage.getItem("user")));
} else {
  app.config.globalProperties.user = null
  store.commit('setUser', null);
}
app.use(Toaster)

import VueHtmlToPaper from './utilits/VueHtmlToPaper';
app.use(VueHtmlToPaper)
app.use(VueSweetalert2)
app.use(ElementPlus, {locale})
app.use(Vue3Storage, {
  prefix: "medical_",
  driver: "memory",
  ttl: 60 * 60 * 24 * 1000, // 24 часа
})
// app.use(require("vue-moment"), {
//   moment,
// })

/** Axios **/
const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/rest/v2",
});
const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + token;
} else {
  router.push("/");
}

axios.interceptors.request.use(function (config) {
  store.commit("setStatus", "load");
  return config;
});

axios.interceptors.response.use(
  function (response) {
    store.commit("setStatus", null);
    return response;
  },
  function (error) {
    console.log("[Error http request]", error.message);
    store.commit("setStatus", null);
    if (error.message.indexOf("code 401") !== -1) {
      store.dispatch("logout");
      router.push("/");
    }
    return Promise.reject(error);
  }
);
app.config.globalProperties.$http = {...axios}

app.config.globalProperties.$modal = {
  show (name) {
    store.commit('show', name)
  },
  hide (name) {
    store.commit('hide', name)
  },
  setUser (data) {
    store.commit('setUser', data)
  }
}

//app.provide('$http', axios);
//app.component("VSelect", vSelect)
app.component("NavbarComponent", Navbar)
app.component("TopInfo", TopInfo)
app.component("TopPacient", TopPacient)
app.component("TopManager", TopManager)
app.component("TopDirector", TopDirector)
app.component("ModalPatient", ModalPatient)
app.component("LoginPage", Login)

app.directive("my-tooltip", VTooltip)

app.config.productionTip = false
app.config.globalProperties.emitter = emitter

app.use(store);
app.use(router);
app.use(Maska);
app.mount('#app');
