<template>
  <div>
    <top-director></top-director>
    <div class="table-content">
      123
    </div>
  </div>
</template>

<script>
export default {
  name: 'Static-Patient',
}
</script>

<style scoped>

</style>
