<template>
  <div>
    <Navbar></Navbar>
    <Header2></Header2>
    <div class="content">
      <div v-for="person of articles" class="table-wrapper" :key="person.id">
        <div class="user-settings">
          <div class="caption-user">Налаштування користувача</div>
          <div class="flex-user full-three">
            <div class="one-item">
              <div class="article-user">Прізвище</div>
              <input type="text" class="custom-input" :value="person.surname">
            </div>
            <div class="one-item">
              <div class="article-user">Ім’я</div>
              <input type="text" class="custom-input" :value="person.name">
            </div>
            <div class="one-item">
              <div class="article-user">По батькові</div>
              <input type="text" class="custom-input" :value="person.father">
            </div>
          </div>
          <ul class="header-tab-box">
            <li><a href="#" :class="{active: tabContent === 1}" @click.prevent="tabContent = 1">Основні</a></li>
            <li><a href="#" :class="{active: tabContent === 2}" @click.prevent="tabContent = 2">eHealth</a></li>
            <li><a href="#" :class="{active: tabContent === 3}" @click.prevent="tabContent = 3">Права</a></li>
          </ul>
          <div v-if="tabContent === 1" class="tab-content-box">
            <div class="flex-user">
              <div class="one-item width-short">
                <div class="article-user">Логін</div>
                <input type="text" class="custom-input" :value="person.loginField">
              </div>
              <div class="one-item width-short">
                <div class="article-user">Пароль</div>
                <input type="text" class="custom-input" :value="person.passwordField">
              </div>
            </div>
            <div class="flex-user">
              <div class="one-item width-short">
                <div class="article-user">Стать</div>
                <ul class="radio-list">
                  <li class="custom-radio">
                    <label>
                      <input type="radio" value="One" :checked="picked == 'man'" @change="e => picked = e.target.value">
                      <span>Чоловіча</span>
                    </label>
                  </li>
                  <li class="custom-radio">
                    <label>
                      <input type="radio" value="Two" :checked="picked == 'woman'" @change="e => picked = e.target.value">
                      <span>Жіноча</span>
                    </label>
                  </li>
                </ul>
              </div>
              <div class="one-item width-long">
                <div class="article-user">Ідентифікаційний код</div>
                <input type="text" class="custom-input">
              </div>
            </div>
            <div class="flex-user">
              <div class="one-item width-short-very">
                <ul class="radio-list">
                  <li class="custom-long-radio">
                    <label>
                      <input type="checkbox" value="foo" v-model="isCheckedSuccess" true-value="1" false-value="0">
                      <span>Доступ</span>
                    </label>
                  </li>
                </ul>
              </div>
              <div class="one-item width-short">
                <div class="article-user">Працівник</div>
<!--                <stf-select v-model="value">-->
<!--                  <div slot="label"></div>-->
<!--                  <div slot="value">-->
<!--                    <div v-if="value">-->
<!--                      <span>{{value.workStatus}} {{value.fullName}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div slot="search-input">-->
<!--                    <input @input="onsearch">-->
<!--                  </div>-->
<!--                  <section class="options delivery_order__options">-->
<!--                    <stf-select-option-->
<!--                      v-for="item of listFinded" :key="item.id"-->
<!--                      :value="item"-->
<!--                      :class="{'stf-select-option_selected': item.id === (value && value.id)}"-->
<!--                    >-->
<!--                      <span>{{item.workStatus}} {{item.fullName}}</span>-->
<!--                    </stf-select-option>-->
<!--                  </section>-->
<!--                </stf-select>-->
              </div>
              <div class="one-item width-long-small">
                <div class="article-user">Підрозділ</div>
<!--                <stf-select v-model="value2">-->
<!--                  <div slot="label"></div>-->
<!--                  <div slot="value">-->
<!--                    <div v-if="value2">-->
<!--                      <span>{{value2.unitStatus}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div slot="search-input">-->
<!--                    <input @input="unitSearch">-->
<!--                  </div>-->
<!--                  <section class="options delivery_order__options">-->
<!--                    <stf-select-option-->
<!--                      v-for="item of unitFinded" :key="item.id"-->
<!--                      :value="item"-->
<!--                      :class="{'stf-select-option_selected': item.id === (value2 && value2.id)}"-->
<!--                    >-->
<!--                      <span>{{item.unitStatus}}</span>-->
<!--                    </stf-select-option>-->
<!--                  </section>-->
<!--                </stf-select>-->
              </div>
            </div>
            <div class="flex-user">
              <div class="one-item width-short-very">
                <ul class="radio-list">
                  <li class="custom-long-radio">
                    <label>
                      <input type="checkbox" value="foo" v-model="isCheckedWorks" true-value="1" false-value="0">
                      <span>Касир</span>
                    </label>
                  </li>
                </ul>
              </div>
              <div class="one-item width-short">
                <div class="article-user">Код касира в КР</div>
                <input type="text" class="custom-input">
              </div>
            </div>
          </div>
          <div v-if="tabContent === 2" class="tab-content-box">
            <div class="flex-user">
              <div class="one-item width-short">
                <div class="article-user">Логін</div>
                <input type="text" class="custom-input">
              </div>
              <div class="one-item width-short">
                <div class="article-user">Пароль</div>
                <input type="text" class="custom-input">
              </div>
            </div>
            <div class="flex-user">
              <div class="one-item width-short-very">
                <ul class="radio-list">
                  <li class="custom-long-radio">
                    <label>
                      <input type="checkbox" name="successCheckbox">
                      <span>Доступ</span>
                    </label>
                  </li>
                </ul>
              </div>
              <div class="one-item width-short">
                <div class="article-user">Працівник</div>
<!--                <stf-select v-model="value">-->
<!--                  <div slot="label"></div>-->
<!--                  <div slot="value">-->
<!--                    <div v-if="value">-->
<!--                      <span>({{value.workStatus}}) {{value.fullName}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div slot="search-input">-->
<!--                    <input @input="onsearch">-->
<!--                  </div>-->
<!--                  <section class="options delivery_order__options">-->
<!--                    <stf-select-option-->
<!--                      v-for="item of listFinded" :key="item.id"-->
<!--                      :value="item"-->
<!--                      :class="{'stf-select-option_selected': item.id === (value && value.id)}"-->
<!--                    >-->
<!--                      <span>({{item.workStatus}}) {{item.fullName}}</span>-->
<!--                    </stf-select-option>-->
<!--                  </section>-->
<!--                </stf-select>-->
              </div>
              <div class="one-item width-long-small">
                <div class="article-user">Підрозділ</div>
<!--                <stf-select v-model="value2">-->
<!--                  <div slot="label"></div>-->
<!--                  <div slot="value">-->
<!--                    <div v-if="value2">-->
<!--                      <span>{{value2.unitStatus}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div slot="search-input">-->
<!--                    <input @input="unitSearch">-->
<!--                  </div>-->
<!--                  <section class="options delivery_order__options">-->
<!--                    <stf-select-option-->
<!--                      v-for="item of unitFinded" :key="item.id"-->
<!--                      :value="item"-->
<!--                      :class="{'stf-select-option_selected': item.id === (value2 && value2.id)}"-->
<!--                    >-->
<!--                      <span>{{item.unitStatus}}</span>-->
<!--                    </stf-select-option>-->
<!--                  </section>-->
<!--                </stf-select>-->
              </div>
            </div>
          </div>
          <div v-if="tabContent === 3"  class="tab-content-box">
            <div class="flex-user">
              <div class="one-item width-short">
                <div class="article-user">Логін</div>
                <input type="text" class="custom-input">
              </div>
              <div class="one-item width-short">
                <div class="article-user">Пароль</div>
                <input type="text" class="custom-input">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-bellow-table">
        <a href="#" class="btn-green standard-width">Зберегти</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserSettings',
  data () {
    return {
      articles: [
        {
          id: 1,
          surname: 'Шпілька',
          name: 'Василь',
          father: 'Миколайович',
          loginField: 'арм',
          passwordField: 'арм'
        }
      ],
      picked: 'man',
      tabContent: 1,
      isCheckedWorks: 0,
      isCheckedSuccess: 1,
      value: 1,
      value2: null,
      list: [
        {
          workStatus: '(Лікар)',
          fullName: 'Шпілька В.М.',
          id: 1
        },
        {
          workStatus: '(Аспірант)',
          fullName: 'Головій В.О.',
          id: 2
        }
      ],
      unitList: [
        {
          unitStatus: 'Гіпертрофічний гінгівіт',
          id: 'unit1'
        },
        {
          unitStatus: 'Пародонтоз',
          id: 'unit2'
        },
        {
          unitStatus: 'Гіпертрофічний гінгівіт',
          id: 'unit3'
        },
        {
          unitStatus: 'Гінекологія',
          id: 'unit4'
        }
      ],
      listFinded: [
      ],
      unitFinded: [
      ]
    }
  },
  created () {
    this.listFinded = this.list
    this.unitFinded = this.unitList
  },
  methods: {
    changeCollapseStatus: function () {
      this.collapseStatus = !this.collapseStatus
    },
    onsearch (e) {
      if (e.target.value) {
        this.listFinded = this.list.filter(el => el.workStatus.indexOf(e.target.value) !== -1 || el.fullName.indexOf(e.target.value) !== -1)
      } else {
        this.listFinded = this.list
      }
    },
    unitSearch (e) {
      if (e.target.value2) {
        this.unitFinded = this.unitList.filter(el => el.workStatus.indexOf(e.target.value2) !== -1)
      } else {
        this.unitFinded = this.unitList
      }
    }
  }
}
</script>

<style scoped>

</style>
