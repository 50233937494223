<template>
  <div>
    <Header3></Header3>
    <div class="content">
      <ul class="list-all-page">
        <li v-for="(link, index) in links" :key="index">{{ index + 1 }}) <a :href="'/' + link">{{ link }}</a></li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  name: 'HomePage',
  data () {
    return {
      links: [
        'Authorization+',
        'Budget+',
        'CostsProceduresManager+',
        'BalanceGroups+',
        'CashFlow+',
        'CashFlowAccounts+',
        'DoctorsAssistants+',
        'HR+',
        'CabinetLoading+',
        'AcceptedPatients',
        'StatisticsDirector+',
        '',
        'PatientCards+',
        'PrimaryPatients',
        'Dispensary',
        'PatientCard+',
        '',
        'TaskOrganizer+',
        'CashRegister+',
        'BulkMailing+',
        'BulkMailing2+',
        'Doctors+',
        'DiscountCards+',
        'ManagerCertificates+',
        'ManagerContact+',
        'ManagerPaydesk',
        '',
        'GroupsPatients+',
        'CumulativeDiscount+',
        'SystemAdministrator+',
        'DispensaryTypes+',
        'Research',
        'UserSettings',
        'InformationTest+',
        'TreatmentCourse+',
        'Sources+',
        'Divisions+',
        'LogPage+',
        'PriceList+',
        'Workplaces+',
        'Relatives+',
        'Specialty+',
        'FileManagerTags+',
        'Safety+',
        'GeneralInformation+',
        '',
        '',
        'TaskOrganizer+'
      ]
    }
  }
}
</script>

<style scoped>
  .list-all-page li a{
    color: lightcoral;
  }
  .list-all-page li{
    margin-bottom: 10px;
    font-size: 16px;
  }
</style>
