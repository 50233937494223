export default {
  Вхід: "Вхід",
  "Будь ласка, авторизуйтеся": "Будь ласка, авторизуйтеся",
  Логін: "Логін",
  Пароль: "Пароль",
  Авторизуватися: "Авторизуватися",
  Дата: "Дата",
  "Попередній день": "Попередній день",
  Сьогодні: "Сьогодні",
  "Наступний день": "Наступний день",
  "Первинний пацієнт": "Первинний пацієнт",
  Застрахований: "Застрахований",
  Л: "Л",
  Пацієнт: "Пацієнт",
  "Мобільний номер телефону": "Мобільний номер телефону",
  Лікар: "Лікар",
  "Опис роботи": "Опис роботи",
  Примітка: "Примітка",
  "Попереджений про візит!": "Попереджений про візит!",
  "Візит не відбувся": "Візит не відбувся",
  "Новий запис": "Новий запис",
  Завершено: "Завершено",
  "На прийомі": "На прийомі",
  Прийшов: "Прийшов",
  "Не прийшов": "Не прийшов",
  Попереджений: "Попереджений",
  "Вивести гроші": "Вивести гроші",
  "Безготівковий розрахунок": "Безготівковий розрахунок",
  Коментар: "Коментар",
  Внести: "Внести",
  Скасувати: "Скасувати",
  "Внести гроші до балансу": "Внести гроші до балансу",
  "Додавання нового запису до розкладу": "Додавання нового запису до розкладу",
  "Запис пацієнта": "Запис пацієнта",
  "Дата призначення": "Дата призначення",
  "Призначений час": "Призначений час",
  "Кінець прийому": "Кінець прийому",
  "Початок прийому": "Початок прийому",
  "Новий пацієнт": "Новий пацієнт",
  "Номер телефону": "Номер телефону",
  Кабінет: "Кабінет",
  "Попереджений про візит": "Попереджений про візит",
  "Факт візиту": "Факт візиту",
  "Увімкнути СМС": "Увімкнути СМС",
  "Перейти до пацієнтів": "Перейти до пацієнтів",
  "В картку пацієнта": "В картку пацієнта",
  Зберегти: "Зберегти",
  "Дата народження": "Дата народження",
  "Реєстрація нового пацієнта": "Реєстрація нового пацієнта",
  "П.І.Б": "П.І.Б",
  Стать: "Стать",
  жіноча: "жіноча",
  чоловіча: "чоловіча",
  "Житель населеного пункту": "Житель населеного пункту",
  місто: "місто",
  село: "село",
  Країна: "Країна",
  Область: "Область",
  Район: "Район",
  "Населений пункт": "Населений пункт",
  Адреса: "Адреса",
  Індекс: "Індекс",
  Телефон: "Телефон",
  ні: "ні",
  так: "так",
  Дисконт: "Дисконт",
  "Дані пацієнта": "Дані пацієнта",
  Основне: "Основне",
  "Контактні та паспортні дані": "Контактні та паспортні дані",
  Інше: "Інше",
  "Зробити/завантажити фото": "Зробити/завантажити фото",
  "Номер картки": "Номер картки",
  "Помістити в архів": "Помістити в архів",
  Паспорт: "Паспорт",
  Серія: "Серія",
  Номер: "Номер",
  "Дата видачі": "Дата видачі",
  ІПН: "ІПН",
  Робота: "Робота",
  "Місце роботи": "Місце роботи",
  Посада: "Посада",
  "Робочий телефон": "Робочий телефон",
  Опікун: "Опікун",
  "Додати опікуна": "Додати опікуна",
  Батько: "Батько",
  Прізвище: "Прізвище",
  "Ім'я": "Ім'я",
  "Ім’я": "Ім’я",
  "По батькові": "По батькові",
  Мати: "Мати",
  "Провідний лікар": "Провідний лікар",
  "Група населенння": "Група населенння",
  Підрозділ: "Підрозділ",
  Гінекологія: "Гінекологія",
  "Диспансерний облік": "Диспансерний облік",
  "Звідки дізналися про клініку": "Звідки дізналися про клініку",
  Група: "Група",
  "Перегляд запису": "Перегляд запису",
  "Закрити зміну": "Закрити зміну",
  "Перейти в картку пацієнта": "Перейти в картку пацієнта",
  Сума: "Сума",
  Знижка: "Знижка",
  "Сума зі знижкою": "Сума зі знижкою",
  "Показати всі рахунки пацієнта": "Показати всі рахунки пацієнта",
  Баланс: "Баланс",
  "Створити рахунок": "Створити рахунок",
  "Переглянути рахунок": "Переглянути рахунок",
  "Рахунок пацієнта №": "Рахунок пацієнта №",
  "Всього незакритих процедур": "Всього незакритих процедур",
  "Всього зі знижками": "Всього зі знижками",
  "Всього без знижок": "Всього без знижок",
  "Додати послугу": "Додати послугу",
  Послуга: "Послуга",
  Додати: "Додати",
  "Сума до оплати": "Сума до оплати",
  грн: "грн",
  Готівка: "Готівка",
  Картка: "Картка",
  "Р/р": "Р/р",
  Розділити: "Розділити",
  "Потрібен чек?": "Потрібен чек?",
  "Виберіть ФОП-а": "Виберіть ФОП-а",
  "Сума готівкою": "Сума готівкою",
  "Сума карткою": "Сума карткою",
  Оплатити: "Оплатити",
  "Виберіть касира(-ів)": "Виберіть касира(-ів)",
  "зміну відкрито": "зміну відкрито",
  Відкрити: "Відкрити",
  Закрити: "Закрити",
  Зника: "Зника",
  СУМА: "СУМА",
  Касири: "Касири",
  відкрито: "відкрито",
  Новий: "Новий",
  Рахунок: "Рахунок",
  "Усі картки": "Усі картки",
  Фільтр: "Фільтр",
  "Введіть для пошуку за станом, кабінетом, лікарем або пацієнтом.":
    "Введіть для пошуку за станом, кабінетом, лікарем або пацієнтом.",
  "Не попереджений": "Не попереджений",
  "№ Картки": "№ Картки",
  Вік: "Вік",
  Страхування: "Страхування",
  "Зах…": "Зах…",
  "Дата створення": "Дата створення",
  Повернення: "Повернення",
  Друк: "Друк",
  Диспансеризація: "Диспансеризація",
  "Майстер запису пацієнта на прийом": "Майстер запису пацієнта на прийом",
  "Історія відвідувань": "Історія відвідувань",
  "Зведений звіт": "Зведений звіт",
  "X-Звіт": "X-Звіт",
  "Винести готівку": "Винести готівку",
  "Внести готівку": "Внести готівку",
  "Від...": "Від...",
  "...до": "...до",
  Показати: "Показати",
  Пошук: "Пошук",
  "Тип оплати": "Тип оплати",
  розділено: "розділено",
  ФОП: "ФОП",
  Касир: "Касир",
  Коментарі: "Коментарі",
  від: "від",
  "Завантажити договір": "Завантажити договір",
  "Завантажити акт": "Завантажити акт",
  Роздрукувати: "Роздрукувати",
  Повернути: "Повернути",
  "Всього прихід": "Всього прихід",
  "надано знижок": "надано знижок",
  фіскалізовано: "фіскалізовано",
  "Виберіть касира": "Виберіть касира",
  Запросити: "Запросити",
  Винести: "Винести",
  "Вкажіть дані": "Вкажіть дані",
  Сформувати: "Сформувати",
  "Основна послуга": "Основна послуга",
  "Оплачені послуги": "Оплачені послуги",
  ГРН: "ГРН",
  "Нал.": "Нал.",
  "Додати новий запис": "Додати новий запис",
  ТІН: "ТІН",
  "Дата початку роботи": "Дата початку роботи",
  "Дата завершення роботи": "Дата завершення роботи",
  "Мобільний номер": "Мобільний номер",
  "Домашній номер": "Домашній номер",
  "Домашня адреса": "Домашня адреса",
  Показано: "Показано",
  Дії: "Дії",
  Діагнози: "Діагнози",
  Щоденник: "Щоденник",
  Історія: "Історія",
  "Ще немає записів": "Ще немає записів",
  Процедури: "Процедури",
  Діагноз: "Діагноз",
  "Редагувати діагноз": "Редагувати діагноз",
  "Видалити діагноз": "Видалити діагноз",
  "Додати діагноз": "Додати діагноз",
  Скарги: "Скарги",
  Призначення: "Призначення",
  "Додати запис": "Додати запис",
  "Поточна дата": "Поточна дата",
  "Скарги хворого, об’єктивних даних, діагноз, перебіг хвороби":
    "Скарги хворого, об’єктивних даних, діагноз, перебіг хвороби",
  "Помилка отримання діагнозів": "Помилка отримання діагнозів",
  "Запис додано": "Запис додано",
  "Помилка додавання запису": "Помилка додавання запису",
  "Помилка додавання запису в щоденник": "Помилка додавання запису в щоденник",
  "Видалити запис?": "Видалити запис?",
  "Помилка видалення": "Помилка видалення",
  "Прайс-лист": "Прайс-лист",
  "Помилка додавання позиції": "Помилка додавання позиції",
  "Курс лікування": "Курс лікування",
  "Загальна сума": "Загальна сума",
  Виконав: "Виконав",
  "нічого не знайдено": "нічого не знайдено",
  "Не вдалось позначити як виконана": "Не вдалось позначити як виконана",
  Тестування: "Тестування",
  "Присутні результати тестування": "Присутні результати тестування",
  "Результати тестування відсутні": "Результати тестування відсутні",
  Видалити: "Видалити",
  переглянути: "переглянути",
  Протоколи: "Протоколи",
  Назва: "Назва",
  "Дата оновлення": "Дата оновлення",
  Категорія: "Категорія",
  "Файловий менеджер": "Файловий менеджер",
  "Виберіть пацієнта": "Виберіть пацієнта",
  "Показати наступні 10 пацієнтів": "Показати наступні 10 пацієнтів",
  "Кольпоскопічні дослідження": "Кольпоскопічні дослідження",
  "Дата обстеження": "Дата обстеження",
  "Результат обстеження": "Результат обстеження",
  Фотографії: "Фотографії",
  Загрузити: "Загрузити",
  "Дата огляду": "Дата огляду",
  "Кольпоскопічне дослідження": "Кольпоскопічне дослідження",
  "УЗ дослідження": "УЗ дослідження",
  УЗД: "УЗД",
  "Вибрати файли з апарату": "Вибрати файли з апарату",
  "застосувати шаблон": "застосувати шаблон",
  Висновок: "Висновок",
  "вибрати всі": "вибрати всі",
  "зняти всі": "зняти всі",
  Друкувати: "Друкувати",
  Очистити: "Очистити",
  "Показати ще": "Показати ще",
  "УЗ обстеження": "УЗ обстеження",
  "Додати новий кабінет": "Додати новий кабінет",
  Колір: "Колір",
  "Порядок виводу": "Порядок виводу",
  "Загальні відомості про клініку": "Загальні відомості про клініку",
  "Логотип клінки": "Логотип клінки",
  Замінити: "Замінити",
  "Назва клінкіки": "Назва клінкіки",
  "Адміністратора створено": "Адміністратора створено",
  Роль: "Роль",
  "Укажіть ФОП-ів": "Укажіть ФОП-ів",
  "Прив'язати до": "Прив'язати до",
  "Також доступ до": "Також доступ до",
  "Додати нову юридичну особу": "Додати нову юридичну особу",
  активна: "активна",
  "Юридичну особу створено": "Юридичну особу створено",
  "Помилка створення": "Помилка створення",
  "Додати підрозділ": "Додати підрозділ",
  "Додати протокол": "Додати протокол",
  "Додати категорію": "Додати категорію",
  "Назва протоколу": "Назва протоколу",
  "Новий протокол": "Новий протокол",
  "Нова категорія": "Нова категорія",
  "Додавання нового працівника": "Додавання нового працівника",
  Місто: "Місто",
  eHealth: "eHealth",
  "Ідентифікацій код": "Ідентифікацій код",
  "Серія і номер паспорта": "Серія і номер паспорта",
  Тип: "Тип",
  Спеціальність: "Спеціальність",
  Заклад: "Заклад",
  "Номер диплому": "Номер диплому",
  Спеціалізація: "Спеціалізація",
  Кваліфікація: "Кваліфікація",
  Україна: "Україна",
  "Дійсний до": "Дійсний до",
  Бакалавр: "Бакалавр",
  Спеціаліст: "Спеціаліст",
  "Молодший спеціаліст": "Молодший спеціаліст",
  Магістр: "Магістр",
  "Поточні події": "Поточні події",
  Присвоєння: "Присвоєння",
  Підтвердження: "Підтвердження",
  Імʼя: "Імʼя",
  Email: "Email",
  "Для екстреного зв'язку": "Для екстреного зв'язку",
  "Ким виданий": "Ким виданий",
  "Номер паспорта": "Номер паспорта",
  "Кодове слово": "Кодове слово",
  "Реєстрація нового медичного закладу": "Реєстрація нового медичного закладу",
  ID: "ID",
  "Назва клініки": "Назва клініки",
  ЄДРПОУ: "ЄДРПОУ",
  "Реєстрація нового закладу": "Реєстрація нового закладу",
  "Ким видана": "Ким видана",
  Жінка: "Жінка",
  Чоловік: "Чоловік",
  "Дійсна до": "Дійсна до",
  "Дата акридетації": "Дата акридетації",
  Опис: "Опис",
  Вулиця: "Вулиця",
  Будинок: "Будинок",
  Сайт: "Сайт",
  "Кінцевий власник": "Кінцевий власник",
  Власник: "Власник",
  Акредетація: "Акредетація",
  Ліцензія: "Ліцензія",
  "Термін дії акридетації": "Термін дії акридетації",
  Підписати: "Підписати",
  "Підпис даних": "Підпис даних",
  КНЕДП: "КНЕДП",
  "Файл підпису": "Файл підпису",
  "Пошук пацієнта": "Пошук пацієнта",
  "Завантаження документів": "Завантаження документів",
  "Підтвердження пацієнта": "Підтвердження пацієнта",
  "Надіслати ще раз": "Надіслати ще раз",
  Надіслати: "Надіслати",
  Код: "Код",
  "Завершення реєстрації": "Завершення реєстрації",
  "Медичні висновки": "Медичні висновки",
  Звернення: "Звернення",
  "Нове звернення": "Нове звернення",
  "Додавання нового звернення": "Додавання нового звернення.",
  Причина: "Причина",
  Скарга: "Скарга",
  Клас: "Клас",
  "План лікування": "План лікування",
  "Початок лікування": "Початок лікування",
  "Кінець лікування": "Кінець лікування",
  "Короткий опис": "Короткий опис",
  "Додавання нового плану лікування": "Додавання нового плану лікування",
  Виберіть: "Виберіть",
  "Закріплений лікар": "Закріплений лікар",
  "Минулий місяць": "Минулий місяць",
  "Поточний місяць": "Поточний місяць",
  "Ввести дату": "Ввести дату",
  "Стани СМС": "Стани СМС",
  "грн.": "грн.",
  Повідомлення: "Повідомлення",
  Статус: "Статус",
  "Дата відправлення": "Дата відправлення",
  "Час доставки": "Час доставки",
  Назад: "Назад",
  Далі: "Далі",
  надіслано: "надіслано",
  "в черзі": "в черзі",
  "не доставлено": "не доставлено",
  "Новий епізод": "Новий епізод",
  "Додавання нового діагнозу": "Додавання нового діагнозу",
  всього: "всього",
  Епізоди: "Епізоди",
  "Умова надання": "Умова надання",
  "Додавання нового сервісу": "Додавання нового сервісу",
  "Спеціалізації лікаря": "Спеціалізації лікаря",
  "Додати спеціалізацію лікаря": "Додати спеціалізацію лікаря",
  "Додавання запису в щоденник": "Додавання запису в щоденник",
  Переглянути: "Переглянути",
  "Дата прийому": "Дата прийому",
  "Час початку": "Час початку",
  "Час завершення": "Час завершення",
  "Надана послуга": "Надана послуга",
  "Додавання нового рецепту": "Додавання нового рецепту",
  "Новий рецепт": "Новий рецепт",
  "Електронні рецепти": "Електронні рецепти",
  "Додавання нового МНП": "Додавання нового МНП",
  "Координата Lng": "Координата Lng",
  "Координата Lat": "Координата Lat",
  "Назва вулиці": "Назва вулиці",
  "Тип вулиці": "Тип вулиці",
  "Назва населеного пункту": "Назва населеного пункту",
  "Тип населеного пункту": "Тип населеного пункту",
  "Деактивувати МНП?": "Деактивувати МНП?",
  "Деактивувати сервіс?": "Деактивувати сервіс?",
  "Помилка зміни запису": "Помилка зміни запису",
  "Активувати МНП?": "Активувати МНП?",
  "Активувати сервіс?": "Активувати сервіс?",
  "Редагування сервісу": "Редагування сервісу",
  "Вибрати всі": "Вибрати всі",
  "Оплачено": "Оплачено",
  "Готівкою": "Готівкою",
  "Карткою": "Карткою",
  "Ітого": "Ітого",
  "Перегляд аналізу": "Перегляд аналізу",
  "Додати аналізи": "Додати аналізи",
  "Так, все вірно": "Так, все вірно",
  "карткою": "карткою",
  "готівкою": "готівкою",
  "p/p": "p/p",
  "всі": "всі",
  "термінал": "термінал",
  "Р/p": "Р/p",
  "Рах. №": "Рах. №",
  "Номер ліцензії": "Номер ліцензії",
  "Юридична особа": "Юридична особа",
  "Назва закладу": "Назва закладу",
  "Юр. особа для акту": "Юр. особа для акту",
  "ПІБ для акту": "ПІБ для акту",
  "Юр. особа для договору": "Юр. особа для договору",
  "Реквізити оплати для договору": "Реквізити оплати для договору",
  "Merchant ID (для роботи терміналу)": "Merchant ID (для роботи терміналу)",
  "Використовувати по замовчуванню": "Використовувати по замовчуванню",
  "": "",
};
