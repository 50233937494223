<template>
  <div>
    <vue-easy-lightbox
        scrollDisabled
        escDisabled
        moveDisabled
        :visible="visibleFancy"
        :imgs="imageSlider"
        :index="indexZoom"
        @hide="handleHide"
    ></vue-easy-lightbox>
    <div class="caption">{{$t('Дата обстеження')}}</div>
    <form @submit.prevent="save">
      <div class="form-examination">
        <div class="left-qr">
          <div class="testing-box">
            <div class="caption-light">{{$t('УЗ дослідження')}}</div>
            <div class="line-btn-right">
              <flat-pickr
                class="custom-input"
                v-model="date"
                :config="config"
              ></flat-pickr>
              <div class="tooltip-wrapper">
                <a href="javascript:;" id="uppyModalOpener" class="full-flex-center btn-border-grey"><i class="ico-45"></i></a>
                <div class="tooltip">{{$t('Загрузити')}}</div>
              </div>
              <div class="tooltip-wrapper">
                <a href="javascript:;" @click="moreSearchPatient.offset = 0;searchPatients()" class="full-flex-center btn-border-grey">{{$t('УЗД')}}</a>
                <div class="tooltip">{{$t('Вибрати файли з апарату')}}</div>
              </div>
            </div>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Лікар')}}</div>
            <el-select v-model="doctor_fk" filterable class="full-width">
              <el-option
                  v-for="item in doctors"
                  :key="item.id"
                  :label="item.fullname"
                  :value="item.id"
              />
            </el-select>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Результат обстеження')}} <a href="#" @click.prevent="$modal.show('PriceList')">({{$t('застосувати шаблон')}})</a></div>
            <vue-editor v-model="conclusion"/>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Висновок')}}</div>
            <vue-editor v-model="result"/>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Примітка')}}</div>
            <textarea
              class="mailing-textarea"
              v-model="note"
            ></textarea>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Фотографії')}} (<span class="select-all" @click="selectAllImage(true)">{{$t('вибрати всі')}}</span>/<span class="deselect-all" @click="selectAllImage(false)">{{$t('зняти всі')}}</span>)</div>
            <div class="upload-field-wr" style="margin-top: 30px">
              <div
                class="box-img"
                v-for="(file, index) in files"
                :key="index"
              >
                <img :src="file.source" alt="img" class="object-fit" @click="showFancy(index)">
                <a href="#" @click.prevent="deleteImage(index)" class="delete-btn-black full-flex-center"><i class="ico-17"></i></a>
                <label class="printChecked">
                  <input type="checkbox" @click="toggleImagePrint" :checked="printImageLink.indexOf(file.source) !== -1" :value="file.source"/> {{$t('Друкувати')}}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="right-qr">
          <div class="testing-box">
            <div class="caption-light">{{$t('Історія')}}</div>
            <div class="date-review">
              <div class="article">{{$t('Дата огляду')}}</div>
              <ul>
                <li v-for="dateHistory of history" :key="dateHistory.id">
                  <a href="#" @click.prevent="see(dateHistory.id)" class="one-date">{{ dateFormat(dateHistory.docdate) }}</a>
                  <a href="#" @click.prevent="del(dateHistory.id)" class="btn-table-change"><i class="ico-20"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="save-testing">
        <div class="wrapper-l-btn">
          <button @click.prevent="print" class="btn-white height-36"><i class="ico-24"></i>{{$t('Роздрукувати')}}</button>
        </div>
        <button @click.prevent="clear" class="btn-white height-36" style="margin-right: 10px">{{$t('Очистити')}}</button>
        <button class="btn-green standard-width small">{{$t('Зберегти')}}</button>
      </div>
    </form>
    <!--Файловий менеджер-->
    <el-dialog
        v-model="$store.state.modal.filemanager"
        width="720px"
        :title="$t('Файловий менеджер')"
        class="modal-custom"
    >
      <div class="body-modal">
        <div class="collectDicom">
          <div
            class="img"
            v-for="file in filesDicom"
            :key="file"
          >
            <input type="checkbox" v-model="selectedFiles" :value="file">
            <img
              :src="file"
              alt=""
              width="100"
            >
          </div>
        </div>
        <div class="moreFiles" v-show="more.show">
          <a href="#" @click.prevent="moreFiles">{{$t('Показати ще')}}</a>
        </div>
      </div>
      <div class="footer-modal text-right">
        <div class="btn-left-footer"></div>
        <div class="btn-right-footer">
          <button type="button" @click="pickFile()" class="btn-green">{{$t('Зберегти')}}</button>
          <button class="btn-red" @click.prevent="hideFilemanager()">{{$t('Скасувати')}}</button>
        </div>
      </div>
    </el-dialog>
    <!-- Список пацієнтів -->
    <el-dialog
        v-model="$store.state.modal.search_patient"
        width="720px"
        :title="$t('Виберіть пацієнта')"
        class="modal-custom"
    >
      <div class="body-modal">
        <table>
          <thead>
          <tr>
            <th>{{$t('Дата оновлення')}}</th>
            <th>ID</th>
            <th>{{$t('Ім\'я')}}</th>
            <th>{{$t('Дата народження')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="patient in listPatientInSearch" :key="patient.uuid" @click="getFiles(patient.uuid)">
            <td>{{patient.update}}</td>
            <td>{{patient.id}}</td>
            <td>{{patient.name}}</td>
            <td>{{patient.birthday}}</td>
          </tr>
          </tbody>
        </table>
        <div class="moreFiles" v-show="moreSearchPatient.show">
          <a href="#" @click.prevent="searchPatients()">{{$t('Показати наступні 10 пацієнтів')}}</a>
        </div>
      </div>
      <div class="footer-modal text-right">
        <div class="btn-left-footer"></div>
        <div class="btn-right-footer">
          <button type="button" @click="pickFile()" class="btn-green">{{$t('Зберегти')}}</button>
          <button class="btn-red" @click.prevent="hideFilemanager()">{{$t('Скасувати')}}</button>
        </div>
      </div>
    </el-dialog>
    <!-- Список протоколів -->
    <el-dialog
        v-model="$store.state.modal.PriceList"
        width="720px"
        :title="$t('Протоколи')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal">
          <div class="table-wrapper">
            <table class="body-bold">
              <thead>
              <tr>
                <th style="width: 100%">
                  <div class="caption">{{$t('Назва')}}</div>
                </th>
                <th style="width: 200px;">
                  <div class="caption">{{$t('Категорія')}}</div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="protocol in protocols"
                :key="protocol.id"
              >
                <td class="text-overflow-ellipsis">
                  <a href="#" @click.prevent="showCreate(protocol.id, protocol.raw)">{{ protocol.name }}</a>
                </td>
                <td style="width: 200px;">{{protocol.category}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </el-dialog>
    <!-- Заповнення протоколу -->
    <el-dialog
        v-model="$store.state.modal.createProtocol"
        width="720px"
        :title="protocol.name"
        class="modal-custom"
    >
      <form @submit.prevent="saveUserProtocol">
        <div class="body-modal">
          <div class="protocol-head">{{protocol.name}}</div>
          <div class="protocol-body">
            <!-- eslint-disable -->
            <div
              class="answer"
              v-for="row in protocol.rows"
              :key="row.id"
              v-if="row?.type !== 'comment'"
            >
              <!-- eslint-enable -->
              <span v-show="row.name !== ''">{{ row.name }}</span>
              <input
                type="text"
                v-model="row.value"
                v-if="row.type === 'text'"
              >
              <span style="font-style: italic" v-if="row.mask">{{row.mask}}</span>
              <el-date-picker
                v-if="row.type === 'date'"
                v-model="row.value"
                type="date"
                value-format="dd.MM.yyyy"
                placeholder="Виберіть дату"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
              <div
                v-if="row.type === 'select'"
                class="select-input"
              >
                <select
                  v-model="row.value"
                  @change="detectWrite(row)"
                >
                  <option
                    v-for="value in row.values"
                    :key="value.id"
                    :value="value.value"
                  >
                    {{value.value}}
                  </option>
                </select>
                <input
                  v-for="(write, index) in row.writes"
                  :key="index"
                  type="text"
                  v-model="write.value"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="submit" class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="$modal.hide('createProtocol')">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
    <div id="printMe" style="display: none">
      <div style="width: 100%;background-color: rgb(128,128,128);color: white;text-align: center;-webkit-print-color-adjust: exact;padding: 5px;font-size: 20px">{{$t('УЗ обстеження')}}</div>
      <table style="width: 100%">
        <tr>
          <td style="width: 25%;padding: 5px;">
            <img src="../../assets/images/blagodar.png" width="200">
          </td>
          <td style="width: 50%;text-align: center">
            <div style="font-size: 18px;font-weight:bold;">{{$t('Картка')}} №{{pid}}</div>
            <div style="font-size: 18px;font-style: italic;font-weight: bold">{{ patient.fullname }}</div>
          </td>
          <td style="width: 25%;font-size: 12px;">
            <div style="font-weight:bold;">{{settings.legalname}}</div>
            <div>{{settings.address}}</div>
            <div>Тел.: {{settings.telephonenumber}}</div>
            <div>{{settings.sitename}}</div>
            <div>{{settings.email}}</div>
          </td>
        </tr>
      </table>
      <div style="margin: 20px 0;font-weight:bold;font-size: 16px;">{{$t('Дата огляду')}}: {{ printData.date }}</div>
      <div v-html="printData.text" style="margin-block-start: 0em;margin-block-end: 0em"></div>
      <div v-show="printData.result !== ''">
        <strong>{{$t('Висновок')}}</strong>
        <div v-html="printData.result" style="margin-block-start: 0em;margin-block-end: 0em"></div>
      </div>
      <div v-show="printData.note !== ''">
        <strong>{{$t('Примітка')}}</strong>
        <div v-html="printData.note" style="margin-block-start: 0em;margin-block-end: 0em"></div>
      </div>
      <div style="margin: 20px 0;font-weight:bold;font-size: 16px;">{{$t('Лікар')}}: {{ printData.doctor }}</div>
      <div>
        <img :key="index" v-for="(image, index) in printData.images" :src="image" alt="" style="width: 45%;margin-right: 1%;margin-bottom: 5%" :data-image="image">
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Ukrainian } from 'flatpickr/dist/l10n/uk'
import { required } from '@vuelidate/validators'
import VueEasyLightbox from 'vue-easy-lightbox'
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Webcam from "@uppy/webcam";
import XHRUpload from "@uppy/xhr-upload";
import moment from "moment";
import Ukr from '@uppy/locales/lib/uk_UA';
import { VueEditor } from "vue3-editor";

export default {
  data () {
    return {
      date: moment().format('YYYY') + '-' + moment().format('MM') + '-' + moment().format('DD'),
      history: [],
      doctors: [],
      templates: [],
      config: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'd.m.Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Ukrainian
      },
      pid: this.id,
      doctor_fk: '',
      conclusion: '',
      note: '',
      files: [],
      filesDicom: [],
      selectedFiles: [],
      more: {
        show: false,
        offset: 0
      },
      printData: {
        date: new Date().toLocaleString(),
        text: '',
        doctor: '',
        images: []
      },
      editorConfig: {
        height: 400,
        contentsCss: 'https://dev.medicalstandard.com.ua/css/global.css'
      },
      result: '',
      indexZoom: null,
      imageSlider: [],
      listPatientInSearch: [],
      moreSearchPatient: {
        show: true,
        offset: 0
      },
      protocols: [],
      protocol: {
        name: '',
        id: 0,
        user: {
          fio: '',
          birthday: '',
          year: 0,
          id: 0
        },
        rows: []
      },
      pickerOptions: {
        firstDayOfWeek: 1
      },
      printImageLink: [],
      visibleFancy: false,
      settings: []
    }
  },
  components: {
    flatPickr,
    VueEasyLightbox,
    VueEditor
  },
  watch: {
    files: function () {
      this.selectedFiles = []
      this.printData.images = []
      //this.saveToStorage('files')
      this.imageSlider = []
      this.files.map(e => {
        if (e.source.indexOf('dicom') === -1) {
          e.source = process.env.APP_URL + '/rest/source/' + e.servername
        }
        this.imageSlider.push({
          src: e.source
        })
      })
    },
  },
  async created () {
    await this.$http.get('/nh-ultrasonography/all', {params: {id: this.id}})
      .then(result => {
        this.history = result.data.uzd
        this.protocols = result.data.protocols
        this.settings = result.data.settings
      })
    await this.$http.get('/doctor/all', {params: {id: this.id}})
      .then(result => {
        this.doctors = result.data.doctors
      })
    await this.$http.get('/nh-ultrasonography/dict', {params: {id: this.id}})
      .then(result => {
        this.templates = result.data
      })
  },
  mounted () {
    let self = this
    // eslint-disable-next-line
    const uppy = new Uppy({
      debug: true,
      autoProceed: false,
      // eslint-disable-next-line
      locale: Ukr,
      restrictions: {
        allowedFileTypes: ['image/*']
      }
    })
      // eslint-disable-next-line
      .use(Dashboard, { trigger: '#uppyModalOpener' })
      // eslint-disable-next-line
      .use(Webcam, {
        // eslint-disable-next-line
        target: Dashboard,
        mirror: false,
        locale: {
          strings: {
            dropPasteImport: 'Перекиньте сюди файли, вставте, %{browse} або імпортуйте з',
            dropPaste: 'Drop files here, paste or %{browse}'
          }
        }
      })
      // eslint-disable-next-line
      .use(XHRUpload, { endpoint: process.env.APP_URL + '/rest/v1/files/upload-patient?pid=' + self.pid, limit: 1 })

    uppy.on('upload-success', (file, response) => {
      if (self.files === null) {
        self.files = [{name: file.name, source: file.preview, servername: response.body.data.servername}]
      } else {
        self.files.push({name: file.name, source: file.preview, servername: response.body.data.servername})
      }
    })
    // Перевірка наявності збережених данних
    // if (localStorage.getItem('dataUZD') !== null) {
    //   const rows = JSON.parse(localStorage.getItem('dataUZD'))
    //   if (rows.id === this.id) {
    //     Object.keys(rows).map((key) => {
    //       if (key !== 'id') {
    //         this[key] = rows[key]
    //       }
    //     })
    //   }
    // }
    //
    // this.saveToStorage('id')
  },
  validations: {
    doctor_fk: {
      required
    },
    conclusion: {
      required
    }
  },
  methods: {
    dateFormat (date) {
      return moment(date).format("DD.MM.YYYY")
    },
    handleHide() {
      this.visibleFancy = false
    },
    showFancy(index) {
      this.setIndex(index)
      this.visibleFancy = true
    },
    /**
     * @brief Додавання
     */
    save () {
      this.$http.post('/nh-ultrasonography/save', {
        doctor_fk: this.doctor_fk,
        conclusion: this.conclusion,
        patient_fk: this.pid,
        id: this.cid,
        docdate: this.date,
        note: this.note,
        files: this.files,
        result: this.result
      })
          .then(result => {
            this.history = result.data.data
            // this.clear()
          })
          .catch(error => {
            this.$root.alerts('error', error.response.data)
          })
    },
    /**
     * @brief Очистка полів
     */
    clear () {
      this.doctor_fk = ''
      this.conclusion = ''
      this.cid = 0
      this.template = ''
      this.note = ''
      this.files = []
      this.selectedFiles = []
      this.more = {
        show: false,
        offset: 0
      }
      this.result = ''
      this.date = moment().format('YYYY') + '-' + moment().format('MM') + '-' + moment().format('DD')
    },
    /**
     * @brief Перегляд
     * @param id
     */
    see (id) {
      this.$http.get('/nh-ultrasonography/see', {
        params: {
          id: id
        }
      })
        .then(result => {
          if (result.data.files!== '') {
            this.files = JSON.parse(result.data.files)
          }
          this.date = result.data.docdate
          this.doctor_fk = String(result.data.doctor_fk?.id)
          this.conclusion = result.data.conclusion
          this.cid = result.data.id
          this.note = result.data.note
          this.result = result.data.result
        })
    },
    /**
     * @brief Видалення запису
     * @param id
     */
    del (id) {
      if (confirm('Видалити запис?')) {
        this.$http.delete('/nh-ultrasonography/delete', {
          params: {
            id: id,
            pid: this.pid
          }
        })
          .then(result => {
            this.history = result.data.data
          })
      }
    },
    getFiles (uuid) {
      this.$modal.hide('search_patient')
      this.$modal.show('filemanager')
      this.$http.get('/dicom/instance', {
        params: {
          uuid,
          clinic_id: 2
        }
      })
        .then(result => {
          this.filesDicom = result.data.files
          this.more.show = false
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    },
    searchPatients () {
      // this.$modal.show('filemanager')
      this.$http.get('/dicom/patients', {
        params: {
          offset: this.moreSearchPatient.offset,
          clinic_id: 2,
        }
      })
        .then(result => {
          this.listPatientInSearch = result.data
          this.$modal.show('search_patient')
          if (result.data.length === 10) {
            this.moreSearchPatient.offset += 10
            this.moreSearchPatient.show = true
          } else {
            this.moreSearchPatient.show = false
          }
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    },
    pickFile () {
      this.selectedFiles.map(e => {
        this.files.push({name: e, source: e, servername: e})
      })
      this.hideFilemanager()
    },
    moreFiles () {
      const newOffset = this.more.offset + 12
      this.$http.get('/dicom/instance', {
        params: {
          offset: newOffset,
          clinic_id: 2
        }
      })
        .then(result => {
          this.more.offset = newOffset
          for (let i = 0; i < 12; i++) {
            this.filesDicom.push(result.data.files[i])
          }
          this.more.show = result.data.more
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    },
    hideFilemanager () {
      this.$modal.hide('filemanager')
    },
    deleteImage (index) {
      this.files.splice(index, 1)
    },
    print () {
      this.printData.date = moment(this.date).format('DD.MM.YYYY') + ' ' + moment().format('HH:mm')
      this.printData.text = this.conclusion
      this.printData.result = this.result
      this.printData.note = this.note
      this.printData.doctor = this.doctor_fk.shortname
      setTimeout(() => {
        this.$htmlToPaper('printMe')
      }, 500)
      // setTimeout(() => {
      //   this.printData.images = []
      // }, 1000)
    },
    selectAllImage (action) {
      if (action) {
        let self = this
        this.printData.images = []
        this.files.map(async file => {
          await this.toDataURL(file.source, function (result) {
            self.printData.images.push(result)
            self.printImageLink.push(file.source)
          })
        })
      } else {
        this.printData.images = []
        this.printImageLink = []
      }
    },
    toggleImagePrint (e) {
      if (e.target.checked) {
        let self = this
        this.toDataURL(e.target.value, function (result) {
          self.printData.images.push(result)
          self.printImageLink.push(e.target.value)
        })
      } else {
        const indx = this.printImageLink.indexOf(e.target.value)
        this.printData.images.splice(indx, 1)
      }
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    setIndex (index) {
      this.indexZoom = index
    },
    saveToStorage (key) {
      if (localStorage.getItem('dataUZD') === null) {
        let dataUZD = {}
        dataUZD[key] = this[key]
        localStorage.setItem('dataUZD', JSON.stringify(dataUZD))
      } else {
        let dataUZD = JSON.parse(localStorage.getItem('dataUZD'))
        dataUZD[key] = this[key]
        localStorage.setItem('dataUZD', JSON.stringify(dataUZD))
      }
    },
    showCreate (id, raw) {
      this.$modal.hide('PriceList')
      if (raw === null) {
        this.$http.get('/template/property', {
          params: {
            id,
            user_id: this.pid
          }
        }).then(result => {
          this.protocol.name = result.data.template.name
          this.protocol.id = result.data.template.id
          this.protocol.rows = result.data.template.rows
          this.protocol.user.fio = result.data.user.fullname
          this.protocol.user.birthday = result.data.user.birthday
          this.protocol.user.year = result.data.user.year
          this.protocol.user.id = this.pid
          this.$modal.show('createProtocol')
        }).catch(error => {
          console.log('[Error]', error)
        })
      } else {
        console.log('[Log]', raw)
        this.conclusion = raw;
      }
    },
    saveUserProtocol () {
      let text = []
      this.protocol.rows.map((row, index) => {
        if (row.type !== 'photo') {
          let line = '';
          if (row.type !== 'comment') {
            line = row.name + ' ' + row.value + (row.mask !== null ? ' ' + row.mask : '')
          } else {
            line = row.name
          }
          if (row.has_break) {
            line = '<p>' + line + '</p>'
          } else {
            if (index < this.protocol.rows.length - 1) {
              line += ', '
            }
          }
          text.push(line)
        }
      })
      this.conclusion = text.join('')
      this.$modal.hide('createProtocol')
      this.protocol = {
        name: '',
        id: 0,
        user: {
          fio: '',
          birthday: '',
          year: 0,
          id: 0
        },
        rows: []
      }
    }

  },
  props: {
    id: {
      default: 0
    },
    patient: {
      default: {}
    }
  }
}
</script>
