<template>
  <div class="page-canvas">
    <TopDirector />
    <div class="filter-top">
      <a href="#" class="refresh">
        <i class="ico-01"></i>
      </a>
      <div class="date">
        <label>
          <input type="radio" value="last_month" v-model="selectedDate">
          <span>Минулий місяць</span>
        </label>
        <label>
          <input type="radio" value="current_month" v-model="selectedDate">
          <span>Поточний місяць</span>
        </label>
        <label>
          <input type="radio" value="select_date" v-model="selectedDate">
          <span>Ввести дату</span>
        </label>
      </div>
      <el-date-picker
        v-model="filterDate"
        type="daterange"
        range-separator="|"
        start-placeholder="З"
        end-placeholder="по"
        format="DD.MM.YYYY"
        @change="filterTable"
        value-format="DD.MM.YYYY"
        :disabled="disablePicker"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
    <div class="filter-hendler" style="padding: 15px 25px;">
      <div class="filterItem">
        <label>Групувати по:</label>
        <div class="checkbox-with-label">
          <label><input type="radio" value="doctor" v-model="groupBy"> лікарях</label>
          <label><input type="radio" value="procedure" v-model="groupBy"> процедурах</label>
          <label><input type="radio" value="patient" v-model="groupBy"> пацієнтах</label>
        </div>
      </div>
      <div class="filterItem">
        <button class="btn-green" type="button" @click.prevent="toFile">Експортувати в XLSX</button>
      </div>
    </div>
    <div class="table-content">
      <table>
        <thead>
        <tr>
          <th v-if="groupBy === 'doctor'">
            <a href="#">Лікар</a>
            <input type="text" v-model="filter.doctor" @input="filterTable">
          </th>
          <th v-if="groupBy === 'procedure'">
            <a href="#">Назва процедури</a>
            <input type="text" v-model="filter.name" @input="filterTable">
          </th>
          <th v-if="groupBy === 'patient'">
            <a href="#">Пацієнт</a>
            <input type="text" v-model="filter.patient" @input="filterTable">
          </th>
          <th>
            <a href="#">Кількість процедур</a>
          </th>
          <th v-if="groupBy === 'procedure'">
            <a href="#">Сума УОП</a>
          </th>
          <th v-if="groupBy === 'procedure'">
            <a href="#">Ціна</a>
          </th>
          <th>
            <a href="#">Сума</a>
          </th>
          <th v-if="groupBy === 'procedure'">
            <a href="#">Розділ</a>
            <input type="text" v-model="filter.parent" @input="filterTable">
          </th>
          <th v-if="groupBy === 'procedure'">
            <a href="#">Пацієнти</a>
            <select class="default-select" @change="getData" v-model="filterNetwork.patient_id">
              <option :value="patient.id" v-for="patient in patients" :key="patient.id">{{patient.label}}</option>
            </select>
          </th>
          <th v-if="groupBy === 'procedure'">
            <a href="#">Лікарі</a>
            <select class="default-select" @change="getData" v-model="filterNetwork.doctor_id">
              <option :value="doc.id" v-for="doc in doctors" :key="doc.id">{{doc.label}}</option>
            </select>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="list in listSearch"
          :key="list.id"
        >
          <td v-if="groupBy === 'doctor'">{{list.doctor}}</td>
          <td v-if="groupBy === 'procedure'">{{list.name}}</td>
          <td v-if="groupBy === 'patient'">{{list.patient}}</td>
          <td>{{list.count}}</td>
          <td v-if="groupBy === 'procedure'">{{list.uop}}</td>
          <td v-if="groupBy === 'procedure'">{{list.price}}</td>
          <td>{{list.total}}</td>
          <td v-if="groupBy === 'procedure'">{{list.parent}}</td>
          <td v-if="groupBy === 'procedure'">{{list.patient}}</td>
          <td v-if="groupBy === 'procedure'">{{list.doctor}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="bottom-status-line">
      <ul>
        <li>всього: <span>{{ total }}</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index-Page',
  data () {
    return {
      filterDate: [this.dateHuman('DD.MM.YYYY', 'first_day'), this.dateHuman('DD.MM.YYYY', 'last_day')],
      disablePicker: true,
      selectedDate: 'current_month',
      url: 'report',
      lists: [],
      total: 0,
      filter: {
        doctor: '',
        name: '',
        patient: '',
        parent: '',
      },
      filterNetwork: {
        doctor_id: 0,
        patient_id: 0
      },
      listSearch: [],
      pickerOptions: {
        firstDayOfWeek: 1
      },
      groupBy: 'procedure',
      doctors: [],
      patients: []
    }
  },
  created () {
    this.getData(1)
  },
  watch: {
    selectedDate (newValue) {
      this.disablePicker = newValue !== 'select_date'
      if (newValue === 'last_month') {
        this.filterDate = [this.dateHuman('DD.MM.YYYY', 'first_day', this.dateHuman('YYYY-MM-DD', 'prev_month')), this.dateHuman('DD.MM.YYYY', 'last_day', this.dateHuman('YYYY-MM-DD', 'prev_month'))]
      }
    },
    filterDate () {
      this.getData()
    },
    groupBy () {
      this.getData()
    },
    listSearch () {
      this.total = 0
      Object.keys(this.listSearch).map(e => {
        const item = this.listSearch[e]
        this.total += Number(item.total)
      })
      this.total = this.total.toFixed(2)
    }
  },
  methods: {
    getData () {
      this.$http.get(this.url + '/index', {
        params: {
          start: this.filterDate[0],
          end: this.filterDate[1],
          group: this.groupBy,
          doctor_id: this.filterNetwork.doctor_id,
          patient_id: this.filterNetwork.patient_id
        }
      })
        .then(result => {
          this.lists = result.data.items
          this.listSearch = result.data.items
          this.total = result.data.total
          this.doctors = result.data.doctors
          this.patients = result.data.patients
        })
    },
    filterTable () {
      this.listSearch = []
      const filterRows = []
      Object.keys(this.filter).filter(e => {
        if (this.filter[e] !== '') {
          filterRows.push(e)
        }
      })
      Object.keys(this.lists).map(e => {
        const item = this.lists[e]
        let allow = true
        filterRows.map(e => {
          if (item[e].toLowerCase().indexOf(this.filter[e].toLowerCase()) === -1) {
            allow = false
          }
        })
        if (allow) {
          this.listSearch.push(item)
        }
      })
    },
    dateHuman (format = 'DD.MM.YYYY', action = 'current', date = false) {
      let d = new Date()
      if (date) {
        d = new Date(date)
      }
      let parts = this.dateHumanParts(d)
      let lastDay
      switch (action) {
        case 'first_day':
          return this.dateHumanFormat(format, new Date(parts.year, parts.month, 1))
        case 'last_day':
          lastDay = new Date(parts.year, parts.month, 0).getDate()
          return this.dateHumanFormat(format, new Date(parts.year, parts.month, lastDay))
        case 'prev_month':
          return this.dateHumanFormat(format, new Date(parts.year, parts.month - 1, 1))
      }
      // Застосовуємо формат
      return this.dateHumanFormat(format, d)
    },
    dateHumanFormat (format, date) {
      let day = date.getDate()
      let month = date.getMonth()
      month = Number(month) + 1
      let year = date.getFullYear()
      if (String(day).length === 1) {
        day = '0' + day
      }
      if (String(month).length === 1) {
        month = '0' + month
      }
      switch (format) {
        case 'YYYY-MM-DD':
          return year + '-' + month + '-' + day
        default:
          return day + '.' + month + '.' + year
      }
    },
    dateHumanParts (date) {
      let day = date.getDate()
      let month = date.getMonth()
      let year = date.getFullYear()
      return {
        day,
        month,
        year
      }
    },
    go (page) {
      this.getData(page)
    },
    next () {
      this.getData(Number(this.pagination['x-pagination-current-page']) + 1)
    },
    prev () {
      this.getData(Number(this.pagination['x-pagination-current-page']) - 1)
    },
    getStatus (status) {
      if (status === null) {
        return '<span class="status-new">В черзі</span>'
      }
      if (status === 0) {
        return '<span class="status-success">Доставлено</span>'
      }
      return '<span class="status-error">Помилка</span>'
    },
    toFile () {
      this.$http.post('/report/export', {
        items: this.listSearch,
        group: this.groupBy,
        dateRange: this.filterDate
      })
      .then(result => {
        location.href = result.data
      }).catch(error => {
        console.log('[Error]', error.message)
      })
    }
  }
}
</script>
