<template>
  <div>
    <Navbar></Navbar>
    <Header1></Header1>
    <div class="content">
      <div class="box-admin">
        <form action="">
          <div class="line-admin-fl">
            <div class="name">Оберіть період відбору</div>
            <div class="box-select">
              <div class="input-wr line-btn-right">
                <VueDatePicker :value="date4" :locale="locale"/>
              </div>
            </div>
          </div>
          <div class="line-admin-fl">
            <div class="name"></div>
            <div class="box-select">
              <button class="btn-green small">Показати</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsDirector',
  data () {
    return {
      date4: new Date([2019, 12, 25]),
      locale,
      value3: null,
      list3: [
        {
          supportStatus: 'Ціна та повний доступ',
          id: 1
        },
        {
          supportStatus: 'Ціна',
          id: 2
        },
        {
          supportStatus: 'Повний доступ',
          id: 3
        }
      ],
      listFinded3: [],
      value4: null,
      list4: [
        {
          supportStatus: 'Без прив’язки до кабінету',
          id: 1
        },
        {
          supportStatus: 'Прив’язка до кабінету',
          id: 2
        }
      ],
      listFinded4: []
    }
  },
  created () {
    this.listFinded3 = this.list3
    this.listFinded4 = this.list4
  }
}
</script>

<style scoped>

</style>
