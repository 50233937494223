<template>
  <div class="authorization-page">
    <div class="form-r">
      <div class="caption">{{$t('Вхід')}}</div>
      <div class="under-caption">{{$t('Будь ласка, авторизуйтеся')}}</div>
      <form @submit.prevent="login" class="form-login">
        <div class="one-input" :class="{'active' : loginLogin!==''}">
          <input type="text" v-model="loginLogin">
          <span>{{$t('Логін')}}</span>
        </div>
        <div class="one-input" :class="{'active' : passwordLogin!==''}">
          <input type="password" v-model="passwordLogin">
          <span>{{$t('Пароль')}}</span>
        </div>
        <button class="btn-dark">{{$t('Авторизуватися')}}</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthorizationPage',
  data() {
    return {
      passwordLogin: '',
      loginLogin: '',
      ip: ''
    }
  },
  created() {
    fetch('https://api.ipify.org?format=json')
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.ip = data.ip
        });
  },
  methods: {
    login() {
      const data = {
        login: this.loginLogin,
        password: this.passwordLogin,
        clinic_id: process.env.VUE_APP_CLINIC_ID,
        ip: this.ip
      }
      this.$store.dispatch('login', data)
          .then((user, token) => {
            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + user.data.api_token
            this.user = user.data
            if (user.data.role === 1 || user.data.role === 3 || user.data.role === 4) {
              this.$router.push('/manager')
            } else if (user.data.role === 7) {
              this.$router.push('/manager/kassa')
            } else if (user.data.role === 8) {
              this.$router.push('/patients')
            }
          })
          .catch(error => {
            console.log('[Error]', error)
          })
    }
  }
}
</script>

<style scoped>

</style>
