<template>
  <div class="page-canvas">
    <TopDirector />
    <div class="filter-top">
      <a href="#" class="refresh">
        <i class="ico-01"></i>
      </a>

      <div class="date">
        <label>
          <input type="radio" value="last_month" v-model="selectedDate">
          <span>{{$t('Минулий місяць')}}</span>
        </label>
        <label>
          <input type="radio" value="current_month" v-model="selectedDate">
          <span>{{$t('Поточний місяць')}}</span>
        </label>
        <label>
          <input type="radio" value="select_date" v-model="selectedDate">
          <span>{{$t('Ввести дату')}}</span>
        </label>
      </div>
      <el-date-picker
        v-model="filterDate"
        type="daterange"
        range-separator="|"
        start-placeholder="З"
        end-placeholder="по"
        format="DD.MM.YYYY"
        @change="filterTable"
        value-format="DD.MM.YYYY"
        :disabled="disablePicker"
        :picker-options="pickerOptions"
      >
      </el-date-picker>

      <div class="info-sms">
        {{$t('Стани СМС')}}: з <span>{{ filterDate[0] }}</span> по <span>{{filterDate[1]}}</span>
      </div>
    </div>
    <div class="table-content">
      <div style="margin: 20px 10px;font-size: 20px;font-weight:bold;">Баланс: {{ balans }} грн.</div>
      <table>
        <thead>
          <tr>
            <th>
              <a href="#">ID</a>
              <input type="text">
            </th>
            <th>
              <a href="#">Мобільний номер</a>
              <input type="text">
            </th>
            <th class="row-text-message">
              <a href="#">Повідомлення</a>
              <input type="text">
            </th>
            <th>
              <a href="#">Дата створення</a>
              <input type="text">
            </th>
            <th>
              <a href="#">Статус</a>
              <input type="text">
            </th>
            <th>
              <a href="#">Дата відправлення</a>
              <input type="text">
            </th>
            <th>
              <a href="#">Час доставки</a>
              <input type="text">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="list in lists"
            :key="list.smsid"
          >
            <td>{{list.smsid}}</td>
            <td>{{list.phone}}</td>
            <td><div class="text-line">{{list.smsmessage}}</div></td>
            <td>{{list.createdate}}</td>
            <td class="outPadding" v-html="getStatus(list.responsecode)"></td>
            <td>{{list.smsid !== null ? list.smssendtime : ''}}</td>
            <td>{{list.smsid !== null ? list.deliverytime : ''}}</td>
          </tr>
        </tbody>
      </table>
      <ul
        class="pagination"
        v-show="pagination['x-pagination-page-count'] > 1"
      >
        <li
          v-show="Number(pagination['x-pagination-current-page']) > 1"
        >
          <a
            @click.prevent="prev"
            href="#"
          >Назад</a>
        </li>
        <li v-show="Number(pagination['x-pagination-current-page']) < Number(pagination['x-pagination-page-count'])"><a href="#" @click.prevent="next">Далі</a></li>
      </ul>
    </div>
    <div class="bottom-status-line">
      <ul>
        <li>надіслано: <span>{{total.send}}</span></li>
        <li>в черзі: <span>{{total.new}}</span></li>
        <li>не доставлено: <span>{{total.error}}</span></li>
        <li>всього: <span>{{ pagination['x-pagination-total-count'] }}</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmsHistory',
  data () {
    return {
      filterDate: [this.dateHuman('DD.MM.YYYY', 'first_day'), this.dateHuman('DD.MM.YYYY', 'last_day')],
      disablePicker: true,
      selectedDate: 'current_month',
      url: 'sms',
      lists: [],
      pagination: [],
      balans: '00.00',
      total: {
        new: 0,
        send: 0,
        error: 0
      },
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  created () {
    this.getData(1)
  },
  watch: {
    selectedDate (newValue) {
      this.disablePicker = newValue !== 'select_date'
      if (newValue === 'last_month') {
        this.filterDate = [this.dateHuman('DD.MM.YYYY', 'first_day', this.dateHuman('DD.MM.YYYY', 'prev_month')), this.dateHuman('DD.MM.YYYY', 'last_day', this.dateHuman('DD.MM.YYYY', 'prev_month'))]
      }
    },
    filterDate () {
      this.getData(1)
    }
  },
  methods: {
    getData (page) {
      this.$http.get(this.url + '/index', {
        params: {
          start: this.filterDate[0],
          end: this.filterDate[1],
          page: page
        }
      })
        .then(result => {
          this.pagination = result.headers
          this.lists = result.data.data
          this.balans = result.data.balans
          this.total = result.data.total
        })
    },
    filterTable () {
      console.log('[Log]', this.filterDate)
    },
    dateHuman (format = 'DD.MM.YYYY', action = 'current', date = false) {
      let d = new Date()
      if (date) {
        d = new Date(date)
      }
      let parts = this.dateHumanParts(d)
      let lastDay
      switch (action) {
        case 'first_day':
          return this.dateHumanFormat(format, new Date(parts.year, parts.month, 1))
        case 'last_day':
          lastDay = new Date(parts.year, parts.month, 0).getDate()
          return this.dateHumanFormat(format, new Date(parts.year, parts.month, lastDay))
        case 'prev_month':
          return this.dateHumanFormat(format, new Date(parts.year, parts.month - 1, 1))
      }
      // Застосовуємо формат
      return this.dateHumanFormat('DD.MM.YYYY', d)
    },
    dateHumanFormat (format, date) {
      let day, month, year
      switch (format) {
        case 'dd.mm.yyyy':
          return date
        default:
          console.log('[Log]', date)
          day = date.getDate()
          month = date.getMonth()
          month = Number(month) + 1
          year = date.getFullYear()
          if (String(day).length === 1) {
            day = '0' + day
          }
          if (String(month).length === 1) {
            month = '0' + month
          }
          return day + '.' + month + '.' + year
      }
    },
    dateHumanParts (date) {
      let day = date.getDate()
      let month = date.getMonth()
      let year = date.getFullYear()
      return {
        day,
        month,
        year
      }
    },
    go (page) {
      this.getData(page)
    },
    next () {
      this.getData(Number(this.pagination['x-pagination-current-page']) + 1)
    },
    prev () {
      this.getData(Number(this.pagination['x-pagination-current-page']) - 1)
    },
    getStatus (status) {
      if (status === null) {
        return '<span class="status-new">В черзі</span>'
      }
      if (status === 0) {
        return '<span class="status-success">Доставлено</span>'
      }
      return '<span class="status-error">Помилка</span>'
    }
  }
}
</script>
