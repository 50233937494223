<template>
  <div>
    <TopManager />
    <ActiveCall/>
    <div
      class="filter-header" :class="openMobileMenu ? 'open' : ''"
      style="justify-content: space-between"
    >
      <span class="clicked" @click="openMobileMenu = !openMobileMenu">
        <i class="ico-02" style="font-size: 24px"></i>
      </span>
      <div style="display: flex">
        <a
          href="#"
          class="standard-circle-btn calendar-arrow brand-hover with-border"
          @click.prevent="update"
        ><i
          class="ico-01"
        /></a>
        <el-date-picker
          v-model="date"
          type="date"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          :placeholder="$t('Дата')"
        />
      </div>
      <a
        v-show="$store.state.user.user.role === 3 || $store.state.user.user.role === 4 || $store.state.user.user.role === 1"
        href="#"
        class="standard-circle-btn add-filter-e brand-hover with-border"
        @click.prevent="showForm()"
      >
        <i class="ico-05" />
      </a>
      <div class="flx">
        <a
          href="#"
          class="btn-today brand-hover with-border"
          @click.prevent="goPrevDay"
        >&lt;
          {{ $t('Попередній день') }}</a>
        <a
          href="#"
          class="btn-today brand-hover with-border"
          @click.prevent="goToday"
        >{{ $t('Сьогодні') }}</a>
        <a
          href="#"
          class="btn-today brand-hover with-border"
          @click.prevent="goNextDay"
        >{{ $t('Наступний день') }}
          ></a>
      </div>
      <!--      <a href="#" class="standard-circle-btn calendar-arrow arrow-left brand-hover with-border"><i class="ico-03"></i></a>-->
      <!--      <a href="#" class="standard-circle-btn calendar-arrow arrow-right brand-hover with-border"><i class="ico-04"></i></a>-->
      <!--      <div class="filter-box">-->
      <!--        <div class="select-cabinet small-select">-->
      <!--          <v-select :options="[]"></v-select>-->
      <!--        </div>-->
      <!--        <div class="number-box-1">-->
      <!--          <div class="item full-flex-center">0</div>-->
      <!--          <div class="item full-flex-center">2</div>-->
      <!--        </div>-->
      <!--        <div class="box-result">-->
      <!--          <span>12 результатів</span>-->
      <!--          <a href="#" class="clean-filter full-flex-center"><i class="ico-17"></i></a>-->
      <!--        </div>-->
      <!--        <a href="#" class="standard-circle-btn brand-hover with-border calendar-btn2"><i class="ico-08"></i></a>-->
      <!--        <a href="#" class="standard-circle-btn btn-filter-header brand-hover with-border" @click.prevent="filterActive = !filterActive"><i class="ico-09"></i></a>-->
      <!--      </div>-->
    </div>
    <div class="drag-field">
      <!-- Часовий блок -->
      <div class="left-for-time-abs">
        <div class="padd-30" />
        <div
          v-for="t in workTime"
          :key="t"
          class="item"
        >
          {{ t }}:00
        </div>
      </div>
      <!-- Загальний блок -->
      <div
        id="gridView"
        class="skBox"
        @scroll="eventScroll"
      >
        <div
          id="headerFixedOnScroll"
          class="head-field"
        >
          <div class="left-for-time" />
          <div
            v-for="room in rooms"
            :key="room.id"
            class="one-column"
            :style="'border-top: 3px solid' + room.roomcolor"
          >
            №{{ room.number }} {{ room.name }}
            <!--          <div class="menu-own-cabinet">-->
            <!--            <a href="#" class="btn-own-cabinet" v-on:click="showDrop = !showDrop"><i class="ico-10"></i></a>-->
            <!--            <div class="box-dropdown-cab" v-if="showDrop">-->
            <!--              <ul>-->
            <!--                <li><a href="#">Редагувати</a></li>-->
            <!--                <li><a href="#">Видалити</a></li>-->
            <!--                <li><a href="#">Добавити запис</a></li>-->
            <!--              </ul>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
        </div>
        <div
          id="dragBox"
          class="body-field"
        >
          <div class="current-time" />
          <div
            v-for="room in rooms"
            :key="room.id"
            class="one-column"
          >
            <div class="padd-30" />
            <div
              v-for="t in workTime"
              :key="t"
              class="item"
              :data-room="room.id"
              :data-time="t"
              @click="targetNewTask(room, t)"
            >
              <!-- eslint-disable -->
              <div v-for="task in tasks[room.id]">
                <div
                  class="box-patient dragEvent"
                  :class="colorClass(task)"
                  :style="marginBox(task?.beginoftheinterval, task.endoftheinterval, task.taskdate)"
                  v-if="task && timeEvent(task.beginoftheinterval, t)"
                  :key="task.id"
                  :data-id="task.id"
                  @click.stop="seeTask(task)"
                  @mouseenter="fullShow"
                  @mouseleave="minShow"
              >
                <div class="startInfo">
                  <div class="patient"><span v-my-tooltip.top-center="$t('Первинний пацієнт')" class="first-time"
                                             v-if="task.is_new"><i class="ico-16"></i></span> П:
                    {{ task.patientid ? task.patientid.shortname : '' }} <img :title="$t('Застрахований')"
                                                                              v-my-tooltip.top-center="$t('Застрахований')"
                                                                              v-show="task.patientid !== null && task.patientid.has_insurance === 1"
                                                                              src="../../assets/images/ic_security_24px.svg"
                                                                              width="16"
                                                                              style="top: 2px;position: relative"></div>
                  <div class="doctor">{{$t('Л')}}: {{ task.doctorid ? task.doctorid.shortname : '' }}</div>
                  <div>{{ task?.beginoftheinterval.substring(0, 5) }} - {{ task.endoftheinterval.substring(0, 5) }}</div>
                  <div class="comment">{{ task.workdescription }}</div>
                  <div class="comment">{{ task.comment }}</div>
                </div>
                <ul class="box-btn-call">
                  <li v-show="task.noticed === '1'">
                    <a href="tel:380660491456"><i class="ico-56"></i></a>
                  </li>
                  <li v-show="task.factofvisit === '1'"><a href="#"><i class="ico-61"></i></a></li>
                  <li v-show="task.factofout === '1'"><a href="#" class="red"><i class="ico-60"></i></a></li>
                </ul>
                <div class="box-inform-patient">
                  <div class="date-time">{{ normalTime(task?.beginoftheinterval) }} -
                    {{ normalTime(task.endoftheinterval) }}
                  </div>
                  <div class="one-info">
                    <div class="article">{{$t('Пацієнт')}}:</div>
                    <span>{{ task.patientid ? task.patientid.fullname : '' }}</span>
                  </div>
                  <div class="one-info">
                    <div class="article">{{$t('Мобільний номер телефону')}}:</div>
                    <span>{{ task.patientid ? task.patientid.mobilenumber : '' }}</span>
                  </div>
                  <div class="one-info">
                    <div class="article">{{$t('Лікар')}}:</div>
                    <span>{{ task.doctorid ? task.doctorid.shortname : '' }}</span>
                  </div>
                  <div class="one-info">
                    <div class="article">{{$t('Опис роботи')}}:</div>
                    <span>{{ task.workdescription }}</span>
                  </div>
                  <div class="one-info">
                    <div class="article">{{$t('Примітка')}}:</div>
                    <span>{{ task.comment }}</span>
                  </div>
                  <div class="status-info">
                    <div class="green" v-show="task.noticed === '1'">{{$t('Попереджений про візит!')}}</div>
                    <div class="red" v-show="task.factofout === '1'">{{$t('Візит не відбувся')}}</div>
                  </div>
                </div>
              </div>
              </div>
              <!-- eslint-enable -->
            </div>
          </div>
        </div>
      </div>
      <div class="status-bar">
        <ul>
          <li><span style="background-color: #dac5df" /> {{ $t('Новий запис') }}</li>
          <li><span style="background-color: #c8e2f8" /> {{ $t('Попереджений') }}</li>
          <li><span style="background-color: rgb(245, 176, 172)" /> {{ $t('Не прийшов') }}</li>
          <li><span style="background-color: rgb(186, 205, 231)" /> {{ $t('Прийшов') }}</li>
          <li><span style="background-color: #a4e3b6" /> {{ $t('На прийомі') }}</li>
          <li><span style="background-color: #AFEEEE" /> {{ $t('Завершено') }}</li>
        </ul>
      </div>
    </div>
    <newOrder
      :rooms="rooms"
      :patients="patients"
      :doctors="doctors"
      :date="date"
      @updateTask="updateTask"
    />
    <addCabinet />
    <watchOrder />
    <viewRecording
      :task-prop="selectTask"
      :board-date="date"
      :rooms="rooms"
      :patients="patients"
      :doctors="doctors"
      @updateListTask="updateListTask"
    />
    <div
      class="filter-box-navbar"
      :class="{'active': filterActive}"
    >
      <div class="caption">
        Фільтр
        <a
          href="#"
          class="close-filter full-flex-center"
          @click.prevent="filterActive = !filterActive"
        ><i
          class="ico-17"
        /></a>
      </div>
      <div class="body-filter">
        <div class="search-bl">
          <input
            type="text"
            class="custom-input"
          >
          <span>Введіть для пошуку за станом, кабінетом, лікарем або пацієнтом.</span>
        </div>
        <div
          class="wr-op"
          :class="{'open' : filter1}"
        >
          <div class="one-block-f">
            <ul class="check-list">
              <li><label><i /><input type="checkbox"><span>Не попереджений</span></label></li>
              <li>
                <label><i style="background-color: #e8f2fa;" /><input
                  type="checkbox"
                ><span>Попереджений</span></label>
              </li>
              <li>
                <label><i style="background-color: #fbe9e8;" /><input
                  type="checkbox"
                ><span>Не прийшов</span></label>
              </li>
              <li>
                <label><i style="background-color: #dadfe6;" /><input type="checkbox"><span>Прийшов</span></label>
              </li>
              <li>
                <label><i style="background-color: #e6f8eb;" /><input
                  type="checkbox"
                ><span>Поточні події</span></label>
              </li>
              <li>
                <label><i style="background-color: #e6f8eb;" /><input
                  type="checkbox"
                ><span>Поточні події</span></label>
              </li>
            </ul>
          </div>
          <div class="btn-filter-inside">
            <a
              href="#"
              @click.prevent="filter1 = !filter1"
            />
          </div>
        </div>
        <div
          class="wr-op"
          :class="{'open' : filter2}"
        >
          <div class="one-block-f">
            <ul class="check-list">
              <li><label><input type="checkbox"><span>Кабінет №1</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №2</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №3</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №4</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №5</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №6</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №7</span></label></li>
            </ul>
          </div>
          <div class="btn-filter-inside">
            <a
              href="#"
              @click.prevent="filter2 = !filter2"
            />
          </div>
        </div>
      </div>
    </div>
    <NewCard />
  </div>
</template>

<script>
import newOrder from './modules/NewOrder'
import addCabinet from './modules/AddCabinet'
import watchOrder from './modules/WatchOrder'
import viewRecording from './modules/ViewRecording'
import NewCard from '../Patients/modules/NewCard'
import $ from 'jquery'
import moment from "moment";
import ActiveCall from "@/components/modules/ActiveCall.vue";

export default {
  components: {
    ActiveCall,
    newOrder,
    watchOrder,
    viewRecording,
    addCabinet,
    NewCard
  },
  data() {
    return {
      openMobileMenu: false,
      startTime: 8, // Година початку роботи закладу
      finishTime: 21, // Година кінця роботи закладу
      timestamp: '',
      url: '/tasks',
      rooms: [],
      date: moment().format('YYYY-MM-DD'),
      filterActive: false,
      filter1: false,
      filter2: false,
      patients: [],
      doctors: [],
      tasks: [],
      showDrop: false,
      moveEvent: false,
      selectTask: [],
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  computed: {
    workTime() {
      let time = []
      for (let i = this.startTime; i < this.finishTime; i++) {
        let j = i
        if (String(j).length === 1) {
          j = '0' + j
        }
        time.push(j)
      }
      return time
    }
  },
  watch: {
    date: function (oldValue, newValue) {
      this.$http.get(this.url + '/date', {
        params: {
          date: oldValue
        }
      })
          .then(result => {
            this.setData(result.data)
          })
    }
  },
  created() {
    this.$http.get(this.url + '/index')
        .then(result => {
          this.setData(result.data)
        })
    this.emitter.on('updateSchedule', this.setData)
  },
  mounted() {
    let drag = document.querySelector('.skBox')
    let time = document.querySelector('.drag-field')
    drag.addEventListener('scroll', (event) => {
      time.scroll(0, event.target.scrollTop)
    })
    this.getNow()
    setInterval(this.getNow, 60000)
  },
  methods: {
    normalTime: function (e) {
      let n = e.split(':')
      return n[0] + ':' + n[1]
    },
    eventScroll() {
      const grid = document.getElementById('gridView')
      const header = document.getElementById('headerFixedOnScroll')
      header.style.top = Math.ceil(grid.scrollTop) + 'px'
    },
    targetNewTask(room_id, hour) {
      this.emitter.emit('setBeforeData', {room_id, time: hour + ':00'})
      this.showForm()
    },
    fullShow(event) {
      let contentHeight = 0
      for (let element of event.target.children) {
        if (element.className === 'startInfo') {
          contentHeight = element.clientHeight + 10
        }
      }
      event.target.setAttribute('data-height', event.target.offsetHeight)
      if (event.target.offsetHeight < contentHeight) {
        event.target.style.height = contentHeight + 'px'
      }
    },
    minShow(event) {
      const height = event.target.getAttribute('data-height')
      event.target.style.height = height + 'px'
    },
    marginBox(start, end, date) {
      let min = new Date(date + ' ' + start)
      let max = new Date(date + ' ' + end)
      let diff = ((max - min) / 1000).toFixed()
      let timeStart = start.split(':')
      let result = 'margin-top: ' + (timeStart[1] * 100 / 60) + 'px;'
      result += 'height: ' + (diff * 100 / 3600) + 'px;'
      return result
    },
    setData(data) {
      //this.$storage.set('schedule', data)
      this.rooms = data.rooms
      this.patients = data.patients
      this.doctors = data.doctors.map(e => {
        e.fullname = e.lastname + ' ' + e.firstname + ' ' + e.middlename
        return e
      })
      this.tasks = data.tasks
    },
    getNow() {
      const now = new Date()
      const m = now.getMonth()
      const Y = now.getFullYear()
      const d = now.getDate()
      const start = new Date(Y, m, d, this.startTime)
      let diff = now.getTime() - start.getTime()
      let allTime = $('.left-for-time-abs').outerHeight()
      let allMinute = (this.finishTime - this.startTime) * 60
      const oneMinPix = allTime / allMinute
      $('.current-time').css('top', (((Math.ceil(diff / 1000 / 60)) * oneMinPix) + 102) + 'px')
    },
    showModal(target) {
      if (!this.moveEvent) {
        this.$modal.show(target)
      }
    },
    timeEvent(taskTime, time) {
      let t = taskTime.split(':')
      return Number(t[0]) >= Number(time) && Number(t[0]) < Number(time) + 1
    },
    updateTask(data) {
      //let tasks = this.$storage.get('tasks')
      // if (tasks === null) {
      //   tasks = {}
      // }
      // tasks.tasks = data
      this.tasks = data
      //this.$storage.set('tasks', tasks)
    },
    colorClass(task) {
      if (Number(task.status_id) === 6) {
        return 'red'
      }
      if (Number(task.status_id) === 4) {
        return 'green'
      }
      if (Number(task.status_id) === 1) {
        return 'violet'
      }
      if (Number(task.status_id) === 2) {
        return 'blue'
      }
      if (Number(task.status_id) === 3) {
        return 'here'
      }
      if (Number(task.status_id) === 5) {
        return 'complete'
      }
    },
    update() {
      this.$http.get(this.url + '/tasks')
          .then(result => {
            this.tasks = result.data
          })
    },
    changeTaskDrag(room, time, id) {
      this.$http.post(this.url + '/change-move', {
        id: id,
        room: room,
        time: time,
        date: this.date
      })
          .then(result => {
            this.tasks = result.data
          })
          .catch(error => {
            console.log('[Error]', error)
          })
    },
    seeTask(task) {
      if (Number(this.$store.state.user.user.role) === 1 && !task.doctorid.users.includes(this.$store.state.user.user.id) && task.doctorid.accountid !== this.$store.state.user.user.id) {
        return false
      }
      this.emitter.emit('viewTask', task)
      this.emitter.emit('boardDate', this.date)
      this.showModal('viewRecordingModal')
    },
    updateListTask(data) {
      this.tasks = data
    },
    showForm() {
      this.emitter.emit('showModalTask')
      document.getElementsByTagName('body')[0].classList.add('overBody')
    },
    goToday() {
      this.date = moment().format('YYYY-MM-DD')
    },
    goPrevDay() {
      this.date = moment(this.date).subtract(1, "days").format('YYYY-MM-DD')
    },
    goNextDay() {
      this.date = moment(this.date).add(1, "days").format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>

</style>
