<template>
  <div>
    <TopInfo />
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="show ()"><i class="ico-11"></i> Додати спеціалізацію</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold table-column-two">
          <thead>
            <tr>
              <th>
                <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
              </th>
              <th>
                <div class="caption">Повна назва спеціальності</div>
                <input type="text" @input="localSearch('item_label', $event)" class="custom-input">
              </th>
              <th>
                <div class="caption">Скорочена назва спеціальності</div>
                <input type="text" @input="localSearch('item_shortlabel', $event)" class="custom-input">
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(person, index) in articles" :key="person.id">
              <td>
                <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
              </td>
              <td>{{ person.item_label }}</td>
              <td>{{ person.item_shortlabel }}</td>
              <td><a href="#" @click.prevent="edit(index)" class="btn-table-change"><i class="ico-19"></i></a></td>
              <td><a href="#" @click.prevent="remove(person.id)" class="btn-table-change"><i class="ico-20"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.addOrder"
        width="720px"
        :title="form.title"
        class="modal-custom"
    >
      <form @submit.prevent="saveForm">
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">Повна назва</div>
            <div class="input-wr"><input type="text" v-model="form.item_label" class="custom-input" :class="{'input-v-error':$v.form.item_label.$error}" @change="$v.form.item_label.$touch()"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Скорочення</div>
            <div class="input-wr"><input type="text" v-model="form.item_shortlabel" class="custom-input" :class="{'input-v-error':$v.form.item_shortlabel.$error}" @change="$v.form.item_shortlabel.$touch()"></div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hide()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
export default {
  name: 'SpecialtyPage',
  data () {
    return {
      articles: [],
      originalArticle: [],
      checked: false,
      form: {
        title: 'Додавання нового спеціалізації',
        item_label: '',
        item_shortlabel: '',
        id: 0
      },
      url: '/tf_dictionaries'
    }
  },
  created () {
    this.$http.get(this.url + '/index')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    setData (data) {
      this.articles = data
      this.originalArticle = data
    },
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    show () {
      this.$modal.show('addOrder')
    },
    hide () {
      this.$modal.hide('addOrder')
      this.clear()
    },
    clear () {
      this.form.title = 'Додавання нового спеціалізації'
      this.form.item_label = ''
      this.form.item_shortlabel = ''
      this.form.id = 0
    },
    saveForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$http.post(this.url + '/save', {
          item_label: this.form.item_label,
          item_shortlabel: this.form.item_shortlabel,
          id: this.form.id
        })
          .then(result => {
            this.setData(result.data)
            this.hide()
            this.clear()
          })
      }
    },
    edit (index) {
      let item = this.articles[index]
      this.form.title = 'Редагування спеціалізації'
      this.form.id = item.id
      this.form.item_label = item.item_label
      this.form.item_shortlabel = item.item_shortlabel
      this.show()
    },
    remove (id) {
      if (confirm('Видали спеціалізацію?')) {
        this.$http.delete(this.url + '/delete', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.setData(result.data)
          })
      }
    },
    localSearch (attr, q) {
      this.articles = this.originalArticle.filter(e => {
        return e[attr].indexOf(q.target.value) !== -1
      })
    }
  },
  validations: {
    form: {
      item_label: {
        required
      },
      item_shortlabel: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
