<template>
  <modal name="registerManager" class="modal-custom" :width="960" height="auto" :scrollable="true">
    <form>
      <div class="caption-modal">
        Додавання нового сертифікату
        <a href="#" class="close-modal" @click.prevent="$modal.hide('registerManager')"><i class="ico-17"></i></a>
      </div>
      <div class="body-modal form-long">
        <div class="line-modal-form">
          <div class="description">Дійсний до</div>
          <div class="input-wr line-btn-right">
            <el-date-picker
                v-model="birthday"
                type="date">
            </el-date-picker>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">Сума</div>
          <div class="input-wr">
            <input type="text" class="custom-input" v-model="country" :class="{'input-v-error':$v.country.$error}" @change="$v.country.$touch()">
          </div>
        </div>
        <div class="caption-black-m">Клієнт</div>
        <div class="line-modal-form">
          <div class="description">Прізвище</div>
          <div class="input-wr">
            <input type="text" class="custom-input" v-model="state" :class="{'input-v-error':$v.state.$error}" @change="$v.state.$touch()">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">Ім’я</div>
          <div class="input-wr">
            <input type="text" class="custom-input" v-model="region">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">По батькові</div>
          <div class="input-wr">
            <input type="text" class="custom-input" v-model="city" :class="{'input-v-error':$v.city.$error}" @change="$v.city.$touch()">
          </div>
        </div>
      </div>
      <div class="footer-modal flex-footer">
        <div class="btn-left-footer">
        </div>
        <div class="btn-right-footer">
          <button class="btn-green">Зберегти</button>
          <button type="button" class="btn-red" @click="cancel">Скасувати</button>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, integer } from '@vuelidate/validators'
import AwesomeMask from 'awesome-mask'
export default {
  data () {
    return {
      fullname: '',
      sex: 0,
      birthday: new Date(),
      discount: 0,
      country: '',
      state: '',
      region: '',
      city: '',
      address: '',
      postalcode: '',
      mobilenumber: '',
      comment: '',
      villageorcity: 1,
      locale
    }
  },
  validations: {
    fullname: {
      required
    },
    country: {
      required
    },
    state: {
      required
    },
    city: {
      required
    },
    address: {
      required
    },
    mobilenumber: {
      required,
      integer
    }
  },
  directives: {
    'mask': AwesomeMask
  },
  methods: {
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const data = {
          fullname: this.fullname,
          sex: this.sex,
          birthday: this.birthday,
          discount: this.discount,
          country: this.country,
          state: this.state,
          ragion: this.ragion,
          city: this.city,
          address: this.address,
          postalcode: this.postalcode,
          mobilenumber: this.mobilenumber,
          comment: this.comment,
          villageorcity: this.villageorcity
        }
        this.$store.dispatch('addNew', data)
          .then(() => {
            this.clear()
          })
      }
    },
    clear () {
      this.fullname = ''
      this.sex = 0
      this.birthday = new Date()
      this.discount = 0
      this.country = ''
      this.state = ''
      this.ragion = ''
      this.city = ''
      this.address = ''
      this.postalcode = ''
      this.mobilenumber = ''
      this.comment = ''
      this.villageorcity = 1
    },
    cancel () {
      this.$modal.hide('registerManager')
      this.clear()
    }
  }
}
</script>

<style scoped>

</style>
