<template>
  <div>
    <TopManager />
    <div class="content with-big-marge-bottom">
      <div class="top-tools">
        <button type="button" @click="$modal.show('xReportModal')" class="btn btn-default"
                style="padding: 11px 20px;width: 100px;flex-shrink: 0;margin-right: 20px;">{{ $t('X-Звіт') }}
        </button>
        <button type="button" @click="$modal.show('outMoneyModal')" class="btn btn-default"
                style="padding: 11px 20px;width: 160px;flex-shrink: 0;margin-right: 20px;">{{ $t('Винести готівку') }}
        </button>
        <button type="button" @click="$modal.show('inMoneyModal')" class="btn btn-default"
                style="padding: 11px 20px;width: 160px;flex-shrink: 0;margin-right: 20px;">{{ $t('Внести готівку') }}
        </button>
        <el-date-picker
          v-model="currentDate"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('Від...')"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          :end-placeholder="$t('...до')"
          style="width: 240px;flex-shrink: 0"
          :picker-options="pickerOptions"
          size="large"
        >
        </el-date-picker>
        <button class="btn-green" @click.prevent="filtering" style="padding: 11px 20px;">{{ $t('Показати') }}</button>
        <input type="search" @input="filterItem" class="custom-input" :placeholder="$t('Пошук')"/>
      </div>
      <div class="filter-hendler">
        <div class="filterItem">
          <label style="margin-right: 10px;">{{ $t('Тип оплати') }}</label>
          <el-select v-model="filter.type" @change="filterEvent">
            <el-option
                v-for="item in [{'id': 0, 'name': $t('всі')}, {'id': 1, 'name': $t('готівкою')}, {'id': 2, 'name': $t('термінал')}, {'id': 3, 'name': $t('розділено')}, {'id': 4, 'name': $t('Р/p')}]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </div>
        <div class="filterItem">
          <label style="margin-right: 10px;">{{ $t('ФОП') }}</label>
          <el-select v-model="filter.cashier_id" @input="filterEvent">
            <el-option
                v-for="item in [{'id': 0, 'name': 'всі'}].concat(cashiers)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </div>
<!--        <div class="filterItem">-->
<!--          <label style="margin-right: 10px;">{{ $t('Касир') }}</label>-->
<!--          <el-select v-model="filter.user_close_id" filterable @input="filterEvent">-->
<!--            <el-option-->
<!--                v-for="item in [{'id': 0, 'username': 'всі'}].concat(users)"-->
<!--                :key="item.id"-->
<!--                :label="item.username"-->
<!--                :value="item.id"-->
<!--            />-->
<!--          </el-select>-->
<!--        </div>-->
      </div>
      <div class="paydesk-wr">
        <div class="one-pay" v-for="(person, index) in articles" :key="index">
          <div class="client-wr before-border width-cf">
            <div class="name-client" v-if="person.patient">
              <div class="surname bold">{{ person.patient.lastname }}</div>
              <div class="name">{{ person.patient.firstname }} {{ person.patient.middlename }}</div>
            </div>
          </div>
          <div class="client-wr before-border">
            <div class="name-client">
              <div class="bill-cf" @click="viewInvoice(index)">{{ $t('Рах. №') }}{{ person.number }} {{ $t('від') }}
                {{ formatDate(person.createdate) }}
              </div>
              <div class="comment-bill">{{ $t('Коментарі') }}:</div>
            </div>
            <div class="buttons-client">
              <div class="top-btn">
                <ul class="bottom-btn pay-box-btn">
                  <li class="tooltip-wrapper">
                    <a href="#" @click="beforeContract(index)" class="full-flex-center brand with-border"><i class="ico-35"></i></a>
                    <div class="tooltip">{{ $t('Завантажити договір') }}</div>
                  </li>
                  <li class="tooltip-wrapper">
                    <a href="#" @click="setAktData(person.check_id)" class="full-flex-center brand with-border"><i class="ico-48"></i></a>
                    <div class="tooltip">{{ $t('Завантажити акт') }}</div>
                  </li>
                  <li class="tooltip-wrapper">
                    <router-link :to="'/patients/' + person.patient.id + '/info'" class="full-flex-center brand-hover with-border"><i class="ico-21"></i></router-link>
                    <div class="tooltip">{{ $t('Перейти в картку пацієнта') }}</div>
                  </li>
                  <li class="tooltip-wrapper">
                    <a href="javascript:void(0);" @click="printCheck(person.items, person.check_id, person.is_fiscal)"
                       class="full-flex-center brand-hover with-border brand"><i class="ico-24"></i></a>
                    <div class="tooltip">{{ $t('Роздрукувати') }}</div>
                  </li>
                  <li class="tooltip-wrapper" v-if="person.is_fiscal === 1">
                    <a href="javascript:void(0);" @click="backCheck(person.check_id, index)"
                       class="full-flex-center brand-hover with-border brand"><i class="ico-29"></i></a>
                    <div class="tooltip">{{ $t('Повернути') }}</div>
                  </li>
                </ul>
              </div>
              <!--              <div class="name-client-cf">{{ person.patient.shortname }}</div>-->
            </div>
          </div>
          <div class="inform-flow">
            <div class="status-flow">{{ typePay(person.type) }}</div>
            <div class="full-sum">{{ person.total }} ₴</div>
            <div v-if="person.discount > 0" class="full-sum sum-with-discount">{{ $t('Знижка') }}: {{ person.discount }} ₴</div>
            <div class="date-client">{{ formatDate(person.dateclose) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-cash-flow">
      <div class="one-block shrink-0">
        <!--        <div class="select-cl">-->
        <!--          <v-select></v-select>-->
        <!--        </div>-->
        <div class="static-all">
          <div>{{ $t('Всього прихід') }}: <span class="brand-color bold">{{ money.all }}</span></div>
          <div> - {{ $t('карткою') }}: <span class="brand-color bold">{{ money.card }}</span></div>
          <div> - {{ $t('готівкою') }}: <span class="brand-color bold">{{ money.cash }}</span></div>
          <div> - {{$t('p/p')}}: <span class="brand-color bold">{{ money.pp }}</span></div>
          <div> - {{$t('фіскалізовано')}}: <span class="brand-color bold">{{ money.fiscal }}</span></div>
          <div> - {{$t('надано знижок')}}: <span class="brand-color bold">{{ money.discount }}</span></div>
          <!--          <div>Всього витрати: <span class="red-color bold">0.00</span></div>-->
        </div>
      </div>
      <div class="one-block width-full">
        <!--        <div class="static-all">-->
        <!--          <div>Фільтр по касирах</div>-->
        <!--        </div>-->
        <!--        <div class="select-cl">-->
        <!--          <v-select></v-select>-->
        <!--        </div>-->
        <div class="balance-footer">{{$t('Баланс')}}: {{ money['all'] }}</div>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.printCheck"
        width="500px"
        :title="$t('Виберіть касира')"
        class="modal-custom"
    >
      <form>
        <div class="caption-modal">
          {{$t('Виберіть касира')}}
          <a href="#" class="close-modal" @click.prevent="$modal.hide('printCheck')"><i class="ico-17"></i></a>
        </div>
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="input-wr">
              <v-select :options="cashiers" label="name" v-model="currentCashier"
                        :reduce="cashiers => cashiers.id"></v-select>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <button class="btn-green" type="button" @click="getCheckInfo">{{$t('Запросити')}}</button>
          <button class="btn-red" @click.prevent="$modal.hide('printCheck')">{{$t('Скасувати')}}</button>
        </div>
      </form>
    </el-dialog>

    <!-- Вибір касира для х-звіту -->
    <el-dialog
        v-model="$store.state.modal.xReportModal"
        width="500px"
        :title="$t('Виберіть касира')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="input-wr">
              <el-select v-model="currentCashier" style="width: 100%">
                <el-option
                    v-for="item in cashiers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <button class="btn-green" type="button" @click="xReport">{{$t('Запросити')}}</button>
          <button class="btn-red" @click.prevent="$modal.hide('xReportModal')">{{$t('Скасувати')}}</button>
        </div>
      </form>
    </el-dialog>

    <!-- Вибір касира для винесення готівки -->
    <el-dialog
        v-model="$store.state.modal.outMoneyModal"
        width="500px"
        :title="$t('Виберіть касира')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="input-wr">
              <label>{{$t('Сума')}}</label>
              <input type="text" v-model="sumOut" class="custom-input">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="input-wr">
              <label>{{$t('Касир')}}</label>
              <el-select v-model="currentCashier" style="width: 100%">
                <el-option
                    v-for="item in cashiers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <button class="btn-green" type="button" @click="outMoney">{{$t('Винести')}}</button>
          <button class="btn-red" @click.prevent="$modal.hide('outMoneyModal')">{{$t('Скасувати')}}</button>
        </div>
      </form>
    </el-dialog>

    <!-- Вибір касира для внесення готівки -->
    <el-dialog
        v-model="$store.state.modal.inMoneyModal"
        width="500px"
        :title="$t('Виберіть касира')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="input-wr">
              <label>{{$t('Сума')}}</label>
              <input type="text" v-model="sumIn" class="custom-input">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="input-wr">
              <label>{{$t('Касир')}}</label>
              <el-select v-model="currentCashier" style="width: 100%">
                <el-option
                    v-for="item in cashiers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <button class="btn-green" type="button" @click="inMoney">{{$t('Внести')}}</button>
          <button class="btn-red" @click.prevent="$modal.hide('inMoneyModal')">{{$t('Скасувати')}}</button>
        </div>
      </form>
    </el-dialog>

    <!-- Формування акту -->
    <el-dialog
        v-model="$store.state.modal.beforeAkt"
        width="500px"
        :title="$t('Вкажіть дані')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="input-wr">
              <label>{{$t('Дата')}}</label>
              <div>
                <el-date-picker
                  v-model="akt.date"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <button class="btn-green" type="button" @click="getAkt()">{{$t('Сформувати')}}</button>
          <button class="btn-red" @click.prevent="$modal.hide('beforeAkt')">{{$t('Скасувати')}}</button>
        </div>
      </form>
    </el-dialog>

    <!-- Формування договору -->
    <el-dialog
        v-model="$store.state.modal.beforeContract"
        width="500px"
        :title="$t('Вкажіть дані')"
        class="modal-custom"
    >
      <form>
        <div class="caption-modal">
          {{$t('Вкажіть дані')}}
          <a href="#" class="close-modal" @click.prevent="$modal.hide('beforeContract')"><i class="ico-17"></i></a>
        </div>
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="input-wr">
              <label>{{$t('Дата')}}</label>
              <div>
                <el-date-picker
                  v-model="contract.date"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="input-wr">
              <label>{{$t('Основна послуга')}}</label>
              <div>
                <v-select :options="currentInvoice" label="name" v-model="contract.id"
                          :reduce="item => item.id"></v-select>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <button class="btn-green" type="button" @click="getContract()">{{$t('Сформувати')}}</button>
          <button class="btn-red" @click.prevent="$modal.hide('beforeContract')">{{$t('Скасувати')}}</button>
        </div>
      </form>
    </el-dialog>

    <!-- Перегляд позицій -->
    <el-dialog
        v-model="$store.state.modal.viewItemsInvoice"
        width="900px"
        :title="$t('Оплачені послуги')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal form-long">
          <div class="form-box-green" v-for="item in currentInvoice" :key="item.id">
            <div class="description">
              <div class="name">{{ item.name }}</div>
              <div class="doc-name-small" style="margin-left: 0">{{ item.doc }} | {{ item.createdate }}</div>
            </div>
            <div class="right-box">
              <span>{{ item.proc_count }} ×</span>
              <span>{{ Number(item.price) - Math.ceil(Number(item.price) * Number(item.discount) / 100) }}</span>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <button class="btn-red" @click.prevent="$modal.hide('viewItemsInvoice')">{{$t('Закрити')}}</button>
        </div>
      </form>
    </el-dialog>

    <!--    <div id="printMe" style="white-space: pre;display: none">-->
    <!--      <div id="check" v-html="printContent"></div>-->
    <!--    </div>-->

    <div id="printСheck" style="display: none">
      <img :src="checkImage" style="width: 48mm" alt="Check"/>
    </div>
<!--    <div id="printMe" style="display: none">-->
<!--      <div class="ticket">-->
<!--        <div class="address">-->
<!--          <p>МЕДИЧНИЙ КАБIНЕТ</p>-->
<!--          <p>ВОЛИНСЬКА ОБЛ., ЛУЦЬКИЙ РАЙОН, С. ТАРАСОВЕ, вулиця Лісова, буд. 1/Б</p>-->
<!--        </div>-->
<!--        <div class="lineItem" v-for="item in localTicket.items" :key="item.id">-->
<!--          <p>{{ item.amount }} x {{ item.price }}</p>-->
<!--          <p v-if="Number(item.discount) > 0">{{$t('Знижка')}}: {{ item.discount }}грн</p>-->
<!--          <p>{{ item.name }}-->
<!--            <span>{{ Number(item.price) - Number(item.discount) }}</span></p>-->
<!--          <div style="clear: both"></div>-->
<!--        </div>-->
<!--        <div class="pay" v-if="localTicket.cash !== '0.00'">-->
<!--          <div>{{$t('Готівка')}}</div>-->
<!--          <div>{{ localTicket.cash }} {{$t('ГРН')}}</div>-->
<!--        </div>-->
<!--        <div class="pay" v-if="localTicket.card !== '0.00'">-->
<!--          <div>{{$t('Картка')}}</div>-->
<!--          <div>{{ localTicket.card }} {{$t('ГРН')}}</div>-->
<!--        </div>-->
<!--        <div class="total">-->
<!--          <div>{{$t('СУМА')}}</div>-->
<!--          <div>{{ localTicket.total }} {{$t('ГРН')}}</div>-->
<!--        </div>-->
<!--        <div class="number">{{ localTicket.number }}</div>-->
<!--        <div class="date">-->
<!--          <div>{{ localTicket.date }}</div>-->
<!--          <div>{{ localTicket.time }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div id="printMe" style="display: none">
      <div class="ticket" style="border-top: 2px solid black">
        <div class="address" style="font-size: 12px;font-weight: 600;border-bottom: 4px solid black;line-height: 13px;margin-bottom: 3px;padding-bottom: 2px">
          <div>{{localTicket.clinic.name}}</div>
          <div>{{localTicket.clinic.address}}</div>
          <div>ІД {{localTicket.clinic.code}}</div>
        </div>
        <div class="lineItem" style="padding: 0;font-size: 12px;font-weight: 600;line-height: 13px;margin-bottom: 6px;" v-for="item in localTicket.items">
          <div>{{ item.amount }} x {{ item.price }}</div>
          <div v-if="Number(item.discount) > 0">Знижка: {{ item.discount }}%</div>
          <div>{{ item.name }}
            <span>{{ ((Number(item.price) - Math.ceil(Number(item.price) * Number(item.discount) / 100))*Number(item.amount)).toFixed(2) }}&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
          <div style="clear: both"></div>
        </div>
        <div class="pay" style="margin-top: -4px;padding: 0 0 3px 0;border-top: 2px solid black;font-size: 12px;font-weight: 600" v-if="localTicket.cash !== '0.00'">
          <div>Готівка</div>
          <div>{{ localTicket.cash }} ГРН</div>
        </div>
        <div class="pay" style="margin-top: -4px;padding: 0 0 3px 0;border-top: 2px solid black;font-size: 12px;font-weight: 600" v-if="localTicket.card !== '0.00'">
          <div>Картка</div>
          <div>{{ localTicket.card }} ГРН</div>
        </div>
        <div class="pay" style="margin-top: -4px;padding: 0 0 3px 0;border-top: 2px solid black;font-size: 12px;font-weight: 600" v-if="localTicket.pp !== '0.00'">
          <div>P/p</div>
          <div>{{ localTicket.pp }} ГРН</div>
        </div>
        <div class="total" style="border-bottom: 4px solid black">
          <div>СУМА</div>
          <div>{{ localTicket.total }} ГРН</div>
        </div>
        <div class="number" style="padding: 0;font-size: 13px;font-weight: 700;margin-top: 3px;border: 0;">№ {{ localTicket.number }}</div>
        <div class="date" style="font-size: 12px;font-weight: 600">
          <div>{{ localTicket.date }}</div>
          <div>{{ localTicket.time }}</div>
        </div>
        <div style="text-align: center">
          <img src="../../assets/images/qr.png" width="60%">
        </div>
        <span style="font-weight: 600;font-size: 12px;display: block;text-align: center">ОНЛАЙН</span>
        <div style="display: flex;justify-content: space-between;font-size: 12px;line-height: 13px;">
          <div>ФН ПРРО</div>
          <div>4000372803</div>
        </div>
        <div style="text-align: center;font-size: 12px;line-height: 13px;">ФІСКАЛЬНИЙ ЧЕК</div>
        <div style="text-align: center;font-weight: 700;padding-bottom: 2px;font-size: 16px;line-height: 17px;">checkbox</div>
      </div>
    </div>
    <div id="printMeOriginal" style="white-space: pre;display: none">
      <div id="check" v-html="printContent"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CashFlow',
  data () {
    return {
      date: new Date(),
      articles: [],
      money: [],
      currentDate: '',
      articlesOriginal: [],
      currentCashier: '',
      cashiers: [],
      check_id: '',
      printContent: '',
      sumOut: '',
      sumIn: '',
      filter: {
        cashier_id: 0,
        type: 0,
        user_close_id: 0
      },
      users: [],
      pickerOptions: {
        firstDayOfWeek: 1
      },
      currentInvoice: [],
      checkImage: '',
      localTicket: {
        card: 0,
        cash: 0,
        date: new Date(),
        items: [],
        time: '00:00',
        total: 0,
        number: '',
        clinic: {
          name: '',
          address: '',
          code: '',
        }
      },
      akt: {
        date: new Date(),
        id: null
      },
      contract: {
        date: new Date(),
        id: null
      }
    }
  },
  created () {
    this.$http.get('/money/get-inactive')
      .then(response => {
        this.setData(response.data)
      })
  },
  methods: {
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    total () {
      let total = 0
      this.money.map(e => {
        total += Number(e.amount)
      })
      return total
    },
    backCheck (check_id, index) {
      this.$http.get('/money/back-check', {
        params: {
          check_id
        }
      }).then(() => {
        this.$delete(this.articles, index)
      }).catch(error => {
        console.log('[Error]', error)
        this.$toastr.e(error.response ? error.response.data : error.message)
      })
    },
    filterItem (e) {
      const q = (e.target.value).toLowerCase()
      const resp = {}
      this.articles = Object.keys(this.articlesOriginal).map(e => {
        const el = this.articlesOriginal[e]
        const lastname = (el.patient.lastname).toLowerCase()
        const firstname = (el.patient.firstname).toLowerCase()
        const middlename = (el.patient.middlename).toLowerCase()
        if (lastname.indexOf(q) !== -1 || firstname.indexOf(q) !== -1 || middlename.indexOf(q) !== -1) {
          resp[e] = el
        }
      })
      this.articles = resp
    },
    setData (data) {
      this.articles = data.invoices
      this.articlesOriginal = data.invoices
      this.money = data.money
      this.cashiers = data.cashiers
      if (this.cashiers.length === 1) {
        this.currentCashier = this.cashiers[0].id;
      } else {
        this.cashiers.map(cash => {
          if (cash.is_default === true) {
            this.currentCashier = cash.id;
          }
        })
      }
      this.users = data.users
    },
    filtering () {
      this.$http.get('/money/get-inactive', {
        params: {
          date: this.currentDate
        }
      })
        .then(response => {
          this.setData(response.data)
        })
    },
    printCheck (items, checkId, isFiscal) {
      let self = this
      let arr = items.map(item => {
        return item.id
      })
      if (Number(isFiscal) === 0) {
        this.$http.get('/money/local-check', {
          params: {
            id: arr
          }
        }).then(result => {
          this.localTicket = result.data
          setTimeout(() => {
            this.$htmlToPaper('printMe')
          }, 500)
        }).catch(error => {
          this.$root.alerts('error', error.message)
        })
      } else {
        this.toDataURL(`https://api.checkbox.in.ua/api/v1/receipts/${checkId}/png?paper_width=58`, function (result) {
          self.checkImage = result
          setTimeout(() => {
            self.$htmlToPaper('printСheck')
          }, 500)
        })
        //this.check_id = checkId
        //this.$modal.show('printCheck')
        //this.checkImage =
        console.log('[Url check]', `https://api.checkbox.in.ua/api/v1/receipts/${checkId}/png?paper_width=58`)
        // setTimeout(() => {
        //   this.$htmlToPaper('printMe')
        // }, 1000)
      }
    },
    toDataURL (url, callback) {
      var xhr = new XMLHttpRequest()
      xhr.onload = function () {
        var reader = new FileReader()
        reader.onloadend = function () {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    },
    getCheckInfo () {
      if (this.currentCashier === 0) {
        alert('Виберіть касира')
      } else {
        this.$http.get('/money/check-info', {
          params: {
            id: this.check_id,
            cashier_id: this.currentCashier
          }
        }).then(result => {
          this.printContent = result.data
          setTimeout(() => {
            this.$htmlToPaper('printMeOriginal')
          }, 500)
          this.$modal.hide('printCheck')
        }).catch(error => {
          console.log('[Error]', error)
          this.$root.alerts('error', 'Помилка отримання інформації про чек')
        })
      }
    },
    xReport () {
      this.$http.get('/money/x-report', {
        params: {
          cashier_id: this.currentCashier
        }
      })
        .then(result => {
          this.printContent = result.data
          setTimeout(() => {
            this.$htmlToPaper('printMeOriginal')
          }, 500)
          this.$modal.hide('xReportModal')
        }).catch(error => {
        this.$root.alerts('error', error.response.data)
      })
    },
    outMoney () {
      if (this.sumOut !== '' && this.currentCashier > 0) {
        this.$http.get('/money/out-money', {
          params: {
            cashier_id: this.currentCashier,
            sum: this.sumOut
          }
        })
          .then(result => {
            this.printContent = result.data
            setTimeout(() => {
              this.$htmlToPaper('printMeOriginal')
            }, 500)
            this.$modal.hide('outMoneyModal')
          })
          .catch(error => {
            console.log('[Log]', error)
            this.$root.alerts('error', error.response.data)
          })
      }
    },
    inMoney () {
      if (this.sumIn !== '' && this.currentCashier > 0) {
        this.$http.get('/money/money-in', {
          params: {
            cashier_id: this.currentCashier,
            sum: this.sumIn
          }
        })
          .then(result => {
            this.printContent = result.data
            setTimeout(() => {
              this.$htmlToPaper('printMeOriginal')
            }, 500)
            this.$modal.hide('inMoneyModal')
          })
          .catch(error => {
            console.log('[Log]', error)
            this.$toastr.e(error.response.data)
          })
      }
    },
    filterOnCashier (event) {
      this.$http.get('/money/get-inactive', {
        params: {
          cashier: event.target.value
        }
      })
        .then(response => {
          this.setData(response.data)
        })
    },
    typePay (type) {
      switch (Number(type)) {
        case 1:
          return 'готівка'
        case 2:
          return 'термінал'
        case 3:
          return 'розділено'
        default:
          return 'р/р'
      }
    },
    filterEvent () {
      console.log('[Log]', 'event')
      let arr = {}
      Object.keys(this.articlesOriginal).filter(key => {
        let e = this.articlesOriginal[key]
        let allow = true
        for (let key in this.filter) {
          if (this.filter[key] !== 0) {
            let newItems = []
            e.items.map(n => {
              if (Number(n[key]) === Number(this.filter[key])) {
                newItems.push(n)
              }
            })
            if (newItems.length > 0) {
              e.items = newItems
            } else {
              allow = false
            }
          }
        }
        if (allow) {
          arr[key] = e
        }
      })
      this.articles = arr
    },
    viewInvoice (index) {
      this.currentInvoice = this.articlesOriginal[index].items
      this.$modal.show('viewItemsInvoice')
    },
    beforeContract (index) {
      this.currentInvoice = this.articlesOriginal[index].items
      this.$modal.show('beforeContract')
    },
    setAktData (id) {
      this.akt.id = id
      this.$modal.show('beforeAkt')
    },
    getAkt () {
      this.$http.get('/doc/akt', {
        params: {
          id: this.akt.id,
          date: this.akt.date
        }
      }).then(response => {
        location.href = response.data.link
      }).catch(error => {
        if (error.response.status === 415) {
          this.$toastr.e(error.response.data)
        } else {
          this.$toastr.e('Помилка формування акту. Зверніться до адміністратора')
        }
      })
    },
    getContract () {
      this.$http.get('/doc/contract', {
        params: {
          id: this.contract.id,
          date: this.contract.date
        }
      }).then(response => {
        this.$modal.hide('beforeContract')
        location.href = response.data.link
      }).catch(error => {
        console.log('[Error]', error)
        this.$toastr.e('Помилка формування акту. Зверніться до адміністратора')
      })
    }
  },
  watch: {
    articles: function () {
      this.money = {
        discount: 0,
        all: 0,
        fiscal: 0,
        cash: 0,
        card: 0,
        pp: 0
      }
      Object.keys(this.articles).map(key => {
        let e = this.articles[key]
        e.items.map(v => {
          this.money.discount += Number(v.discount)
          let price = (Number(v.price) * Number(v.proc_count)) - Number(v.discount)
          this.money.all += price
          if (Number(v.is_fiscal) === 1) {
            this.money.fiscal += price
          }
          if (Number(v.type) !== 4) {
            this.money.cash += Number(v.price_cash)
            this.money.card += Number(v.price_card)
          } else {
            this.money.pp += Number(v.price_cash)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
