export default {
  state: {
    patients: [],
    patient: {}
  },

  getters: {
    getPatients (state) {
      return state.patients
    },
    getOnePatient (state, id) {
      return state.patient
    }
  },

  mutations: {
    setPatients (state, items) {
      state.patients = items
    },
    setPatient (state, item) {
      state.patient = item
    }
  },

  actions: {
    getPatients ({commit}, offset) {
      commit('setStatus', 'load')
      const settings = {
        limit: 20,
        offset: offset
      }
      let self = this
      return new Promise((resolve, reject) => {
        self.$http.get('/patient', {params: settings})
          .then(response => {
            commit('setStatus', null)
            commit('setPatients', response.data.patients)
            resolve(response)
          })
          .catch(error => {
            commit('setStatus', 'error')
            reject(error)
          })
      })
    },
    addNew ({commit, dispatch}, data) {
      commit('setStatus', 'load')
      return new Promise((resolve, reject) => {
        Vue.$http.post('/patient/create', data)
          .then(response => {
            dispatch('getPatients', 0)
            resolve(response)
          })
          .catch(error => {
            commit('setStatus', 'error')
            reject(error)
          })
      })
    },
    searchPatient ({commit}, search) {
      commit('setStatus', 'load')
      const settings = {
        limit: 20,
        offset: 0,
        search: search
      }
      return new Promise((resolve, reject) => {
        self.$http.get('/patient/search', {params: settings})
          .then(response => {
            commit('setStatus', null)
            commit('setPatients', response.data.patients)
            resolve(response)
          })
          .catch(error => {
            commit('setStatus', 'error')
            reject(error)
          })
      })
    },
    deletePatient ({commit, dispatch}, id) {
      commit('setStatus', 'load')
      return new Promise((resolve, reject) => {
        Vue.$http.delete('/patient/remove', {params: {id: id}})
          .then(response => {
            commit('setStatus', null)
            dispatch('getPatients', 0)
            resolve(response)
          })
          .catch(error => {
            commit('setStatus', 'error')
            reject(error)
          })
      })
    }
  }
}
