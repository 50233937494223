import axios from 'axios'

export default {
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {}
  },

  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  },

  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, data) {
      console.log('[Data]', data)
      state.status = 'success'
      state.token = data.token
      state.user = data.user
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
    },
    setUser (state, data) {
      state.user = data
    }
  },

  actions: {
    login ({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('setStatus', 'load')
        axios({url: process.env.VUE_APP_API_URL + '/rest/v2/auth/login', data: user, method: 'POST'})
          .then(response => {
            commit('setStatus', null)
            const token = response.data.api_token
            const user = response.data
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(user))
            console.log('[User]', user)
            commit('auth_success', {token, user})
            resolve(response)
          })
          .catch(error => {
            commit('setStatus', null)
            commit('auth_error')
            localStorage.removeItem('token')
            reject(error)
          })
      })
    },
    logout ({commit}) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  }
}
