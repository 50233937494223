<template>
  <div class="wrapper-user-ico">
    <div class="box-close-menu" :class="{'open': avatarMenu}" @click.prevent="avatarMenu = !avatarMenu"></div>
    <a href="#" class="user-panel full-flex-center" @click.prevent="avatarMenu = !avatarMenu">B</a>
    <div class="drop-user" :class="{'open': avatarMenu}">
      <div class="head-drop">
<!--        <div class="box-ico full-flex-center">{{$root.user.info.firstname.splice(0, 1)}}</div>-->
        <div class="ful-name">
          <div class="name"><a href="#">{{ $store.state.user.user.info !== null ? $store.state.user.user.info.lastname : '' }}</a></div>
          <div class="under-n">{{ $store.state.user.user.info !== null ? $store.state.user.user.info.firstname : '' }} {{ $store.state.user.user.info !== null ?  $store.state.user.user.info.middlename : '' }}</div>
        </div>
      </div>
      <div class="body-drop">
<!--        <div class="job">Медсестра</div>-->
<!--        <div class="name-job">Руцька Олена Валеріївна</div>-->
        <a href="#" @click.prevent="logout" class="btn-grey">Вийти</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data () {
    return {
      avatarMenu: false
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
