<template>
  <div class="responsive-menu" :class="openMobileMenu ? 'open' : ''">
    <span class="clicked" @click="openMobileMenu = !openMobileMenu">
      <span></span>
      <span></span>
      <span></span>
    </span>
    <div :class="topClass()" />
    <div class="header">
      <div class="top-menu">
        <ul>
          <li
            v-for="page in pages"
            v-show="page && page.roles.includes($store.state.user.user.role)"
            :key="page.url"
          >
            <router-link
              :to="page.url"
              :class="{'active': current === page.url}"
            >
              {{ page.name }}
            </router-link>
          </li>
        </ul>
        <LoginPage />
      </div>
      <div class="bottom-menu">
        <ul>
          <!-- eslint-disable -->
          <li
              v-for="subPage in subPages[subActive]"
              :key="subPage.url"
              v-show="subPage && subPage.roles.includes($store.state.user.user.role)"
          >
            <router-link :to="subPage.url" :class="{'active': subPage.url === fullUrl}">{{ subPage.name }}</router-link>
          </li>
          <!-- eslint-enable -->
        </ul>
      </div>
    </div>
    <div
      class="filter-box-navbar"
      :class="{'active': filterActive}"
    >
      <div class="caption">
        Фільтр
        <a
          href="#"
          class="close-filter full-flex-center"
          @click.prevent="filterActive = !filterActive"
        ><i
          class="ico-17"
        /></a>
      </div>
      <div class="body-filter">
        <div class="search-bl">
          <input
            type="text"
            class="custom-input"
          >
          <span>Введіть для пошуку за станом, кабінетом, лікарем або пацієнтом.</span>
        </div>
        <div
          class="wr-op"
          :class="{'open' : filter1}"
        >
          <div class="one-block-f">
            <ul class="check-list">
              <li><label><i /><input type="checkbox"><span>Не попереджений</span></label></li>
              <li>
                <label><i style="background-color: #e8f2fa;" /><input
                  type="checkbox"
                ><span>Попереджений</span></label>
              </li>
              <li>
                <label><i style="background-color: #fbe9e8;" /><input
                  type="checkbox"
                ><span>Не прийшов</span></label>
              </li>
              <li>
                <label><i style="background-color: #dadfe6;" /><input type="checkbox"><span>Прийшов</span></label>
              </li>
              <li>
                <label><i style="background-color: #e6f8eb;" /><input
                  type="checkbox"
                ><span>Поточні події</span></label>
              </li>
              <li>
                <label><i style="background-color: #e6f8eb;" /><input
                  type="checkbox"
                ><span>Поточні події</span></label>
              </li>
            </ul>
          </div>
          <div class="btn-filter-inside">
            <a
              href="#"
              @click.prevent="filter1 = !filter1"
            />
          </div>
        </div>
        <div
          class="wr-op"
          :class="{'open' : filter2}"
        >
          <div class="one-block-f">
            <ul class="check-list">
              <li><label><input type="checkbox"><span>Кабінет №1</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №2</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №3</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №4</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №5</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №6</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №7</span></label></li>
            </ul>
          </div>
          <div class="btn-filter-inside">
            <a
              href="#"
              @click.prevent="filter2 = !filter2"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        v-model="modal.addOrder"
        width="720px"
    >
      <form>
        <div class="caption-modal">
          {{ $t('Вивести гроші') }}
          <a
            href="#"
            class="close-modal"
            @click.prevent="hide ()"
          ><i class="ico-17" /></a>
        </div>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">
              {{ $t('Пацієнт') }}
            </div>
            <div class="input-wr">
              <!--              <stf-select v-model="value4">-->
              <!--                <div slot="label"></div>-->
              <!--                <div slot="value">-->
              <!--                  <div v-if="value4">-->
              <!--                    <span>{{value4.supportStatus}}</span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div slot="search-input">-->
              <!--                  <input @input="onsearch4">-->
              <!--                </div>-->
              <!--                <section class="options delivery_order__options">-->
              <!--                  <stf-select-option-->
              <!--                    v-for="item of listFinded4" :key="item.id"-->
              <!--                    :value="item"-->
              <!--                    :class="{'stf-select-option_selected': item.id === (value4 && value4.id)}"-->
              <!--                  >-->
              <!--                    <span>{{item.supportStatus}}</span>-->
              <!--                  </stf-select-option>-->
              <!--                </section>-->
              <!--              </stf-select>-->
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description" />
            <div class="input-wr">
              <label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>{{ $t('Безготівковий розрахунок') }}</span></label>
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description">
              {{ $t('Коментар') }}
            </div>
            <div class="input-wr">
              <textarea class="mailing-textarea" />
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer">
            <div class="box-value-ml">
              <span>{{ $t('Внести') }}:</span>
              <input
                type="text"
                placeholder="0"
                class="custom-input"
              >
            </div>
          </div>
          <div class="btn-right-footer">
            <button class="btn-green">
              ОК
            </button>
            <button
              class="btn-red"
              @click.prevent="hide ()"
            >
              {{ $t('Скасувати') }}
            </button>
          </div>
        </div>
      </form>
    </el-dialog>
    <el-dialog
        v-model="modal.addOrder2"
        width="720px"
    >
      <form>
        <div class="caption-modal">
          {{ $t('Внести гроші до балансу') }}
          <a
            href="#"
            class="close-modal"
            @click.prevent="hide ()"
          ><i class="ico-17" /></a>
        </div>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">
              {{ $t('Пацієнт') }}
            </div>
            <div class="input-wr">
              <!--              <stf-select v-model="value3">-->
              <!--                <div slot="label"></div>-->
              <!--                <div slot="value">-->
              <!--                  <div v-if="value3">-->
              <!--                    <span>{{value3.supportStatus}}</span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div slot="search-input">-->
              <!--                  <input @input="onsearch3">-->
              <!--                </div>-->
              <!--                <section class="options delivery_order__options">-->
              <!--                  <stf-select-option-->
              <!--                    v-for="item of listFinded3" :key="item.id"-->
              <!--                    :value="item"-->
              <!--                    :class="{'stf-select-option_selected': item.id === (value3 && value3.id)}"-->
              <!--                  >-->
              <!--                    <span>{{item.supportStatus}}</span>-->
              <!--                  </stf-select-option>-->
              <!--                </section>-->
              <!--              </stf-select>-->
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description" />
            <div class="input-wr">
              <label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>{{ $t('Безготівковий розрахунок') }}</span></label>
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description">
              {{ $t('Коментар') }}
            </div>
            <div class="input-wr">
              <textarea class="mailing-textarea" />
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer">
            <div class="box-value-ml">
              <span>{{ $t('Внести') }}:</span>
              <input
                type="text"
                placeholder="0"
                class="custom-input"
              >
            </div>
          </div>
          <div class="btn-right-footer">
            <button class="btn-green">
              ОК
            </button>
            <button
              class="btn-red"
              @click.prevent="hide ()"
            >
              {{ $t('Скасувати') }}
            </button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {computed} from "vue";

export default {
  data() {
    return {
      openMobileMenu: false,
      modal: {
        addOrder: false,
        addOrder2: false,
      },
      avatarMenu: false,
      date: new Date(),
      filterActive: false,
      filter1: false,
      filter2: false,
      value3: null,
      list3: [
        {
          supportStatus: 'Пацієнт 1',
          id: 1
        },
        {
          supportStatus: 'Пацієнт 2',
          id: 2
        },
        {
          supportStatus: 'Пацієнт 3',
          id: 3
        },
        {
          supportStatus: 'Пацієнт 4',
          id: 4
        }
      ],
      listFinded3: [],
      value4: null,
      list4: [
        {
          supportStatus: 'Пацієнт 1',
          id: 1
        },
        {
          supportStatus: 'Пацієнт 2',
          id: 2
        },
        {
          supportStatus: 'Пацієнт 3',
          id: 3
        },
        {
          supportStatus: 'Пацієнт 4',
          id: 4
        }
      ],
      listFinded4: [],
      pages: [
        {
          name: 'Органайзер завдань',
          url: '/manager',
          roles: [1, 3, 4]
        },
        {
          name: 'Каса',
          url: '/manager/kassa',
          roles: [3, 4]
        },
        // {
        //   name: 'Сертифікати',
        //   url: '/manager/sertificats',
        //   roles: [3]
        // },
        {
          name: 'Кадри',
          url: '/manager/cadrs',
          roles: [3]
        },
        // {
        //   name: 'Дисконтні карти',
        //   url: '/manager/discount-cards',
        //   roles: [3]
        // },
        // {
        //   name: 'Контакти',
        //   url: '/manager/contacts',
        //   roles: [3]
        // },
        {
          name: 'Розклад',
          url: '/manager/schedule',
          roles: [1]
        },
        {
          name: 'Вихідні',
          url: '/manager/free-day',
          roles: [1]
        }
      ],
      current: '/manager/organaizer',
      subActive: 'organaizer',
      subPages: {
        'organaizer': [],
        'sms': [
          {
            name: 'Перегляд СМС',
            url: '/manager/sms',
            roles: [3]
          },
          {
            name: 'Масова розсилка',
            url: '/manager/sms/create',
            roles: [3]
          }
        ],
        'kassa': [
          {
            name: 'Рахунки',
            url: '/manager/kassa',
            roles: [3, 7, 4]
          },
          {
            name: 'Каса',
            url: '/manager/kassa/kas',
            roles: [3, 7]
          }
        ]
      },
      fullUrl: ''
    }
  },
  created() {
    this.current = this.$route.path
    this.fullUrl = this.$route.fullPath
    let url = (this.$route.fullPath).split('/')
    if (url.length > 3) {
      this.current = '/' + url[1] + '/' + url[2]
    }
    this.subActive = url[2]
    this.topClass()
  },
  methods: {
    onsearch3(e) {
      if (e.target.value3) {
        this.listFinded3 = this.list3.filter(el => el.supportStatus.indexOf(e.target.value3) !== -1)
      } else {
        this.listFinded3 = this.list3
      }
    },
    onsearch4(e) {
      if (e.target.value4) {
        this.listFinded4 = this.list4.filter(el => el.supportStatus.indexOf(e.target.value4) !== -1)
      } else {
        this.listFinded4 = this.list4
      }
    },
    show3() {
      this.$modal.show('addOrder3')
    },
    hide3() {
      this.$modal.hide('addOrder3')
    },
    show2() {
      this.$modal.show('addOrder2')
    },
    hide2() {
      this.$modal.hide('addOrder2')
    },
    show() {
      this.$modal.show('addOrder')
    },
    hide() {
      this.$modal.hide('addOrder')
    },
    topClass() {
      if (this.subPages[this.subActive] === undefined) {
        return 'backing2'
      }
      return 'backing1'
    }
  },
}
</script>

<style scoped>

</style>
