<template>
  <div>
    <div class="caption">{{$t('Тестування')}}</div>
    <div class="test-result">
      <div class="line-result">
        <span>{{isResult ? $t('Присутні результати тестування') : $t('Результати тестування відсутні')}}</span>
        <a v-if="isResult" href="#" @click.prevent="deleteTest" class="btn-white">{{$t('Видалити')}}</a>
      </div>
    </div>
    <form action="">
      <div class="testing-box" v-for="question in list" :key="question.id">
        <div class="testing-caption">{{question.seqnumber}}. {{question.question}}</div>
        <div v-if="question.configxml['@attributes'].type === 'DropDownList' || question.configxml['@attributes'].type === 'RadioButton'">
          <v-select searchable :options="question.configxml.answer" v-model="answer['question_' + question.id]"></v-select>
        </div>
        <div v-if="question.configxml['@attributes'].type === 'TextInput'">
          <textarea class="mailing-textarea" v-model="answer['question_' + question.id]"></textarea>
        </div>
      </div>
      <div class="save-testing">
        <div class="wrapper-l-btn">
<!--          <button class="btn-white default height-36"><i class="ico-24"></i>Результатів</button>-->
<!--          <button class="btn-white height-36"><i class="ico-24"></i>Шаблону</button>-->
        </div>
        <button type="button" @click="save" class="btn-green standard-width small">{{$t('Зберегти')}}</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'TestingBox',
  data () {
    return {
      checked: false,
      picked: 'man',
      value: 1,
      list: [],
      answer: [],
      pid: this.id,
      isResult: false
    }
  },
  props: {
    id: {
      default: 0
    }
  },
  created () {
    this.$http.get('/patient/tests', {params: {id: this.id}})
      .then(result => {
        this.list = result.data.test
        for (let item in result.data.result.results) {
          this.answer['question_' + result.data.result.results[item]['@attributes'].id] = result.data.result.results[item].result
          this.isResult = true
        }
      })
  },
  methods: {
    save () {
      let answer = {}
      for (let key in this.answer) {
        answer[key] = this.answer[key]
      }
      this.$http.post('/patient/save-test', {answers: answer, id: this.pid})
        .then(() => {
          this.isResult = true
          console.log('[Save success]')
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    },
    deleteTest () {
      if (confirm('Видалити результати тестування?')) {
        this.$http.delete('/patient/delete-test', {params: {id: this.pid}})
          .then(() => {
            this.answer = []
            this.isResult = false
            console.log('[Success]')
          })
          .catch(error => {
            console.log('[Error]', error)
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
