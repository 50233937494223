<template>
  <div>
    <TopInfo />
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="$modal.show('add')"><i class="ico-11"></i> {{$t('Додати')}}</a>
      </div>
      <div class="table-wrapper">
        <div id="table"/>
      </div>
    </div>
    <Add></Add>
  </div>
</template>

<script>
import Add from './SystemAdministrator/Add'
import { Grid, h, html } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

export default {
  data () {
    return {
      users: [],
      checked: false,
      url: '/user',
      grid: new Grid()
    }
  },
  created () {
    this.$http.get(this.url + '/all')
      .then(result => {
        this.setData(result.data)
      })
    this.emitter.on('updateUser', this.updateUser)
  },
  methods: {
    setData (data) {
      this.users = data
      this.grid.updateConfig({
        columns: [{
          id: 'id',
          name: 'ID'
        },{
          id: 'username',
          name: this.$t('Логін')
        }, {
          data: (row) => row?.doctor?.lastname,
          name: this.$t('Прізвище')
        },{
          data: (row) => row?.doctor?.firstname,
          name: this.$t('Ім’я')
        },{
          data: (row) => row?.doctor?.middlename,
          name: this.$t('По батькові')
        }, {
          id: 'phone',
          name: this.$t('Телефон')
        }, {
          id: 'email',
          name: 'Email'
        },{
          id: 'action',
          name: '',
          width: '100px',
          formatter: (cell, row) => {
            const btn = []
            btn.push(h('a', {
              className: 'btn-table-change',
              onClick: () => {
                this.edit(row._cells[0].data)
              }
            }, html('<i class="ico-19"></i>')));
            btn.push(h('a', {
              className: 'btn-table-change',
              onClick: () => {
                this.deleteUser(row._cells[0].data)
              }
            }, html('<i class="ico-20"></i>')));
            return btn;
          }
        }],
        data: data,
        pagination: {
          enabled: true,
          limit: 20,
          summary: false,
        },
        search: {
          debounceTimeout: 1000,
        },
        language: {
          'search': {
            'placeholder': '🔍 ' + this.$t('Пошук')
          },
          'pagination': {
            'previous': '⬅️',
            'next': '➡️',
          }
        }
      });
      this.grid.render(document.getElementById("table"));
    },
    updateUser (data) {
      this.users = data
      this.grid.updateConfig({
        data: data
      }).forceRender();
    },
    edit (index) {
      const user = this.users.find(e => {
        return Number(e.id) === Number(index)
      })
      this.emitter.emit('editAdmins', user)
    },
    deleteUser (id) {
      if (confirm('Видалити користувача?')) {
        this.$http.delete('/user/remove', {
          params: {
            id
          }
        }).then(result => {
          this.setData(result.data)
        }).catch(error => {
          this.$root.alerts('error', error.message)
        })
      }
    }
  },
  components: {
    Add
  }
}
</script>

<style scoped>

</style>
