<template>
  <div>
    <Navbar></Navbar>
    <Header9></Header9>
    <div class="content">
      <div class="paydesk-wr">
        <div class="one-pay" v-for="person in articles" :class="{'new' : person.statusPaydesk}" :key="person.idPay">
          <div class="number-count before-border">
            <div class="new-pay-t bold hide-old">{{ person.statusText }}</div>
            <div class="caption-grey black bold hide-new">Рахунок</div>
            <div class="counter hide-new">{{ person.counterPay }}</div>
            <div class="caption-grey hide-new">Нал.</div>
            <div class="date-time black hide-new">{{ person.dateTimePay }}</div>
          </div>
          <div class="client-wr before-border">
            <div class="name-client">
              <div class="surname bold">{{ person.surnameClient }}</div>
              <div class="name">{{ person.nameClient }}</div>
            </div>
            <div class="buttons-client">
              <div class="top-btn">
                <div class="date-time black hide-old">{{ person.dateClient }}</div>
                <span class="bold hide-new">{{ person.job }}</span>
                <ul class="pay-box-btn hide-new">
                  <li>
                    <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-17"></i></a>
                  </li>
                </ul>
              </div>
              <ul class="bottom-btn pay-box-btn">
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-21"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-22"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-23"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="sum-count before-border">
            <div class="caption-grey">Сума</div>
            <div class="sum-counter black bold">{{ person.sumCounter }}</div>
            <div class="caption-grey">Знижка</div>
            <div class="discount black bold">{{ person.discount }}%</div>
          </div>
          <div class="discount-wr">
            <div class="caption-grey">Сума зі знижкою</div>
            <div class="counter">{{ person.sumCounter - ((person.sumCounter / 100) * person.discount) }}</div>
          </div>
          <div class="last-block-pay hide-new">
            <div class="btn-2 before-border">
              <ul class="pay-box-btn">
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-24"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-25"></i></a>
                  <div class="tooltip">Показати всі рахунки пацієнта</div>
                </li>
              </ul>
            </div>
            <div class="balance">
              <div class="caption-grey">Баланс</div>
              <div class="sum-counter black bold">{{ person.balance }}</div>
            </div>
            <div class="wrap-money-btn">
              <a href="#" class="take-money-btn brand-hover with-border">Вивести гроші</a>
            </div>
          </div>
          <div class="last-block-new hide-old">
            <a href="#" class="btn-create-bill bold brand-hover with-border" @click.prevent="show2 ()">Створити рахунок</a>
            <div class="right-b">
              <div class="caption-grey">Сума</div>
              <div class="sum-counter black bold">{{ person.sumCounter - ((person.sumCounter / 100) * person.discount) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="registerPatient" class="modal-custom" :width="960" height="auto" :scrollable="true">
      <form action="">
        <div class="caption-modal">
          Рахунок пацієнта
          <a href="#" class="close-modal" @click.prevent="hide2 ()"><i class="ico-17"></i></a>
        </div>
        <div class="box-blue-modal clearfix">
          Закатєва Ольга Анатоліївна
          <span class="right brand-color">Баланс 0.00</span>
        </div>
        <div class="body-modal form-long">
          <div class="line-pay-l">
            <div class="one-i">
              <div class="bold-i">Знижка пацієнта</div>
              <div class="pr">0%</div>
            </div>
            <div class="one-i">
              <div class="bold-i">Рахунок №</div>
              <div class="pr"><input type="text" class="custom-input" placeholder="Авто"></div>
            </div>
            <ul class="radio-list flex">
              <li class="custom-radio">
                <label>
                  <input type="radio" value="One" checked name="payM">
                  <span>Безготівковий</span>
                </label>
              </li>
              <li class="custom-radio">
                <label>
                  <input type="radio" value="Two" name="payM">
                  <span>Термінал</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="line-day-i">
            <div class="text-l">від</div>
            <div class="date-line input-wr line-btn-right">
              <VueDatePicker  :value="date2" :locale="locale"/>
            </div>
            <div class="time-i">
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter1">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter1--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter1++"><i class="ico-27"></i></a>
              </div>
            </div>
            <div class="time-i">
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter2">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter2--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter2++"><i class="ico-27"></i></a>
              </div>
            </div>
            <div class="time-i">
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter3">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter3--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter3++"><i class="ico-27"></i></a>
              </div>
            </div>
          </div>
          <div class="form-box-green" v-for="person2 in articles2" :key="person2.id">
            <div class="description">
              <div class="name">{{ person2.nameP }}</div>
              <div class="text-1">
                <span>Знижка:</span>
                <div>{{ person2.discount }}</div>
                <span>{{ person2.art1 }}</span>
                <span>{{ person2.art2 }}</span>
              </div>
            </div>
            <div class="right-box">
              <span>{{ person2.countValue }} ×</span>
              <input type="text" class="custom-input" :value="person2.valueI">
              <span>= {{ person2.countValue * person2.valueI}}</span>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <div class="pay-finish-t">Оплачено:</div>
          <input type="text" class="custom-input box-f-t" placeholder="0">
          <button class="btn-green">OK</button>
          <button class="btn-red">Скасувати</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'ManagerPaydesk',
  data () {
    return {
      date: new Date([2019, 4, 12]),
      date2: new Date([2019, 4, 12]),
      locale,
      valueCounter1: 0,
      valueCounter2: 0,
      valueCounter3: 0,
      articles: [
        {
          idPay: 1,
          statusPaydesk: true,
          statusText: 'Новий',
          counterPay: '51917',
          dateTimePay: '04.10.2019',
          surnameClient: 'Руцька',
          nameClient: 'Олена Валеріївна',
          dateClient: '04.10.2019',
          job: 'Касир',
          sumCounter: '100',
          discount: '2',
          balance: '21570.00'
        },
        {
          idPay: 2,
          statusPaydesk: true,
          statusText: 'Новий',
          counterPay: '51917',
          dateTimePay: '04.10.2019',
          surnameClient: 'Головій',
          nameClient: 'Володимир Олександрович',
          dateClient: '04.10.2091',
          job: 'Касир',
          sumCounter: '100',
          discount: '0',
          balance: '21570.00'
        },
        {
          idPay: 3,
          statusPaydesk: false,
          statusText: 'Новий',
          counterPay: '51917',
          dateTimePay: '04.10.2019',
          surnameClient: 'Пасан',
          nameClient: 'Олена Петрівна',
          dateClient: '04.10.2091',
          job: 'Касир',
          sumCounter: '100',
          discount: '0',
          balance: '21570.00'
        },
        {
          idPay: 4,
          statusPaydesk: false,
          statusText: 'Новий',
          counterPay: '51917',
          dateTimePay: '04.10.2019',
          surnameClient: 'Терешковіч',
          nameClient: 'Богдан Сербовіч',
          dateClient: '04.10.2091',
          job: 'Касир',
          sumCounter: '100',
          discountStatus: true,
          discount: '2',
          balance: '21570.00'
        }
      ],
      articles2: [
        {
          id: 1,
          discount: 'Так',
          art1: 'кріо7',
          art2: 'Петровський В.Ф. 04.09.2019 10:38:40',
          nameP: 'Кріоконсервація і зберігання ембріонів 6 міс',
          countValue: '3',
          valueI: '3105.00'
        }
      ]
    }
  },
  methods: {
    show2 (e) {
      this.$modal.show('registerPatient')
      this.listFinded = this.list
    },
    hide2 () {
      this.$modal.hide('registerPatient')
    },
    onsearch (e) {
      if (e.target.value) {
        this.listFinded = this.list.filter(el => el.workStatus.indexOf(e.target.value) !== -1)
      } else {
        this.listFinded = this.list
      }
    }
  }
}
</script>

<style scoped>

</style>
