<template>
  <div>
    <div class="caption">{{$t('Діагнози')}}</div>
    <ul class="header-tab-box">
      <li><a href="#" :class="{active: tabContent === 1}" @click.prevent="tabContent = 1">{{$t('Діагнози')}}</a></li>
      <li><a href="#" :class="{active: tabContent === 2}" @click.prevent="tabContent = 2">{{$t('Щоденник')}}</a></li>
    </ul>
    <div class="tab-diagnoses" v-if="tabContent === 1" >
      <div class="name-i">{{$t('Історія')}}</div>
      <div class="clean-order full-flex-center" v-if="articles.length <= 0">
        {{$t('Ще немає записів')}}
      </div>
      <div class="table-wrapper" v-if="articles.length > 0">
        <table class="body-bold">
          <thead>
          <tr>
            <th>
              <div class="caption">{{$t('Лікар')}}</div>
            </th>
            <th style="width: 120px">
              <div class="caption">{{$t('Дата')}}</div>
            </th>
            <th style="width: 120px">
              <div class="caption">{{$t('Процедури')}}</div>
            </th>
            <th>
              <div class="caption">{{$t('Діагноз')}}</div>
            </th>
            <th style="width: 40px"></th>
            <th style="width: 40px"></th>
            <th style="width: 40px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(person, index) of articles" :key="person.id">
            <td>{{ person.doctorid.fullname }}</td>
            <td>{{ person.examdate }}</td>
            <td><router-link :to="'/patients/' + $route.params.id + '/kurs/' + person.treatmentcourse_fk.id">{{$t('переглянути')}}</router-link></td>
            <td class="font-w-normal word-break">{{ person.nh_diagnos }}</td>
            <td>
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="$t('Редагувати діагноз')"
                placement="top"
              >
                <a href="#" class="btn-table-change" @click.prevent="modify(index)"><i class="ico-19"></i></a>
              </el-tooltip>
            </td>
            <td>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="$t('Видалити діагноз')"
                  placement="top"
              >
                <a href="#" class="btn-table-change" @click.prevent="remove(person.id)"><i class="ico-20"></i></a>
              </el-tooltip>
            </td>
            <td>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="$t('Додати послугу')"
                  placement="top"
              >
                <a href="#" class="btn-table-change" @click.prevent="addTreatment(person.treatmentcourse_fk.id)"><i class="ico-48"></i></a>
              </el-tooltip>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="line-btn-m">
        <a href="#" class="btn-green" @click.prevent="show()">{{$t('Додати діагноз')}}</a>
      </div>
      <Treatment
        v-bind:id="currentDiagnoz"
        v-bind:patient_id="$route.params.id"
        v-bind:showModal="showModalTreatment"
        v-on:closeModal="showModalTreatment = false"
      />
    </div>
    <div class="tab-diagnoses" v-if="tabContent === 2" >
      <div class="clean-order full-flex-center" v-show="Diary.items.length <= 0">
        {{$t('Ще немає записів')}}
      </div>
      <div class="table-wrapper" v-if="Diary.items.length > 0">
        <table class="body-bold">
          <thead>
          <tr>
            <th>
              <div class="caption">{{$t('Лікар')}}</div>
            </th>
            <th style="width: 120px">
              <div class="caption">{{$t('Дата')}}</div>
            </th>
            <th style="width: 120px">
              <div class="caption">{{$t('Скарги')}}</div>
            </th>
            <th>
              <div class="caption">{{$t('Призначення')}}</div>
            </th>
            <th style="width: 40px"></th>
            <th style="width: 40px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) of Diary.items" :key="item.id">
            <td>{{ item.fkDoctor.fullname }}</td>
            <td>{{ formatDate(item.dairydate) }}</td>
            <td>{{ item.dairytext }}</td>
            <td>{{item.dairyresume}}</td>
            <td><a href="#" class="btn-table-change" @click.prevent="modifyDiary(index)"><i class="ico-19"></i></a></td>
            <td><a href="#" @click.prevent="removeDiary(item.id)" class="btn-table-change"><i class="ico-20"></i></a></td>
            <td><a href="#" @click.prevent="addCourseTreatment(item.id)" class="btn-table-change">+</a></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="line-btn-m">
        <a href="#" class="btn-green" @click.prevent="show2()">{{$t('Додати запис')}}</a>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.addDiagnose"
        width="960px"
        :title="$t('Додавання нового діагнозу')"
        class="modal-custom"
    >
      <form @submit.prevent="saveForm()">
        <div class="body-modal">
          <div class="row">
            <div class="col">
              <div class="line-modal-form">
                <div class="description text-right">{{$t('Лікар')}}</div>
                <div class="input-wr">
                  <el-select v-model="form.doctor" filterable class="full-width">
                    <el-option
                        v-for="item in doctors"
                        :key="item.id"
                        :label="item.fullname"
                        :value="item.id"
                    />
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="line-modal-form">
                <div class="description text-right">{{$t('Поточна дата')}}</div>
                <div class="input-wr line-btn-right">
                  <el-date-picker
                      v-model="form.date"
                      type="date"
                      format="DD.MM.YYYY"
                      placeholder="Дата"
                      value-format="YYYY-MM-DD"
                      :picker-options="pickerOptions"
                      class="full-width"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description text-right">{{$t('Діагноз')}}</div>
            <div class="input-wr">
              <textarea v-model="form.text" class="mailing-textarea shot"></textarea>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hide2 ()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
    <el-dialog
        v-model="$store.state.modal.addOrder"
        width="960px"
        :title="$t('Додавання запису в щоденник')"
        class="modal-custom"
    >
      <form @submit.prevent="addDiary">
        <div class="body-modal">
          <div class="row">
            <div class="col">
              <div class="line-modal-form">
                <div class="description text-right">{{$t('Лікар')}}</div>
                <div class="input-wr">
                  <el-select v-model="Diary.NhDairy.fk_doctor" filterable class="full-width">
                    <el-option
                        v-for="item in doctors"
                        :key="item.id"
                        :label="item.fullname"
                        :value="item.id"
                    />
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="line-modal-form">
                <div class="description text-right">{{$t('Поточна дата')}}</div>
                <div class="input-wr">
                  <el-date-picker
                      v-model="Diary.NhDairy.dairydate"
                      type="date"
                      format="DD.MM.YYYY"
                      placeholder="Дата"
                      value-format="YYYY-MM-DD"
                      :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description text-right">{{$t('Скарги хворого, об’єктивних даних, діагноз, перебіг хвороби')}}</div>
            <div class="input-wr">
              <textarea v-model="Diary.NhDairy.dairytext" class="mailing-textarea h190"></textarea>
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description text-right">{{$t('Призначення')}}</div>
            <div class="input-wr">
              <textarea v-model="Diary.NhDairy.dairyresume" class="mailing-textarea h120"></textarea>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hide2()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import Treatment from './modules/Treatment'
import {ElMessage} from "element-plus";
export default {
  name: 'DiagnosesPage',
  components: {Treatment},
  data () {
    return {
      doctors: [],
      tabContent: 1,
      articles: [],
      form: {
        doctor: null,
        date: '',
        text: '',
        patient_id: this.$route.params.id,
        id: 0
      },
      Diary: {
        NhDairy: {
          fk_doctor: '',
          dairydate: '',
          dairytext: '',
          dairyresume: '',
          fk_patient: this.$route.params.id,
          id: 0
        },
        items: []
      },
      pickerOptions: {
        firstDayOfWeek: 1
      },
      showModalTreatment: false,
      currentDiagnoz: 0
    }
  },
  created () {
    this.$http.get('patient/toothexam', {
      params: {
        id: this.$route.params.id
      }
    })
      .then(response => {
        this.setData(response.data)
      })
      .catch(() => {
        this.$toastr.e(this.$t('Помилка отримання діагнозів'))
      })
  },
  methods: {
    formatDate (date) {
      const d = new Date(date)
      let formatDate = new Intl.DateTimeFormat('uk', {month: 'numeric', year: 'numeric', day: '2-digit'}).format(d)
      return formatDate
    },
    addTreatment (diagnoz_id) {
      this.currentDiagnoz = diagnoz_id
      this.showModalTreatment = true
    },
    setData (data) {
      this.doctors = data.doctors
      this.articles = data.rows
      this.Diary.items = data.dairy
    },
    show () {
      this.$modal.show('addDiagnose')
    },
    hide () {
      this.$modal.hide('addDiagnose')
    },
    show2 () {
      this.$modal.show('addOrder')
    },
    hide2 () {
      this.$modal.hide('addOrder')
    },
    saveForm () {
      this.$http.post('/patient/save-toothexam', this.form)
        .then(result => {
          ElMessage({
            message: this.$t('Запис додано'),
            type: 'success',
          })
          this.setData(result.data)
          this.clear()
        })
        .catch(() => {
          ElMessage({
            message: this.$t('Помилка додавання запису'),
            type: 'error',
          })
        })
    },
    clear () {
      this.form.doctor = ''
      this.form.text = ''
      this.form.date = new Date()
      this.hide()
    },
    clearDiary () {
      this.Diary.NhDairy.fk_doctor = 0
      this.Diary.NhDairy.dairydate = ''
      this.Diary.NhDairy.dairytext = ''
      this.Diary.NhDairy.dairyresume = ''
      this.hide2()
    },
    addDiary () {
      this.$http.post('/patient/add-diary', this.Diary)
        .then(result => {
          this.Diary.items = result.data
          this.hide2()
          this.clearDiary()
        })
        .catch(() => {
          this.$root.alerts('error', this.$t('Помилка додавання запису в щоденник'))
        })
    },
    getDate () {
      let d = new Date()
      function fullDate (str) {
        if (String(str).length < 2) {
          return '0' + str
        }
        return str
      }
      return d.getFullYear() + '.' + fullDate(d.getMonth()) + '.' + fullDate(d.getDate())
    },
    modify (index) {
      let e = this.articles[index]
      this.form.doctor = e.doctorid
      this.form.date = this.reverseDate(e.examdate)
      this.form.text = e.nh_diagnos
      this.form.id = e.id
      this.show()
    },
    modifyDiary (index) {
      let e = this.Diary.items[index]
      this.Diary.NhDairy.fk_doctor = e.fk_doctor
      this.Diary.NhDairy.dairydate = e.dairydate
      this.Diary.NhDairy.dairytext = e.dairytext
      this.Diary.NhDairy.dairyresume = e.dairyresume
      this.Diary.NhDairy.id = e.id
      this.show2()
    },
    remove (id) {
      if (confirm(this.$t('Видалити запис?'))) {
        this.$http.delete('/patient/delete-diagnoz', {params: {id}})
          .then(response => {
            this.setData(response.data)
          })
          .catch(() => {
            this.$root.alerts('error', 'Помилка видалення')
          })
      }
    },
    removeDiary (id) {
      if (confirm('Видалити запис?')) {
        this.$http.delete('/patient/delete-diary', {params: {id}})
          .then(response => {
            this.setData(response.data)
          })
          .catch(() => {
            this.$root.alerts('error', this.$t('Помилка видалення'))
          })
      }
    },
    reverseDate (date) {
      let d = String(date).split('.')
      return d[2] + '-' + d[1] + '-' + d[0]
    },
    addCourseTreatment (id) {
      console.log('[ID]', id)
    }
  },
}
</script>

<style scoped>

</style>
