<template>
  <div class="page-canvas">
    <TopManager />
    <div class="filter-top">
      <a href="#" class="refresh">
        <i class="ico-01"></i>
      </a>
      <el-date-picker
        v-model="date"
        type="date"
        format="DD.MM.YYYY"
        value-format="DD.MM.YYYY"
        placeholder="Дата"
        :picker-options="pickerOptions"
      >
      </el-date-picker>

      <div class="info-sms">
        {{ $t('Розклад на') }} <span>{{ date }}</span>
      </div>
    </div>
    <div class="table-content">
      <table>
        <thead>
          <tr>
            <th>
              <a href="#">Час прийому</a>
            </th>
            <th>
              <a href="#">Статус</a>
              <input type="text">
            </th>
            <th>
              <a href="#">Номер картки</a>
              <input type="text">
            </th>
            <th style="width: 200px">
              <a href="#">ПІБ</a>
              <input type="text">
            </th>
            <th>
              <a href="#">Вік</a>
            </th>
            <th>
              <a href="#">Номер телефону</a>
              <input type="text">
            </th>
            <th>
              <a href="#">Опис роботи</a>
              <input type="text">
            </th>
            <th style="width: 60px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="list?.patient !== null"
            v-for="list in lists"
            :key="list.id"
          >
            <td>{{list.beginoftheinterval}} - {{list.endoftheinterval}}</td>
            <td>{{list.status}}</td>
            <td>{{list.patientid.id}}</td>
            <td>{{list.patientid.fullname}}</td>
            <td>{{ list.patientid.year }}</td>
            <td>{{ list.patientid.mobilenumber }}</td>
            <td>{{list.workdescription}}</td>
            <td>
              <router-link :to="'/patients/' + list.patientid.id + '/info'"><i class="ico-21"></i></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchedulePage',
  data () {
    return {
      date: this.dateHuman('DD.MM.YYYY'),
      url: 'tasks',
      lists: [],
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  created () {
    this.getData()
  },
  watch: {
    date () {
      this.getData()
    }
  },
  methods: {
    getData () {
      this.$http.get(this.url + '/doctor-schedule', {
        params: {
          date: this.date
        }
      })
        .then(result => {
          this.lists = result.data.tasks
        })
    },
    filterTable () {
      console.log('[Log]', this.filterDate)
    },
    dateHuman (format = 'DD.MM.YYYY', action = 'current', date = false) {
      let d = new Date()
      if (date) {
        d = new Date(date)
      }
      let parts = this.dateHumanParts(d)
      let lastDay
      switch (action) {
        case 'first_day':
          return this.dateHumanFormat(format, new Date(parts.year, parts.month, 1))
        case 'last_day':
          lastDay = new Date(parts.year, parts.month, 0).getDate()
          return this.dateHumanFormat(format, new Date(parts.year, parts.month, lastDay))
        case 'prev_month':
          return this.dateHumanFormat(format, new Date(parts.year, parts.month - 1, 1))
      }
      // Застосовуємо формат
      return this.dateHumanFormat('DD.MM.YYYY', d)
    },
    dateHumanFormat (format, date) {
      let day, month, year
      switch (format) {
        case 'dd.mm.yyyy':
          return date
        default:
          console.log('[Log]', date)
          day = date.getDate()
          month = date.getMonth()
          month = Number(month) + 1
          year = date.getFullYear()
          if (String(day).length === 1) {
            day = '0' + day
          }
          if (String(month).length === 1) {
            month = '0' + month
          }
          return day + '.' + month + '.' + year
      }
    },
    dateHumanParts (date) {
      let day = date.getDate()
      let month = date.getMonth()
      let year = date.getFullYear()
      return {
        day,
        month,
        year
      }
    }
  }
}
</script>
