<template>
  <div>
    <div class="caption">Експорт картки</div>
    <a href="#" class="btn-green"><i class="ico-44"></i>Додати діагноз</a>
  </div>
</template>

<script>
export default {
  name: 'ExportCard'
}
</script>

<style scoped>

</style>
