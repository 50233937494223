<template>
  <div>
    <TopDirector></TopDirector>
    <div class="content">
      <div class="filter-above">
        <ul class="list-filter-checkbox">
          <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Групи</span></label></li>
          <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Лікар</span></label></li>
          <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Пацієнт</span></label></li>
          <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Процедури</span></label></li>
        </ul>
      </div>
      <div class="table-wrapper">
        <table class="director-table">
          <thead>
            <tr>
              <th rowspan="3">
                <div class="caption">Процедура</div>
              </th>
              <th></th>
              <th></th>
              <th rowspan="2">
                <div class="caption">Витрати за процедурою</div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr class="thead-bottom">
            <td>
              <div class="caption">Назва</div>
            </td>
            <td>
              <div class="caption">План</div>
            </td>
            <td>
              <div class="caption">Факт</div>
            </td>
            <td>
              <div class="caption">Сума</div>
            </td>
            <td>
              <div class="caption">Коментар</div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TopDirector from '../modules/TopDirector'
export default {
  name: 'CostsProceduresManager',
  components: {
    TopDirector
  }
}
</script>

<style scoped>

</style>
