<template>
  <div>
    <top-manager></top-manager>
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="show('addCard')"><i class="ico-11"></i> Додати новий запис</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold table-column-tree">
          <thead>
          <tr>
            <th>
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th>
              <div class="caption">Номер</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Власник</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Знижка, %</div>
              <input type="text" class="custom-input">
            </th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(card, index) of cards" :key="card.id">
            <td>
              <label class="checkbox-custom ico-text-after"><input type="checkbox"><span></span></label>
            </td>
            <td>{{ card.id }}</td>
            <td>{{ card.patientid.fullname }}</td>
            <td>{{ card.summ }}</td>
            <td><a href="#" class="btn-table-change" @click.prevent="edit(index)"><i class="ico-19"></i></a></td>
            <td><a href="#" class="btn-table-change" @click.prevent="remove(card.id)"><i class="ico-20"></i></a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal name="addCard" class="modal-custom" :width="720" height="auto" :scrollable="true">
      <form @submit.prevent="saveForm">
        <div class="caption-modal">
          {{form.title}}
          <a href="#" class="close-modal" @click.prevent="hide ()"><i class="ico-17"></i></a>
        </div>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">Процент</div>
            <div class="input-wr"><input type="text" v-model="form.summ" class="custom-input" :class="{'input-v-error':$v.form.summ.$error}" @change="$v.form.summ.$touch()"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Власник</div>
            <div class="input-wr">
              <v-select @search="fetchOptions" :options="patients" v-model="form.patientid" label="fullname"></v-select>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">
              <label class="checkbox-custom with-text ico-text-after"><input type="checkbox" v-model="form.enabled"><span>Активна</span></label>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">Зберегти</button>
            <button class="btn-red" @click.prevent="hide()">Скасувати</button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { minValue } from '@vuelidate/validators'
export default {
  name: 'DiscountCards.vue',
  data () {
    return {
      cards: [],
      form: {
        title: 'Додавання дисконтної картки',
        summ: 0,
        patientid: 0,
        enabled: true,
        id: 0
      },
      url: '/discountcard',
      patients: []
    }
  },
  created () {
    this.$http.get(this.url + '/index')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    onsearch (e) {
      if (e.target.value) {
        this.listFinded = this.list.filter(el => el.text.indexOf(e.target.value) !== -1)
      } else {
        this.listFinded = this.list
      }
    },
    show (modal) {
      this.$modal.show(modal)
    },
    hide () {
      this.$modal.hide('addCard')
      this.clear()
    },
    setData (data) {
      this.cards = data.cards
      this.patients = data.patients
    },
    clear () {
      this.form.title = 'Додавання дисконтної картки'
      this.form.summ = 0
      this.form.patientid = 0
      this.form.id = 0
      this.form.enable = 1
    },
    saveForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$http.post(this.url + '/save', {
          summ: this.form.summ,
          patientid: this.form.patientid.id,
          enabled: this.form.enabled ? 1 : 0,
          id: this.form.id
        })
          .then(result => {
            this.setData(result.data)
            this.hide('addCard')
            this.clear()
          })
      }
    },
    edit (index) {
      let item = this.cards[index]
      this.form.title = 'Редагування дисконтної картки'
      this.form.id = item.id
      this.form.summ = item.summ
      this.form.patientid = item.patientid
      this.form.enabled = item.enabled
      this.show('addCard')
    },
    fetchOptions (search, loading) {
      loading(true)
      this.$http.get('/patient/search', {
        params: {
          limit: 20,
          offset: 0,
          search: {
            firstname: search,
            middlename: search,
            lastname: search
          }
        }
      })
        .then(result => {
          this.patients = result.data.patients
          loading(false)
        })
        .catch(() => {
          this.$root.alert('error', 'Помилка пошуку пацієнта')
          loading(false)
        })
    },
    remove (id) {
      if (confirm('Видали картку?')) {
        this.$http.delete(this.url + '/delete', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.setData(result.data)
          })
      }
    }
  },
  validations: {
    form: {
      summ: {
        minValue: minValue(1)
      }
    }
  }
}
</script>

<style scoped>

</style>
