<template>
  <div>
    <top-director></top-director>
    <div class="content">
      <div class="table-wrapper">
        <table class="director-table">
          <thead>
            <tr>
              <th class="inform-th">
                <div class="caption">Група бюджету</div>
              </th>
              <th>
                <div class="caption">Готівка +</div>
              </th>
              <th>
                <div class="caption">Готівка –</div>
              </th>
              <th>
                <div class="caption">Сума готівко</div>
              </th>
              <th>
                <div class="caption">Безготівка +</div>
              </th>
              <th>
                <div class="caption">Безготівка –</div>
              </th>
              <th>
                <div class="caption">Сума безготівкою</div>
              </th>
              <th>
                <div class="caption">Загальна сума</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><div class="main-info">Пацієнти</div></td>
              <td class="green-td">
                <div class="price-td">1979697.70</div>
              </td>
              <td></td>
              <td class="green-td">
                <div class="price-td">1979697.70</div>
              </td>
              <td class="green-td">
                <div class="price-td">175037.50</div>
              </td>
              <td></td>
              <td class="green-td">
                <div class="price-td">175037.50</div>
              </td>
              <td class="green-td">
                <div class="price-td">2154735.20</div>
              </td>
            </tr>
            <tr>
              <td><div class="main-info">ЗП лікарів (%)</div><div class="under-main-info">видано</div></td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td class="">
                <div class="price-td">0</div>
              </td>
            </tr>
            <tr>
              <td><div class="main-info">ЗП лікарів (%)</div><div class="under-main-info">Витрати</div></td>
              <td></td>
              <td>
                <div class="under-price-td">-5088.00</div>
              </td>
              <td>
                <div class="price-td">0</div>
                <div class="under-price-td">-5088.00</div>
              </td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td class="">
                <div class="price-td">0</div>
                <div class="under-price-td">-5088.00</div>
              </td>
            </tr>
            <tr>
              <td><div class="main-info">Невизначені до груп</div></td>
              <td class="green-td">
                <div class="price-td">2200.00</div>
              </td>
              <td class="red-td">
                <div class="price-td">-62280.00</div>
              </td>
              <td class="red-td">
                <div class="price-td">-60080.00</div>
              </td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td class="red-td">
                <div class="price-td">-60080.00</div>
              </td>
            </tr>
            <tr>
              <td><div class="main-info">ЗП лікарів (%)</div><div class="under-main-info">видано</div></td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td class="">
                <div class="price-td">0</div>
              </td>
            </tr>
            <tr>
              <td><div class="under-main-info">Службове внесення</div></td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td class="">
                <div class="price-td">0</div>
              </td>
            </tr>
            <tr>
              <td><div class="under-main-info">Повернення коштів</div></td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td class="">
                <div class="price-td">0</div>
              </td>
            </tr>
            <tr>
              <td><div class="under-main-info">Службова видача</div></td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td></td>
              <td></td>
              <td class="">
                <div class="price-td">0</div>
              </td>
              <td class="">
                <div class="price-td">0</div>
              </td>
            </tr>
            <tr>
              <td><div class="main-info">Сума</div></td>
              <td class="green-td">
                <div class="price-td">2200.00</div>
              </td>
              <td class="red-td">
                <div class="price-td">62280.00</div>
              </td>
              <td class="green-td">
                <div class="price-td">60080.00</div>
              </td>
              <td class="green-td">
                <div class="price-td">2200.00</div>
              </td>
              <td></td>
              <td class="green-td">
                <div class="price-td">2200.00</div>
              </td>
              <td class="green-td">
                <div class="price-td">2200.00</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Budget-Page'
}
</script>

<style scoped>

</style>
