<template>
  <div class="patient-modal">
    <form>
      <div class="caption-modal">
        Додавання дисконтної картки
        <a href="#" class="close-modal" @click.prevent="hide ()"><i class="ico-17"></i></a>
      </div>
      <div class="body-modal">
        <div class="line-modal-form">
          <div class="description">Номер</div>
          <div class="input-wr"><input type="text" class="custom-input"></div>
        </div>
        <div class="line-modal-form">
          <div class="description">Сума</div>
          <div class="input-wr"><input type="text" class="custom-input"></div>
        </div>
        <div class="line-modal-form">
          <div class="description">Власник</div>
          <div class="input-wr">
            <!--              <stf-select v-model="value">-->
            <!--                <div slot="label"></div>-->
            <!--                <div slot="value">-->
            <!--                  <div v-if="value">-->
            <!--                    <span>({{value.workStatus}}) {{value.fullName}}</span>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div slot="search-input">-->
            <!--                  <input @input="onsearch">-->
            <!--                </div>-->
            <!--                <section class="options delivery_order__options">-->
            <!--                  <stf-select-option-->
            <!--                    v-for="item of listFinded" :key="item.id"-->
            <!--                    :value="item"-->
            <!--                    :class="{'stf-select-option_selected': item.id === (value && value.id)}"-->
            <!--                  >-->
            <!--                    <span>({{item.workStatus}}) {{item.fullName}}</span>-->
            <!--                  </stf-select-option>-->
            <!--                </section>-->
            <!--              </stf-select>-->
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            <label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Активна</span></label>
          </div>
        </div>
      </div>
      <div class="footer-modal text-right">
        <button class="btn-green">Зберегти</button>
        <button class="btn-red" @click.prevent="hide ()">Скасувати</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ModalPatient'
}
</script>

<style scoped>

</style>
