<template>
  <div>
    <Navbar></Navbar>
    <Header1></Header1>
    <div class="content">
      <div class="box-admin">
        <form action="">
          <div class="line-radio-fl">
            <div class="one-item">
              <span>Запустити вибірку за період</span> <div class="custom-radio"><label><input type="radio" name="accepted"><span>принятих пацієнтів</span></label></div>
            </div>
            <div class="one-item"><span>або</span></div>
            <div class="one-item">
              <div class="custom-radio"><label><input type="radio" name="accepted"><span>виконаних процедур</span></label></div>
              <span>з фільтром по лікарю (для пацієнтів) або розділу стоматології (для процедур)</span>
            </div>
          </div>
          <div class="line-admin-fl">
            <div class="name">Прив’язати даний комп’ютер до кабінету:</div>
            <div class="box-select">
<!--              <stf-select v-model="value3">-->
<!--                <div slot="label"></div>-->
<!--                <div slot="value">-->
<!--                  <div v-if="value3">-->
<!--                    <span>{{value3.supportStatus}}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div slot="search-input">-->
<!--                  <input @input="onsearch3">-->
<!--                </div>-->
<!--                <section class="options delivery_order__options">-->
<!--                  <stf-select-option-->
<!--                    v-for="item of listFinded3" :key="item.id"-->
<!--                    :value="item"-->
<!--                    :class="{'stf-select-option_selected': item.id === (value3 && value3.id)}"-->
<!--                  >-->
<!--                    <span>{{item.supportStatus}}</span>-->
<!--                  </stf-select-option>-->
<!--                </section>-->
<!--              </stf-select>-->
            </div>
          </div>
          <div class="line-admin-fl">
            <div class="name">Налаштування прайс-листа на даному комп’ютері</div>
            <div class="box-select">
<!--              <stf-select v-model="value4">-->
<!--                <div slot="label"></div>-->
<!--                <div slot="value">-->
<!--                  <div v-if="value4">-->
<!--                    <span>{{value4.supportStatus}}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div slot="search-input">-->
<!--                  <input @input="onsearch4">-->
<!--                </div>-->
<!--                <section class="options delivery_order__options">-->
<!--                  <stf-select-option-->
<!--                    v-for="item of listFinded4" :key="item.id"-->
<!--                    :value="item"-->
<!--                    :class="{'stf-select-option_selected': item.id === (value4 && value4.id)}"-->
<!--                  >-->
<!--                    <span>{{item.supportStatus}}</span>-->
<!--                  </stf-select-option>-->
<!--                </section>-->
<!--              </stf-select>-->
            </div>
          </div>
          <div class="line-admin-fl">
            <div class="name">Оберіть період відбору</div>
            <div class="box-select">
              <div class="input-wr line-btn-right">
<!--                <VueDatePicker :value="date4" :locale="locale"/>-->
              </div>
            </div>
          </div>
          <div class="line-admin-fl">
            <div class="name"></div>
            <div class="box-select">
              <label class="checkbox-custom with-text ico-text-after">
                <input type="checkbox">
                <span>згурупувати по лікарях</span>
              </label>
            </div>
          </div>
          <div class="line-admin-fl">
            <div class="name"></div>
            <div class="box-select">
              <button class="btn-green small">Показати</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AcceptedPatients',
  data () {
    return {
      date4: new Date([2019, 12, 25]),
      value3: null,
      list3: [
        {
          supportStatus: 'Ціна та повний доступ',
          id: 1
        },
        {
          supportStatus: 'Ціна',
          id: 2
        },
        {
          supportStatus: 'Повний доступ',
          id: 3
        }
      ],
      listFinded3: [],
      value4: null,
      list4: [
        {
          supportStatus: 'Без прив’язки до кабінету',
          id: 1
        },
        {
          supportStatus: 'Прив’язка до кабінету',
          id: 2
        }
      ],
      listFinded4: []
    }
  },
  created () {
    this.listFinded3 = this.list3
    this.listFinded4 = this.list4
  },
  methods: {
    onsearch3 (e) {
      if (e.target.value3) {
        this.listFinded3 = this.list3.filter(el => el.supportStatus.indexOf(e.target.value3) !== -1)
      } else {
        this.listFinded3 = this.list3
      }
    },
    onsearch4 (e) {
      if (e.target.value4) {
        this.listFinded4 = this.list4.filter(el => el.supportStatus.indexOf(e.target.value4) !== -1)
      } else {
        this.listFinded4 = this.list4
      }
    }
  }
}
</script>

<style scoped>

</style>
