<template>
  <el-dialog
      v-model="$store.state.modal.registerPatient"
      width="50%"
      :title="$t('Реєстрація нового пацієнта')"
      class="modal-custom"
  >
    <form @submit.prevent="save">
      <div class="body-modal form-long">
        <div class="line-modal-form">
          <div class="description">
            {{ $t('П.І.Б') }}. *
          </div>
          <div class="input-wr">
            <input
              v-model="v$.fullname.$model"
              type="text"
              style="text-transform: capitalize;"
              class="custom-input"
              :class="{'input-v-error':v$.fullname.$errors.length}"
            >
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Стать') }}
          </div>
          <div class="input-wr">
            <ul class="radio-list flex">
              <li class="custom-radio">
                <label>
                  <input
                    v-model="sex"
                    type="radio"
                    value="1"
                  >
                  <span>{{ $t('жіноча') }}</span>
                </label>
              </li>
              <li class="custom-radio">
                <label>
                  <input
                    v-model="sex"
                    type="radio"
                    value="0"
                  >
                  <span>{{ $t('чоловіча') }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Дата народження') }}
          </div>
          <div class="input-wr line-btn-right">
            <el-date-picker
              v-model="birthday"
              type="date"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
              :placeholder="$t('Дата')"
              :picker-options="pickerOptions"
            />
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Житель населеного пункту') }}
          </div>
          <div class="input-wr">
            <ul class="radio-list flex">
              <li class="custom-radio">
                <label>
                  <input
                    v-model="villageorcity"
                    type="radio"
                    value="1"
                    checked
                  >
                  <span>{{ $t('місто') }}</span>
                </label>
              </li>
              <li class="custom-radio">
                <label>
                  <input
                    v-model="villageorcity"
                    type="radio"
                    value="2"
                  >
                  <span>{{ $t('село') }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Країна') }}
          </div>
          <div class="input-wr">
            <input
              v-model="v$.country.$model"
              type="text"
              class="custom-input"
              :class="{'input-v-error':v$.country.$errors.length}"
            >
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Область') }}
          </div>
          <div class="input-wr">
            <input
              v-model="v$.state.$model"
              type="text"
              class="custom-input"
              :class="{'input-v-error':v$.state.$errors.length}"
              @change="$v.state.$touch()"
            >
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Район') }}
          </div>
          <div class="input-wr">
            <input
              v-model="ragion"
              type="text"
              class="custom-input"
            >
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Населений пункт') }}
          </div>
          <div class="input-wr">
            <input
              v-model="v$.city.$model"
              type="text"
              class="custom-input"
              :class="{'input-v-error':v$.city.$errors.length}"
            >
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Адреса') }}
          </div>
          <div class="input-wr">
            <input
              v-model="v$.address.$model"
              type="text"
              class="custom-input"
              :class="{'input-v-error':v$.address.$errors.length}"
            >
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Індекс') }}
          </div>
          <div class="input-wr">
            <input
              v-model="postalcode"
              type="text"
              class="custom-input"
            >
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Телефон') }} *
          </div>
          <div class="input-wr">
            <input
              v-model="v$.mobilenumber.$model"
              v-maska="'+380 (##) ### ## ##'"
              class="custom-input"
              :class="{'input-v-error':v$.mobilenumber.$errors.length}"
            />
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Застрахований') }}
          </div>
          <div class="input-wr">
            <ul class="radio-list flex">
              <li class="custom-radio">
                <label>
                  <input
                    v-model="has_insurance"
                    type="radio"
                    value="0"
                  >
                  <span>{{ $t('ні') }}</span>
                </label>
              </li>
              <li class="custom-radio">
                <label>
                  <input
                    v-model="has_insurance"
                    type="radio"
                    value="1"
                  >
                  <span>{{ $t('так') }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">
            {{ $t('Дисконт') }}
          </div>
          <div class="input-wr number-counter degree">
            <input
              type="text"
              class="custom-input"
              :value="discount"
            >
            <span class="degree-text full-flex-center">%</span>
            <a
              href="#"
              class="priceBtn count-minus"
              @click.prevent="discount > 0 ? discount-- : false"
            ><i class="ico-28" /></a>
            <a
              href="#"
              class="priceBtn count-plus"
              @click.prevent="discount++"
            ><i class="ico-27" /></a>
          </div>
        </div>
        <div class="line-modal-form text-top">
          <div class="description">
            {{ $t('Примітка') }}
          </div>
          <div class="input-wr">
            <textarea
              v-model="comment"
              class="mailing-textarea"
            />
          </div>
        </div>
      </div>
      <div class="footer-modal flex-footer">
        <div class="btn-left-footer" />
        <div class="btn-right-footer">
          <button class="btn-green">
            {{ $t('Зберегти') }}
          </button>
          <button
            type="button"
            class="btn-red"
            @click="cancel"
          >
            {{ $t('Скасувати') }}
          </button>
        </div>
      </div>
    </form>
  </el-dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {store} from '../../../store/index';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      showModal: false,
      fullname: '',
      sex: 0,
      birthday: new Date(),
      discount: 0,
      country: 'Україна',
      state: '',
      ragion: '',
      city: '',
      address: '',
      postalcode: 43000,
      mobilenumber: '',
      comment: '',
      villageorcity: 1,
      has_insurance: 0,
      pickerOptions: {
        firstDayOfWeek: 1
      },
      store
    }
  },
  validations () {
    return {
      fullname: {
        required
      },
      country: {},
      state: {},
      city: {},
      address: {},
      mobilenumber: {
        required
      }
    }
  },
  methods: {
    save () {
      if (!this.v$.$invalid) {
        const data = {
          fullname: this.fullname,
          sex: this.sex,
          birthday: this.birthday,
          discount: this.discount,
          country: this.country,
          state: this.state,
          ragion: this.ragion,
          city: this.city,
          address: this.address,
          postalcode: this.postalcode,
          mobilenumber: this.mobilenumber,
          comment: this.comment,
          villageorcity: this.villageorcity,
          has_insurance: this.has_insurance
        }
        this.$http.post('/patient/create', data)
          .then(result => {
            this.$emit('updatePatient', result.data.list)
            this.emitter.emit('newPatient', result.data.current)
            this.cancel()
            ElMessage({
              message: $t('Картку створено'),
              type: 'success',
            })
          })
          .catch(() => {
            ElMessage({
              message: $t('Помилка створення картки'),
              type: 'error',
            })
          })
      }
    },
    clear () {
      this.fullname = ''
      this.sex = 0
      this.birthday = new Date()
      this.discount = 0
      this.country = ''
      this.state = ''
      this.ragion = ''
      this.city = ''
      this.address = ''
      this.postalcode = ''
      this.mobilenumber = ''
      this.comment = ''
      this.villageorcity = 1
      this.showModal = false
    },
    cancel () {
      this.$modal.hide('registerPatient')
      this.clear()
    }
  }
}
</script>

<style scoped>

</style>
