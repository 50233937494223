<template>
  <div>
    <div class="caption">Файловий менеджер</div>
    <form action="">
      <div class="search-line">
        <ul class="search-btn">
          <li class="tooltip-wrapper">
            <a href="javascript:;" id="uppyModalOpener" class="full-flex-center btn-border-grey"><i class="ico-47"></i></a>
            <div class="tooltip">Завантажити файл</div>
          </li>
        </ul>
        <div class="search-field">
          <button class="search-btn"><i class="ico-49"></i></button>
          <input type="text" @keyup="searchFile" class="custom-input" placeholder="Пошук">
        </div>
      </div>
    </form>
    <div class="search-result">
      <div
        class="item"
        v-for="(file, index) in filterFiles"
        :key="file.id"
      >
        <div>
          <textarea :value="file.name"></textarea>
          <div class="box-img full-flex-center">
            <img v-if="['jpg', 'gif', 'jpeg', 'png'].includes(file.extention)" :src="$root.serverUrl + '/rest/source/' + file.servername" alt="img" class="object-fit">
            <img v-if="!['jpg', 'gif', 'jpeg', 'png', 'pdf'].includes(file.extention)" :src="FileList" alt="img" style="width: 100px">
            <img v-if="['pdf'].includes(file.extention)" src="../../assets/images/ic_picture_as_pdf_24px.svg" alt="img" style="width: 100px">
          </div>
          <ul class="bottom-btn pay-box-btn average-btn">
            <li v-if="!['pdf'].includes(file.extention)" class="tooltip-wrapper">
              <a :href="$root.serverUrl + '/rest/source/' + file.servername" download class="full-flex-center brand-hover2 with-border">
                <img :src="Download" style="width: 20px">
              </a>
              <div class="tooltip">Завантажити</div>
            </li>
            <li v-if="['pdf'].includes(file.extention)" class="tooltip-wrapper">
              <a :href="$root.serverUrl + '/rest/source/' + file.servername" target="_blank" class="full-flex-center brand-hover2 with-border">
                <img :src="EyeLine" style="width: 20px">
              </a>
              <div class="tooltip">Переглянути</div>
            </li>
<!--            <li class="tooltip-wrapper">-->
<!--              <a href="#" class="full-flex-center brand-hover2 with-border"><i class="ico-46"></i></a>-->
<!--              <div class="tooltip">Показати всі рахунки пацієнта</div>-->
<!--            </li>-->
            <li class="tooltip-wrapper">
            </li>
            <li class="tooltip-wrapper">
              <a href="#" @click.prevent="del(file.id, index)" class="full-flex-center brand-hover2 with-border"><i class="ico-17"></i></a>
              <div class="tooltip">Видалити</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileList from '../../assets/images/File List 2 Line.svg';
import Download from '../../assets/images/Download 2 Fill.svg';
import EyeLine from '../../assets/images/Eye Line.svg';
export default {
  data () {
    return {
      pid: this.id,
      files: [],
      filterFiles: []
    }
  },
  async created () {
    await this.$http.get('/files/file-manager', {params: {id: this.pid}})
      .then(result => {
        this.files = this.filterFiles = result.data
      })
  },
  methods: {
    del (id, index) {
      if (confirm('Видалити файл?')) {
        this.$http.delete('/files/delete', {
          params: {
            id: id
          }
        })
          .then(() => {
            this.filterFiles.splice(index, 1)
          })
      }
    },
    searchFile (e) {
      this.filterFiles = this.files.filter(file => file.name.indexOf(e.target.value) > -1)
    }
  },
  props: {
    id: {
      default: 0
    }
  },
  mounted () {
    let self = this
    // eslint-disable-next-line
    const uppy = Uppy.Core({
      debug: true,
      autoProceed: false,
      // eslint-disable-next-line
      locale: Uppy.locales.ru_RU,
      restrictions: {
        allowedFileTypes: ['*/*']
      }
    })
    // eslint-disable-next-line
      .use(Uppy.Dashboard, { trigger: '#uppyModalOpener' })
      // eslint-disable-next-line
      .use(Uppy.Webcam, {target: Uppy.Dashboard})
      // eslint-disable-next-line
      .use(Uppy.XHRUpload, { endpoint: process.env.APP_URL + '/rest/v1/files/upload-patient?pid=' + self.pid })

    uppy.on('upload-success', (file, response) => {
      self.files.push({name: file.name, source: file.preview, servername: response.body.data.servername})
    })
  }
}
</script>

<style scoped>

</style>
