<template>
  <ul
    class="pagination"
    v-show="page > 1"
  >
    <li
      :class="{'disabled': activePage === 1}"
    >
      <a
        @click.prevent="prev"
        href="#"
      >Назад</a>
    </li>
    <li
      v-for="i in leftPage"
      :key="i"
    >
      <a href="#" @click.prevent="go(i)" :class="{'active': i === active}">{{i}}</a></li>
    <li><a href="#" @click.prevent="next">Далі</a></li>
  </ul>
</template>

<script>
export default {
  name: 'PaginationComponent',
  data () {
    return {
      leftPage: [],
      maxPage: 20,
      active: this.activePage
    }
  },
  props: {
    page: {
      default: 0
    },
    activePage: {
      default: 1
    }
  },
  created () {
    this.calc()
  },
  watch: {
    active () {
      this.calc()
    },
    page () {
      this.calc()
    }
  },
  methods: {
    calc () {
      this.leftPage = []
      let minPage = 1
      let maxPage = 9
      if (this.active > 5) {
        minPage = this.active - 4
        maxPage = this.active + 4
      }
      for (let i = minPage; i <= maxPage && i <= this.page; i++) {
        this.leftPage.push(i)
      }
    },
    go (page) {
      this.active = page
      this.$emit('goPage', page)
    }
  }
}
</script>

<style scoped>

</style>
