<template>
  <div>
    <el-dialog
        v-model="$store.state.modal.addLab"
        width="720px"
        :title="$t('Новий запис')"
        class="modal-custom"
    >
      <form @submit.prevent="save">
        <div class="body-modal">
          <div class="line-modal-form" v-show="doctors.length > 1">
            <div class="description">Лікар</div>
            <div class="input-wr">
              <el-select v-model="form.doctor_id" style="width: 100%">
                <el-option
                    v-for="item in doctors"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Примітка</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="form.comment">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Тиждень вагітності</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="form.preg_week">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Фаза менструального циклу</div>
            <div class="input-wr">
              <el-select v-model="form.menstrphase" style="width: 100%">
                <el-option
                    v-for="item in faza"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="line-modal-form" style="display: block">
            <div class="description" style="display: flex;align-items: center">Аналізи <a style="margin-left: 10px" class="plus-btn-ico" @click.prevent="$modal.show('addL')" href="#"><i class="ico-05"></i></a></div>
            <table style="width: 100%;margin-top: 20px;">
              <tbody>
              <tr v-for="(item, index) in form.items" :key="item.id">
                <td>{{item.name}}</td>
                <td>{{Number(item.price)/100}}</td>
                <td style="width: 50px;color: red;cursor: pointer"><i class="ico-20" @click="removeInLabs(index)"></i></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="submit" class="btn-green">Зберегти</button>
            <button class="btn-red" @click.prevent="$modal.hide('addLab')">Скасувати</button>
          </div>
        </div>
      </form>
    </el-dialog>

    <!-- View -->
    <el-dialog
        v-model="$store.state.modal.viewLab"
        width="720px"
        :title="$t('Перегляд аналізу')"
        class="modal-custom"
    >
      <form @submit.prevent="update">
        <div class="body-modal" v-if="view !== null">
          <div class="line-modal-form">
            <div class="description">Примітка</div>
            <div class="input-wr">
              {{view.comment}}
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Тиждень вагітності</div>
            <div class="input-wr">
              {{view.preg_week}}
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Фаза менструального циклу</div>
            <div class="input-wr">
              {{view.menstrphase}}
            </div>
          </div>
          <div class="line-modal-form" style="display: block">
            <div class="description" style="display: flex;align-items: center">Аналізи <a style="margin-left: 10px" class="plus-btn-ico" @click.prevent="$modal.show('addL')" href="#"><i class="ico-05"></i></a></div>
            <table style="width: 100%;margin-top: 20px;">
              <tbody>
              <tr v-for="(item, index) in view.items" :key="item.id">
                <td>{{item.code}}</td>
                <td>{{item.name}}</td>
                <td>
                  <div v-for="(file, index) in item.pdf" :key="index"><a style="color: #0cbc3d;margin-bottom: 5px;" target="_blank" :href="file.link">Переглянути pdf</a></div>
                </td>
                <td style="width: 50px;color: red;cursor: pointer"><i class="ico-20" @click="removeInServer(index)"></i></td>
              </tr>
              <tr v-for="(item, index) in view.newItems" :key="item.id">
                <td>{{item.code}}</td>
                <td>{{item.name}}</td>
                <td style="width: 50px;color: red;cursor: pointer"><i class="ico-20" @click="removeInLabs(index)"></i></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="submit" class="btn-green">Зберегти</button>
            <button class="btn-red" @click.prevent="closeView">Закрити</button>
          </div>
        </div>
      </form>
    </el-dialog>
    <!-- end view -->

    <!-- Самі аналізи -->
    <el-dialog
        v-model="$store.state.modal.addL"
        width="720px"
        :title="$t('Додати аналізи')"
        class="modal-custom"
    >
      <form @submit.prevent="addInLabs()">
        <div class="body-modal">
          <input type="search" v-model="searchString" class="search-input" placeholder="Пошук"/>
          <div class="step-1" style="height: 400px;overflow-y: scroll">
            <div class="handler" v-for="item in searchPriceList" :key="'search' + item.id">
              <i class="ico-35"></i>
              {{item.name}} <span>[{{Number(item.price)/100}}]</span>
              <div class="tool">
                <a href="#" class="btn-table-change" @click.prevent="addInLabs(item)">+</a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="button" @click.prevent="$modal.hide('addL')" class="btn-green">Закрити</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>
<script>

import {ElMessage} from "element-plus";

export default {
  data () {
    return {
      form: {
        comment: '',
        preg_week: 0,
        menstrphase: {id: '100', label: 'лютеїнова'},
        items: [],
        patient_id: this.patient_id,
        doctor_id: 0,
        cid: this.course_id
      },
      activeIndex: null,
      searchPriceList: [],
      searchString: '',
      faza: [{id: '100', label: 'лютеїнова'},{id: '200', label: 'овуляторна'},{id: '300', label: 'фолікулінова'},{id: '400', label: 'пременопауза'},{id: '500', label: 'менопауза'},{id: '600', label: 'постменопауза'}],
    }
  },
  validations: {

  },
  watch: {
    searchString: function (newValue, oldValue) {
      if (newValue !== '') {
        this.searchPriceList = this.priceList.filter(e => {
          const planName = e.name.toLowerCase()
          const code = e.code === null ? '' : e.code
          return planName.indexOf(newValue.toLowerCase()) !== -1 || code.indexOf(newValue.toLowerCase()) !== -1
        })
      } else {
        this.priceSearch = this.priceList
      }
    },
    priceList: function (newValue) {
      this.searchPriceList = this.priceList
    }
  },
  methods: {
    /** Додавання аналізів **/
    addInLabs (item) {
      if (this.view === null) {
        this.form.items.push(item)
      } else {
        this.view.newItems.push(item)
      }
      ElMessage({
        message: this.$t('Аналіз додано'),
        type: 'success',
      })
    },
    /**
     * Видалення з списку аналізів
     * @param index
     */
    removeInLabs (index) {
      if (this.view === null) {
        this.form.items.splice(index, 1)
      } else {
        this.form.newItems.splice(index, 1)
      }
      ElMessage({
        message: this.$t('Аналіз видалено'),
        type: 'success',
      })
    },
    save () {
      this.$http.post('/lab/save', this.form).then(result => {
        //this.articles.push(result.data.model)
        this.emitter.emit('addTreatment', result.data.items)
        this.$modal.hide('addLab')
      }).catch(error => {
        ElMessage({
          message: error.response ? error.response.data : error.message,
          type: 'error',
        })
      })
    },
    update () {
      this.$http.put('/lab/update', this.view).then(result => {
        if (this.activeIndex !== null) {
          this.articles[this.activeIndex] = result.data.model
          this.closeView()
        }
      }).catch(error => {
        this.$toastr.e(error.response ? error.response.data : error.message);
      })
    },
    viewLab (article, index) {
      this.activeIndex = index
      this.view = article
      this.$set(this.view, 'newItems', [])
      this.$modal.show('viewLab')
    },
    closeView () {
      this.activeIndex = null
      this.$modal.hide('viewLab')
    },
    removeInServer (index) {
      this.$http.delete('/lab/remove',  {
        data: {
          id: this.view.id,
          item_id: this.view.items[index].id
        }
      }).then(result => {
        this.view = result.data.model
      })
    }
  },
  props: {
    doctors: {
      default: Array
    },
    patient_id: {
      default: Number
    },
    view: {
      default: Object
    },
    priceList: {
      default: Object
    },
    course_id: {
      default: Number
    }
  }
}
</script>
