<template>
  <div>
    <Navbar></Navbar>
    <Header1></Header1>
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="show ()"><i class="ico-11"></i> Додати кабінет</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold">
          <thead>
          <tr>
            <th style="width: 46px;">
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th style="width: 90px;">
              <div class="caption">№</div>
<!--              <stf-select v-model="value4">-->
<!--                <div slot="label"></div>-->
<!--                <div slot="value">-->
<!--                  <div v-if="value4">-->
<!--                    <span>{{value4.supportStatus}}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div slot="search-input">-->
<!--                  <input @input="onsearch4">-->
<!--                </div>-->
<!--                <section class="options delivery_order__options">-->
<!--                  <stf-select-option-->
<!--                    v-for="item of listFinded4" :key="item.id"-->
<!--                    :value="item"-->
<!--                    :class="{'stf-select-option_selected': item.id === (value4 && value4.id)}"-->
<!--                  >-->
<!--                    <span>{{item.supportStatus}}</span>-->
<!--                  </stf-select-option>-->
<!--                </section>-->
<!--              </stf-select>-->
            </th>
            <th style="width: 30%;">
              <div class="caption">Назва кабінету</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 30%"></th>
            <th style="width: 70px"><a href="#" class="btn-watch" :class="{'active': watchAll}" @click.prevent="lookedBtnForm" ><i class="ico-30"></i></a></th>
            <th style="width: 40px;"></th>
            <th style="width: 40px;"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="person of articles" :key="person.id">
            <td>
              <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
            </td>
            <td>{{ person.number }}</td>
            <td>{{ person.name }}</td>
            <td><div class="badge-color" :style="{background: person.color}"></div></td>
            <td><a href="#" class="btn-watch" :class="{'active': person.looked, 'active': watchAll}" @click.prevent="person.looked = !person.looked"><i class="ico-30"></i></a></td>
            <td><a href="#" class="btn-table-change"><i class="ico-19"></i></a></td>
            <td><a href="#" class="btn-table-change"><i class="ico-20"></i></a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal name="addOrder" class="modal-custom" :width="720" height="auto" :scrollable="true">
      <form>
        <div class="caption-modal">
          Додавання нового запису
          <a href="#" class="close-modal" @click.prevent="hide ()"><i class="ico-17"></i></a>
        </div>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">№</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Послідовність</div>
            <div class="input-wr"><input type="text" class="custom-input" placeholder="0"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Назва кабінету</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description">Опис</div>
            <div class="input-wr">
              <textarea class="mailing-textarea h120"></textarea>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">Зберегти</button>
            <button class="btn-red" @click.prevent="hide ()">Скасувати</button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'WorkplacesPage',
  data () {
    return {
      articles: [
        {
          id: 1,
          number: '17',
          name: 'Ващук К.А.',
          color: '#fef0e5',
          looked: false,
          checked: false
        },
        {
          id: 2,
          number: '19',
          name: 'Назарчук С.Ф.',
          color: '#fe0710',
          looked: false,
          checked: false
        },
        {
          id: 3,
          number: '15',
          name: 'Нелепа Л.О.',
          color: '#fe6fff',
          looked: false,
          checked: false
        },
        {
          id: 4,
          number: '17',
          name: 'Оксюта О.М.',
          color: '#9b51e0',
          looked: false,
          checked: false
        },
        {
          id: 5,
          number: '14',
          name: 'Петрова Л.С.',
          color: '#035bbc',
          looked: false,
          checked: false
        },
        {
          id: 6,
          number: '17',
          name: 'Борисюк І.В.',
          color: '#0e4667',
          looked: false,
          checked: false
        },
        {
          id: 7,
          number: '19',
          name: 'Бісюк М.В.',
          color: '#00fefe',
          looked: false,
          checked: false
        }
      ],
      checked: false,
      watchAll: false,
      value4: null,
      list4: [
        {
          supportStatus: 1,
          id: 1
        },
        {
          supportStatus: 2,
          id: 2
        },
        {
          supportStatus: 3,
          id: 3
        },
        {
          supportStatus: 4,
          id: 4
        }
      ],
      listFinded4: [
      ]
    }
  },
  created () {
    this.listFinded4 = this.list4
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    onsearch4 (e) {
      if (e.target.value4) {
        this.listFinded4 = this.list4.filter(el => el.supportStatus.indexOf(e.target.value4) !== -1)
      } else {
        this.listFinded4 = this.list4
      }
    },
    lookedBtnForm: function () {
      this.watchAll = !this.watchAll
    },
    show () {
      this.$modal.show('addOrder')
    },
    hide () {
      this.$modal.hide('addOrder')
    }
  }
}
</script>

<style scoped>

</style>
