<template>
  <div>
    <TopInfo />
    <div class="content">
      <div class="btn-above-table">
        <a href="#preventNote"  @click.prevent="show()" class="btn-green"><i class="ico-11"></i> {{$t('Додати протокол')}}</a>
        <a href="#preventNote" style="margin-left: 20px;"  @click.prevent="$modal.show('addRawProtocol')" class="btn-green"><i class="ico-11"></i> {{$t('Додати готовий протокол')}}</a>
        <a href="#preventNote" style="margin-left: 20px;" @click.prevent="showAddCategory()" class="btn-green"><i class="ico-11"></i> {{$t('Додати категорію')}}</a>
      </div>
      <div class="table-wrapper">
        <div id="table"/>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.addOrder"
        width="720px"
        :title="$t('Новий протокол')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal">
          <div>
            <div class="description">{{$t('Назва')}}</div>
            <div class="input-wr">
              <input type="text" v-model="form.name" class="custom-input">
            </div>
          </div>
          <div>
            <div class="description">{{$t('Категорія')}}</div>
            <div class="input-wr ">
              <select class="custom-input" v-model="form.category_id">
                <option v-for="option in categories" :key="option.id" :value="option.id">{{option.name}}</option>
              </select>
            </div>
          </div>
          <div class="protocol-answer" v-for="(answer, index) in form.answers" :key="index">
            <i v-show="index > 0" @click="removeAnswer(index)" class="remove"></i>
            <div class="basic">
              <input type="text" class="custom-input" v-model="answer.title" :placeholder="$t('Назва питання')">
              <select class="custom-input" v-model="answer.type">
                <option
                  v-for="type in types"
                  :key="type.key"
                  :value="type.key"
                >
                  {{type.name}}
                </option>
              </select>
              <input type="text" class="custom-input" v-model="answer.mask" :placeholder="$t('Маска')">
              <label><input type="checkbox" v-model="answer.has_break"/> \n</label>
            </div>
            <div class="options" v-show="answer.type === 'select' || answer.type === 'select_input'">
              <div class="one-option" v-for="(option, subIndex) in answer.options" :key="subIndex">
                <i class="remove" @click="removeOption(index, subIndex)"></i>
                <input
                  type="text"
                  class="custom-input"
                  v-model="option.value"
                >
              </div>
              <a href="#" @click.prevent="addOption(index)">{{$t('Додати варіант')}}</a>
            </div>
          </div>
          <a href="#" @click.prevent="addAnswer">{{$t('Додати питання')}}</a>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="button" @click="save" class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hide ()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>

    <el-dialog
        v-model="$store.state.modal.addRawProtocol"
        width="720px"
        :title="$t('Новий готовий протокол')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal">
          <div style="margin-bottom: 20px">
            <div class="description">{{$t('Назва')}}</div>
            <div class="input-wr ">
              <input type="text" v-model="formRaw.name" class="custom-input">
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div class="description">{{$t('Категорія')}}</div>
            <div class="input-wr ">
              <select class="custom-input" v-model="formRaw.category_id">
                <option v-for="option in categories" :key="option.id" :value="option.id">{{option.name}}</option>
              </select>
            </div>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Висновок')}}</div>
            <vue-editor v-model="formRaw.raw"/>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="button" @click="saveRaw" class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="$modal.hide('addRawProtocol')">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>

    <el-dialog
        v-model="$store.state.modal.addCategory"
        width="720px"
        :title="$t('Нова категорія')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal">
          <div>
            <div class="description">{{$t('Назва')}}</div>
            <div class="input-wr ">
              <input type="text" v-model="category.name" class="custom-input">
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="button" @click="saveCategory" class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hideAddCategory()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import { Grid, h, html } from "gridjs";
import {VueEditor} from "vue3-editor";

export default {
  name: 'TreatmentCourse',
  components: {VueEditor},
  data () {
    return {
      checked: false,
      types: [
        {
          name: 'Текст',
          key: 'comment'
        },
        {
          name: 'Текстове поле',
          key: 'text'
        },
        {
          name: 'Варіант вибору',
          key: 'select'
        },
        {
          name: 'Дата',
          key: 'date'
        },
        {
          name: 'Фото',
          key: 'photo'
        }
      ],
      form: {
        id: 0,
        category_id: null,
        name: '',
        answers: [
          {
            id: 0,
            title: '',
            type: 'text',
            options: [
              {
                value: ''
              }
            ],
            has_break: false,
            mask: ''
          }
        ]
      },
      formRaw: {
        id: 0,
        category_id: null,
        name: '',
        raw: '',
      },
      category: {
        name: ''
      },
      protocols: [],
      categories: [],
      editId: 0,
      grid: new Grid()
    }
  },
  created () {
    this.$http.get('/template/init')
      .then(result => {
        this.protocols = result.data.protocols
        this.categories = result.data.categories
        this.grid.updateConfig({
          columns: [{
            id: 'id',
            name: 'ID'
          },{
            id: 'name',
            name: this.$t('Назва')
          }, {
            id: 'category',
            name: this.$t('Категорія')
          },{
            id: 'action',
            name: '',
            width: '100px',
            formatter: (cell, row) => {
              const btn = []
              btn.push(h('a', {
                className: 'btn-table-change',
                onClick: () => {
                  this.edit(row._cells[0].data)
                }
              }, html('<i class="ico-19"></i>')));
              btn.push(h('a', {
                className: 'btn-table-change',
                onClick: () => {
                  this.deleteUser(row._cells[0].data)
                }
              }, html('<i class="ico-20"></i>')));
              return btn;
            }
          }],
          data: result.data.protocols,
          pagination: {
            enabled: true,
            limit: 20,
            summary: false,
          },
          search: {
            debounceTimeout: 1000,
          },
          language: {
            'search': {
              'placeholder': '🔍 ' + this.$t('Пошук')
            },
            'pagination': {
              'previous': '⬅️',
              'next': '➡️',
            }
          }
        });
        this.grid.render(document.getElementById("table"));
      })
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    show () {
      this.$modal.show('addOrder')
    },
    showAddCategory () {
      this.$modal.show('addCategory')
    },
    hide () {
      this.$modal.hide('addOrder')
    },
    hideAddCategory () {
      this.$modal.hide('addCategory')
    },
    addAnswer () {
      this.form.answers.push({
        title: '',
        type: 'text',
        options: [
          {
            value: ''
          }
        ],
        has_break: false,
        mask: ''
      })
    },
    addOption (index) {
      this.form.answers[index].options.push({
        value: ''
      })
    },
    removeAnswer (index) {
      this.form.answers.splice(index, 1)
    },
    removeOption (index, subIndex) {
      this.form.answers[index].options.splice(subIndex, 1)
    },
    save () {
      this.$http.post('/template/add', this.form)
        .then(result => {
          if (this.editId === 0) {
            this.protocols.push(result.data)
          } else {
            this.protocols[this.editId] = result.data
          }
          this.form.name = ''
          this.form.category_id = null
          this.form.id = 0
          this.editId = 0
          this.form.answers = [
            {
              id: 0,
              title: '',
              type: 'text',
              options: [
                {
                  value: ''
                }
              ],
              has_break: false,
              mask: ''
            }
          ]
          this.hide()
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    },
    saveRaw () {
      this.$http.post('/template/add-raw', this.formRaw)
        .then(result => {
          if (this.editId === 0) {
            this.protocols.push(result.data)
          } else {
            this.protocols[this.editId] = result.data
          }
          this.formRaw.name = ''
          this.formRaw.category_id = null
          this.formRaw.id = 0
          this.editId = 0
          this.$modal.hide('addRawProtocol');
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    },
    saveCategory () {
      this.$http.post('/template/add-category', this.category)
        .then(result => {
          this.categories.push(result.data)
          this.category.name = ''
          this.hideAddCategory()
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    },
    edit (id, index) {
      this.$http.get('/template/get?id=' + id)
        .then(result => {
          if (result.data.raw === null) {
            this.editId = index
            this.form.answers = []
            this.form.category_id = result.data.category_id
            this.form.name = result.data.name
            this.form.id = result.data.id
            result.data.rows.forEach(e => {
              let obj = {
                title: e.name,
                type: e.type,
                options: [],
                has_break: e.has_break,
                mask: e.mask,
                id: e.id
              }
              e.values.forEach(v => {
                obj.options.push({
                  value: v.value
                })
              })
              this.form.answers.push(obj)
            })
            this.$modal.show('addOrder')
          } else {
            this.editId = index
            this.formRaw.answers = []
            this.formRaw.category_id = result.data.category_id
            this.formRaw.name = result.data.name
            this.formRaw.raw = result.data.raw
            this.formRaw.id = result.data.id
            this.$modal.show('addRawProtocol');
          }
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    }
  }
}
</script>

<style scoped>

</style>
