<template>
  <ul class="sidebar-patient">
    <li v-for="page in pages" :key="page.url">
      <router-link :class="{active: current === '/patients/' + $route.params.id + '/' + page.url}" :to="'/patients/' + $route.params.id + '/' + page.url"><span class="bix-ico"><i :class="page.icon"></i></span>{{page.name}}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavbarComponent',
  data () {
    return {
      pages: [
        {
          url: 'info',
          name: 'Дані пацієнта',
          icon: 'ico-07'
        },
        {
          url: 'testing',
          name: 'Тестування',
          icon: 'ico-38'
        },
        {
          url: 'diagnoz',
          name: 'Діагнози',
          icon: 'ico-39'
        },
        {
          url: 'protocols',
          name: 'Протоколи лікування',
          icon: 'ico-40'
        },
        {
          url: 'kolpos',
          name: 'Кольпоскопічні дослідження',
          icon: 'ico-41'
        },
        {
          url: 'uzd',
          name: 'УЗ дослідження',
          icon: 'ico-42'
        },
        {
          url: 'endoskopia',
          name: 'Ендоскопія',
          icon: 'ico-endo'
        },
        {
          url: 'labs',
          name: 'Аналізи',
          icon: 'ico-64'
        },
        {
          url: 'files',
          name: 'Файловий менеджер',
          icon: 'ico-43'
        },
        {
          url: 'export',
          name: 'Експорт картки',
          icon: 'ico-44'
        },
        {
          url: 'print',
          name: 'Друк форми',
          icon: 'ico-24'
        }
      ],
      current: this.$router.currentRoute.path
    }
  },
  mounted () {
    console.log('[Current]', this.$router.currentRoute)
  },
  watch: {
    $route (to, from) {
      this.current = to.path
    }
  }
}
</script>

<style scoped>

</style>
