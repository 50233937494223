<template>
  <div>
    <top-info></top-info>
    <div class="content">
      <div class="table-wrapper">
        <table class="log-table">
          <thead>
          <tr>
            <th style="width: 170px">
              <div class="caption">Дата/час</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 180px;">
              <div class="caption">Користувач</div>
              <v-select :searchable="true" :options="[]"></v-select>
            </th>
            <th style="width: 180px;">
              <div class="caption">Таблиця</div>
              <v-select :searchable="true" :options="[]"></v-select>
            </th>
            <th style="width: 180px;">
              <div class="caption">Поле</div>
              <v-select :searchable="true" :options="[]"></v-select>
            </th>
            <th style="width: 150px;">
              <div class="caption">ID</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 100%">
              <div class="caption">Значення</div>
              <input type="text" class="custom-input">
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="person of articles" :key="person.idLog">
            <td>{{ person.dateLog }}</td>
            <td class="text-overflow-ellipsis">{{ person.userLog }}</td>
            <td :class="person.statusColor"><i class="ico-32" :class="{'up': person.statusArrow}"></i>{{ person.statusText }}</td>
            <td class="text-overflow-ellipsis">{{ person.fieldLog }}</td>
            <td>{{ person.idLog }}</td>
            <td>{{ person.valueLog }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogPage',
  data () {
    return {
      articles: [],
      url: '/log'
    }
  },
  created () {
    this.$http.get(this.url + '/all')
      .then(result => {
        this.article = result.data
      })
  },
  methods: {
    onsearch (e) {
      if (e.target.value) {
        this.listFinded = this.list.filter(el => el.workStatus.indexOf(e.target.value) !== -1)
      } else {
        this.listFinded = this.list
      }
    },
    onsearch2 (e) {
      if (e.target.value2) {
        this.listFinded2 = this.list2.filter(el => el.workStatus2.indexOf(e.target.value2) !== -1)
      } else {
        this.listFinded2 = this.list2
      }
    },
    onsearch3 (e) {
      if (e.target.value3) {
        this.listFinded3 = this.list3.filter(el => el.fieldStatus.indexOf(e.target.value3) !== -1)
      } else {
        this.listFinded3 = this.list3
      }
    }
  }
}
</script>

<style scoped>

</style>
