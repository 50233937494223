<template>
  <div>
    <top-director></top-director>
    <div class="wrapper-cabLoad">
      <div class="left-tbl-q">
        <div class="item">
          <div class="caption">Дата</div>
          <div class="bl">16.09.2019</div>
          <div class="bl">17.09.2019</div>
          <div class="bl">18.09.2019</div>
          <div class="bl">19.09.2019</div>
          <div class="bl">20.09.2019</div>
          <div class="bl">21.09.2019</div>
        </div>
      </div>
      <div class="wrapper-cl">
        <div class="wl-t">
          <div class="item">
            <div class="caption">Ващук К.А.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
          <div class="item">
            <div class="caption">Назарчук С.Ф.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
          <div class="item">
            <div class="caption">Нелепа Л.О.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
          <div class="item">
            <div class="caption">Оксюта О.М.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
          <div class="item">
            <div class="caption">Петрова Л.С.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
          <div class="item">
            <div class="caption">Борисюк І.В.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
          <div class="item">
            <div class="caption">Бісюк М.В.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
          <div class="item">
            <div class="caption">Грек Ю.А.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
          <div class="item">
            <div class="caption">Кунчик У.С.</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
            <div class="bl">00.0/9 (0.00%)</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CabinetLoading'
}
</script>

<style scoped>

</style>
