<template>
  <div>
    <div :class="topClass()"></div>
    <div class="header">
      <div class="top-menu">
        <ul>
          <li
            v-for="page in pages"
            :key="page.url"
            v-show="page.roles.includes($root.user.role)"
          >
            <router-link :to="page.url" :class="{'active': current === page.url}">{{page.name}}</router-link>
          </li>
        </ul>
        <LoginPage />
      </div>
      <div class="bottom-menu">
        <ul>
          <li
            v-for="subPage in subPages[subActive]"
            :key="subPage.url"
            v-show="subPage.roles.includes($root.user.role)"
          >
            <router-link :to="subPage.url" :class="{'active': subPage.url === subCurrent}">{{subPage.name}}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="filter-box-navbar" :class="{'active': filterActive}">
      <div class="caption">
        Фільтр
        <a href="#" class="close-filter full-flex-center"  @click.prevent="filterActive = !filterActive"><i class="ico-17"></i></a>
      </div>
      <div class="body-filter">
        <div class="search-bl">
          <input type="text" class="custom-input">
          <span>Введіть для пошуку за станом, кабінетом, лікарем або пацієнтом.</span>
        </div>
        <div class="wr-op" :class="{'open' : filter1}">
          <div class="one-block-f">
            <ul class="check-list">
              <li><label><i></i><input type="checkbox"><span>Не попереджений</span></label></li>
              <li><label><i style="background-color: #e8f2fa;"></i><input type="checkbox"><span>Попереджений</span></label></li>
              <li><label><i style="background-color: #fbe9e8;"></i><input type="checkbox"><span>Не прийшов</span></label></li>
              <li><label><i style="background-color: #dadfe6;"></i><input type="checkbox"><span>Прийшов</span></label></li>
              <li><label><i style="background-color: #e6f8eb;"></i><input type="checkbox"><span>Поточні події</span></label></li>
              <li><label><i style="background-color: #e6f8eb;"></i><input type="checkbox"><span>Поточні події</span></label></li>
            </ul>
          </div>
          <div class="btn-filter-inside"><a href="#" @click.prevent="filter1 = !filter1"></a></div>
        </div>
        <div class="wr-op" :class="{'open' : filter2}">
          <div class="one-block-f">
            <ul class="check-list">
              <li><label><input type="checkbox"><span>Кабінет №1</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №2</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №3</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №4</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №5</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №6</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №7</span></label></li>
            </ul>
          </div>
          <div class="btn-filter-inside"><a href="#" @click.prevent="filter2 = !filter2"></a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      avatarMenu: false,
      date: new Date(),
      filterActive: false,
      filter1: false,
      filter2: false,
      value3: null,
      list3: [
        {
          supportStatus: 'Пацієнт 1',
          id: 1
        },
        {
          supportStatus: 'Пацієнт 2',
          id: 2
        },
        {
          supportStatus: 'Пацієнт 3',
          id: 3
        },
        {
          supportStatus: 'Пацієнт 4',
          id: 4
        }
      ],
      listFinded3: [
      ],
      value4: null,
      list4: [
        {
          supportStatus: 'Пацієнт 1',
          id: 1
        },
        {
          supportStatus: 'Пацієнт 2',
          id: 2
        },
        {
          supportStatus: 'Пацієнт 3',
          id: 3
        },
        {
          supportStatus: 'Пацієнт 4',
          id: 4
        }
      ],
      listFinded4: [],
      pages: [
        {
          name: 'Управління зарплатами',
          url: '/director/index',
          roles: [3]
        },
        // {
        //   name: 'Витрати на процедури',
        //   url: '/director/costs'
        // },
        // {
        //   name: 'Бюджет',
        //   url: '/director/money'
        // },
        // {
        //   name: 'Рух коштів',
        //   url: '/director/money-transfer'
        // },
        {
          name: 'Аналітика',
          url: '/director/analitics/patients',
          roles: [3, 4]
        },
        {
          name: 'Менеджер СМС',
          url: '/director/sms',
          roles: [3, 4]
        }
        // {
        //   name: 'Групи балансу',
        //   url: '/director/group-balance'
        // }
      ],
      current: '/director/index',
      subActive: 'index',
      subPages: {
        'index': [
          {
            name: 'Лікарі/Асистенти',
            url: '/director/index',
            roles: [3]
          }
          // {
          //   name: 'Персонал',
          //   url: '/director/personal'
          // }
        ],
        'costs': [
          {
            name: 'Менеджер',
            url: '/director/costs',
            roles: [3]
          },
          {
            name: 'Групи',
            url: '/director/costs-group',
            roles: [3]
          }
        ],
        'sms': [
          {
            name: 'Перегляд',
            url: '/director/sms',
            roles: [3, 4]
          },
          {
            name: 'Налаштування',
            url: '/director/sms/setting',
            roles: [3]
          }
        ],
        'money-transfer': [
          {
            name: 'Баланс',
            url: '/director/money-transfer'
          },
          {
            name: 'Рахунки',
            url: '/director/money-transfer/invoices'
          },
          {
            name: 'Боржники',
            url: '/director/money-transfer/debtors'
          },
          {
            name: 'Кредитори',
            url: '/director/money-transfer/creditors'
          }
        ],
        'analitics': [
          // {
          //   name: 'Бюджет',
          //   url: '/director/analitics'
          // },
          // {
          //   name: 'Статичний ОЛАП',
          //   url: '/director/analitics/static-olap'
          // },
          // {
          //   name: 'ОЛАП',
          //   url: '/director/analitics/olap'
          // },
          // {
          //   name: 'Навантаження кабінетів',
          //   url: '/director/analitics/cabinets'
          // },
          // {
          //   name: 'Навантаження лікарів',
          //   url: '/director/analitics/doctors'
          // },
          // {
          //   name: 'СМС',
          //   url: '/director/analitics/sms'
          // },
          {
            name: 'Звіт по пацієнтах',
            url: '/director/analitics/patients',
            roles: [3, 4]
          }
        ]
      },
      subCurrent: '/director/index'
    }
  },
  created () {
    this.current = this.$route.path
    this.fullUrl = this.$route.fullPath
    let url = (this.$route.fullPath).split('/')
    if (url.length > 3) {
      this.current = '/' + url[1] + '/' + url[2]
    }
    this.subActive = url[2]
    this.subCurrent = this.$router.currentRoute.path
    this.topClass()
  },
  methods: {
    topClass () {
      if (this.subPages[this.subActive] === undefined) {
        return 'backing2'
      }
      return 'backing1'
    }
  }
}
</script>

<style scoped>

</style>
