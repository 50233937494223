<template>
  <div>
    <top-info></top-info>
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green"  @click.prevent="show ()"><i class="ico-11"></i> Додати новий запис</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold" v-if="person.type === '3'">
          <thead>
          <tr>
            <th style="width: 46px">
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th style="width: 70px;"><div class="caption">№</div></th>
            <th>
              <div class="caption">Опис</div>
              <input type="text" class="custom-input" @input="localSearch" v-model="search.description">
            </th>
            <th style="width: 40px"></th>
            <th style="width: 40px"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(person, index) of articles" :key="person.number">
              <td>
                <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
              </td>
              <td>{{ person.number }}</td>
              <td>{{ person.description }}</td>
              <td><a href="#" @click.prevent="edit(index)" class="btn-table-change"><i class="ico-19"></i></a></td>
              <td><a href="#" @click.prevent="remove(person.id)" class="btn-table-change"><i class="ico-20"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal name="addOrder" class="modal-custom" :width="720" height="auto" :scrollable="true">
      <form @submit.prevent="saveForm">
        <div class="caption-modal">
          Додавання нового запису
          <a href="#" class="close-modal" @click.prevent="hide ()"><i class="ico-17"></i></a>
        </div>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">№</div>
            <div class="input-wr"><input type="text" class="custom-input" v-model="form.number" :class="{'input-v-error':$v.form.number.$error}" @change="$v.form.number.$touch()"></div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description">Опис</div>
            <div class="input-wr">
              <textarea class="mailing-textarea h120" v-model="form.description" :class="{'input-v-error':$v.form.description.$error}" @change="$v.form.description.$touch()"></textarea>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">Зберегти</button>
            <button class="btn-red" @click.prevent="hide()">Скасувати</button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
export default {
  name: 'FileManagerTags',
  data () {
    return {
      articles: [],
      originalArticles: [],
      checked: false,
      url: '/patientcarddictionaries',
      search: {
        description: ''
      },
      form: {
        id: 0,
        number: '',
        description: '',
        type: 3
      }
    }
  },
  created () {
    this.$http.get(this.url + '/all')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    show () {
      this.$modal.show('addOrder')
    },
    hide () {
      this.$modal.hide('addOrder')
      this.clear()
    },
    setData (data) {
      this.articles = data
      this.originalArticles = data
    },
    localSearch () {
      this.articles = this.originalArticles.filter(e => {
        let result = true
        for (let key in this.search) {
          if (this.search[key] !== '') {
            if (e[key].indexOf(this.search[key]) === -1) {
              result = false
            }
          }
        }
        return result ? e : false
      })
    },
    clear () {
      this.form.title = 'Додавання нового запису'
      this.form.number = ''
      this.form.description = ''
      this.form.id = 0
    },
    saveForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$http.post(this.url + '/saved', {
          number: this.form.number,
          description: this.form.description,
          id: this.form.id,
          type: this.form.type
        })
          .then(result => {
            this.$root.alerts('success', 'Запис збережено')
            this.setData(result.data)
            this.hide()
            this.clear()
          })
          .catch(() => {
            this.$root.alerts('error', 'Виникла помилка')
          })
      }
    },
    edit (index) {
      let item = this.articles[index]
      this.form.title = 'Редагування запису'
      this.form.id = item.id
      this.form.number = item.number
      this.form.description = item.description
      this.show()
    },
    remove (id) {
      if (confirm('Видали запис?')) {
        this.$http.delete(this.url + '/delete', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.$root.alerts('success', 'Запис видалено')
            this.setData(result.data)
          })
          .catch(() => {
            this.$root.alerts('error', 'Помилка видалення')
          })
      }
    }
  },
  validations: {
    form: {
      number: {
        required
      },
      description: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
