<template>
  <div>
    <top-manager></top-manager>
    <div class="content">
      <form class="table-wrapper">
        <div class="mailing-box right-bl">
          <div class="balance">Баланс: 1598 смс (959.00 грн.)</div>
          <label class="checkbox-custom with-text ico-text-after"><input type="checkbox" v-model="settings['SMSEnable'].value"><span>Увімкнути СМС-розсилку</span></label>
          <a href="#" class="settings-btn full-flex-center" @click.prevent="show()"><i class="ico-53"></i></a>
        </div>
        <div class="mailing-box">
          <div class="caption-mailing">Умови розсилки</div>
          <div class="patient-line-p">
            <div class="label-bulk">
              <label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Пацієнт</span></label>
            </div>
            <div class="select-p">
              <v-select></v-select>
            </div>
          </div>
          <div class="list-sex">
            Стать:
            <ul class="radio-list">
              <li class="custom-radio">
                <label>
                  <input type="radio" value="One" :checked="picked == 'all'" @change="e => picked = e.target.value">
                  <span>Будь-яка</span>
                </label>
              </li>
              <li class="custom-radio">
                <label>
                  <input type="radio" value="One" :checked="picked == 'man'" @change="e => picked = e.target.value">
                  <span>Чоловіча</span>
                </label>
              </li>
              <li class="custom-radio">
                <label>
                  <input type="radio" value="Two" :checked="picked == 'woman'" @change="e => picked = e.target.value">
                  <span>Жіноча</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="mailing-box">
          <div class="caption-mailing">Шаблон повідомлення</div>
          <ul class="btn-template-list">
            <li><a href="#">Прізвище</a></li>
            <li><a href="#">Ім’я</a></li>
            <li><a href="#">По батькові</a></li>
            <li><a href="#">Прізвище І.П.</a></li>
            <li><a href="#">П.І.П.</a></li>
          </ul>
          <textarea class="mailing-textarea"></textarea>
        </div>
      </form>
    </div>
    <modal name="settingSms" class="modal-custom" :width="720" height="auto" :scrollable="true">
      <form>
        <div class="caption-modal">
          Налаштування СМС-розсилки
          <a href="#" class="close-modal" @click.prevent="hide ()"><i class="ico-17"></i></a>
        </div>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">Режим роботи СМС модуля</div>
            <div class="input-wr">
<!--              <stf-select v-model="value2">-->
<!--                <div slot="label"></div>-->
<!--                <div slot="value">-->
<!--                  <div v-if="value2">-->
<!--                    <span>{{value2.workStatus}}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div slot="search-input">-->
<!--                  <input @input="onsearch2">-->
<!--                </div>-->
<!--                <section class="options delivery_order__options">-->
<!--                  <stf-select-option-->
<!--                    v-for="item of listFinded2" :key="item.id"-->
<!--                    :value="item"-->
<!--                    :class="{'stf-select-option_selected': item.id === (value2 && value2.id)}"-->
<!--                  >-->
<!--                    <span>{{item.workStatus}}</span>-->
<!--                  </stf-select-option>-->
<!--                </section>-->
<!--              </stf-select>-->
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Логін</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Пароль</div>
            <div class="input-wr"><input type="password" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Час розсилки</div>
            <div class="input-wr">
              <div class="line-day-i long">
                <div class="time-i">
                  <div class="input-wr number-counter">
                    <vue-timepicker format="hh:mm" v-model="yourData"></vue-timepicker>
                    <a href="#" class="priceBtn count-minus"><i class="ico-28"></i></a>
                    <a href="#" class="priceBtn count-plus"><i class="ico-27"></i></a>
                  </div>
                </div>
                <div class="line-tire-i">–</div>
                <div class="time-i">
                  <div class="input-wr number-counter">
                    <vue-timepicker format="hh:mm" v-model="yourData2"></vue-timepicker>
                    <a href="#" class="priceBtn count-minus"><i class="ico-28"></i></a>
                    <a href="#" class="priceBtn count-plus"><i class="ico-27"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Попередити за скільки днів</div>
            <div class="input-wr">
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter3">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter3--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter3++"><i class="ico-27"></i></a>
              </div>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Припинити відправку за скільки годин</div>
            <div class="input-wr">
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter2">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter2--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter2++"><i class="ico-27"></i></a>
              </div>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Ім’я відправника</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Розмір черги відправника</div>
            <div class="input-wr">
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter1">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter1--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter1++"><i class="ico-27"></i></a>
              </div>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Дні відправлення</div>
            <div class="input-wr">
              <ul class="list-filter-checkbox small-margin">
                <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Пн</span></label></li>
                <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Вт</span></label></li>
                <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Ср</span></label></li>
                <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Чт</span></label></li>
                <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Пт</span></label></li>
                <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Сб</span></label></li>
                <li><label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Нд</span></label></li>
              </ul>
            </div>
          </div>
          <div class="template-wr">
            <div class="list-t-btn">
              <label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Трансліт</span></label>
              <ul class="btn-template-list">
                <li><a href="#">Fn</a></li>
                <li><a href="#">Ln</a></li>
                <li><a href="#">Pn</a></li>
                <li><a href="#">Dt</a></li>
                <li><a href="#">Tm</a></li>
                <li><a href="#">Sh</a></li>
                <li><a href="#">Fu</a></li>
              </ul>
              <a href="#" class="question-btn full-flex-center"><i class="ico-59"></i></a>
            </div>
            <div class="one-t">
              <div class="clearfix">
                <div class="one-bl">
                  <div class="caption-grey">Шаблон попередження про візит</div>
                  <textarea class="mailing-textarea h120">%date%p. o %time% чекаємо Вас у Мед.Центрі Боголюби. У разі відміни повідомте +380994455404</textarea>
                </div>
                <div class="one-bl">
                  <div class="caption-grey">Шаблон попередження про диспансеризацію</div>
                  <textarea class="mailing-textarea h120" v-model="templateTextarea2"></textarea>
                </div>
              </div>
              <div class="description">
                <label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Увімкнути попередження візитів</span></label>
              </div>
            </div>
            <div class="one-t">
              <div class="caption-grey">Шаблон привітання з днем народження</div>
              <textarea class="mailing-textarea h120" v-model="templateTextarea2"></textarea>
              <div class="description">
                <label class="checkbox-custom with-text ico-text-after"><input type="checkbox"><span>Увімкнути розсилку привітань</span></label>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modal">
          <div class="btn-left-footer">
            <button class="btn-white left-btn-l">Перевірити баланс</button>
          </div>
          <div class="btn-right-footer">
            <button class="btn-green">Зберегти</button>
            <button class="btn-red" @click.prevent="hide ()">Скасувати</button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
export default {
  data () {
    return {
      articles: [
        {
          id: 1,
          idMail: '23626',
          phoneMail: '+38(063) 547-96-77',
          messageMail: 'Chekaemo Vas u Med.Tsentri Bogolyub',
          dateCreate: '16.09.2019 14:15:02',
          statusMail: true,
          sateSend: '16.09.2019 14:15:02',
          timeSend: '16.09.2019 14:15:02',
          checked: false
        },
        {
          id: 2,
          idMail: '23626',
          phoneMail: '+38(063) 547-96-77',
          messageMail: 'Chekaemo Vas u Med.Tsentri Bogolyub',
          dateCreate: '16.09.2019 14:15:02',
          statusMail: false,
          sateSend: '16.09.2019 14:15:02',
          timeSend: '16.09.2019 14:15:02',
          checked: false
        },
        {
          id: 3,
          idMail: '23626',
          phoneMail: '+38(063) 547-96-77',
          messageMail: 'Chekaemo Vas u Med.Tsentri Bogolyub',
          dateCreate: '16.09.2019 14:15:02',
          statusMail: true,
          sateSend: '16.09.2019 14:15:02',
          timeSend: '16.09.2019 14:15:02',
          checked: false
        },
        {
          id: 4,
          idMail: '23626',
          phoneMail: '+38(063) 547-96-77',
          messageMail: 'Chekaemo Vas u Med.Tsentri Bogolyub',
          dateCreate: '16.09.2019 14:15:02',
          statusMail: true,
          sateSend: '16.09.2019 14:15:02',
          timeSend: '16.09.2019 14:15:02',
          checked: false
        },
        {
          id: 5,
          idMail: '23626',
          phoneMail: '+38(063) 547-96-77',
          messageMail: 'Chekaemo Vas u Med.Tsentri Bogolyub',
          dateCreate: '16.09.2019 14:15:02',
          statusMail: true,
          sateSend: '16.09.2019 14:15:02',
          timeSend: '16.09.2019 14:15:02',
          checked: false
        },
        {
          id: 6,
          idMail: '23626',
          phoneMail: '+38(063) 547-96-77',
          messageMail: 'Chekaemo Vas u Med.Tsentri Bogolyub',
          dateCreate: '16.09.2019 14:15:02',
          statusMail: true,
          sateSend: '16.09.2019 14:15:02',
          timeSend: '16.09.2019 14:15:02',
          checked: false
        }
      ],
      checked: false,
      picked: 'man',
      yourData: {
        hh: '09',
        mm: '00'
      },
      yourData2: {
        hh: '10',
        mm: '30'
      },
      templateTextarea1: '%date%p. o %time% чекаємо Вас у Мед.Центрі Боголюби. У разі відміни повідомте +380994455404',
      templateTextarea2: '%firstname%! Нагадуємо Вам про запланований візит до лікаря',
      value: 1,
      list: [
        {
          workStatus: 'Умова 1',
          id: 1
        },
        {
          workStatus: 'Умова 2',
          id: 2
        }
      ],
      listFinded: [
      ],
      value2: 1,
      list2: [
        {
          workStatus: 'GinSMS',
          id: 1
        },
        {
          workStatus: 'GinSMS 2',
          id: 2
        }
      ],
      listFinded2: [
      ],
      url: '/settings',
      settings: []
    }
  },
  created () {
    this.$http.get(this.url + '/index', {
      params: {
        type: 'sms'
      }
    })
      .then(result => {
        this.setData(result.data)
      })
  },
  components: { VueTimepicker },
  methods: {
    onsearch (e) {
      if (e.target.value) {
        this.listFinded = this.list.filter(el => el.workStatus.indexOf(e.target.value) !== -1)
      } else {
        this.listFinded = this.list
      }
    },
    onsearch2 (e) {
      if (e.target.value2) {
        this.listFinded2 = this.list2.filter(el => el.workStatus.indexOf(e.target.value2) !== -1)
      } else {
        this.listFinded2 = this.list2
      }
    },
    show () {
      this.$modal.show('addOrder')
    },
    hide () {
      this.$modal.hide('addOrder')
    },
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    setData (data) {
      this.settings = data.settings
      console.log('[Settings]', this.settings)
    }
  }
}
</script>

<style scoped>

</style>
