<template>
  <div class="box-inform-table">
    <div class="item">
      <div class="caption-t">Стаціонар</div>
      <div class="table-flex-i">
        <div class="head">
          <div class="one-line">
            <div class="one-block">Взято</div>
            <div class="one-block">Виписаний</div>
          </div>
        </div>
        <div class="body">
          <div class="one-line">
            <div class="one-block">Тескт 1</div>
            <div class="one-block">Тескт 2</div>
          </div>
          <div class="one-line">
            <div class="one-block"></div>
            <div class="one-block"></div>
          </div>
          <div class="one-line">
            <div class="one-block"></div>
            <div class="one-block"></div>
          </div>
          <div class="one-line">
            <div class="one-block"></div>
            <div class="one-block"></div>
          </div>
          <div class="one-line">
            <div class="one-block"></div>
            <div class="one-block"></div>
          </div>
        </div>
      </div>
      <a href="#" class="btn-white green-text">Взяти на стаціонар</a>
    </div>
    <div class="item">
      <div class="caption-t">Вагітність</div>
      <div class="table-flex-i">
        <div class="head">
          <div class="one-line">
            <div class="one-block">Взято</div>
            <div class="one-block">Виписана</div>
          </div>
        </div>
        <div class="body">
          <div class="one-line">
            <div class="one-block">Тескт 1</div>
            <div class="one-block">Тескт 2</div>
          </div>
          <div class="one-line">
            <div class="one-block"></div>
            <div class="one-block"></div>
          </div>
          <div class="one-line">
            <div class="one-block"></div>
            <div class="one-block"></div>
          </div>
          <div class="one-line">
            <div class="one-block"></div>
            <div class="one-block"></div>
          </div>
          <div class="one-line">
            <div class="one-block"></div>
            <div class="one-block"></div>
          </div>
        </div>
      </div>
      <a href="#" class="btn-white green-text">Взяти на облік</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OblikComponent',
}
</script>

<style scoped>

</style>
