<template>
  <div>
    <TopInfo />
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="show ()"><i class="ico-11"></i> {{$t('Додати підрозділ')}}</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold division-table">
          <thead>
          <tr>
            <th style="width: 46px;">
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th>
              <div class="caption">Назва</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 200px;">
              <div class="caption">Місто</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 130px;">
              <div class="caption">Поточний № КО</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 130px;">
              <div class="caption">Поточний № БР</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 180px;">
              <div class="caption">Поточний № рахунку</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 170px;"></th>
            <th style="width: 170px;"></th>
            <th style="width: 40px;"></th>
            <th style="width: 40px;"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="person of division" :key="person.id">
            <td>
              <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
            </td>
            <td>{{ person.name }}</td>
            <td>{{ person.city }}</td>
            <td>{{ person.ordernumber_cash }}</td>
            <td>{{ person.ordernumber_clearing }}</td>
            <td>{{ person.invoicenumber }}</td>
            <td style="padding: 0;"><a href="#" class="btn-white">Змінити нумерацію</a></td>
            <td style="padding: 0;"><a href="#" class="btn-white">Обнулити нумерацію</a></td>
            <td><a href="#" class="btn-table-change"><i class="ico-19"></i></a></td>
            <td><a href="#" class="btn-table-change"><i class="ico-20"></i></a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.addOrder"
        width="720px"
        :title="$t('Додавання нового запису')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">Назва</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Місто</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Адреса</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hide ()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DivisionsPage',
  data () {
    return {
      division: [],
      checked: false
    }
  },
  created () {
    this.$http.get('/subdivision/index')
      .then(result => {
        this.division = result.data
      })
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    show () {
      this.$modal.show('addOrder')
    },
    hide () {
      this.$modal.hide('addOrder')
    }
  }
}
</script>

<style scoped>

</style>
