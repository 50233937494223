<template>
  <div class="page-canvas">
    <top-director></top-director>
    <div class="table-content p-20">
      <form @submit.prevent="save">
        <div class="cols-form">
          <div class="line-modal-form">
            <div class="description">Почати надсилання в:</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="params.SMSStartTime.value">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Завершити надсилання в:</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="params.SMSEndTime.value">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Попередити за (-днів):</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="params.SMSSendBeforeInDays.value">
            </div>
          </div>
        </div>
        <div class="line-modal-form line-with-textarea">
          <div class="description">Шаблон повідомлення:</div>
          <div class="input-wr">
            <textarea v-model="params.SMSTaskTemplate.value"></textarea>
          </div>
        </div>
        <button class="btn-green">Зберегти</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmsSetting',
  data () {
    return {
      params: {
        SMSStartTime: {
          value: ''
        },
        SMSEndTime: {
          value: ''
        },
        SMSSendBeforeInDays: {
          value: ''
        },
        SMSTaskTemplate: {
          value: ''
        }
      }
    }
  },
  created () {
    this.$http.get('/settings/index', {
      params: {
        type: 'sms'
      }
    }).then(result => {
      this.params = result.data.settings
    }).catch(error => {
      console.log('[Error]', error.message)
    })
  },
  methods: {
    save () {
      console.log('[Init save]')
      this.$http.post('/settings/save', this.params)
    }
  }
}
</script>
