<template>
  <div>
    <div class="backing1"></div>
    <div class="header">
      <div class="top-menu">
        <ul>
          <li
            v-for="page in pages"
            :key="page.url"
          >
            <router-link :to="page.url" :class="{'active': current === page.url}">{{page.name}}</router-link>
          </li>
        </ul>
        <Login></Login>
      </div>
      <div class="bottom-menu">
        <ul>
          <li
            v-for="subPage in subPages[subActive]"
            :key="subPage.url"
          >
            <router-link :to="subPage.url" :class="{'active': subPage.url === subCurrent}">{{subPage.name}}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Login from './Login'
export default {
  data () {
    return {
      avatarMenu: false,
      pages: [
        {
          name: 'Клініка',
          url: '/info/index'
        },
        // {
        //   name: 'Картки пацієнтів',
        //   url: '/info/patient-cards'
        // },
        {
          name: 'Конструктор (Курс лікування)',
          url: '/info/counstruct'
        },
        {
          name: 'Прайс-лист послуг',
          url: '/info/price-list'
        },
        {
          name: 'Тест',
          url: '/info/test'
        }
      ],
      current: '/info/index',
      subCurrent: '/info/index',
      subActive: 'index',
      subPages: {
        'index': [
          {
            name: 'Кабінети та робочі місця',
            url: '/info/index'
          },
          {
            name: 'Загальні відомості про клініку',
            url: '/info/index/about-us'
          },
          {
            name: 'Підрозділи',
            url: '/info/index/subs'
          },
          {
            name: 'Спеціальності',
            url: '/info/index/specials'
          },
          // {
          //   name: 'Дослідження',
          //   url: '/info/index/doslids'
          // },
          {
            name: 'Адміністратор системи',
            url: '/info/index/admins'
          },
          // {
          //   name: 'Безпека',
          //   url: '/info/index/security'
          // },
          // {
          //   name: 'Лог',
          //   url: '/info/index/logs'
          // }
          {
            name: 'Юридичні особи',
            url: '/info/index/cashiers'
          },
        ],
        'sms': [
          {
            name: 'Перегляд СМС',
            url: '/manager/sms'
          },
          {
            name: 'Масова розсилка',
            url: '/manager/sms/create'
          }
        ],
        'kassa': [
          {
            name: 'Рахунки',
            url: '/manager/kassa'
          },
          {
            name: 'Каса',
            url: '/manager/kassa/kas'
          },
          {
            name: 'Боржники',
            url: '/manager/kassa/borg'
          },
          {
            name: 'Кредитори',
            url: '/manager/kassa/credit'
          }
        ],
        'patient-cards': [
          {
            name: 'Джерела інформації',
            url: '/info/patient-cards'
          },
          {
            name: 'Родичі',
            url: '/info/patient-cards/parents'
          },
          {
            name: 'Групи пацієнтів',
            url: '/info/patient-cards/group-patients'
          },
          {
            name: 'Теги файлового менеджера',
            url: '/info/patient-cards/tag-fm'
          },
          {
            name: 'Типи диспансеризації',
            url: '/info/patient-cards/types-dispanser'
          },
          {
            name: 'Накопичувальна система знижок',
            url: '/info/patient-cards/discount-system'
          }
        ]
      }
    }
  },
  created () {
    this.current = this.$route.path
    this.fullUrl = this.$route.fullPath
    let url = (this.$route.fullPath).split('/')
    if (url.length > 3) {
      this.current = '/' + url[1] + '/' + url[2]
    }
    this.subActive = url[2]
    this.subCurrent = this.$route.path
    this.topClass()
  },
  components: {
    Login
  },
  methods: {
    topClass() {
      if (this.subPages[this.subActive] === undefined) {
        return 'backing2'
      }
      return 'backing1'
    }
  }
}
</script>

<style scoped>

</style>
