<template>
  <div class="active-call" v-if="call">
    <div class="active-call-body">
      <div class="active-call-header">
        <div class="active-call-title">Вхідний дзвінок</div>
        <a href="#" @click.prevent="call = false">
          <i class="ico-17"></i>
        </a>
      </div>
      <div class="active-call-content">
        <div class="active-call-info">
          <div class="active-call-group">
            <div class="active-call-group-title">Імʼя</div>
            <div class="active-call-group-data">{{call.patient.firstname}}</div>
          </div>
          <div class="active-call-group">
            <div class="active-call-group-title">Прізвище</div>
            <div class="active-call-group-data">{{call.patient.lastname}}</div>
          </div>
          <div class="active-call-group">
            <div class="active-call-group-title">Телефон</div>
            <div class="active-call-group-data">{{call.patient.mobilenumber}}</div>
          </div>
          <div class="active-call-group">
            <div class="active-call-group-title">Дата народження</div>
            <div class="active-call-group-data">{{call.patient.birthday}}</div>
          </div>
          <div class="active-call-group">
            <div class="active-call-group-title">Страхування</div>
            <div class="active-call-group-data">{{call.patient.has_insurance === 0 ? 'Немає' : 'Застрахований'}}</div>
          </div>
          <a href="#" @click.prevent="$router.push('/patients/' + call.patient.id + '/info')" class="btn-green" style="width: 100%;margin-bottom: 10px">Перейти в кабінет</a>
          <a href="#" class="btn-green" style="width: 100%;" @click.prevent="() => {
            emitter.emit('showModalTask');
            emitter.emit('newPatient', call.patient);
            call = false
          }">Новий запис</a>
        </div>
        <div class="active-call-notes">
          <div class="name-note">Майбутні записи</div>
          <div class="note-box" v-for="task in call.tasksActive">
            <div class="note-box-doc">{{task?.doctorid?.fullname}}</div>
            <div class="note-box-cabinet">{{task?.roomid?.number}} {{task?.roomid?.name}}</div>
            <div class="note-box-tag">
              <span><i class="ico-08" style="color:rgba(87, 93, 102, 1)"></i> {{moment(task.taskdate).format("DD.MM.YYYY")}}</span>
              <span>{{task.beginoftheinterval}}</span>
            </div>
            <div class="note-box-text">{{task?.workdescription}}</div>
          </div>
        </div>
        <div class="active-call-notes">
          <div class="name-note">Історія записів</div>
          <div class="note-box" v-for="task in call.tasksOld">
            <div class="note-box-doc">{{task?.doctorid?.fullname}}</div>
            <div class="note-box-cabinet">{{task?.roomid?.number}} {{task?.roomid?.name}}</div>
            <div class="note-box-tag">
              <span style="background: #F2F4F7"><i class="ico-08" style="color:rgba(87, 93, 102, 1)"></i> {{moment(task.taskdate).format("DD.MM.YYYY")}}</span>
              <span style="background: #F2F4F7">{{task.beginoftheinterval}}</span>
            </div>
            <div class="note-box-text">{{task?.workdescription}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="call-notification" v-show="phone">
    <div class="title">
      <i class="ico-56"></i>
      <span>Новий дзвінок</span>
    </div>
    <div class="phone">{{phone}}</div>
    <div class="buttons">
      <button type="button" @click="phone = false" class="btn-red">Закрити</button>
      <button type="button" @click="getData" class="btn-green">Переглянути</button>
    </div>
  </div>
</template>
<script>
  import {ElMessage} from "element-plus";
  import moment from "moment";

  export default {
    name: 'ActiveCall',
    computed: {
      moment() {
        return moment
      }
    },
    data () {
      return {
        phone: false,
        url: '/call',
        call: false
      }
    },
    mounted() {
        const socket = new WebSocket('wss://websocket.medicalstandard.com.ua?user_id=1&uid=' + this.$store.state.user.user.id);
        
        socket.addEventListener("open", (event) => {
          console.log("Hello Server!");
        });
        socket.addEventListener("error", (event) => {
          console.log('[Error]', event)
        });
        socket.addEventListener("message", (event) => {
          const data = JSON.parse(event.data);
          console.log('[Log]', data);
          if (data.message === 'newCall') {
            this.phone = data.data.externalNumber;
          }
        });
    },
    methods: {
      getData() {
        this.$http.get(this.url + '/get-data', {
          params: {
            phone: this.phone
          }
        }).then((result) => {
          const activeTasks = result.data.tasks.filter(task => {
            return task.status === 1;
          })
          const oldTasks = result.data.tasks.filter(task => {
            return task.status !== 1;
          })
          this.call = {
            patient: result.data.patient,
            tasksActive: activeTasks,
            tasksOld: oldTasks
          };
          this.phone = false;
        }).catch((error) => {
          ElMessage({
            message: this.$t('Дані про пацієнта не знайдено'),
            type: 'error',
          });
          this.phone = false;
          console.log('[Error]', error.message);
        })
      }
    }
  }
</script>

<style scoped>
.note-box-text {
  color: #535A67;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.note-box-tag span {
  border-radius: 4px;
  background: #E6F8EB;
  padding: 4px 8px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-right: 4px;
}
.note-box-tag {
  margin-bottom: 12px;
}
.note-box-cabinet {
  color: #535A67;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-bottom: 12px;
}
.note-box-doc {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 4px;
}
.note-box{
  border-radius: 4px;
  border: 1px solid #DADFE6;
  background: #FFF;
  padding: 12px;
  margin-bottom: 8px;
}
.name-note {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  margin-bottom: 20px;
}

.active-call-group-data {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
}

.active-call-group-title {
  color: #535A67;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
  .active-call-group {
    margin-bottom: 20px;
  }
  .active-call-notes {
    border-left: 2px solid #DADFE6;
    flex: 1;
    height: calc(100vh - 105px);
    overflow: auto;
    padding: 20px;
  }

  .active-call-info {
    background: #DADFE6;
    padding: 40px;
    height: calc(100vh - 105px);
    width: 280px;
  }

  .active-call-content {
    display: flex;
    height: 100%;
  }

  .active-call-header {
    padding: 20px 40px;
    border-bottom: 2px solid #DADFE6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .active-call-header a {
    color: #000;

    &:hover {
      color: #0bae38;
    }
  }

  .active-call-title {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
  }

  .active-call {
    position: fixed;
    background: rgba(0, 0, 0, .6);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }

  .active-call-body {
    background: white;
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
  }
</style>
