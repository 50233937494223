<template>
  <div>
    <Navbar></Navbar>
    <Header5></Header5>
    <div class="content">
      <div class="paydesk-wr dispensary">
        <div class="one-pay" v-for="person in articles" :key="person.idUser">
          <div class="number-count before-border">
            <div class="new-pay-t bold text-overflow-ellipsis">{{ person.statusText }}</div>
          </div>
          <div class="client-wr before-border">
            <div class="name-client">
              <div class="surname bold">{{ person.surnameClient }}</div>
              <div class="name">{{ person.nameClient }}</div>
            </div>
          </div>
          <div class="client-wr before-border width2">
            <div class="name-client">
              <div class="surname bold">{{ person.surnameClient2 }}</div>
              <div class="name">{{ person.nameClient2 }}</div>
            </div>
          </div>
          <div class="service-box before-border">
            <div class="top">
              <div class="line-btn-right small">
<!--                <VueDatePicker  :value="date" :locale="locale"/>-->
              </div>
            </div>
            <div class="bottom clearfix">
              <div class="small-select">
<!--                <stf-select v-model="value">-->
<!--                  <div slot="label"></div>-->
<!--                  <div slot="value">-->
<!--                    <div v-if="value">-->
<!--                      <span>{{value.workStatus}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div slot="search-input">-->
<!--                    <input @input="onsearch">-->
<!--                  </div>-->
<!--                  <section class="options delivery_order__options">-->
<!--                    <stf-select-option-->
<!--                      v-for="item of listFinded" :key="item.id"-->
<!--                      :value="item"-->
<!--                      :class="{'stf-select-option_selected': item.id === (value && value.id)}"-->
<!--                    >-->
<!--                      <span>{{item.workStatus}}</span>-->
<!--                    </stf-select-option>-->
<!--                  </section>-->
<!--                </stf-select>-->
              </div>
              <div class="check-wr">
                <label class="checkbox-custom ico-text-after"><input type="checkbox"><span>смс</span></label>
              </div>
            </div>
          </div>
          <div class="btn-orange">
            <ul>
              <li><a href="#"><i class="ico-02"></i></a></li>
              <li><a href="#"><i class="ico-02"></i></a></li>
              <li><a href="#"><i class="ico-02"></i></a></li>
            </ul>
          </div>
           <div class="btn-dispensary">
             <ul>
               <li><a href="#" class="btn-table-change"><i class="ico-19"></i></a></li>
               <li><a href="#" class="btn-table-change"><i class="ico-20"></i></a></li>
             </ul>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DispensaryPage',
  data () {
    return {
      date: new Date([2019, 4, 12]),
      articles: [
        {
          idUser: '1',
          statusText: '194нова_2017',
          surnameClient: 'Закатєва',
          nameClient: 'Ольга Анатоліївна',
          surnameClient2: 'Михалюк',
          nameClient2: 'Надія'
        }
      ],
      value: null,
      list: [
        {
          workStatus: 'Лікар 1',
          id: 1
        },
        {
          workStatus: 'Лікар 2',
          id: 2
        }
      ],
      listFinded: [
      ]
    }
  },
  created () {
    this.listFinded = this.list
  },
  methods: {
    onsearch (e) {
      if (e.target.value) {
        this.listFinded = this.list.filter(el => el.workStatus.indexOf(e.target.value) !== -1)
      } else {
        this.listFinded = this.list
      }
    }
  }
}
</script>

<style scoped>

</style>
