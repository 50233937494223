<template>
  <div>
    <TopInfo />
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="$modal.show('add')"><i class="ico-11"></i> {{$t('Додати')}}</a>
      </div>
      <div class="table-wrapper p-20">
        <div id="table"/>
      </div>
    </div>
    <Add v-bind:cashiers-props="users"></Add>
  </div>
</template>

<script>
import Add from './Cashiers/Add'
import { Grid, h, html } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

export default {
  name: 'CashierPage',
  data () {
    return {
      users: [],
      url: '/cashiers',
      grid: new Grid()
    }
  },
  created () {
    this.$http.get(this.url + '/index')
      .then(result => {
        this.setData(result.data)
      })
    this.emitter.on('update', this.update)
  },
  methods: {
    setData (data) {
      this.users = data.cashiers
      this.grid.updateConfig({
        columns: [{
          id: 'id',
          name: 'ID'
        },{
          id: 'login',
          name: this.$t('Логін')
        }, {
          id: 'license',
          name: this.$t('Номер ліцензії')
        },{
          id: 'cashier_name',
          name: this.$t('Юридична особа')
        },{
          id: 'departament',
          name: this.$t('Назва закладу')
        },{
          id: 'action',
          name: '',
          width: '70px',
          formatter: (cell, row) => {
            const btn = []
            btn.push(h('a', {
              className: 'btn-table-change',
              onClick: () => {
                this.edit(row._cells[0].data)
              }
            }, html('<i class="ico-19"></i>')));
            return btn;
          }
        }],
        data: data.cashiers,
        pagination: {
          enabled: true,
          limit: 20,
          summary: false,
        },
        search: {
          debounceTimeout: 1000,
        },
        language: {
          'search': {
            'placeholder': '🔍 ' + this.$t('Пошук')
          },
          'pagination': {
            'previous': '⬅️',
            'next': '➡️',
          }
        }
      });
      this.grid.render(document.getElementById("table"));
    },
    update (data) {
      this.users = data
    },
    edit (index) {
      const user = this.users.find(user => {
        return Number(user.id) === Number(index)
      })
      this.emitter.emit('edit', user)
    },
  },
  components: {
    Add
  }
}
</script>

<style scoped>

</style>
