<template>
  <div>
    <top-manager></top-manager>
    <div class="content">
      <div class="btn-above-table">
        <a href="#" @click.prevent="show('addContact')" class="btn-green"><i class="ico-11"></i> Додати новий запис</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold table-column-two">
          <thead>
          <tr>
            <th>
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th>
              <div class="caption">П.І.Б</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Телефон</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Адреса</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Примітка</div>
              <input type="text" class="custom-input">
            </th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(person, index) in articles" :key="person.id">
              <td>
                <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
              </td>
              <td>{{ person.name }}</td>
              <td>{{ person.phones }}</td>
              <td>{{ person.address }}</td>
              <td>{{ person.comment }}</td>
              <td><a href="#" @click.prevent="edit(index)" class="btn-table-change"><i class="ico-19"></i></a></td>
              <td><a href="#" @click.prevent="remove(person.id)" class="btn-table-change"><i class="ico-20"></i></a></td>
            </tr>
          </tbody>
        </table>
        <Pagination
          v-bind:page="page"
          v-on:goPage="goPage"
        ></Pagination>
      </div>

      <modal name="addContact" class="modal-custom" :width="720" height="auto" :scrollable="true">
        <form @submit.prevent="saveForm">
          <div class="caption-modal">
            Додати контакт
          </div>
          <div class="body-modal">
            <div class="line-modal-form">
              <div class="description">П.І.Б *</div>
              <div class="input-wr">
                <input
                  type="text"
                  v-model="form.name"
                  class="custom-input"
                  :class="{'input-v-error':$v.form.name.$error}"
                  @change="$v.form.name.$touch()"
                >
              </div>
            </div>
            <div class="line-modal-form">
              <div class="description">Телефон *</div>
              <div class="input-wr">
                <input
                  type="text"
                  class="custom-input"
                  v-model="form.phones"
                  :class="{'input-v-error':$v.form.phones.$error}"
                  @change="$v.form.phones.$touch()"
                >
              </div>
            </div>
            <div class="line-modal-form">
              <div class="description">Адреса</div>
              <div class="input-wr"><input type="text" class="custom-input" v-model="form.address"></div>
            </div>
            <div class="line-modal-form">
              <div class="description">Примітка</div>
              <div class="input-wr"><input type="text" class="custom-input" v-model="form.comment"></div>
            </div>
          </div>
          <div class="footer-modal">
            <div class="btn-right-footer">
              <button class="btn-green">Зберегти</button>
              <button class="btn-red" @click.prevent="hide('addContact')">Скасувати</button>
            </div>
          </div>
        </form>
      </modal>
    </div>
  </div>
</template>

<script>
import Pagination from '../modules/Pagination'
import { required } from '@vuelidate/validators'
export default {
  data () {
    return {
      articles: [],
      checked: false,
      url: '/contacts',
      page: 0,
      form: {
        name: '',
        phones: '',
        address: '',
        comment: '',
        id: 0
      }
    }
  },
  created () {
    this.$http.get(this.url + '/index')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    setData (data) {
      this.articles = data.contacts
      this.page = data.page
    },
    show (modal) {
      this.$modal.show(modal)
    },
    hide (modal) {
      this.$modal.hide(modal)
    },
    goPage (data) {
      this.$http.get(this.url + '/index', {
        params: {
          limit: 20 * (data - 1)
        }
      })
        .then(result => {
          this.setData(result.data)
        })
    },
    clear () {
      this.form.title = 'Додавання контакту'
      this.form.name = ''
      this.form.phones = ''
      this.form.id = 0
      this.form.address = ''
      this.form.comment = ''
    },
    saveForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$http.post(this.url + '/save', {
          name: this.form.name,
          phones: this.form.phones,
          address: this.form.address,
          id: this.form.id,
          comment: this.form.comment
        })
          .then(result => {
            this.setData(result.data)
            this.hide('addContact')
            this.clear()
          })
      }
    },
    edit (index) {
      let item = this.articles[index]
      this.form.title = 'Редагування контакту'
      this.form.id = item.id
      this.form.name = item.name
      this.form.phones = item.phones
      this.form.address = item.address
      this.form.comment = item.comment
      this.show('addContact')
    },
    remove (id) {
      if (confirm('Видали контакт?')) {
        this.$http.delete(this.url + '/delete', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.setData(result.data)
          })
      }
    }
  },
  components: {
    Pagination
  },
  validations: {
    form: {
      name: {
        required
      },
      phones: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
