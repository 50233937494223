<template>
  <div>
    <div style="display: flex;justify-content: space-between">
      <div class="caption">{{$t('Курс лікування')}}</div>
      <div class="caption">{{$t('Загальна сума')}}: {{dTotal}}</div>
    </div>
    <div class="block-sum-print">
      <div class="item" v-for="(person) of articles" :key="person.id">
<!--        <div class="visit full-flex-center border-right">-->
<!--          <div>{{ person.visit }}</div>-->
<!--          візит-->
<!--        </div>-->
        <div class="information-item ">
          <div class="name-i">{{ person.name }}</div>
          <div class="description-i">
            <span><b>{{ person.createdate }}</b></span>
<!--            <span>{{$t('Виконав')}}: <b>&#45;&#45;</b></span>-->
            <span>{{ person.themeC }}</span>
          </div>
        </div>
        <div class="input-wr number-counter">
          <input type="text" class="custom-input" disabled="" :value="person.price">
        </div>
        <div class="count-sum full-flex-center" style="width: 120px">
          х<input type="text" class="custom-input" @input="event => changeAmountNumber(person.id, event.target.value)" style="margin-left: 10px;text-align: center" :value="person.proc_count">
          <div class="input-counter" v-if="person.is_close !== '1'">
            <span @click="changeAmount(person.id, 'plus')">+</span>
            <span @click="changeAmount(person.id, 'minus')">-</span>
          </div>
        </div>
        <div class="price-block full-flex-center border-right">
          Σ{{ person.proc_count * person.price }}
        </div>
        <div class="box-check-i full-flex-center">
          <label class="checkbox-custom big-input"><input type="checkbox" disabled :checked="person.is_close === '1'"><span></span></label>
        </div>
        <a href="#" v-if="person.is_close !== '1'" @click.prevent="removeTreatproc(person.id)" class="remove-treatment"><i class="ico-20"></i></a>
      </div>
    </div>
    <div class="box-app-price">
        <a href="#" class="btn-green small" @click.prevent="show2()">{{$t('Додати')}}</a>
        <button class="btn-green small" style="margin-left: 20px;" @click="$modal.show('addLab')">{{$t('Додати аналізи')}}</button>
    </div>
    <Treatment
      v-bind:id="this.$route.params.sub_id"
      v-bind:patient_id="this.$route.params.id"
      v-bind:showModal="showModal"
      v-on:closeModal="showModal = false"
    />
    <LabModal :doctors="doctors" :patient_id="$route.params.id" :price-list="prices" :view="null" :course_id="this.$route.params.sub_id"/>
  </div>
</template>

<script>
import Treatment from './modules/Treatment'
import LabModal from "./modules/LabModal.vue";
export default {
  name: 'CourseTreatment',
  components: {Treatment, LabModal},
  data () {
    return {
      pid: this.id,
      articles: [],
      checked: false,
      articles2: [],
      originalArticles: [],
      value: '',
      value2: '',
      value3: '',
      tabContent: 1,
      valueCounter1: 60,
      showModal: false,
      dTotal: 0,
      prices: [],
      doctors: [],
      debounce: null
    }
  },
  props: {
    id: {
      default: 0
    }
  },
  created () {
    this.$http.get('/patient/kurs', {params: {id: this.$route.params.sub_id}})
      .then(result => {
        this.articles = result.data.procedures
        this.prices = result.data.prices
        this.doctors = result.data.doctors
      })
  },
  mounted () {
    this.emitter.on('addTreatment', this.addEvent)
  },
  watch: {
    searchString: function (newValue, oldValue) {
      if (newValue !== '') {
        this.priceSearch = this.listPrices.filter(e => {
          const planName = e.planname.toLowerCase()
          return e.childrens.length === 0 && planName.indexOf(newValue.toLowerCase()) !== -1
        })
        if (this.priceSearch.length === 0) {
          this.priceSearch.push({id: 0, planname: this.$t('нічого не знайдено'), childrens: []})
        }
      } else {
        this.priceSearch = []
      }
    },
    articles () {
      let total = 0;
      this.articles.map(art => {
        total += art.proc_count * art.price
      })
      this.dTotal = total
    }
  },
  methods: {
    changeAmount (id, type) {
      this.$http.post('/patient/change-amount', {
        id,
        type,
      }).then(response => {
        this.articles = this.articles.map((article, index) => {
          if (Number(article.id) === Number(response.data.id)) {
            article = response.data
          }
          return article
        })
      }).catch(error => {
        this.$root.alerts('error', error.response ? error.response.data : error.message)
      })
    },
    changeAmountNumber (id, amount) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$http.post('/patient/change-amount', {
          id,
          type: 'number',
          amount
        }).then(response => {
          this.articles = this.articles.map((article, index) => {
            if (Number(article.id) === Number(response.data.id)) {
              article = response.data
            }
            return article
          })
        }).catch(error => {
          this.$root.alerts('error', error.response ? error.response.data : error.message)
        })
      }, 1000)
    },
    setData (data) {
      this.articles2 = data
      this.originalArticles = data
    },
    changeCollapseStatus: function () {
      this.collapseStatus = !this.collapseStatus
    },
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    onsearch (e) {
      if (e.target.value) {
        this.listFinded = this.list.filter(el => el.diagnoseStatus.indexOf(e.target.value) !== -1 || el.diagnoseTime.indexOf(e.target.value) !== -1)
      } else {
        this.listFinded = this.list
      }
    },
    show2 () {
      this.showModal = true
    },
    onsearch2 (e) {
      if (e.target.value2) {
        this.listFinded2 = this.list2.filter(el => el.text.indexOf(e.target.value2) !== -1)
      } else {
        this.listFinded2 = this.list2
      }
    },
    onsearch3 (e) {
      if (e.target.value3) {
        this.listFinded3 = this.list3.filter(el => el.text.indexOf(e.target.value3) !== -1)
      } else {
        this.listFinded3 = this.list3
      }
    },
    show () {
      this.$modal.show('addDiagnose')
    },
    hide () {
      this.$modal.hide('addDiagnose')
    },
    finish (id) {
      this.$http.post('/patient/set-close-procedure', {id: this.articles[id].id, close: this.articles[id].is_close === '1' ? 0 : 1})
        .then(result => {
          console.log('[res]', result)
        })
        .catch(() => {
          this.$root.alerts(this.$t('Не вдалось позначити як виконана'))
        })
    },
    removeTreatproc (id) {
      if (confirm("Видалити?")) {
        this.$http.delete('/patient/remove-treatproc', {
          params: {
            id
          }
        }).then(resp => {
          this.articles = resp.data.procedures
        }).catch(error => {
          this.$root.alerts('error', error.message)
        })
      }
    },
    addEvent (items) {
      this.articles= items
    }
  },
}
</script>

<style scoped>

</style>
