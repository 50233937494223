<template>
  <div class="alert-list">
    <div class="alert-item" :class="alert.type === 'success' ? 'alert-item-success' : 'alert-item-error'"
         v-show="alert.show">
      <div class="alert-item-icon"></div>
      <div class="alert-item-content">
        <div class="alert-item-title">{{ alert.type === 'success' ? 'Успішно' : 'Помилка' }}</div>
        <div class="alert-item-desc">{{ alert.message }}</div>
      </div>
      <div class="alert-item-close"></div>
    </div>
  </div>
  <NavbarComponent/>
  <div class="loader" :class="{'active': store.getters.status === 'load'}">
    <div class="load">
      <hr/>
      <hr/>
      <hr/>
      <hr/>
    </div>
  </div>
  <router-view/>
</template>
<script>
import {store} from './store/index';
export default {
  data () {
    return {
      store
    }
  },
};
</script>
<style>
@import './assets/fonts/icomoon/style.css';
@import 'https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap';
@import './assets/style/main.css';
@import './assets/style/alerts.css';
@import './assets/style/app.css';
@import 'https://releases.transloadit.com/uppy/v2.12.1/uppy.min.css';
</style>
