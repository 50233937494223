<template>
  <div>
    <TopInfo/>
    <div class="content">
      <div class="btn-above-table">
        <a href="#" @click="addNew" class="btn-green"><i class="ico-11"></i> Додати питання</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold table-column-two-small2">
          <thead>
            <tr>
              <th>
                <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
              </th>
              <th></th>
              <th>
                <div class="caption">Назва</div>
                <input type="text" class="custom-input">
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(person, index) of articles" :key="person.id">
              <td>
                <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
              </td>
              <td><a href="#" class="btn-watch" :class="{'active': person.looked, 'active': person.isenabled}" @click.prevent="person.looked = !person.looked"><i class="ico-30"></i></a></td>
              <td>{{ person.question }}</td>
              <td><a href="#" class="btn-table-change" @click.prevent="edit(index)"><i class="ico-19"></i></a></td>
              <td><a href="#" @click.prevent="remove(person.id)" class="btn-table-change"><i class="ico-20"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.QuestionsEditor"
        width="720px"
        :title="$t('Редактор тестових питань')"
        class="modal-custom"
    >
      <form @submit.prevent="saveForm">
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="description">Порядковий номер</div>
            <div class="input-wr">
              <input v-model="form.seqnumber">
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description">Текст питання</div>
            <div class="input-wr">
              <textarea class="mailing-textarea" v-model="form.question"></textarea>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Вид питання</div>
            <div class="input-wr">
              <el-select v-model="form.type" filterable class="full-width">
                <el-option
                    v-for="item in [{label:'поле вводу', code: 'TextInput'}, {label:'випадаючий список', code: 'DropDownList'}, {label:'один варіант', code: 'RadioButton'}]"
                    :key="item.code"
                    :label="item.label"
                    :value="item.code"
                />
              </el-select>
            </div>
          </div>
          <div class="table-wrapper table-test">
            <table class="body-bold">
              <thead>
              <tr>
                <th>
                  <div class="caption">Відповідь</div>
                </th>
                <th style="width: 40px;"></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(ans, index) in answers" :key="index">
                  <td>{{ans}}</td>
                  <td><a href="#" @click.prevent="removeAnswer(index)" class="btn-table-change"><i class="ico-20"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="line-modal-form">
            <div class="description">Новий варіант відповіді</div>
            <div class="input-wr flex box-right-btn">
              <div class="wr-sel2">
                <input type="text" v-model="newAnswer" class="custom-input">
              </div>
              <ul class="bottom-btn ">
                <li class="tooltip-wrapper">
                  <a href="#" @click.prevent="addAnswer" class="full-flex-center btn-border-grey green-text"><i class="ico-11"></i></a>
                  <div class="tooltip">Додати новий варіант відповіді</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">Зберегти</button>
            <button class="btn-red" @click.prevent="hide()">Скасувати</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
export default {
  name: 'InformationTest',
  data () {
    return {
      articles: [],
      originalArticles: [],
      checked: false,
      url: '/templatetest',
      search: {
        description: ''
      },
      form: {
        id: 0,
        question: '',
        configxml: '',
        seqnumber: '',
        isenabled: 1,
        type: 'поле вводу'
      },
      answers: [],
      newAnswer: '',
      configXml: {
        '@attributes': {
          type: ''
        },
        answers: []
      }
    }
  },
  created () {
    this.$http.get(this.url + '/all')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    show () {
      this.$modal.show('QuestionsEditor')
    },
    hide () {
      this.$modal.hide('QuestionsEditor')
    },
    addNew () {
      this.clear()
      this.show()
    },
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    setData (data) {
      this.articles = data
      this.originalArticles = data
    },
    localSearch () {
      this.articles = this.originalArticles.filter(e => {
        let result = true
        for (let key in this.search) {
          if (this.search[key] !== '') {
            if (e[key].indexOf(this.search[key]) === -1) {
              result = false
            }
          }
        }
        return result ? e : false
      })
    },
    clear () {
      this.form.title = 'Додавання нового питання'
      this.form.question = ''
      this.form.configxml = ''
      this.form.seqnumber = ''
      this.form.isenabled = 1
      this.answers = []
      this.form.id = 0
    },
    saveForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.configXml['@attributes'].type = this.form.type
        this.configXml.answers = this.answers
        this.$http.post(this.url + '/saved', {
          seqnumber: this.form.seqnumber,
          question: this.form.question,
          configxml: this.configXml,
          id: this.form.id
        })
          .then(result => {
            this.$root.alerts('success', 'Запис збережено')
            this.setData(result.data)
            this.hide()
            this.clear()
          })
          .catch(() => {
            this.$root.alerts('error', 'Виникла помилка')
          })
      }
    },
    edit (index) {
      let item = this.articles[index]
      this.form.title = 'Редагування питання'
      this.form.id = item.id
      this.form.seqnumber = item.seqnumber
      this.form.question = item.question
      this.answers = item.configxml.answer
      switch (item.configxml['@attributes'].type) {
        case 'DropDownList':
          this.form.type = 'випадаючий список'
          break
        case 'RadioButton':
          this.form.type = 'один варіант'
          break
        case 'TextInput':
          this.form.type = 'поле вводу'
          break
      }
      console.log('[Item]', this.form)
      this.show()
    },
    remove (id) {
      if (confirm('Видали питання?')) {
        this.$http.delete(this.url + '/delete', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.$root.alerts('success', 'Питання видалено')
            this.setData(result.data)
          })
          .catch(() => {
            this.$root.alerts('error', 'Помилка видалення')
          })
      }
    },
    addAnswer () {
      if (this.newAnswer === '') {
        this.$root.alerts('error', 'Введіть значення')
      } else {
        this.answers.push(this.newAnswer)
        this.newAnswer = ''
      }
    },
    removeAnswer (index) {
      this.answers.splice(index, 1)
    }
  },
  validations: {
    form: {
      seqnumber: {
        required
      },
      question: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
