<template>
  <div>
    <TopInfo />
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="show ()"><i class="ico-11"></i> {{$t('Додати новий кабінет')}}</a>
      </div>
      <div class="table-wrapper">
        <div id="table"/>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.addOrder"
        width="720px"
        :title="form.title"
        class="modal-custom"
    >
      <form @submit.prevent="saveForm">
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">№</div>
            <div class="input-wr">
              <input type="text" v-model="v$.form.number.$model" class="custom-input" :class="{'input-v-error':v$.form.number.$error.length}">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Порядок виводу')}}</div>
            <div class="input-wr">
              <input type="text" v-model="v$.form.sort.$model" class="custom-input" :class="{'input-v-error':v$.form.sort.$error.length}">
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description">{{$t('Опис')}}</div>
            <div class="input-wr">
              <textarea v-model="v$.form.name.$model" :class="{'input-v-error':v$.form.name.$error.lenght}" class="mailing-textarea h120"></textarea>
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description">{{$t('Закріплений лікар')}}</div>
            <div class="input-wr">
              <el-select
                  v-model="form.doctor_id"
                  class="m-2"
                  size="large"
                  filterable
                  style="width: 100%"
                  :placeholder="$t('Виберіть')"
              >
                <el-option
                    v-for="item in doctors"
                    :key="item.id"
                    :label="item.fullname"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Колір')}}</div>
            <div class="input-wr">
              <div class="box-color-change" :class="{open: activeColor}">
                <div class="head-color" :style="{background: form.color}" @click="activeColor = !activeColor"></div>
                <div class="drop-color">
                  <ColorPicker
                      theme="light"
                      :color="form.color"
                      :sucker-hide="false"
                      :sucker-canvas="suckerCanvas"
                      :sucker-area="suckerArea"
                      @changeColor="updateValue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hide()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, integer } from '@vuelidate/validators'
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'
import {Grid, h, html} from "gridjs";

export default {
  name: 'GroupsPatients',
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      rooms: [],
      activeColor: false,
      checked: false,
      form: {
        title: 'Додавання нового кабінету',
        number: '',
        name: '',
        color: '#b4e3f5',
        id: 0,
        sort: 1,
        doctor_id: ''
      },
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      doctors: [],
      grid: new Grid()
    }
  },
  created () {
    this.$http.get('/rooms/index')
      .then(result => {
        this.rooms = result.data.rooms
        this.doctors = result.data.doctors
        this.grid.updateConfig({
          columns: [{
            id: 'id',
            name: 'ID',
            width: '50px',
          },{
            id: 'name',
            name: this.$t('Назва')
          },{
            id: 'number',
            name: this.$t('Номер')
          },{
            id: 'action',
            name: '',
            width: '70px',
            formatter: (cell, row) => {
              const btn = []
              btn.push(h('a', {
                className: 'btn-table-change',
                onClick: () => {
                  this.edit(row._cells[0].data)
                }
              }, html('<i class="ico-19"></i>')));
              btn.push(h('a', {
                className: 'btn-table-change',
                onClick: () => {
                  this.remove(row._cells[0].data)
                }
              }, html('<i class="ico-20"></i>')));
              return btn;
            }
          }],
          data: this.rooms,
          pagination: {
            enabled: true,
            limit: 20,
            summary: false,
          },
          search: {
            debounceTimeout: 1000,
          },
          language: {
            'search': {
              'placeholder': '🔍 ' + this.$t('Пошук')
            },
            'pagination': {
              'previous': '⬅️',
              'next': '➡️',
            }
          }
        });
        this.grid.render(document.getElementById("table"));
      })
  },
  components: {
    ColorPicker
  },
  methods: {
    updateValue (event) {
      this.form.color = event.hex
      this.activeColor = 0
    },
    show () {
      this.$modal.show('addOrder')
    },
    hide () {
      this.$modal.hide('addOrder')
      this.clear()
    },
    clear () {
      this.form = {
        title: 'Додавання нового кабінету',
        number: '',
        name: '',
        color: '#b4e3f5',
        id: 0,
        sort: 1,
        doctor_id: ''
      }
    },
    saveForm () {
      if (!this.v$.form.$invalid) {
        this.$http.post('/rooms/save', {
          number: this.form.number,
          roomcolor: this.form.color,
          name: this.form.name,
          id: this.form.id,
          sort: this.form.sort,
          doctor_id: this.form.doctor_id
        })
          .then(result => {
            this.rooms = result.data
            this.hide()
            this.clear()
          })
      }
    },
    edit (index) {
      let item = this.rooms.find(e => {
        return Number(e.id) === Number(index)
      })
      this.form.title = 'Редагування кабінету'
      this.form.id = item.id
      this.form.name = item.name
      this.form.color = item.roomcolor
      this.form.number = item.number
      this.form.sort = item.sort
      this.form.doctor_id = item.doctor_id
      this.show()
    },
    remove (id) {
      if (confirm('Видали кабінет?')) {
        this.$http.delete('/rooms/delete', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.rooms = result.data
          })
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      number: {
        required,
        integer
      },
      sort: {
        required,
        integer
      },
      doctor_id: {
        required,
      }
    }
  }
}
</script>

<style scoped>

</style>
