<template>
  <div>
    <div class="caption">{{$t('Кольпоскопічні дослідження')}}</div>
    <form @submit.prevent="save" ref="form">
      <div class="form-examination">
        <div class="left-qr">
          <div class="testing-box">
            <div class="caption-light">{{$t('Дата обстеження')}}</div>
            <div class="line-btn-right">
              <flat-pickr
                class="custom-input"
                v-model="docdate"
                :config="config"
              ></flat-pickr>
            </div>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Лікар')}}</div>
            <el-select
                v-model="doctor_fk"
                class="m-2"
                size="large"
            >
              <el-option
                  v-for="item in doctors"
                  :key="item.id"
                  :label="item.fullname"
                  :value="item.id"
              />
            </el-select>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Результат обстеження')}}</div>
            <textarea
              class="mailing-textarea h190"
              v-model="v$.conclusion.$model"
              :class="{'input-v-error':v$.conclusion.$error.length}"
            ></textarea>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Примітка')}}</div>
            <textarea class="mailing-textarea h120" v-model="notes"></textarea>
          </div>
          <div class="testing-box">
            <div class="caption-light">{{$t('Фотографії')}}</div>
            <div class="upload-field-wr">
              <div
                class="box-img"
                v-for="(file, imageIndex) in files"
                :key="file.imageIndex"
              >
                <img :src="file.source" alt="img" class="object-fit" @click="fancyUrl = file.source">
                <a href="#" v-if="file.name" @click.prevent="deleteImage(imageIndex)" class="delete-btn-black full-flex-center"><i class="ico-17"></i></a>
              </div>
              <div class="upload-field">
                <ul class="btn-list-upload full-flex-center">
                  <li class="tooltip-wrapper">
                    <a href="javascript:;" id="uppyModalOpener" class="full-flex-center btn-border-grey"><i class="ico-45"></i></a>
                    <div class="tooltip">{{$t('Загрузити')}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="right-qr">
          <div class="testing-box">
            <div class="caption-light">{{$t('Історія')}}</div>
            <div class="date-review">
              <div class="article">{{$t('Дата огляду')}}</div>
              <ul>
                <li v-for="dateHistory of history" :key="dateHistory.id">
                  <a href="#" @click.prevent="see(dateHistory.id)" class="one-date">{{ dateFormat(dateHistory.docdate) }}</a>
                  <a href="#" @click.prevent="del(dateHistory.id)" class="btn-table-change"><i class="ico-20"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="save-testing">
        <div class="wrapper-l-btn">
          <button type="button" @click.prevent="print" class="btn-white height-36"><i class="ico-24"></i>{{$t('Роздрукувати')}}</button>
        </div>
        <button class="btn-green standard-width small">{{$t('Зберегти')}}</button>
      </div>
    </form>
    <div id="printMe" style="display: none">
      <div style="width: 100%;background-color: gray;color: white;text-align: center;-webkit-print-color-adjust: exact;padding: 5px;font-size: 20px">{{$t('Кольпоскопічне дослідження')}}</div>
      <table style="width: 100%">
        <tr>
          <td style="width: 25%;padding: 5px;">
            <img src="../../assets/images/logo.png" width="200px;">
          </td>
          <td style="width: 50%;text-align: center">
            <div style="font-size: 18px;font-weight:bold;">{{$t('Картка')}} №{{pid}}</div>
            <div style="font-size: 18px;font-style: italic;font-weight: bold">{{ patient ? patient.fullname : '' }}</div>
          </td>
          <td style="width: 25%;font-size: 12px;">
            <div style="font-weight:bold;">Медичний центр «Боголюби»</div>
            <div>45624, Волинська область, Луцький район,</div>
            <div>с. Тарасове, вул. Лісова, 1-Б</div>
            <div>Тел.: (099) 44-55-404,</div>
            <div>(067) 851-79-38</div>
            <div>www.bogolybu.com.ua,</div>
            <div>e-mail: bogolubu@gmail.com</div>
          </td>
        </tr>
      </table>
      <div style="margin: 20px 0;font-weight:bold;font-size: 16px;">{{$t('Дата огляду')}}: {{ printData.date }}</div>
      <div style="margin: 20px 0 0;font-weight:bold;font-size: 16px;">{{$t('Результат обстеження')}}:</div>
      <div v-html="printData.text" style="margin-block-start: 0em;margin-block-end: 0em"></div>
      <div v-show="printData.note !== ''">
        <strong>{{$t('Примітка')}}</strong>
        <div v-html="printData.note" style="margin-block-start: 0em;margin-block-end: 0em"></div>
      </div>
      <div style="margin: 20px 0;font-weight:bold;font-size: 16px;">{{$t('Лікар')}}: {{ printData.doctor }}</div>
      <div>
        <img :key="index" v-for="(image, index) in files" :src="image.source" alt="" style="width: 45%;margin-right: 1%;margin-bottom: 1%" :data-image="image.source">
      </div>
    </div>
    <div class="fancyImage" v-show="fancyUrl !== null" @click="fancyUrl = null">
      <img :src="fancyUrl">
    </div>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Ukrainian } from 'flatpickr/dist/l10n/uk'
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Webcam from "@uppy/webcam";
import Ukr from '@uppy/locales/lib/uk_UA'
import XHRUpload from "@uppy/xhr-upload";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      pid: this.id,
      config: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'd.m.Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Ukrainian
      },
      docdate: moment().format('YYYY') + '-' + moment().format('MM') + '-' + moment().format('DD'),
      doctors: [],
      doctor_fk: '',
      conclusion: '',
      notes: '',
      files: [{
        source: 'https://master.medicalstandard.com.ua/rest/1643227505395.jpg'
      }],
      history: [],
      cid: 0,
      printData: {
        date: new Date().toLocaleString(),
        text: '',
        doctor: '',
        images: []
      },
      fancyUrl: null
    }
  },
  components: {
    flatPickr
  },
  props: {
    id: {
      default: 0
    }
  },
  async created () {
    await this.$http.get('/patient/nh-colposcope', {params: {id: this.id}})
      .then(result => {
        this.history = result.data.data
      })
    await this.$http.get('/doctor/all', {params: {id: this.id}})
      .then(result => {
        this.doctors = result.data.doctors
      })
  },
  validations: {
    doctor_fk: {
      required
    },
    conclusion: {
      required
    }
  },
  mounted () {
    let self = this
    // eslint-disable-next-line
    const uppy = new Uppy({
      debug: true,
      autoProceed: false,
      // eslint-disable-next-line
      locale: Ukr,
      restrictions: {
        allowedFileTypes: ['image/*']
      }
    })
    // eslint-disable-next-line
      .use(Dashboard, { trigger: '#uppyModalOpener' })
      // eslint-disable-next-line
      .use(Webcam, {target: Dashboard, mirror: false})
      // eslint-disable-next-line
      // eslint-disable-next-line
      .use(XHRUpload, { endpoint: process.env.VUE_APP_API_URL + '/rest/v1/files/upload-patient?pid=' + self.pid, limit: 1 })

    uppy.on('upload-success', (file, response) => {
      if (self.files === null) {
        self.files = [{name: file.name, source: process.env.VUE_APP_API_URL + '/rest/source/' + response.body.data.servername, servername: response.body.data.servername}]
      } else {
        self.files.push({name: file.name, source: process.env.VUE_APP_API_URL + '/rest/source/' + response.body.data.servername, servername: response.body.data.servername})
      }
    })
  },
  methods: {
    dateFormat (date) {
      return moment(date).format("DD.MM.YYYY")
    },
    print () {
      this.printData.date = moment(this.date).format('DD.MM.YYYY') + ' ' + moment().format('HH:mm')
      this.printData.text = this.conclusion
      //this.printData.result = this.result
      this.printData.note = this.notes
      this.printData.doctor = this.doctor_fk.shortname
      setTimeout(() => {
        this.$htmlToPaper('printMe')
      }, 500)
      // setTimeout(() => {
      //   this.printData.images = []
      // }, 1000)
    },
    /**
     * @brief Додавання
    */
    save () {
      if (!v$.form.$invalid) {
        this.$http.post('/patient/save-colscope', {
          doctor_fk: this.doctor_fk.id,
          conclusion: this.conclusion,
          notes: this.notes,
          files: this.files,
          patient_fk: this.pid,
          id: this.cid,
          docdate: this.docdate
        })
          .then(result => {
            this.history = result.data.data
            this.clear()
          })
      }
    },
    /**
     * @brief Очистка полів
     */
    clear () {
      this.doctor_fk = ''
      this.conclusion = ''
      this.notes = ''
      this.files = [{source: 'https://master.medicalstandard.com.ua/rest/1643227505395.jpg'}]
      this.cid = 0
    },
    /**
     * @brief Видалення карти
     * @param index
     */
    deleteImage (index) {
      console.log('[Delete image]', index)
      this.files.splice(index, 1)
    },
    /**
     * @brief Перегляд
     * @param id
     */
    see (id) {
      this.$http.get('/patient/see-colcoskop', {
        params: {
          id: id
        }
      })
        .then(result => {
          this.files = [{source: 'https://master.medicalstandard.com.ua/rest/1643227505395.jpg'}]
          result.data.files.forEach(e => {
            this.files.push({name: e.name, source: this.$root.serverUrl + '/rest/source/' + e.servername, servername: e.servername})
          })
          this.docdate = result.data.info.docdate
          this.doctor_fk = result.data.info.doctor_fk
          this.conclusion = result.data.info.conclusion
          this.notes = result.data.info.notes
          this.cid = result.data.info.id
        })
    },
    /**
     * @brief Видалення запису
     * @param id
     */
    del (id) {
      if (confirm('Видалити запис?')) {
        this.$http.delete('/patient/delete-colskope', {
          params: {
            id: id,
            pid: this.pid
          }
        })
          .then(result => {
            this.history = result.data.data
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
