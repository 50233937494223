<template>
  <!-- eslint-disable -->
  <div class="patient-data">
    <div class="caption">{{$t('Дані пацієнта')}}</div>
    <ul class="header-tab-box padding-header">
      <li><a href="#" :class="{active: tabContent === 1}" @click.prevent="tabContent = 1">{{$t('Основне')}}</a></li>
      <li><a href="#" :class="{active: tabContent === 2}" @click.prevent="tabContent = 2">{{$t('Контактні та паспортні дані')}}</a></li>
      <li><a href="#" :class="{active: tabContent === 3}" @click.prevent="tabContent = 3">{{$t('Інше')}}</a></li>
    </ul>
    <div v-if="tabContent === 1">
      <form action="">
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="description"></div>
            <div class="input-wr clearfix upload-cards">
              <div class="upload-img-box">
                <i class="ico-52"></i>
              </div>
              <ul class="search-btn">
                <li class="tooltip-wrapper">
                  <a href="#" id="uppyModalOpener" class="full-flex-center btn-border-grey"><i class="ico-50"></i></a>
                  <div class="tooltip">{{$t('Зробити/завантажити фото')}}</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Номер картки')}}:</div>
            <div class="input-wr">
              <strong>{{patient.id}}</strong>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('П.І.Б')}} *</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.fullname">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Стать')}}</div>
            <div class="input-wr">
              <ul class="radio-list flex">
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="1" v-model="patient.sex">
                    <span>{{$t('жіноча')}}</span>
                  </label>
                </li>
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="0" v-model="patient.sex">
                    <span>{{$t('чоловіча')}}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Дата народження')}}</div>
            <div class="input-wr line-btn-right">
              <el-date-picker
                v-model="patient.birthday"
                type="date"
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                :placeholder="$t('Дата')"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Житель населеного пункту')}}</div>
            <div class="input-wr">
              <ul class="radio-list flex">
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="1" v-model="patient.villageorcity">
                    <span>{{$t('місто')}}</span>
                  </label>
                </li>
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="2" v-model="patient.villageorcity">
                    <span>{{$t('село')}}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Країна')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.country">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Область')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.state">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Район')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.ragion">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Населений пункт')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.city">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Адреса')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.address">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Індекс')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.postalcode">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Телефон')}}</div>
            <div class="input-wr">
              <input v-maska="'+38 (###) ###-##-##'" class="custom-input" v-model="patient.mobilenumber"/>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Дисконт')}}</div>
            <div class="input-wr number-counter degree">
              <input type="text" class="custom-input" v-model="patient.discount">
              <span class="degree-text full-flex-center">%</span>
              <a href="#" class="priceBtn count-minus" @click.prevent="patient.discount > 0 ? patient.discount-- : false"><i class="ico-28"></i></a>
              <a href="#" class="priceBtn count-plus" @click.prevent="patient.discount++"><i class="ico-27"></i></a>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Застрахований')}}</div>
            <div class="input-wr">
              <ul class="radio-list flex">
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="0" v-model="patient.has_insurance">
                    <span>{{$t('ні')}}</span>
                  </label>
                </li>
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="1" v-model="patient.has_insurance">
                    <span>{{$t('так')}}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="line-modal-form text-top">
            <div class="description">{{$t('Примітка')}}</div>
            <div class="input-wr">
              <textarea class="mailing-textarea" v-model="patient.comment"></textarea>
            </div>
          </div>
        </div>
        <div class="footer-modal flex-footer">
          <div class="btn-left-footer">
            <button type="button" class="btn-red">{{$t('Помістити в архів')}}</button>
          </div>
          <div class="btn-right-footer">
            <button class="btn-green" type="button" @click="save">{{$t('Зберегти')}}</button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="tabContent === 2">
      <form action="">
        <div class="body-modal form-long">
          <div class="caption-black-m">{{$t('Паспорт')}}</div>
          <div class="line-modal-form">
            <div class="description">{{$t('Серія')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.passport_series">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Номер')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.passport_number">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Дата видачі')}}</div>
            <div class="input-wr line-btn-right">
              <el-date-picker v-model="patient.passport_date" type="date"/>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('ІПН')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.passport_issuing">
            </div>
          </div>
          <div class="caption-black-m">{{$t('Робота')}}</div>
          <div class="line-modal-form">
            <div class="description">{{$t('Місце роботи')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.employmentplace">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Посада')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.job">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Робочий телефон')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.workphone">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">E-mail</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.email">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Телефон')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.telephonenumber">
            </div>
          </div>
          <div class="caption-black-m">{{$t('Опікун')}}</div>
          <div class="line-modal-form">
            <div class="description">{{$t('Опікун')}}</div>
            <div class="input-wr flex box-right-btn">
              <div class="wr-sel2">
                <v-select :searchable="true" :options="[]"></v-select>
              </div>
              <ul class="bottom-btn ">
                <li class="tooltip-wrapper">
                  <a href="#" class="full-flex-center btn-border-grey green-text"><i class="ico-11"></i></a>
                  <div class="tooltip">{{$t('Додати опікуна')}}</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="caption-black-m">{{$t('Батько')}}</div>
          <div class="line-modal-form">
            <div class="description">{{$t('Прізвище')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.fatherlastname">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Ім\'я')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.fatherfirstname">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('По батькові')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.fathermiddlename">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Дата народження')}}</div>
            <div class="input-wr line-btn-right">
              <el-date-picker v-model="patient.fatherbirthdate" type="date"/>
            </div>
          </div>
          <div class="caption-black-m">{{$t('Мати')}}</div>
          <div class="line-modal-form">
            <div class="description">{{$t('Прізвище')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.motherlastname">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Ім\'я')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.motherfirstname">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('По батькові')}}</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="patient.mothermiddlename">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Дата народження')}}</div>
            <div class="input-wr line-btn-right">
              <el-date-picker v-model="patient.motherbirthdate" type="date"/>
            </div>
          </div>
        </div>
        <div class="footer-modal flex-footer">
          <div class="btn-left-footer">
            <button class="btn-red">{{$t('Помістити в архів')}}</button>
          </div>
          <div class="btn-right-footer">
            <button class="btn-green" type="button" @click="save">{{$t('Зберегти')}}</button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="tabContent === 3">
      <form action="">
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="description">{{$t('Провідний лікар')}}</div>
            <div class="input-wr">
              <v-select searchable :options="doctors" v-model="patient.doctorid"></v-select>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Група населенння')}}</div>
            <div class="input-wr">
              <v-select :searchable="true" v-model="patient.populationgroupe" :options="groupnas" label="description"></v-select>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Підрозділ')}}</div>
            <div class="input-wr">{{$t('Гінекологія')}}</div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Диспансерний облік')}}</div>
            <div class="input-wr">
              <ul class="radio-list flex">
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="One" checked name="typeProcedure">
                    <span>{{$t('так')}}</span>
                  </label>
                </li>
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="Two" name="typeProcedure">
                    <span>{{$t('ні')}}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">
              {{$t('Звідки дізналися про клініку')}}
            </div>
            <div class="input-wr">
              <v-select :searchable="true" :options="targets" label="description"></v-select>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">
              {{$t('Група')}}
            </div>
            <div class="input-wr">
              <v-select :searchable="true" :options="[]"></v-select>
            </div>
          </div>
          <Oblik></Oblik>
        </div>
        <div class="footer-modal flex-footer">
          <div class="btn-left-footer">
            <button class="btn-red">{{$t('Помістити в архів')}}</button>
          </div>
          <div class="btn-right-footer">
            <button class="btn-green" type="button" @click="save">{{$t('Зберегти')}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>
import Oblik from './modules/Oblik'
import Uppy from "@uppy/core";
import Ukrainian from '@uppy/locales/lib/uk_UA'
import Dashboard from '@uppy/dashboard'
import XHRUpload from '@uppy/xhr-upload'
import Webcam from '@uppy/webcam'
export default {
  data () {
    return {
      item: this.patient,
      checked: false,
      tabContent: 1, // активний таб,
      groupnas: [],
      targets: [],
      url: '/patient',
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  components: {
    Oblik,
  },
  props: {
    patient: {
      default: {}
    }
  },
  computed: {
    doctors () {
      let doctors = this.$store.getters.getDoctors
      let items = []
      doctors.forEach(function (e) {
        items.push({label: e.fullname, code: e.id})
      })
      return items
    }
  },
  created () {
    this.$http.get(this.url + '/dictonary')
      .then(response => {
        this.targets = response.data.carddictonaries.filter(e => {
          return Number(e.type) === 0
        })
        this.groupnas = response.data.carddictonaries.filter(e => {
          return Number(e.type) === 2
        })
      })
  },
  methods: {
    save () {
      this.$http.post(this.url + '/edit', this.patient)
        .catch(error => {
          console.log('[Error]', error)
          this.$root.alerts('error', 'Помилка редагування данних')
        })
    }
  },
  mounted () {
    let self = this
    // eslint-disable-next-line
    const uppy = new Uppy({
      debug: true,
      autoProceed: false,
      // eslint-disable-next-line
      locale: Ukrainian,
      restrictions: {
        allowedFileTypes: ['image/*']
      }
    })
    // eslint-disable-next-line
      .use(Dashboard, { trigger: '#uppyModalOpener' })
      // eslint-disable-next-line
      .use(Webcam, {target: Dashboard})
      // eslint-disable-next-line
      .use(XHRUpload, { endpoint: process.env.APP_URL + '/rest/v1' + this.url + '/upload-photo?id=' + self.patient.id })

    uppy.on('upload-success', (file, response) => {
      self.files.push({name: file.name, source: file.preview, servername: response.body.data.servername})
    })
  }
}
</script>
