<template>
    <div>
      <div class="caption">Друк форми</div>
      <div class="description-print-form">
        <div class="one-line">
          <div class="left-q">
            Пацієнт:
          </div>
          <div class="right-q">
            {{fullname}}
          </div>
        </div>
        <div class="one-line">
          <div class="left-q">
            № карти:
          </div>
          <div class="right-q">
            {{cardNumber}}
          </div>
        </div>
      </div>
      <ul class="btn-print-form">
        <li><a href="#" @click.prevent="generate('Hello.docx')" class="btn-white">066</a></li>
        <li><a href="#" class="btn-white">003</a></li>
        <li><a href="#" class="btn-white">Виписка</a></li>
        <li><a href="#" class="btn-white">007</a></li>
        <li><a href="#" class="btn-white">014</a></li>
      </ul>
    </div>
</template>

<script>
export default {
  data () {
    return {
      pid: this.id,
      fullname: '',
      cardNumber: ''
    }
  },
  async created () {
    await this.$http.get('/patient/one', {
      params: {
        id: this.pid
      }
    })
      .then(result => {
        this.fullname = result.data.fullname
        this.cardNumber = result.data.id
      })
  },
  methods: {
    generate (name) {
      this.$http.get('/doc/generate', {
        params: {
          docName: name
        }
      })
        .then(result => {
          location.href = this.$root.serverUrl + '/rest/doc/' + result.data.data
        })
    }
  },
  props: {
    id: {
      default: 0
    }
  }
}
</script>

<style scoped>

</style>
