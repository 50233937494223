<template>
  <div>
    <TopInfo />
    <div class="content">
      <div class="general-information">
        <div class="general-caption">
          <span>{{$t('Загальні відомості про клініку')}}</span>
        </div>
        <form action="" class="form-general">
          <div class="upload-box">
            <div class="caption">{{$t('Логотип клінки')}}</div>
            <div class="upload-img full-flex-center">
              <img :src="clinic.logo" alt="clinic">
            </div>
            <div class="line-btn-upload">
              <div class="btn-upload full-flex-center" id="uppyModalOpener">{{$t('Замінити')}}</div>
<!--              <div class="btn-delete full-flex-center"><i class="ico-17"></i></div>-->
            </div>
          </div>
          <div class="form-wr">
            <div class="one-block">
              <div class="one-line">
                <span>{{$t('Назва клінкіки')}}</span>
                <input type="text" class="custom-input" v-model="clinic.name">
              </div>
              <div class="one-line">
                <span>Юридична назва</span>
                <input type="text" class="custom-input" v-model="clinic.legalname">
              </div>
              <div class="one-line">
                <span>ЄДРПОУ</span>
                <input type="text" class="custom-input" v-model="clinic.code">
              </div>
              <div class="one-line">
                <span>E-mail</span>
                <input type="text" class="custom-input" v-model="clinic.email">
              </div>
            </div>
            <div class="one-block">
              <div class="one-line">
                <span>Адреса</span>
                <input type="text" class="custom-input" v-model="clinic.address">
              </div>
              <div class="one-line">
                <span>Юридична адреса</span>
                <input type="text" class="custom-input" v-model="clinic.legaladdress">
              </div>
<!--              <div class="one-line">-->
<!--                <span>Головний бухгалтер</span>-->
<!--                <input type="text" class="custom-input">-->
<!--              </div>-->
<!--              <div class="one-line">-->
<!--                <span>Директор</span>-->
<!--                <input type="text" class="custom-input">-->
<!--              </div>-->
              <div class="one-line">
                <span>Рорзрахунковий банк</span>
                <input type="text" class="custom-input" v-model="clinic.bank_name">
              </div>
              <div class="one-line">
                <span>р./р.</span>
                <input type="text" class="custom-input" v-model="clinic.bank_currentaccount">
              </div>
              <div class="one-line">
                <span>МФО</span>
                <input type="text" class="custom-input" v-model="clinic.bank_mfo">
              </div>
              <div class="one-line">
                <span>Ліцензія: серія</span>
                <input type="text" class="custom-input" v-model="clinic.license_series">
              </div>
              <div class="one-line">
                <span>Ліцензія: номер</span>
                <input type="text" class="custom-input" v-model="clinic.license_number">
              </div>
              <div class="one-line">
                <span>Номер</span>
                <input type="text" class="custom-input" v-model="clinic.telephonenumber">
              </div>
              <div class="one-line">
                <span>Факс</span>
                <input type="text" class="custom-input" v-model="clinic.fax">
              </div>
              <div class="one-line">
                <span>Адреса сайту</span>
                <input type="text" class="custom-input" v-model="clinic.sitename">
              </div>
              <div class="one-line">
                <span>Skype</span>
                <input type="text" class="custom-input" v-model="clinic.skype">
              </div>
<!--              <div class="one-line">-->
<!--                <span>Орган влади</span>-->
<!--                <input type="text" class="custom-input">-->
<!--              </div>-->
              <div class="one-line">
                <span>Місто розташування клініки</span>
                <input type="text" class="custom-input" v-model="clinic.city">
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="btn-bellow-table">
        <a href="#" class="btn-green standard-width" @click.prevent="saved">{{$t('Зберегти')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Webcam from "@uppy/webcam";
import XHRUpload from "@uppy/xhr-upload";
import Ukr from '@uppy/locales/lib/uk_UA';

export default {
  data () {
    return {
      clinic: {
        name: '',
        legalname: '',
        address: '',
        code: '',
        email: '',
        legaladdress: '',
        bank_name: '',
        bank_mfo: '',
        bank_currentaccount: '',
        license_number: '',
        license_series: '',
        telephonenumber: '',
        fax: '',
        sitename: '',
        skype: '',
        city: '',
        logo: ''
      }
    }
  },
  created () {
    this.$http.get('/clinic/index')
      .then(result => {
        for (let key in result.data) {
          if (key === 'logo') {
            this.clinic.logo = process.env.VUE_APP_API_URL + '/rest/source/logo/' + result.data.logo
          } else {
            this.clinic[key] = result.data[key]
          }
        }
      })
  },
  mounted () {
    let self = this
    // eslint-disable-next-line
    const uppy = new Uppy({
      debug: true,
      autoProceed: false,
      // eslint-disable-next-line
      locale: Ukr,
      restrictions: {
        allowedFileTypes: ['image/*']
      }
    })
    // eslint-disable-next-line
      .use(Dashboard, { trigger: '#uppyModalOpener' })
      // eslint-disable-next-line
      .use(XHRUpload, { endpoint: process.env.VUE_APP_API_URL + '/rest/v1/clinic/upload-logo?clinic_id=' + process.env.VUE_APP_CLINIC_ID })

    uppy.on('upload-success', (file, response) => {
      self.clinic.logo = process.env.VUE_APP_API_URL + '/rest/source/logo/' + response.body
    })
  },
  methods: {
    saved () {
      this.$http.post('/clinic/saved', this.clinic)
        .then(result => {
          console.log('[Result]', result)
        })
    }
  }
}
</script>

<style scoped>

</style>
