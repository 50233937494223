<template>
  <div>
    <TopPacient />
    <div class="content">
      <div class="btn-above-table">
        <a
          href="#"
          class="btn-green"
          @click.prevent="showModal('registerPatient')"
        ><i class="ico-11" /> {{ $t('Реєстрація нового пацієнта') }}</a>
      </div>
      <div class="table-wrapper p-20">
        <div id="table"/>
      </div>
    </div>
    <newcard
      @updatePatient="updatePatient"
    />
  </div>
</template>

<script>
import Pagination from '../modules/Pagination'
import newcard from './modules/NewCard'
import TopPacient from "@/components/modules/TopPacient";
import moment from 'moment';
import { Grid, html, h } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
export default {
  components: {
    TopPacient,
    newcard,
    Pagination
  },
  data () {
    return {
      allCheck: false,
      patient: [],
      search: {
        cardnumber: '',
        fullname: '',
        birthday: '',
        carddate: ''
      },
      timer: {
        search: null
      },
      patients: [],
      page: 0,
      url: '/patient',
      grid: new Grid()
    }
  },
  mounted() {
    this.grid.updateConfig({
      columns: [{
        id: 'number',
        name: this.$t('№ Картки')
      }, {
        id: 'pib',
        name: this.$t('П.І.Б')
      }, {
        id: 'year',
        name: this.$t('Вік')
      }, {
        id: 'birthday',
        name: this.$t('Дата народження')
      }, {
        id: 'created_at',
        name: this.$t('Дата створення')
      }, {
        id: 'phone',
        name: this.$t('Номер телефону')
      }, {
        id: 'action',
        name: '',
        width: '100px',
        formatter: (cell, row) => {
          const btn = []
          // btn.push(h('a', {
          //   className: 'btn-table-change',
          // }, html('<i class="ico-24"></i>')));
          // btn.push(h('a', {
          //   className: 'btn-table-change',
          // }, html('<i class="ico-36" title="'+this.$t('Диспансеризація')+'"></i>')));
          // btn.push(h('a', {
          //   className: 'btn-table-change',
          // }, html('<i class="ico-37" title="'+this.$t('Майстер запису пацієнта на прийом')+'"></i>')));
          // btn.push(h('a', {
          //   className: 'btn-table-change',
          // }, html('<i class="ico-08" title="'+this.$t('Історія відвідувань')+'"></i>')));
          // btn.push(h('a', {
          //   className: 'btn-table-change',
          // }, html('<i class="ico-22" title="'+this.$t('Зведений звіт')+'"></i>')));
          btn.push(h('a', {
            className: 'btn-table-change',
            onClick: () => {
              this.$router.push('/patients/' + row._cells[0].data + '/info')
            }
          }, html('<i class="ico-19"></i>')));
          btn.push(h('a', {
            className: 'btn-table-change',
            onClick: () => {
              this.actionDelete(row._cells[0].data)
            }
          }, html('<i class="ico-20"></i>')));
          return btn;
        }
      }],
      server: {
        url: process.env.VUE_APP_API_URL + '/rest/v2' + this.url + '/all',
        data: (opts) => {
          return new Promise((resolve, reject) => {
            // let's implement our own HTTP client
            this.$http.get(opts.url).then(response => {
              resolve({
                data: response.data.patients.map(user => [user.id, user.fullname, this.dateRange(user.birthday), this.dateFormat(user.birthday), this.dateFormat(user.carddate), user.mobilenumber]),
                total: response.data.total,
              })
            })
          });
        }
      },
      pagination: {
        enabled: true,
        limit: 20,
        summary: false,
        server: {
          url: (prev, page, limit) => {
            return prev.indexOf('?') === -1 ? `${prev}?limit=${limit}&offset=${page * limit}` : `${prev}&limit=${limit}&offset=${page * limit}`
          }
        }
      },
      search: {
        debounceTimeout: 2000,
        server: {
          url: (prev, keyword) => `${prev}?search=${keyword}`
        }
      },
      language: {
        'search': {
          'placeholder': '🔍 ' + this.$t('Пошук')
        },
        'pagination': {
          'previous': '⬅️',
          'next': '➡️',
        }
      }
    });
    this.grid.render(document.getElementById("table"));
  },
  // created () {
  //   const settings = {
  //     limit: 20,
  //     offset: 0
  //   }
  //   this.$http.get(this.url + '/all', {params: settings})
  //     .then(response => {
  //       this.$store.commit('setStatus', null)
  //       this.patients = response.data.patients
  //       this.page = Math.ceil(response.data.page / 20)
  //       const arr = []
  //       this.patients.map((user, index) => {
  //         arr.push([user.id, user.fullname, this.dateRange(user.birthday), this.dateFormat(user.birthday), this.dateFormat(user.carddate)])
  //       })
  //       this.grid.updateConfig({
  //         columns: [{
  //           id: 'number',
  //           name: this.$t('№ Картки')
  //         }, {
  //           id: 'pib',
  //           name: this.$t('П.І.Б')
  //         }, {
  //           id: 'year',
  //           name: this.$t('Вік')
  //         }, {
  //           id: 'birthday',
  //           name: this.$t('Дата народження')
  //         }, {
  //           id: 'created_at',
  //           name: this.$t('Дата створення')
  //         }, {
  //           id: 'phone',
  //           name: this.$t('Номер телефону')
  //         }, {
  //           id: 'action',
  //           name: '',
  //           width: '90px',
  //           formatter: (cell, row) => {
  //             const btn = []
  //             btn.push(h('a', {
  //               className: 'btn-table-change',
  //             }, html('<i class="ico-24"></i>')));
  //             btn.push(h('a', {
  //               className: 'btn-table-change',
  //             }, html('<i class="ico-36" title="'+$t('Диспансеризація')+'"></i>')));
  //             btn.push(h('a', {
  //               className: 'btn-table-change',
  //             }, html('<i class="ico-37" title="'+$t('Майстер запису пацієнта на прийом')+'"></i>')));
  //             btn.push(h('a', {
  //               className: 'btn-table-change',
  //             }, html('<i class="ico-08" title="'+$t('Історія відвідувань')+'"></i>')));
  //             btn.push(h('a', {
  //               className: 'btn-table-change',
  //             }, html('<i class="ico-22" title="'+$t('Зведений звіт')+'"></i>')));
  //             btn.push(h('a', {
  //               className: 'btn-table-change',
  //               onClick: () => {
  //                 this.$router.push('/patients/' + row._cells[0].data + '/info')
  //                 //this.remove(row._cells[7].data.id)
  //               }
  //             }, html('<i class="ico-19"></i>')));
  //             btn.push(h('a', {
  //               className: 'btn-table-change',
  //               onClick: () => {
  //                 this.actionDelete(row._cells[0].data)
  //               }
  //             }, html('<i class="ico-20"></i>')));
  //             return btn;
  //           }
  //         }],
  //         data: arr,
  //         pagination: {
  //           enabled: true,
  //           limit: 10,
  //           summary: false
  //         },
  //         search: true,
  //         language: {
  //           'search': {
  //             'placeholder': '🔍 ' + this.$t('Пошук')
  //           },
  //           'pagination': {
  //             'previous': '⬅️',
  //             'next': '➡️',
  //           }
  //         }
  //       });
  //       this.grid.render(document.getElementById("table"));
  //     })
  //     .catch(() => {
  //       this.$store.commit('setStatus', 'error')
  //     })
  // },
  methods: {
    dateRange (date) {
      return moment(date).diff(moment(), 'years') * -1
    },
    dateFormat (date) {
      return moment(date).format("DD.MM.YYYY")
    },
    searchAction () {
      if (this.timer.search) {
        clearTimeout(this.timer.search)
      }
      this.timer.search = setTimeout(() => {
        this.$http.get(this.url + '/search', {
          params: {
            limit: 20,
            offset: 0,
            search: this.search
          }
        }).then(result => {
          this.patients = result.data.patients
        }).catch(error => {
          console.log('[Error]', error)
        })
      }, 2000)
    },
    actionDelete (id) {
      if (confirm('Видалити пацієнта?')) {
        this.$http.delete('/patient/remove', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.patients = result.data
            this.grid.updateConfig({
              data: result.data
            }).forceRender();
          })
          .catch(() => {
            ElMessage({
              message: $t('Помилка видалення'),
              type: 'error',
            })
          })
      }
    },
    checkedAll: function () {
      this.checked = !this.checked
      this.allCheck = this.checked
    },
    showModal (target) {
      this.$modal.show(target)
    },
    goPage (data) {
      const settings = {
        limit: 20,
        offset: (data - 1) * 20
      }
      this.$http.get(this.url + '/all', {params: settings})
        .then(response => {
          this.patients = response.data.patients
          this.page = response.data.page
        })
        .catch(error => {
          console.log('[Error]', error)
        })
    },
    updatePatient (data) {
      this.patients = data
      this.grid.updateConfig({
        data: data
      }).forceRender();
    }
  }
}
</script>
