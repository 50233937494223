<template>
  <div>
    <Navbar></Navbar>
    <Header3></Header3>
    <div class="content">
      <div class="table-wrapper">
        <table class="body-bold">
          <thead>
            <tr>
              <th>
                <div class="caption">Прізвище</div>
                <input type="text" class="custom-input">
              </th>
              <th>
                <div class="caption">Ім’я</div>
                <input type="text" class="custom-input">
              </th>
              <th>
                <div class="caption">По батькові</div>
                <input type="text" class="custom-input">
              </th>
              <th style="width: 200px;">
                <div class="caption">Номер мобільного телефону</div>
                <input type="text" class="custom-input">
              </th>
              <th style="width: 200px;">
                <div class="caption">Дата запланованого прийому</div>
                <input type="text" class="custom-input">
              </th>
              <th style="width: 200px;">
                <div class="caption">Призначений на прийом до</div>
                <input type="text" class="custom-input">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="person of articles" :key="person.idP">
              <td class="text-overflow-ellipsis">{{ person.surnameP }}</td>
              <td class="text-overflow-ellipsis">{{ person.nameP }}</td>
              <td class="text-overflow-ellipsis">{{ person.fatherP }}</td>
              <td>{{ person.phoneP }}</td>
              <td>{{ person.dateP }}</td>
              <td>{{ person.doctorP }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrimaryPatients',
  data () {
    return {
      articles: [
        {
          idP: '1',
          surnameP: 'Закатєва',
          nameP: 'Закатєва',
          fatherP: 'Закатєва',
          phoneP: '51913',
          dateP: '16.09.2019',
          doctorP: 'Ващук К.А. '
        },
        {
          idP: '2',
          surnameP: 'Грихтоль',
          nameP: 'Грихтоль',
          fatherP: 'Грихтоль',
          phoneP: '51913',
          dateP: '17.09.2019',
          doctorP: 'Назарчук С.Ф.'
        },
        {
          idP: '3',
          surnameP: 'Михальчук',
          nameP: 'Михальчук',
          fatherP: 'Михальчук',
          phoneP: '51913',
          dateP: '01.10.2019',
          doctorP: 'Нелепа Л.О.'
        },
        {
          idP: '4',
          surnameP: 'Гіргиль',
          nameP: 'Гіргиль',
          fatherP: 'Гіргиль',
          phoneP: '51913',
          dateP: '02.10.2019',
          doctorP: 'Оксюта О.М.'
        },
        {
          idP: '5',
          surnameP: 'Руцька',
          nameP: 'Руцька',
          fatherP: 'Руцька',
          phoneP: '51913',
          dateP: '04.10.2019',
          doctorP: 'Петрова Л.С.'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
