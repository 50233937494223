<template>
  <div>
    <div class="caption">{{ $t('Протоколи') }}</div>
    <div class="block-sum-print">
      <div class="table-wrapper">
        <table class="body-bold">
          <thead>
          <tr>
            <th style="width: 100%">
              <div class="caption">{{ $t('Назва') }}</div>
            </th>
            <th style="width: 200px;">
              <div class="caption">{{ $t('Дата створення') }}</div>
            </th>
            <th style="width: 200px;">
              <div class="caption">{{ $t('Дата оновлення') }}</div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(protocol, index) in userProtocols"
              :key="protocol.id"
          >
            <td class="text-overflow-ellipsis">
              <a href="#" @click="viewProtocol(index)">{{ protocol.template.name }}</a>
            </td>
            <td style="width: 200px;">{{ protocol.created_at }}</td>
            <td style="width: 200px;">{{ protocol.updated_at }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="box-app-price">
      <a href="#" class="btn-green small" @click.prevent="show()">{{ $t('Додати') }}</a>
    </div>

    <el-dialog
        v-model="$store.state.modal.PriceList"
        width="720px"
        :title="$t('Протоколи')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal">
          <div class="table-wrapper">
            <table class="body-bold">
              <thead>
              <tr>
                <th style="width: 100%">
                  <div class="caption">{{ $t('Назва') }}</div>
                </th>
                <th style="width: 200px;">
                  <div class="caption">{{ $t('Категорія') }}</div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="protocol in protocols"
                  :key="protocol.id"
              >
                <td class="text-overflow-ellipsis">
                  <a href="#" @click.prevent="showCreate(protocol.id)">{{ protocol.name }}</a>
                </td>
                <td style="width: 200px;">{{ protocol.category }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </el-dialog>

    <el-dialog
        v-model="$store.state.modal.createProtocol"
        width="720px"
        :title="protocol.name"
        class="modal-custom"
    >
      <form @submit.prevent="saveUserProtocol">
        <div class="body-modal">
          <div class="protocol-head">{{ protocol.name }}</div>
          <div class="protocol-body">
            <div class="answer">
              <span>{{ $t('Пацієнт') }}:</span>
              <input type="text" disabled v-model="protocol.user.fio">
            </div>
            <div class="answer-two answer">
              <div class="col">
                <span>{{ $t('Дата народження') }}:</span>
                <input type="text" disabled v-model="protocol.user.birthday">
              </div>
              <div class="col">
                <span>{{ $t('Вік') }}:</span>
                <input type="text" disabled v-model="protocol.user.year">
              </div>
            </div>
            <!-- eslint-disable -->
            <div
                class="answer"
                v-for="row in protocol.rows"
                :key="row.id"
            >
              <!-- eslint-enable -->
              <span v-show="row.name !== ''">{{ row.name }}:</span>
              <input
                  type="text"
                  v-model="row.value"
                  v-if="row.type === 'text'"
              >
              <span style="font-style: italic" v-if="row.mask">{{ row.mask }}</span>
              <el-date-picker
                  v-if="row.type === 'date'"
                  v-model="row.value"
                  type="date"
                  value-format="dd.MM.yyyy"
                  placeholder="Виберіть дату"
                  :picker-options="pickerOptions"
              >
              </el-date-picker>
              <div
                  v-if="row.type === 'select'"
                  class="select-input"
              >
                <select
                    v-model="row.value"
                    @change="detectWrite(row)"
                >
                  <option
                      v-for="value in row.values"
                      :key="value.id"
                      :value="value.value"
                  >
                    {{ value.value }}
                  </option>
                </select>
                <input
                    v-for="(write, index) in row.writes"
                    :key="index"
                    type="text"
                    v-model="write.value"
                >
              </div>
              <div class="file" v-if="row.type === 'photo'">
                <a href="#"
                   @click.prevent="moreSearchPatient.offset = 0;searchPatients(row)">{{ $t('Вибрати файли') }}</a>
                <div class="file-gallery" v-html="row.value"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="submit" class="btn-green">{{ $t('Зберегти') }}</button>
            <button class="btn-red" @click.prevent="hideCreate()">{{ $t('Скасувати') }}</button>
          </div>
        </div>
      </form>
    </el-dialog>

    <el-dialog
        v-model="$store.state.modal.viewProtocol"
        width="720px"
        :title="view.template.name"
        class="modal-custom"
    >
      <form @submit.prevent="saveUserProtocol">
        <div class="body-modal" id="printMe">
          <div class="protocol-head" style="text-align: center;margin-bottom: 10px;">
            <strong>{{ view.template.name }}</strong></div>
          <div class="protocol-body">
            <div class="answer">
              <span><strong>{{ $t('Пацієнт') }}:</strong></span>
              <strong>{{ view.fio }}</strong>
            </div>
            <div class="answer">
              <span><strong>{{ $t('Дата народження') }}:</strong></span>
              {{ view.birthday }}
            </div>
            <div class="answer">
              <span><strong>${{ $t('Вік') }}:</strong></span>
              {{ view.year }}
            </div>
            <span
                :class="{'inline': !row.has_break}"
                class="answer"
                v-for="row in view.rows"
                :key="row.id"
            >
              <span v-show="row.question !== '' && row.type !== 'photo'"><strong>{{ row.question }}</strong></span>
              <span v-html="row.answer" v-show="row.type !== 'comment' && row.type !== 'photo'"></span>
              <span v-show="!row.has_break && row.type !== 'photo'">,</span>
              <div v-html="row.answer" v-if="row.type === 'photo'"></div>
              <br v-if="row.has_break"/>
            </span>
          </div>
          <div class="protocol-footer">
            <table style="width: 100%;margin-top: 50px">
              <tr>
                <td style="width: 33%"><strong>{{ $t('Лікар') }}</strong></td>
                <td style="width: 33%;text-align: center"><br>______________<br>({{ $t('підпис') }})</td>
                <td style="width: 33%;text-align: right">Гаврилюк Т.І.</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button type="button" @click="print" class="btn-green" style="width: 50px;"><i class="ico-24"></i></button>
            <button class="btn-red" @click.prevent="hideView()">{{ $t('Скасувати') }}</button>
          </div>
        </div>
      </form>
    </el-dialog>

    <el-dialog
        v-model="$store.state.modal.filemanager"
        width="720px"
        :title="$t('Файловий менеджер')"
        class="modal-custom"
    >
      <div class="body-modal">
        <div class="collectDicom">
          <div
              class="img"
              v-for="file in filesDicom"
              :key="file"
          >
            <input type="checkbox" v-model="selectedFiles" :value="file">
            <img
                :src="file"
                alt=""
                width="100"
            >
          </div>
        </div>
        <div class="moreFiles" v-show="more.show">
          <a href="#" @click.prevent="moreFiles">{{ $t('Показати ще') }}</a>
        </div>
      </div>
      <div class="footer-modal text-right">
        <div class="btn-left-footer"></div>
        <div class="btn-right-footer">
          <button type="button" @click="pickFile()" class="btn-green">{{ $t('Зберегти') }}</button>
          <button class="btn-red" @click.prevent="hideFilemanager()">{{ $t('Скасувати') }}</button>
        </div>
      </div>
    </el-dialog>

    <!-- Список пацієнтів -->
    <el-dialog
        v-model="$store.state.modal.search_patient"
        width="720px"
        :title="$t('Виберіть пацієнта')"
        class="modal-custom"
    >
      <div class="caption-modal">
        {{ $t('Виберіть пацієнта') }}
        <a href="#" class="close-modal" @click.prevent="$modal.hide('search_patient')"><i class="ico-17"></i></a>
      </div>
      <div class="body-modal">
        <table>
          <thead>
          <tr>
            <th>{{ $t('Дата оновлення') }}</th>
            <th>ID</th>
            <th>{{ $t('Ім\'я') }}</th>
            <th>{{ $t('Дата народження') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="patient in listPatientInSearch" :key="patient.uuid" @click="getFiles(patient.uuid)">
            <td>{{ patient.update }}</td>
            <td>{{ patient.id }}</td>
            <td>{{ patient.name }}</td>
            <td>{{ patient.birthday }}</td>
          </tr>
          </tbody>
        </table>
        <div class="moreFiles" v-show="moreSearchPatient.show">
          <a href="#" @click.prevent="searchPatients()">{{ $t('Показати наступні 10 пацієнтів') }}</a>
        </div>
      </div>
      <div class="footer-modal text-right">
        <div class="btn-left-footer"></div>
        <div class="btn-right-footer">
          <button type="button" @click="pickFile()" class="btn-green">{{ $t('Зберегти') }}</button>
          <button class="btn-red" @click.prevent="hideFilemanager()">{{ $t('Скасувати') }}</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ProtocolPatient',
  data() {
    return {
      pid: this.id,
      articles: [],
      checked: false,
      articles2: [],
      originalArticles: [],
      value: '',
      value2: '',
      value3: '',
      tabContent: 1,
      valueCounter1: 60,
      // мої дані
      prices: [],
      categories: [],
      protocols: [],
      userProtocols: [],
      protocol: {
        name: '',
        id: 0,
        user: {
          fio: '',
          birthday: '',
          year: 0,
          id: 0
        },
        rows: []
      },
      view: {
        template: {
          name: ''
        }
      },
      files: [],
      selectedFiles: [],
      more: {
        show: false,
        offset: 0
      },
      activeField: null,
      listPatientInSearch: [],
      moreSearchPatient: {
        show: true,
        offset: 0
      },
      filesDicom: [],
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  props: {
    id: {
      default: 0
    }
  },
  created() {
    this.$http.get('/patient/protocols', {params: {id: this.id}})
        .then(result => {
          this.userProtocols = result.data.userProtocols
          this.protocols = result.data.protocols
          this.categories = result.data.categories
        })
  },
  methods: {
    show() {
      this.$modal.show('PriceList')
    },
    hide() {
      this.$modal.hide('PriceList')
    },
    hideCreate() {
      this.$modal.hide('createProtocol')
    },
    hideFilemanager() {
      this.$modal.hide('filemanager')
    },
    hideView() {
      this.$modal.hide('viewProtocol')
    },
    showCreate(id) {
      this.hide()
      this.$http.get('/template/property', {
        params: {
          id,
          user_id: this.pid
        }
      }).then(result => {
        this.protocol.name = result.data.template.name
        this.protocol.id = result.data.template.id
        this.protocol.rows = result.data.template.rows
        this.protocol.user.fio = result.data.user.fullname
        this.protocol.user.birthday = result.data.user.birthday
        this.protocol.user.year = result.data.user.year
        this.protocol.user.id = this.pid
        this.$modal.show('createProtocol')
      }).catch(error => {
        console.log('[Error]', error)
      })
    },
    saveUserProtocol() {
      this.$http.post('/patient/save-protocol', this.protocol).then(result => {
        this.userProtocols = result.data
        this.hideCreate()
        this.$root.alerts('success', 'Протокол збережено')
        this.protocol = {
          name: '',
          id: 0,
          user: {
            fio: '',
            birthday: '',
            year: 0,
            id: 0
          },
          rows: []
        }
      }).catch(error => {
        console.log('[Error]', error)
      })
    },
    viewProtocol(index) {
      this.view = this.userProtocols[index]
      this.$modal.show('viewProtocol')
    },
    print() {
      this.$htmlToPaper('printMe')
    },
    detectWrite(el) {
      if (el.value === 'Інше') {
        el.writes = [{
          'value': ''
        }]
      } else {
        let c = el.value.split('{_}').length - 1
        el.writes = []
        for (let i = 1; i <= c; i++) {
          el.writes.push({
            'value': ''
          })
        }
      }
    },
    pickFile() {
      let pickedFile = []
      this.selectedFiles.map(e => {
        pickedFile.push('<img src="' + e + '" width="45%" style="margin-right: 3%;margin-bottom: 3%">')
      })
      this.$set(this.activeField, 'value', pickedFile.join(''))
      this.selectedFiles = []
      this.hideFilemanager()
    },
    moreFiles() {
      const newOffset = this.more.offset + 12
      this.$http.get('/dicom/instance', {
        params: {
          offset: newOffset
        }
      })
          .then(result => {
            this.more.offset = newOffset
            for (let i = 0; i < 12; i++) {
              this.filesDicom.push(result.data.files[i])
            }
            this.more.show = result.data.more
          })
          .catch(error => {
            console.log('[Error]', error)
          })
    },
    getFiles(uuid) {
      this.$modal.hide('search_patient')
      this.$modal.show('filemanager')
      this.$http.get('/dicom/instance', {
        params: {
          uuid
        }
      })
          .then(result => {
            this.filesDicom = result.data.files
            this.more.show = false
          })
          .catch(error => {
            console.log('[Error]', error)
          })
    },
    searchPatients(el) {
      this.activeField = el
      this.$http.get('/dicom/patients', {
        params: {
          offset: this.moreSearchPatient.offset
        }
      })
          .then(result => {
            this.listPatientInSearch = result.data
            this.$modal.show('search_patient')
            if (result.data.length === 10) {
              this.moreSearchPatient.offset += 10
              this.moreSearchPatient.show = true
            } else {
              this.moreSearchPatient.show = false
            }
          })
          .catch(error => {
            console.log('[Error]', error)
          })
    }
  },
  filters: {
    normalDate(data) {
      let d = new Date(data)

      function fullDate(str) {
        if (String(str).length < 2) {
          return '0' + str
        }
        return str
      }

      return fullDate(d.getDate()) + '.' + fullDate(d.getMonth()) + '.' + d.getFullYear()
    }
  }
}
</script>

<style scoped>

</style>
