<template>
  <el-dialog
      v-model="$store.state.modal.add"
      width="720px"
      :title="title"
      class="modal-custom"
  >
    <form @submit.prevent="save">
      <div class="body-modal">
        <div class="line-modal-form">
          <div class="description">{{$t('Логін')}}*</div>
          <div class="input-wr">
            <input type="text" v-model="user.username" class="custom-input" :class="{'input-v-error':v$.user.username.$error.length}">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Пароль')}}*</div>
          <div class="input-wr">
            <input type="text" v-model="user.password" class="custom-input" :class="{'input-v-error':v$.user.password.$error.length}">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">Email</div>
          <div class="input-wr">
            <input type="text" v-model="user.email" class="custom-input">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Роль')}}*</div>
          <div class="input-wr">
            <el-select v-model="user.role" filterable class="full-width">
              <el-option
                  v-for="item in [{id: 1, name: 'лікар'},{id: 2, name: 'користувач'},{id: 3, name: 'адміністратор'},{id: 4, name: 'рецепшин'},{id: 5, name: 'директор'},{id: 6, name: 'кадровик'},{id: 7, name: 'касир'},{id: 8, name: 'УЗД'}]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <!-- Якщо касир - треба ще вказати ФОП -->
        <div class="line-modal-form" v-show="user.role === 4 || user.role === 3 || user.role === 7">
          <div class="description">{{$t('Укажіть ФОП-ів')}}</div>
          <div class="input-wr">
            <el-select v-model="user.cashiers" filterable class="full-width" multiple>
              <el-option
                  v-for="item in cashiers"
                  :key="item.id"
                  :label="item.cashier_name"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <!-- END -->
        <div class="line-modal-form">
          <div class="description">{{$t('Прив\'язати до')}}</div>
          <div class="input-wr">
            <el-select v-model="user.doctor" filterable class="full-width">
              <el-option
                  v-for="item in doctors"
                  :key="item.id"
                  :label="item.fullname"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Також доступ до')}}:</div>
          <div class="input-wr">
            <el-select v-model="user.userDoctors" filterable class="full-width" multiple>
              <el-option
                  v-for="item in doctors"
                  :key="item.id"
                  :label="item.shortname"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="footer-modal text-right">
        <div class="btn-left-footer"></div>
        <div class="btn-right-footer">
          <button class="btn-green">{{$t('Зберегти')}}</button>
          <button class="btn-red" @click.prevent="hide()">{{$t('Скасувати')}}</button>
        </div>
      </div>
    </form>
  </el-dialog>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {ElMessage} from "element-plus";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: 'AddSystemAdmin',
  data () {
    return {
      user: {
        username: '',
        password: '',
        email: '',
        status: 10,
        doctor: '',
        role: '',
        id: 0,
        cashiers: [],
        userDoctors: []
      },
      title: 'Додати нового адміністратора',
      doctors: [],
      cashiers: []
    }
  },
  created () {
    this.$http.get('/doctor/all')
      .then(result => {
        this.doctors = result.data.doctors
        this.cashiers = result.data.cashiers
      })

    this.emitter.on('editAdmins', this.editAdmins)
  },
  methods: {
    hide () {
      this.$modal.hide('add')
      this.user = {
        username: '',
        password: '',
        email: '',
        status: 10,
        doctor: '',
        role: '',
        id: 0
      }
    },
    save () {
      if (!this.v$.user.$invalid) {
        this.$http.post('/user/save', this.user)
          .then(result => {
            this.user = {
              username: '',
              password: '',
              email: '',
              status: 10,
              doctor: '',
              role: '',
              id: 0
            }
            this.hide()
            this.emitter.emit('updateUser', result.data)
            ElMessage({
              message: this.$t('Адміністратора створено'),
              type: 'success',
            })
          })
          .catch(error => {
            console.log('[Error]', error)
          })
      }
    },
    editAdmins (data) {
      this.user = data
      this.user.role = Number(this.user.role)
      this.$modal.show('add')
    }
  },
  validations() {
    return {
      user: {
        username: {
          required
        },
        password: {
          required
        },
        role: {
          required
        }
      }
    }
  },
}
</script>
