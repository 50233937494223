<template>
  <div>
    <div class="caption">Аналізи</div>
    <div>
      <button class="btn-green" @click="$modal.show('addLab')">Додати</button>
    </div>
    <table>
      <thead>
      <tr>
        <th>Дата створення</th>
        <th>Дата оновлення</th>
        <th>Лікар</th>
        <th>Примітка</th>
      </tr>
      </thead>
      <tbody>
      <tr class="tr-hovered" v-for="(article, index) in articles" :key="article.id" @click="viewLab(article, index)">
        <td>{{article.created_at}}</td>
        <td>{{article.updated_at}}</td>
        <td>{{article.doctor.fullname}}</td>
        <td>{{article.comment}}</td>
        <td>{{article.status}}</td>
      </tr>
      </tbody>
    </table>

    <LabModal :doctors="doctors" :patient_id="$route.params.id" :price-list="priceList" :view="view"/>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import moment from "moment";
import { required, integer } from '@vuelidate/validators'
import {ElMessage} from "element-plus";
import LabModal from "@/components/Patients/modules/LabModal.vue";

export default {
  name: 'LabsPage',
  data () {
    return {
      date: moment().format('YYYY-MM-DD'),
      priceList: [],
      searchPriceList: [],
      searchString: '',
      form: {
        comment: '',
        preg_week: 0,
        menstrphase: {id: '100', label: 'лютеїнова'},
        items: [],
        patient_id: this.$route.params.id,
        doctor_id: ''
      },
      articles: [],
      view: null,
      activeIndex: null,
      faza: [{id: '100', label: 'лютеїнова'},{id: '200', label: 'овуляторна'},{id: '300', label: 'фолікулінова'},{id: '400', label: 'пременопауза'},{id: '500', label: 'менопауза'},{id: '600', label: 'постменопауза'}],
      doctors: []
    }
  },
  watch: {
    searchString: function (newValue, oldValue) {
      if (newValue !== '') {
        this.searchPriceList = this.priceList.filter(e => {
          const planName = e.name.toLowerCase()
          return planName.indexOf(newValue.toLowerCase()) !== -1
        })
      } else {
        this.priceSearch = this.priceList
      }
    }
  },
  async created () {
    this.$http.get('/lab/prices', {
      params: {
        patientId: this.id
      }
    }).then(response => {
      this.priceList = response.data.prices;
      this.searchPriceList = response.data.prices;
      this.articles = response.data.labs
      if (response.data.doctors) {
        this.doctors = response.data.doctors
      }
    }).catch(error => {
      ElMessage({
        message: this.$t('Помилка завантаження аналізів. Оновіть сторінку'),
        type: 'error',
      })
    })
  },
  components: {
    LabModal,
  },
  methods: {
    /** Додавання аналізів **/
    addInLabs (item) {
      if (this.view === null) {
        this.form.items.push(item)
      } else {
        this.view.newItems.push(item)
      }
      ElMessage({
        message: this.$t('Аналіз додано'),
        type: 'success',
      })
    },
    /**
     * Видалення з списку аналізів
     * @param index
     */
    removeInLabs (index) {
      if (this.view === null) {
        this.form.items.splice(index, 1)
      } else {
        this.form.newItems.splice(index, 1)
      }
      ElMessage({
        message: this.$t('Аналіз видалено'),
        type: 'success',
      })
    },
    save () {
      this.$http.post('/lab/save', this.form).then(result => {
        this.articles.push(result.data.model)
        this.$modal.hide('addLab')
      }).catch(error => {
        ElMessage({
          message: error.response ? error.response.data : error.message,
          type: 'error',
        })
      })
    },
    update () {
      this.$http.put('/lab/update', this.view).then(result => {
        if (this.activeIndex !== null) {
          this.articles[this.activeIndex] = result.data.model
          this.closeView()
        }
      }).catch(error => {
        ElMessage({
          message: error.response ? error.response.data : error.message,
          type: 'error',
        })
      })
    },
    viewLab (article, index) {
      this.activeIndex = index
      this.view = article
      //this.$set(this.view, 'newItems', [])
      this.$modal.show('viewLab')
    },
    closeView () {
      this.view = null
      this.activeIndex = null
      this.$modal.hide('viewLab')
    },
    removeInServer (index) {
      this.$http.delete('/lab/remove',  {
        data: {
          id: this.view.id,
          item_id: this.view.items[index].id
        }
      }).then(result => {
        this.view = result.data.model
      })
    }
  },
  props: {
    id: {
      default: 0
    },
    patient: {
      default: {}
    }
  },
  validations: {
    form: {
      preg_week: {
        required,
        integer
      }
    }
  },
}
</script>
