<template>
  <el-dialog
      title="Tips"
      width="50%"
  >
    <form>
      <div class="caption-modal">
        Додавання нового кабінету
        <a href="#" class="close-modal" @click.prevent="$modal.hide('addCabinetModal')"><i class="ico-17"></i></a>
      </div>
      <div class="body-modal form-long">
        <div class="line-modal-form">
          <div class="description">№ *</div>
          <div class="input-wr"><input type="text" class="custom-input"></div>
        </div>
        <div class="line-modal-form">
          <div class="description">Назва кабінету</div>
          <div class="input-wr"><input type="text" class="custom-input"></div>
        </div>
        <div class="line-modal-form">
          <div class="description">Послідовність *</div>
          <div class="input-wr">
            <v-select searchable></v-select>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">Опис кабінету</div>
          <div class="input-wr"><input type="text" class="custom-input"></div>
        </div>
        <div class="footer-modal flex-footer footer-margin">
          <div class="btn-left-footer">
          </div>
          <div class="btn-right-footer">
            <button class="btn-green">Зберегти</button>
            <button type="button" class="btn-red" @click="cancel">Скасувати</button>
          </div>
        </div>
      </div>
    </form>
  </el-dialog>

</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    clear () {},
    cancel () {
      this.$modal.hide('addCabinetModal')
      this.clear()
    }
  }
}
</script>
