<template>
  <div>
    <top-manager></top-manager>
    <div class="filter-header">
      <div class="reset-box">
        <a href="#" class="standard-circle-btn btn-filter-header brand-hover with-border"><i class="ico-01"></i></a>
      </div>
      <ul class="radio-list">
        <li class="custom-radio">
          <label>
            <input type="radio" name="DateTime">
            <span>Минулий місяць</span>
          </label>
        </li>
        <li class="custom-radio">
          <label>
            <input type="radio" name="DateTime">
            <span>Поточний місяць</span>
          </label>
        </li>
        <li class="custom-radio">
          <label>
            <input type="radio" name="DateTime">
            <span>Ввести дату</span>
          </label>
        </li>
      </ul>
      <a href="#" class="btn-calendar full-flex-center brand-hover calendar-write"><i class="ico-02"></i></a>
      <input type="text" class="calendar-write-field custom-input" value="01.09.2019">
      <div class="text-between-calendar">по</div>
      <input type="text" class="calendar-write-field custom-input" value="01.09.2019">
      <a href="#" class="btn-calendar full-flex-center brand-hover calendar-write"><i class="ico-02"></i></a>
      <div class="filter-box">
        Стани СМС: з <span> 16.09.2019 </span> по <span> 16.09.2019 </span>
      </div>
    </div>
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green"><i class="ico-11"></i> Додати смс</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold mailing-table">
          <thead>
          <tr>
            <th style="width: 46px;">
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th style="width: 90px;">
              <div class="caption">ID</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 150px;">
              <div class="caption">Мобільний номер</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Повідомлення</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 160px;">
              <div class="caption">Дата створення</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 160px;">
              <div class="caption">Статус</div>
              <v-select :options="['доставлено', 'не доставлено']"></v-select>
            </th>
            <th style="width: 160px;">
              <div class="caption">Дата відправлення</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 160px;">
              <div class="caption">Час доставки</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 40px;"></th>
            <th style="width: 40px;"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="person of articles" :key="person.id">
            <td>
              <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
            </td>
            <td>{{ person.id }}</td>
            <td>{{ person.phone }}</td>
            <td class="text-overflow-ellipsis">{{ person.smsmessage }}</td>
            <td>{{ person.createdate }}</td>
            <td class="status-mailing" :class="smsStatus(person.responsecode)"><span></span></td>
            <td>{{ person.scheduledtime }}</td>
            <td>{{ person.deliverytime }}</td>
            <td><a href="#" class="btn-table-change"><i class="ico-29"></i></a></td>
            <td><a href="#" class="btn-table-change"><i class="ico-20"></i></a></td>
          </tr>
          </tbody>
        </table>
        <Pagination
          v-bind:page="page"
          v-on:goPage="goPage"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../modules/Pagination'
export default {
  data () {
    return {
      articles: [],
      checked: false,
      url: '/sms',
      page: 0
    }
  },
  created () {
    this.$http.get(this.url + '/index')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    onsearch4 (e) {
      if (e.target.value4) {
        this.listFinded4 = this.list4.filter(el => el.supportStatus.indexOf(e.target.value4) !== -1)
      } else {
        this.listFinded4 = this.list4
      }
    },
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    setData (data) {
      this.articles = data.sms
      this.page = data.page
      if (this.page < this.leftPage) {
        this.leftPage = this.page
      }
    },
    smsStatus (status) {
      let color = 'green'
      switch (status) {
        case '1':
          color = 'yellow'
          break
        case '2':
          color = 'red'
          break
      }
      return color
    },
    goPage (data) {
      this.$http.get(this.url + '/index', {
        params: {
          limit: 20 * (data - 1)
        }
      })
        .then(result => {
          this.setData(result.data)
        })
    }
  },
  components: {
    Pagination
  }
}
</script>

<style scoped>

</style>
