<template>
  <div>
    <div :class="topClass()" />
    <div class="header">
      <div class="top-menu">
        <ul>
          <li
            v-for="page in pages"
            :key="page.url"
          >
            <router-link
              :class="{'active': page.url === current}"
              :to="page.url"
            >
              {{ page.name }}
            </router-link>
          </li>
        </ul>
        <LoginPage />
      </div>
      <div
        v-show="viewCard"
        class="bottom-menu"
      >
        <div class="patient-header">
          <router-link
            to="/patients"
            class="btn-back"
          >
            <i class="ico-32" />
            {{ $t('Усі картки') }}
          </router-link>
          <div class="name">
            {{ patient.fullname }}
          </div>
        </div>
      </div>
      <!--      <div class="filter-header">-->
      <!--        <a href="#" class="standard-circle-btn calendar-arrow brand-hover with-border"><i class="ico-01"></i></a>-->
      <!--        <a href="#" class="standard-circle-btn calendar-arrow arrow-left brand-hover with-border"><i class="ico-03"></i></a>-->
      <!--        <div class="box-calendar-l box-calendar-wl">-->
      <!--          <VueDatePicker  :value="date" :locale="locale"/>-->
      <!--        </div>-->
      <!--        <div class="box-calendar-r box-calendar-wl">-->
      <!--          <VueDatePicker  :value="date2" :locale="locale"/>-->
      <!--        </div>-->
      <!--        <a href="#" class="standard-circle-btn calendar-arrow arrow-right brand-hover with-border"><i class="ico-04"></i></a>-->
      <!--        <div class="filter-box">-->
      <!--          <ul class="list-balance-header">-->
      <!--            <li><a href="#" @click.prevent="show ()"><i class="ico-03"></i><i class="ico-22"></i><i class="ico-04"></i></a></li>-->
      <!--            <li><a href="#" @click.prevent="show2 ()"><i class="ico-11"></i><i class="ico-07"></i><i class="ico-22"></i></a></li>-->
      <!--            <li><a href="#" @click.prevent="show3 ()"><i class="ico-11"></i><i class="ico-22"></i></a></li>-->
      <!--          </ul>-->
      <!--          <a href="#" class="standard-circle-btn btn-filter-header brand-hover with-border" @click.prevent="filterActive = !filterActive"><i class="ico-09"></i></a>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div
      class="filter-box-navbar"
      :class="{'active': filterActive}"
    >
      <div class="caption">
        {{ $t('Фільтр') }}
        <a
          href="#"
          class="close-filter full-flex-center"
          @click.prevent="filterActive = !filterActive"
        ><i class="ico-17" /></a>
      </div>
      <div class="body-filter">
        <div class="search-bl">
          <input
            type="text"
            class="custom-input"
          >
          <span>{{ $t('Введіть для пошуку за станом, кабінетом, лікарем або пацієнтом.') }}</span>
        </div>
        <div
          class="wr-op"
          :class="{'open' : filter1}"
        >
          <div class="one-block-f">
            <ul class="check-list">
              <li><label><i /><input type="checkbox"><span>{{ $t('Не попереджений') }}</span></label></li>
              <li><label><i style="background-color: #e8f2fa;" /><input type="checkbox"><span>{{ $t('Попереджений') }}</span></label></li>
              <li><label><i style="background-color: #fbe9e8;" /><input type="checkbox"><span>{{ $t('Не прийшов') }}</span></label></li>
              <li><label><i style="background-color: #dadfe6;" /><input type="checkbox"><span>{{ $t('Прийшов') }}</span></label></li>
              <li><label><i style="background-color: #e6f8eb;" /><input type="checkbox"><span>{{ $t('Поточні події') }}</span></label></li>
              <li><label><i style="background-color: #e6f8eb;" /><input type="checkbox"><span>{{ $t('Поточні події') }}</span></label></li>
            </ul>
          </div>
          <div class="btn-filter-inside">
            <a
              href="#"
              @click.prevent="filter1 = !filter1"
            />
          </div>
        </div>
        <div
          class="wr-op"
          :class="{'open' : filter2}"
        >
          <div class="one-block-f">
            <ul class="check-list">
              <li><label><input type="checkbox"><span>Кабінет №1</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №2</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №3</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №4</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №5</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №6</span></label></li>
              <li><label><input type="checkbox"><span>Кабінет №7</span></label></li>
            </ul>
          </div>
          <div class="btn-filter-inside">
            <a
              href="#"
              @click.prevent="filter2 = !filter2"
            />
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="box-status-message">-->
    <!--      <div class="one-block">-->
    <!--        <div class="block-message-i color1"><i class="message1-ico"></i></div>-->
    <!--        <span>Error message</span>-->
    <!--        <a href="#" class="close-bl"></a>-->
    <!--      </div>-->
    <!--      <div class="one-block">-->
    <!--        <div class="block-message-i color2"><i class="message2-ico"></i></div>-->
    <!--        <span>Success message</span>-->
    <!--        <a href="#" class="close-bl"></a>-->
    <!--      </div>-->
    <!--      <div class="one-block">-->
    <!--        <div class="block-message-i color3"><i class="message3-ico"></i></div>-->
    <!--        <span>Warning message</span>-->
    <!--        <a href="#" class="close-bl"></a>-->
    <!--      </div>-->
    <!--      <div class="one-block">-->
    <!--        <div class="block-message-i color4"><i class="message4-ico"></i></div>-->
    <!--        <span>Info message</span>-->
    <!--        <a href="#" class="close-bl"></a>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    vc: {
      default: false
    },
    patient: {
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      date: new Date(),
      date2: new Date(),
      filterActive: false,
      filter1: false,
      filter2: false,
      value2: '1',
      list2: [
        {
          icoS: 'ico-06',
          supportStatus: 'Кабінет 1',
          id: 1
        },
        {
          icoS: 'ico-07',
          supportStatus: 'Кабінет 2',
          id: 2
        }
      ],
      listFinded2: [
      ],
      value3: null,
      list3: [
        {
          supportStatus: 'Пацієнт 1',
          id: 1
        },
        {
          supportStatus: 'Пацієнт 2',
          id: 2
        },
        {
          supportStatus: 'Пацієнт 3',
          id: 3
        },
        {
          supportStatus: 'Пацієнт 4',
          id: 4
        }
      ],
      listFinded3: [
      ],
      value4: null,
      list4: [
        {
          supportStatus: 'Пацієнт 1',
          id: 1
        },
        {
          supportStatus: 'Пацієнт 2',
          id: 2
        },
        {
          supportStatus: 'Пацієнт 3',
          id: 3
        },
        {
          supportStatus: 'Пацієнт 4',
          id: 4
        }
      ],
      listFinded4: [
      ],
      current: '/',
      pages: [
        {
          url: '/patients',
          name: 'Картки пацієнтів'
        }
      ],
      viewCard: this.vc
    }
  },
  watch: {
    $route (to, from) {
      let url = to.path.split('/')
      this.current = '/' + url[1]
    }
  },
  created () {
    let url = (this.$route.path).split('/')
    this.current = '/' + url[1]
  },
  methods: {
    topClass () {
      if (this.$route.path === '/patients') {
        return 'backing2'
      } else {
        return 'backing1'
      }
    }
  }
}
</script>

<style scoped>

</style>
