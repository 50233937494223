import {createStore} from 'vuex'
import user from './user'
import patients from './patients'
import modal from './modal'

export const store = createStore({
  modules: {
    user,
    patients,
    modal
  },
  state: {
    status: null,
    doctors: []
  },
  getters: {
    status: state => state.status,
    getDoctors: state => state.doctors
  },
  mutations: {
    setStatus (state, status) {
      state.status = status
    },
    setDoctors (state, items) {
      state.doctors = items
    }
  },
  actions: {
    getDoctors ({commit}) {
      commit('setStatus', 'load')
      return new Promise((resolve, reject) => {
        this.$http.get('/doctor/all', {})
          .then(response => {
            commit('setStatus', null)
            commit('setDoctors', response.data.doctors)
            resolve(response)
          })
          .catch(error => {
            commit('setStatus', 'error')
            reject(error)
          })
      })
    }
  }
})
