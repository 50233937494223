<template>
  <div>
    <top-director></top-director>
    <div class="content">
      <div class="btn-above-table">
        <a href="#preventNote"  @click.prevent="show ()" class="btn-green"><i class="ico-11"></i> Додати</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold">
          <thead>
          <tr>
            <th style="width: 46px">
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th style="width: 260px;">
              <div class="caption">Назва</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Тип групи</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Застосовано до</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Щомісяця</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption text-overflow-ellipsis">В місяць замовчуванння</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Група залишків</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 140px">
              <div class="caption">Хто бачить</div>
              <v-select></v-select>
            </th>
            <th style="width: 40px;"></th>
            <th style="width: 40px;"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="person of articles" :key="person.idGroup">
            <td>
              <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
            </td>
            <td><div class="balance-color">{{ person.name }} <span :style="{'background': person.colorName}"></span></div></td>
            <td>{{ person.typeGroup }}</td>
            <td>{{ person.usedFor }}</td>
            <td>{{ person.monthB }}</td>
            <td>{{ person.monthB2 }}</td>
            <td>{{ person.monthB3 }}</td>
            <td>{{ person.watchB }}</td>
            <td><a href="#" class="btn-table-change"><i class="ico-19"></i></a></td>
            <td><a href="#" class="btn-table-change"><i class="ico-20"></i></a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalanceGroups',
  data () {
    return {
      articles: [
        {
          idGroup: 1,
          name: 'Службове внесення',
          colorName: '#e6f8eb',
          typeGroup: 'Доходи',
          usedFor: 'Готівка',
          monthB: 'Немає',
          monthB2: '',
          monthB3: '',
          watchB: 'Загальна',
          checked: false
        },
        {
          idGroup: 2,
          name: 'Повернення коштів',
          colorName: '#fbe9e8',
          typeGroup: 'Витрати',
          usedFor: 'Готівка',
          monthB: 'Немає',
          monthB2: '',
          monthB3: '',
          watchB: 'Загальна',
          checked: false
        },
        {
          idGroup: 3,
          name: 'Службова видача',
          colorName: '#e8f2fa',
          typeGroup: 'Витрати',
          usedFor: 'Готівка',
          monthB: 'Немає',
          monthB2: '',
          monthB3: '',
          watchB: 'Загальна',
          checked: false
        }
      ],
      checked: false,
      value3: null,
      value4: null,
      value5: null,
      list3: [
        {
          supportStatus: 'Протокол 1',
          id: 1
        },
        {
          supportStatus: 'Протокол 2',
          id: 2
        },
        {
          supportStatus: 'Протокол 3',
          id: 3
        },
        {
          supportStatus: 'Протокол 4',
          id: 4
        }
      ],
      listFinded3: [
      ],
      list4: [
        {
          supportStatus: 1,
          id: 1
        },
        {
          supportStatus: 2,
          id: 2
        },
        {
          supportStatus: 3,
          id: 3
        },
        {
          supportStatus: 4,
          id: 4
        }
      ],
      listFinded4: [
      ],
      list5: [
        {
          supportStatus: 'пластична хірургія',
          id: 1
        },
        {
          supportStatus: 'хірургія',
          id: 2
        }
      ],
      listFinded5: [
      ]
    }
  },
  created () {
    this.listFinded3 = this.list3
    this.listFinded4 = this.list4
    this.listFinded5 = this.list5
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    onsearch3 (e) {
      if (e.target.value3) {
        this.listFinded3 = this.list3.filter(el => el.supportStatus.indexOf(e.target.value3) !== -1)
      } else {
        this.listFinded3 = this.list3
      }
    },
    onsearch4 (e) {
      if (e.target.value4) {
        this.listFinded4 = this.list4.filter(el => el.supportStatus.indexOf(e.target.value4) !== -1)
      } else {
        this.listFinded4 = this.list4
      }
    },
    onsearch5 (e) {
      if (e.target.value5) {
        this.listFinded5 = this.list5.filter(el => el.supportStatus.indexOf(e.target.value5) !== -1)
      } else {
        this.listFinded5 = this.list5
      }
    },
    show () {
      this.$modal.show('addOrder')
    },
    hideBtn1 () {
      this.$modal.hide('addOrder')
      this.$modal.show('modalBtn1')
    },
    hideBtn2 () {
      this.$modal.hide('addOrder')
      this.$modal.show('modalBtn2')
    },
    hideBtn3 () {
      this.$modal.hide('addOrder')
      this.$modal.show('modalBtn3')
    },
    hideBtn4 () {
      this.$modal.hide('addOrder')
      this.$modal.show('modalBtn4')
    },
    hideBtn5 () {
      this.$modal.hide('addOrder')
      this.$modal.show('modalBtn5')
    },
    hide () {
      this.$modal.hide('addOrder')
    },
    hideBtn () {
      this.$modal.hide('modalBtn1')
      this.$modal.hide('modalBtn2')
      this.$modal.hide('modalBtn3')
      this.$modal.hide('modalBtn4')
      this.$modal.hide('modalBtn5')
    }
  }
}
</script>

<style scoped>

</style>
