<template>
  <div>
    <TopInfo />
    <div class="content">
      <div class="btn-above-table clearfix">
        <a href="#" class="btn-green" @click.prevent="add()"><i class="ico-11"></i> {{$t('Додати послугу')}}</a>
        <a href="#" class="btn-white btn-price-list" @click.prevent="dowmloadPrice('checkbox')"><i class="ico-24"></i> {{$t('Завантажити прайс-лист (для Чекбокс)')}}</a>
        <a href="#" class="btn-white btn-price-list" @click.prevent="dowmloadPrice('cashalot')"><i class="ico-24"></i> {{$t('Завантажити прайс-лист (для Кашалот)')}}</a>
      </div>
      <div class="table-wrapper" style="padding: 30px">
        <input type="search" v-model="searchString" class="search-input" placeholder="Пошук"/>
        <div class="step-1" v-for="item in articles" :key="item.id" v-show="priceSearch.length === 0">
          <div class="handler" @click="collapseRow(item)">
            <i class="ico-33" v-if="item.childrens.length > 0"></i>
            <i class="ico-35" v-if="item.childrens.length === 0"></i>
            {{item.planname}} <span class="span-price" v-if="item.childrens.length === 0">[{{$t('ціна')}}: {{item.price}}]</span>
            <div class="tool">
              <a href="#" class="btn-table-change" @click.prevent="modify(item)"><i class="ico-19"></i></a>
              <a href="#" class="btn-table-change" @click.prevent="remove(item)"><i class="ico-20"></i></a>
            </div>
          </div>
          <div class="step-2" v-for="item2 in item.childrens" :key="item2.id" v-show="item.collapseStatus">
            <div class="handler" @click="collapseRow(item2)">
              <i class="ico-33" v-if="item2.childrens.length > 0"></i>
              <i class="ico-35" v-if="item2.childrens.length === 0"></i>
              {{item2.planname}} <span class="span-price" v-if="item2.childrens.length === 0">[{{$t('ціна')}}: {{item2.price}}]</span>
              <div class="tool">
                <a href="#" class="btn-table-change" @click.prevent="modify(item2)"><i class="ico-19"></i></a>
                <a href="#" class="btn-table-change" @click.prevent="remove(item2)"><i class="ico-20"></i></a>
              </div>
            </div>
            <div class="step-3" v-for="item3 in item2.childrens" :key="item3.id" v-show="item2.collapseStatus">
              <div class="handler">
                <i class="ico-33" v-if="item3.childrens.length > 0"></i>
                <i class="ico-35" v-if="item3.childrens.length === 0"></i>
                {{item3.planname}} <span class="span-price" v-if="item3.childrens.length === 0">[{{$t('ціна')}}: {{item3.price}}]</span>
                <div class="tool">
                  <a href="#" class="btn-table-change" @click.prevent="modify(item3)"><i class="ico-19"></i></a>
                  <a href="#" class="btn-table-change" @click.prevent="remove(item3)"><i class="ico-20"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Пошук -->
        <div class="step-1" v-for="search in priceSearch" :key="'search' + search.id" v-show="priceSearch.length > 0">
          <div class="handler">
            <i class="ico-35"></i>
            {{search.planname}} <span class="span-price" v-if="search.childrens.length === 0">[{{$t('ціна')}}: {{search.price}}]</span>
            <div class="tool">
              <a href="#" class="btn-table-change" @click.prevent="modify(search)"><i class="ico-19"></i></a>
              <a href="#" class="btn-table-change" @click.prevent="remove(search)"><i class="ico-20"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.priceModal"
        width="720px"
        :title="edit ? 'Редагування запису в прайс-листі' : 'Додавання запису в прайс-лист'"
        class="modal-custom"
    >
      <form @submit.prevent="save()">
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="description">Назва процедури *</div>
            <div class="input-wr"><input type="text" v-model="form.name" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Назва процедури для курсу лікування *</div>
            <div class="input-wr"><input type="text" v-model="form.planname" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Шифр</div>
            <div class="input-wr"><input type="text" v-model="form.shifr" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Вид процедури</div>
            <div class="input-wr">
              <treeselect v-model="form.parentid" :multiple="false" :options="makeOptions(originalArticles)" placeholder="Виберіть" :searchable="true"/>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Тип процедури</div>
            <div class="input-wr">
              <ul class="radio-list flex">
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="0" checked name="typeProcedure" v-model="form.proctype">
                    <span>загальна</span>
                  </label>
                </li>
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="1" name="typeProcedure" v-model="form.proctype">
                    <span>шаблону</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Код</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="form.code">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Тривалість, хв.</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="form.proctime">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Вартість послуги, грн.</div>
            <div class="input-wr">
              <input type="text" class="custom-input" v-model="form.price">
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">
              Чи поширюється дисконт
            </div>
            <div class="input-wr">
              <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="form.discount_flag"><span></span></label>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">Договір ({{form.is_contract ? 'завантажено' : 'не завантажено'}})</div>
            <div class="input-wr">
              <input type="file" @change="pickFile($event, form.id)">
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <button class="btn-green">Зберегти</button>
          <button class="btn-red" @click.prevent="hide()">Скасувати</button>
        </div>
      </form>
    </el-dialog>
    <el-dialog
        v-model="$store.state.modal.addOrder"
        width="720px"
        :title="$t('Додавання нового запису в прайс-листі')"
        class="modal-custom"
    >
      <form>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">Тип структурного елементу</div>
            <div class="input-wr">
              <ul class="radio-list flex">
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="One" name="structuralElement" checked @click="tabContent = 1" v-model="checked">
                    <span>Підрозділ</span>
                  </label>
                </li>
                <li class="custom-radio">
                  <label>
                    <input type="radio" value="Two" name="structuralElement" @click="tabContent = 2" v-model="checked">
                    <span>Процедура</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="tabContent === 1">
            <div class="line-modal-form">
              <div class="description">Шифр</div>
              <div class="input-wr"><input type="text" class="custom-input"></div>
            </div>
            <div class="line-modal-form">
              <div class="description">Назва розділу</div>
              <div class="input-wr"><input type="text" class="custom-input"></div>
            </div>
          </div>
          <div v-if="tabContent === 2">
            <div class="line-modal-form">
              <div class="description">Назва процедури</div>
              <div class="input-wr"><input type="text" class="custom-input"></div>
            </div>
            <div class="line-modal-form">
              <div class="description">Назва процедури для курсу лікування</div>
              <div class="input-wr"><input type="text" class="custom-input"></div>
            </div>
            <div class="line-modal-form">
              <div class="description">Шифр</div>
              <div class="input-wr"><input type="text" class="custom-input"></div>
            </div>
            <div class="line-modal-form">
              <div class="description">Вид процедури</div>
              <div class="input-wr">
<!--                <stf-select v-model="value3">-->
<!--                  <div slot="label"></div>-->
<!--                  <div slot="value">-->
<!--                    <div v-if="value3">-->
<!--                      <span>{{value3.workStatus}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div slot="search-input">-->
<!--                    <input @input="onsearch3">-->
<!--                  </div>-->
<!--                  <section class="options delivery_order__options">-->
<!--                    <stf-select-option-->
<!--                      v-for="item of listFinded3" :key="item.id"-->
<!--                      :value="item"-->
<!--                      :class="{'stf-select-option_selected': item.id === (value3 && value3.id)}"-->
<!--                    >-->
<!--                      <span>{{item.workStatus}}</span>-->
<!--                    </stf-select-option>-->
<!--                  </section>-->
<!--                </stf-select>-->
              </div>
            </div>
            <div class="line-modal-form">
              <div class="description">Тип процедури</div>
              <div class="input-wr">
                <ul class="radio-list flex">
                  <li class="custom-radio">
                    <label>
                      <input type="radio" value="One" name="structuralElement" checked>
                      <span>Загальна</span>
                    </label>
                  </li>
                  <li class="custom-radio">
                    <label>
                      <input type="radio" value="Two" name="structuralElement">
                      <span>Шаблону</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="line-modal-form">
              <div class="description">Фах лікаря</div>
              <div class="input-wr">
<!--                <stf-select v-model="value2">-->
<!--                  <div slot="label"></div>-->
<!--                  <div slot="value">-->
<!--                    <div v-if="value2">-->
<!--                      <span>{{value2.workStatus}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div slot="search-input">-->
<!--                    <input @input="onsearch2">-->
<!--                  </div>-->
<!--                  <section class="options delivery_order__options">-->
<!--                    <stf-select-option-->
<!--                      v-for="item of listFinded2" :key="item.id"-->
<!--                      :value="item"-->
<!--                      :class="{'stf-select-option_selected': item.id === (value2 && value2.id)}"-->
<!--                    >-->
<!--                      <span>{{item.workStatus}}</span>-->
<!--                    </stf-select-option>-->
<!--                  </section>-->
<!--                </stf-select>-->
              </div>
            </div>
            <div class="line-modal-form">
              <div class="description">Код (повинен збігатися з кодом</div>
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter4">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter4--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter4++"><i class="ico-27"></i></a>
              </div>
            </div>
            <div class="line-modal-form">
              <div class="description">Тривалість, хв.</div>
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter5">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter5--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter5++"><i class="ico-27"></i></a>
              </div>
            </div>
            <div class="line-modal-form">
              <div class="description">Вартість послуги, грн.</div>
              <div class="input-wr number-counter">
                <input type="text" class="custom-input" :value="valueCounter6">
                <a href="#" class="priceBtn count-minus" @click.prevent="valueCounter6--"><i class="ico-28"></i></a>
                <a href="#" class="priceBtn count-plus" @click.prevent="valueCounter6++"><i class="ico-27"></i></a>
              </div>
            </div>
            <div class="line-modal-form">
              <div class="description">
                Чи поширюється дисконт
              </div>
              <div class="input-wr">
                <label class="checkbox-custom ico-text-after"><input type="checkbox"><span></span></label>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">Підтвердити</button>
            <button class="btn-red" @click.prevent="hide2()">Скасувати</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import AwesomeMask from 'awesome-mask'
import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import {ElMessage} from "element-plus";
export default {
  name: 'PriceList',
  components: { Treeselect },
  data () {
    return {
      tags: [],
      articles: [],
      originalArticles: [],
      checked: false,
      url: '/healthproc',
      tabContent: 1,
      form: {
        name: '',
        planname: '',
        shifr: '',
        code: '',
        proctype: '',
        sequincenumber: '',
        proctime: '',
        price: '',
        discount_flag: '',
        parentid: null,
        id: 0,
        is_contract: false
      },
      edit: false,
      searchString: '',
      priceSearch: [],
      listPrices: [],
    }
  },
  created () {
    this.$http.get(this.url + '/all')
      .then(result => {
        this.setData(result.data)
      })
  },
  directives: {
    'mask': AwesomeMask
  },
  watch: {
    searchString: function (newValue) {
      if (newValue !== '') {
        this.priceSearch = this.listPrices.filter(e => {
          const planName = e.planname.toLowerCase()
          const shifr = e.shifr.toLowerCase()
          return e.childrens.length === 0 && (planName.indexOf(newValue.toLowerCase()) !== -1 || shifr.indexOf(newValue.toLowerCase()) !== -1)
        })
        if (this.priceSearch.length === 0) {
          this.priceSearch.push({id: 0, planname: 'нічого не знайдено', childrens: []})
        }
      } else {
        this.priceSearch = []
      }
    }
  },
  methods: {
    dowmloadPrice (target) {
      this.$http.get(this.url + '/export', {
        params: {
          target,
        }
      }).then(resp => {
        window.location.href = resp.data
      })
    },
    collect (arr) {
      arr.map(e => {
        this.listPrices.push(e)
        if (e.childrens.length > 0) {
          this.collect(e.childrens)
        }
      })
    },
    setData (data) {
      this.articles = data.healthproc
      this.collect(this.articles)
      this.originalArticles = data.healthproc
    },
    show () {
      this.$modal.show('priceModal')
      this.listFinded = this.list
    },
    hide () {
      this.$modal.hide('priceModal')
    },
    changeCollapseStatus: function () {
      this.collapseStatus = !this.collapseStatus
    },
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    collapseRow (e) {
      if (e.collapseStatus === undefined || e.collapseStatus === false) {
        e.collapseStatus = true
      } else if (e.collapseStatus === true) {
        e.collapseStatus = false
      }
    },
    save () {
      this.$http.post(this.url + '/add', this.form)
        .then(result => {
          this.setData(result.data)
          ElMessage({
            message: this.$t('Запис додано'),
            type: 'success',
          })
          this.hide()
        })
        .catch(error => {
          console.log('[Error]', error)
          ElMessage({
            message: this.$t('Помилка додавання запису'),
            type: 'error',
          })
        })
    },
    getParents () {
      let parents = [{id: 0, planname: 'не вказувати'}]
      for (let article in this.articles) {
        if (this.articles[article].parentid === null || this.articles[article].parentid === '0') {
          parents.push({id: this.articles[article].id, planname: this.articles[article].planname})
        }
      }
      return parents
    },
    modify (index) {
      this.form.name = index.name
      this.form.planname = index.planname
      this.form.shifr = index.shifr
      this.form.parentid = index.parentid
      this.form.discount_flag = index.discount_flag
      this.form.price = index.price
      this.form.proctime = index.proctime
      this.form.sequincenumber = index.sequincenumber
      this.form.proctype = index.proctype
      this.form.code = index.code
      this.form.id = index.id
      this.form.is_contract = Number(index.load_template_contract) === 1
      this.show()
    },
    clear () {
      this.form = {
        name: '',
        planname: '',
        shifr: '',
        code: '',
        proctype: '',
        sequincenumber: '',
        proctime: '',
        price: '',
        discount_flag: '',
        parentid: 0,
        id: 0,
        is_contract: false
      }
    },
    add () {
      this.clear()
      this.show()
    },
    tree (items, step = 1) {
      let html = ''
      items.map(e => {
        html += '<div class="step-' + step + '" onclick="toggleRow()"><i class="ico-' + (e.childrens.length > 0 ? '33' : '35') + '"></i> ' + e.planname
        if (e.childrens.length > 0) {
          html += this.tree(e.childrens, step + 1)
        }
        html += '</div>'
      })
      return html
    },
    makeOptions (items) {
      let html = []
      items.map(e => {
        let obj = {
          id: e.id,
          label: e.name !== null && e.name !== '' ? e.name : e.planname
        }
        if (e.childrens.length > 0) {
          obj.children = this.makeOptions(e.childrens)
        }
        html.push(obj)
      })
      return html
    },
    pickFile (event, id) {
      let data = new FormData();
      data.append('file', event.target.files[0]);
      data.append('id', id)
      this.$http.post('/files/load-template', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.$toastr.s("Договір завантажено")
      }).catch(error => {
        this.$toastr.e(error.response.data)
      })
    },
    remove(element) {
      if (confirm('Видалити послугу?')) {
        this.$http.delete(this.url + '/disable', {
          params: {
            id: element.id
          }
        }).then(response => {
          location.reload()
        }).catch(error => {
          ElMessage({
            message: this.$t('Помилка видалення послуги'),
            type: 'error',
          })
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
