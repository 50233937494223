<template>
  <el-dialog
      v-model="$store.state.modal.PriceList"
      width="720px"
      :title="$t('Прайс-лист')"
      class="modal-custom"
  >
    <form>
      <div class="body-modal">
        <input type="search" v-model="searchString" class="search-input" :placeholder="$t('Пошук')"/>
        <div class="step-1" v-for="item in prices" :key="item.id" v-show="priceSearch.length === 0">
          <div class="handler" @click="collapseRow(item)" :class="{'same-procedure': item.childrens.length === 0}">
            <i class="ico-33" v-if="item.childrens.length > 0"></i>
            <i class="ico-35" v-if="item.childrens.length === 0"></i>
            {{item.planname}} <span class="span-price" v-if="item.childrens.length === 0">[ціна: {{item.price}}]</span>
            <div class="tool">
              <a href="#" v-if="item.childrens.length === 0" class="btn-table-change" @click.prevent="addToInvoice(item.id)">+</a>
            </div>
          </div>
          <div class="step-2" v-for="item2 in item.childrens" :key="item2.id" v-show="item.collapseStatus">
            <div class="handler" @click="collapseRow(item2)" :class="{'same-procedure': item2.childrens.length === 0}">
              <i class="ico-33" v-if="item2.childrens.length > 0"></i>
              <i class="ico-35" v-if="item2.childrens.length === 0"></i>
              {{item2.planname}} <span class="span-price" v-if="item2.childrens.length === 0">[ціна: {{item2.price}}]</span>
              <div class="tool">
                <a v-if="item2.childrens.length === 0" href="#" class="btn-table-change" @click.prevent="addToInvoice(item2.id)">+</a>
              </div>
            </div>
            <div class="step-3" v-for="item3 in item2.childrens" :key="item3.id" v-show="item2.collapseStatus">
              <div class="handler" :class="{'same-procedure': item3.childrens.length === 0}">
                <i class="ico-33" v-if="item3.childrens.length > 0"></i>
                <i class="ico-35" v-if="item3.childrens.length === 0"></i>
                {{item3.planname}} <span class="span-price" v-if="item3.childrens.length === 0">[ціна: {{item3.price}}]</span>
                <div class="tool">
                  <a href="#" class="btn-table-change" @click.prevent="addToInvoice(item3.id)">+</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Пошук -->
        <div class="step-1" v-for="search in priceSearch" :key="'search' + search.id" v-show="priceSearch.length > 0">
          <div class="handler">
            <i class="ico-35"></i>
            {{search.planname}} <span>[{{search.price}}]</span>
            <div class="tool">
              <a href="#" v-if="search.childrens.length === 0" class="btn-table-change" @click.prevent="addToInvoice(search.id)">+</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: 'TreatmentComponent',
  data () {
    return {
      prices: [],
      searchString: '',
      priceSearch: [],
      listPrices: [],
    }
  },
  watch: {
    showModal: function (newValue, oldValue) {
      if (newValue) {
        this.$modal.show('PriceList')
      }
    },
    searchString: function (newValue, oldValue) {
      if (newValue !== '') {
        this.priceSearch = this.listPrices.filter(e => {
          const planName = e.planname.toLowerCase()
          const shifr = e.shifr.toLowerCase()
          return e.childrens.length === 0 && (planName.indexOf(newValue.toLowerCase()) !== -1 || shifr.indexOf(newValue.toLowerCase()) !== -1)
        })
        if (this.priceSearch.length === 0) {
          this.priceSearch.push({id: 0, planname: this.$t('нічого не знайдено'), childrens: []})
        }
      } else {
        this.priceSearch = []
      }
    }
  },
  props: {
    id: {
      default: Number
    },
    showModal: {
      default: Boolean,
    },
    patient_id: {
      default: Number
    }
  },
  created () {
    this.$http.get('/lab/index').then(response => {
      const laba = {
        id: 0,
        planname: 'Лабораторія',
        childrens: []
      };
      response.data.prices.map(price => {
        laba.childrens.push({
          id: price.id,
          planname: price.name,
          price: Number(price.price)/100,
          childrens: []
        })
      })
      this.$http.get('/patient/prices', {params: {id: this.id}})
          .then(result => {
            this.prices = result.data.prices
            this.prices.push(laba);
            this.collect(this.prices)
          })
    }).catch(error => {
      ElMessage({
        message: this.$t('Помилка завантаження аналізів. Оновіть сторінку'),
        type: 'error',
      })
    })
  },
  methods: {
    collect (arr) {
      arr.map(e => {
        this.listPrices.push(e)
        if (e.childrens.length > 0) {
          this.collect(e.childrens)
        }
      })
    },
    collapseRow (e) {
      if (e.collapseStatus === undefined || e.collapseStatus === false) {
        e.collapseStatus = true
      } else if (e.collapseStatus === true) {
        e.collapseStatus = false
      }
    },
    addToInvoice (priceId) {
      this.$http.post('/patient/add-to-invoice', {price_id: priceId, course_id: this.id, patient_id: this.patient_id})
        .then(result => {
          ElMessage({
            message: this.$t('Запис додано'),
            type: 'success',
          })
          this.emitter.emit('addTreatment', result.data.items)
        })
        .catch(() => {
          ElMessage({
            message: this.$t('Помилка додавання позиції'),
            type: 'error',
          })
        })
    },
    hide2 () {
      this.$modal.hide('PriceList')
    },
  }
}
</script>
