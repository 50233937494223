<template>
  <div>
    <TopInfo />
    <div class="content">
      <ul class="safety-btn">
        <li><a href="#">На видалення (пацієнти)</a></li>
        <li><a href="#">На видалення (каса)</a></li>
        <li><a href="#">На видалення (курс лікування)</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SafetyPage'
}
</script>

<style scoped>

</style>
