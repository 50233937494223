export default {
  state: {
    registerPatient: false,
    printCheck: false,
    xReportModal: false,
    outMoneyModal: false,
    inMoneyModal: false,
    beforeAkt: false,
    beforeContract: false,
    viewItemsInvoice: false,
    createOrder: false,
    addService: false,
    submitPayment: false,
    addDoctor: false,
    openShift: false,
    addDiagnose: false,
    addOrder: false,
    PriceList: false,
    createProtocol: false,
    viewProtocol: false,
    filemanager: false,
    search_patient: false,
    add: false,
    priceModal: false,
    addFreeDay: false,
    addCategory: false,
    QuestionsEditor: false,
    viewRecordingModal: false,
    addLab: false,
    viewLab: false,
    addL: false,
    addRawProtocol: false,
  },
  mutations: {
    show (state, name) {
      state[name] = true
    },
    hide (state, name) {
      state[name] = false
    },
  }
}
