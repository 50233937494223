<template>
  <div>
    <top-pacient
      v-bind:vc="true"
      v-bind:patient="patient"
    ></top-pacient>
    <div class="content">
      <div class="table-wrapper">
        <div class="patient-page">
          <navbar></navbar>
          <div class="content-for-sidebar">
            <PatientData
              v-if="$route.params.component === 'info'"
              v-bind:patient="patient"
            ></PatientData>
            <TestingBox
              v-if="$route.params.component === 'testing'"
              v-bind:id="$route.params.id"
            ></TestingBox>
            <Diagnoses v-if="$route.params.component === 'diagnoz'"></Diagnoses>
            <CourseTreatment
              v-if="$route.params.component === 'kurs'"
              v-bind:id="$route.params.id"
            ></CourseTreatment>
            <ColposcopicExamination
              v-if="$route.params.component === 'kolpos'"
              v-bind:id="$route.params.id"
              v-bind:patient="patient"
            ></ColposcopicExamination>
            <UltrasoundStudy
              v-if="$route.params.component === 'uzd'"
              v-bind:id="$route.params.id"
              v-bind:patient="patient"
            ></UltrasoundStudy>
            <Endoskopia
              v-if="$route.params.component === 'endoskopia'"
              v-bind:id="$route.params.id"
              v-bind:patient="patient"
            ></Endoskopia>
            <Labs
              v-if="$route.params.component === 'labs'"
              v-bind:id="$route.params.id"
              v-bind:patient="patient"
            ></Labs>
            <FileManager
              v-if="$route.params.component === 'files'"
              v-bind:id="$route.params.id"
            ></FileManager>
            <ExportCard
              v-if="$route.params.component === 'export'"
            ></ExportCard>
            <PrintForms
              v-if="$route.params.component === 'print'"
              v-bind:id="$route.params.id"
            ></PrintForms>
            <Protocols
              v-if="$route.params.component === 'protocols'"
              v-bind:id="$route.params.id"
            ></Protocols>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from './modules/Navbar'
import PatientData from './PatientData'
import TestingBox from './TestingBox'
import Diagnoses from './Diagnoses'
import CourseTreatment from './CourseTreatment'
import ColposcopicExamination from './ColposcopicExamination'
import UltrasoundStudy from './UltrasoundStudy'
import FileManager from './FileManager'
import ExportCard from './ExportCard'
import PrintForms from './PrintForms'
import Protocols from './Protocols'
import Endoskopia from './Endoskopia'
import Labs from './Labs'

export default {
  data () {
    return {
      patient: {},
      tabContent: 9,
      checked: false
    }
  },
  mounted () {
    this.$store.commit('setStatus', 'load')
    return new Promise((resolve, reject) => {
      this.$http.get('/patient/one', {params: {id: this.$route.params.id}})
        .then(response => {
          this.$store.commit('setStatus', null)
          this.patient = response.data
          resolve(response)
        })
        .catch(error => {
          this.$store.commit('setStatus', null)
          reject(error)
        })
    })
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    }
  },
  components: {
    navbar,
    PatientData,
    TestingBox,
    Diagnoses,
    CourseTreatment,
    ColposcopicExamination,
    UltrasoundStudy,
    FileManager,
    ExportCard,
    PrintForms,
    Protocols,
    Endoskopia,
    Labs
  }
}
</script>
