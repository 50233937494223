import {createWebHashHistory, createRouter} from "vue-router";
import {store} from '../store/index'

import Home from "@/components/Home";
import DiscountCards from "@/components/Manager/DiscountCards";
import FileManagerTags from "@/components/Info/PatientCards/FileManagerTags";
import Safety from "@/components/Info/Safety";
import GeneralInformation from "@/components/Info/GeneralInformation";
import InformationTest from "@/components/Info/InformationTest";
import ManagerContact from "@/components/Manager/ManagerContact";
import Specialty from "@/components/Info/Specialty";
import ManagerPaydesk from "@/components/ManagerPaydesk";
import TreatmentCourse from "@/components/Info/TreatmentCourse";
import ManagerCertificates from "@/components/Manager/ManagerCertificates";
import Relatives from "@/components/Info/PatientCards/Relatives";
import Sources from "@/components/Info/PatientCards/Sources";

import PatientCards from "@/components/Patients/PatientCards";
import PatientCard from "@/components/Patients/PatientCard";
import Workplaces from "@/components/Workplaces";
import Divisions from "@/components/Info/Divisions";
import BulkMailing from "@/components/Manager/BulkMailing";
import BulkMailing2 from "@/components/Manager/BulkMailing2";
import Doctors from "@/components/Manager/Doctors";
import PriceList from "@/components/Info/PriceList";
import UserSettings from "@/components/UserSettings";
import LogPage from "@/components/Info/LogPage";
import Authorization from "@/components/Authorization";
import PrimaryPatients from "@/components/PrimaryPatients";
import Dispensary from "@/components/Dispensary";
import Research from "@/components/Research";
import DispensaryTypes from "@/components/Info/PatientCards/DispensaryTypes";
import SystemAdministrator from "@/components/Info/SystemAdministrator";
import Cashiers from "@/components/Info/Cashiers";
import CumulativeDiscount from "@/components/Info/PatientCards/CumulativeDiscount";
import GroupsPatients from "@/components/Info/GroupsPatients";
import Budget from "@/components/Director/Budget";
import DirectorCosts from "@/components/Director/Costs";
import BalanceGroups from "@/components/Director/BalanceGroups";
import CashFlow from "@/components/Manager/CashFlow";
import CashFlowAccounts from "@/components/Manager/CashFlowAccounts";
import DirectorIndex from "@/components/Director/Index";
import HR from "@/components/HR";
import CabinetLoading from "../components/Director/Analitics/CabinetLoading";
import AcceptedPatients from "@/components/AcceptedPatients";
import StatisticsDirector from "@/components/StatisticsDirector";
// import CashRegister from '@/components/Manager/CashRegister'PatientCard
import TaskOrganizer from "@/components/Manager/TaskOrganizer";

import DirectorAnalitics from "../components/Director/Analitics";
import DirectorAnaliticsPatient from "../components/Director/Analitics/Patient";
import DirectorAnaliticsStaticOlap from "../components/Director/Analitics/StaticOlap";
import DirectorMoneyTransfer from "../components/Director/MoneyTransfer";
import DirectorMoneyTransferInvoices from "../components/Director/MoneyTransfer/Invoices";
import Sms from "../components/Director/Sms";
import SmsSetting from "../components/Director/Sms/Setting";
import Schedule from "../components/Manager/Schedule";
import FreeDays from "../components/Manager/FreeDays";

let router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "login",
      component: Authorization,
    },
    {
      path: "/home",
      name: "Home",
      component: Home,
    },
    /** Роути для менеджера **/
    {
      path: "/manager/schedule",
      name: "Schedule",
      component: Schedule,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager/free-day",
      name: "FreeDays",
      component: FreeDays,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager",
      name: "TaskOrganizer",
      component: TaskOrganizer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager/sms",
      name: "BulkMailing",
      component: BulkMailing,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager/sms/create",
      name: "BulkMailing2",
      component: BulkMailing2,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager/sertificats",
      name: "ManagerCertificates",
      component: ManagerCertificates,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager/cadrs",
      name: "Doctors",
      component: Doctors,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager/discount-cards",
      name: "DiscountCards",
      component: DiscountCards,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager/contacts",
      name: "ManagerContact",
      component: ManagerContact,
      meta: {
        requiresAuth: true,
      },
    },
    /** end **/
    /** Роути директора **/
    {
      path: "/director/money",
      name: "Budget",
      component: Budget,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/director/group-balance",
      name: "BalanceGroups",
      component: BalanceGroups,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/StatisticsDirector",
      name: "StatisticsDirector",
      component: StatisticsDirector,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/AcceptedPatients",
      name: "AcceptedPatients",
      component: AcceptedPatients,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/director/costs",
      name: "DirectorCosts",
      component: DirectorCosts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/HR",
      name: "HR",
      component: HR,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/director/index",
      name: "DirectorIndex",
      component: DirectorIndex,
      meta: {
        requiresAuth: true,
      },
    },
    // Аналітика
    {
      path: "/director/analitics/patients",
      name: "DirectorAnaliticsPatient",
      component: DirectorAnaliticsPatient,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/director/analitics/static-olap",
      name: "DirectorAnaliticsStaicOlap",
      component: DirectorAnaliticsStaticOlap,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/director/analitics/cabinets",
      name: "CabinetLoading",
      component: CabinetLoading,
      meta: {
        requiresAuth: true,
      },
    },
    // кінець Аналітики
    {
      path: "/director/money-transfer",
      name: "DirectorMoneyTransfer",
      component: DirectorMoneyTransfer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/director/money-transfer/invoices",
      name: "DirectorMoneyTransferInvoices",
      component: DirectorMoneyTransferInvoices,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/director/sms",
      name: "Sms",
      component: Sms,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/director/sms/setting",
      name: "Sms Setting",
      component: SmsSetting,
      meta: {
        requiresAuth: true,
      },
    },
    /** End Director **/
    {
      path: "/manager/kassa",
      name: "CashFlowAccounts",
      component: CashFlowAccounts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manager/kassa/kas",
      name: "CashFlow",
      component: CashFlow,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/patient-cards/tag-fm",
      name: "FileManagerTags",
      component: FileManagerTags,
      meta: {
        requiresAuth: true,
      },
    },
    /** Роути інформації **/
    {
      path: "/info/index/security",
      name: "Safety",
      component: Safety,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/index",
      name: "GroupsPatients",
      component: GroupsPatients,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/index/about-us",
      name: "GeneralInformation",
      component: GeneralInformation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/patient-cards/discount-system",
      name: "CumulativeDiscount",
      component: CumulativeDiscount,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/index/admins",
      name: "SystemAdministrator",
      component: SystemAdministrator,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/index/cashiers",
      name: "Cashiers",
      component: Cashiers,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Dispensary",
      name: "Dispensary",
      component: Dispensary,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/patient-cards/types-dispanser",
      name: "DispensaryTypes",
      component: DispensaryTypes,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Research",
      name: "Research",
      component: Research,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/counstruct",
      name: "TreatmentCourse",
      component: TreatmentCourse,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/test",
      name: "InformationTest",
      component: InformationTest,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Workplaces",
      name: "Workplaces",
      component: Workplaces,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/index/subs",
      name: "Divisions",
      component: Divisions,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/price-list",
      name: "PriceList",
      component: PriceList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Authorization",
      name: "Authorization",
      component: Authorization,
    },
    {
      path: "/info/patient-cards/parents",
      name: "Relatives",
      component: Relatives,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/index/specials",
      name: "Specialty",
      component: Specialty,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/index/logs",
      name: "LogPage",
      component: LogPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info/patient-cards",
      name: "Sources",
      component: Sources,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/info",
      name: "Sources",
      component: Sources,
      meta: {
        requiresAuth: true,
      },
    },
    /** Роути сторінки пацієнтів **/
    {
      path: "/patients",
      name: "PatientCards",
      component: PatientCards,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/patients/:id/:component",
      name: "PatientCard",
      component: PatientCard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/patients/:id/:component/:sub_id",
      name: "PatientCardSub",
      component: PatientCard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/PrimaryPatients",
      name: "PrimaryPatients",
      component: PrimaryPatients,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManagerPaydesk",
      name: "ManagerPaydesk",
      component: ManagerPaydesk,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/UserSettings",
      name: "UserSettings",
      component: UserSettings,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
