<template>
  <div>
    <top-manager></top-manager>
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="showModal('registerManager')" ><i class="ico-11"></i> Додати сертифікат</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold">
          <thead>
          <tr>
            <th style="width: 46px;">
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th style="width: 150px;">
              <div class="caption">Номер</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 150px;">
              <div class="caption">Сума</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 150px;">
              <div class="caption">Дата</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 250px;">
              <div class="caption">П.І.Б.</div>
              <input type="text" class="custom-input">
            </th>
            <th>
              <div class="caption">Послуга</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 40px;"></th>
            <th style="width: 40px;"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="certificate of certificates" :key="certificate.id">
            <td>
              <label class="checkbox-custom ico-text-after"><input type="checkbox"><span></span></label>
            </td>
            <td>{{ certificate.id }}</td>
            <td>{{ certificate.summ }}</td>
            <td>{{ certificate.dateopen }}</td>
            <td>{{ certificate.dateclose }}</td>
            <td>{{ certificate.lastname }}</td>
            <td>{{ certificate.subdivisionid }}</td>
            <td><a href="#" class="btn-table-change"><i class="ico-19"></i></a></td>
            <td><a href="#" class="btn-table-change"><i class="ico-20"></i></a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <newCertificates></newCertificates>
  </div>
</template>

<script>
import newCertificates from './modules/NewCard'
export default {
  name: 'ManagerCertificates',
  data () {
    return {
      certificates: [],
      checked: false,
      url: '/certificate'
    }
  },
  components: {
    newCertificates
  },
  created () {
    this.$http.get(this.url + '/index')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    showModal (target) {
      this.$modal.show(target)
    },
    setDate (data) {
      this.certificates = data.certificates
    }
  }
}
</script>

<style scoped>

</style>
