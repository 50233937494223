<template>
  <div>
    <Navbar></Navbar>
    <Header1></Header1>
    <div class="content">
      <div class="table-wrapper">
        <table class="body-bold hr-table">
          <thead>
          <tr>
            <th>
              <div class="caption text-overflow-ellipsis">Посада</div>
            </th>
            <th>
              <div class="caption">П.І.Б.</div>
            </th>
            <th><div class="caption">1</div></th>
            <th><div class="caption">2</div></th>
            <th><div class="caption">3</div></th>
            <th><div class="caption">4</div></th>
            <th><div class="caption">5</div></th>
            <th><div class="caption">6</div></th>
            <th><div class="caption">7</div></th>
            <th><div class="caption">8</div></th>
            <th><div class="caption">9</div></th>
            <th><div class="caption">10</div></th>
            <th><div class="caption">11</div></th>
            <th><div class="caption">12</div></th>
            <th><div class="caption">13</div></th>
            <th><div class="caption">14</div></th>
            <th><div class="caption">15</div></th>
            <th><div class="caption">16</div></th>
            <th><div class="caption">17</div></th>
            <th><div class="caption">18</div></th>
            <th><div class="caption">19</div></th>
            <th><div class="caption">20</div></th>
            <th><div class="caption">21</div></th>
            <th><div class="caption">22</div></th>
            <th><div class="caption">23</div></th>
            <th><div class="caption">24</div></th>
            <th><div class="caption">25</div></th>
            <th><div class="caption">26</div></th>
            <th><div class="caption">27</div></th>
            <th><div class="caption">28</div></th>
            <th><div class="caption">29</div></th>
            <th><div class="caption">30</div></th>
            <th><div class="caption">31</div></th>
            <th><div class="caption">Загалом</div></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="person of articles" :key="person.id">
            <td>{{ person.job }}</td>
            <td>{{ person.name }}</td>
            <td>{{ person.number1 }}</td>
            <td>{{ person.number2 }}</td>
            <td>{{ person.number3 }}</td>
            <td>{{ person.number4 }}</td>
            <td>{{ person.number5 }}</td>
            <td>{{ person.number6 }}</td>
            <td>{{ person.number7 }}</td>
            <td>{{ person.number8 }}</td>
            <td>{{ person.number9 }}</td>
            <td>{{ person.number10 }}</td>
            <td>{{ person.number11 }}</td>
            <td>{{ person.number12 }}</td>
            <td>{{ person.number13 }}</td>
            <td>{{ person.number14 }}</td>
            <td>{{ person.number15 }}</td>
            <td>{{ person.number16 }}</td>
            <td>{{ person.number17 }}</td>
            <td>{{ person.number18 }}</td>
            <td>{{ person.number19 }}</td>
            <td>{{ person.number20 }}</td>
            <td>{{ person.number21 }}</td>
            <td>{{ person.number22 }}</td>
            <td>{{ person.number23 }}</td>
            <td>{{ person.number24 }}</td>
            <td>{{ person.number25 }}</td>
            <td>{{ person.number26 }}</td>
            <td>{{ person.number27 }}</td>
            <td>{{ person.number28 }}</td>
            <td>{{ person.number29 }}</td>
            <td>{{ person.number30 }}</td>
            <td>{{ person.number31 }}</td>
            <td>{{ person.numberFul }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HR',
  data () {
    return {
      articles: [
        {
          id: 8,
          job: 'Лікар',
          name: 'Ващук К.А. ',
          number1: '1',
          number2: '2',
          number3: '3',
          number4: '4',
          number5: '5',
          number6: '6',
          number7: '7',
          number8: '8',
          number9: '9',
          number10: '10',
          number11: '11',
          number12: '12',
          number13: '13',
          number14: '14',
          number15: '15',
          number16: '16',
          number17: '17',
          number18: '18',
          number19: '19',
          number20: '20',
          number21: '21',
          number22: '22',
          number23: '23',
          number24: '24',
          number25: '25',
          number26: '26',
          number27: '27',
          number28: '28',
          number29: '29',
          number30: '30',
          number31: '31',
          numberFul: '0'
        }
      ],
      checked: false
    }
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    }
  }
}
</script>

<style scoped>

</style>
