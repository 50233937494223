<template>
  <div
    class="asidePanel"
    :class="modalClass()"
  >
    <a
      href="#"
      class="close"
      @click.prevent="closeForm"
    ><i class="ico-17" /></a>
    <a
      href="#"
      class="mini"
      @click.prevent="formMinimize = !formMinimize"
    >
      <i />
      <i />
    </a>
    <form :class="{'minimize': formMinimize}">
      <div class="caption-modal">
        {{ $t('Додавання нового запису до розкладу') }}
      </div>
      <div class="body-modal form-long modal-form-p">
        <ul class="header-tab-box padding-header">
          <li>
            <a
              href="#"
              :class="{active: tabContent === 1}"
              @click.prevent="tabContent = 1"
            >{{ $t('Запис пацієнта') }}</a>
          </li>
        </ul>
        <div
          v-if="tabContent === 1"
          class="padding-order-f new-box-flex"
        >
          <div class="line-modal-form">
            <div class="description">
              {{ $t('Дата призначення') }} *
            </div>
            <div class="input-wr line-btn-right">
              <el-date-picker
                v-model="form.taskdate"
                type="date"
                format="DD.MM.YYYY"
                :placeholder="$t('Дата')"
                :picker-options="pickerOptions"
              />
            </div>
          </div>
          <div class="line-modal-form line-50-percent full-width">
            <div class="description">
              {{ $t('Призначений час') }} *
            </div>
            <div class="input-wr line-btn-right order-time-list">
              <el-time-select
                v-model="form.startTime"
                :placeholder="$t('Початок прийому')"
                step="00:05"
                start="08:00"
                end="20:00"
              />
              <el-time-select
                v-model="form.endTime"
                :placeholder="$t('Кінець прийому')"
                :min-time="form.startTime"
                step="00:05"
                start="08:00"
                end="20:00"
              />
            </div>
          </div>
          <div class="line-modal-form line-50-percent">
            <div class="description">
              {{ $t('Пацієнт') }} *
            </div>
            <div class="input-wr">
              <el-select
                v-model="form.patientid"
                class="m-2"
                size="large"
                filterable
                remote
                :remote-method="fetchOptions"
                @change="selectedPatient"
              >
                <el-option
                  v-for="item in searchPatient"
                  :key="item.id"
                  :label="item.displayName"
                  :value="item.id"
                />
              </el-select>
              <br/>
              <a
                href="#"
                @click.prevent="$modal.show('registerPatient')"
              >{{ $t('Новий пацієнт') }}</a>
            </div>
          </div>
          <div class="line-modal-form line-50-percent">
            <div class="description">
              {{ $t('Номер телефону') }} *
            </div>
            <div class="input-wr">
              <input
                v-model="form.phone"
                v-maska="'+38 (###) ### ## ##'"
                class="custom-input"
              >
            </div>
          </div>
          <div class="line-modal-form line-50-percent">
            <div class="description">
              {{ $t('Лікар') }} *
            </div>
            <div class="input-wr">
              <el-select
                v-model="form.doctorid"
                class="m-2"
                size="large"
                filterable
              >
                <el-option
                  v-for="item in doctors"
                  :key="item.id"
                  :label="item.fullname"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="line-modal-form line-50-percent">
            <div class="description">
              {{ $t('Кабінет') }} *
            </div>
            <div class="input-wr">
              <el-select
                v-model="form.roomid"
                class="m-2"
                size="large"
                filterable
              >
                <el-option
                  v-for="item in rooms"
                  :key="item.id"
                  :label="item.number"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <!--          <div class="line-modal-form line-50-percent">-->
          <!--            <div class="description">Послуга</div>-->
          <!--            <div class="input-wr">-->
          <!--              <v-select multiple :options="services" label="name" v-model="form.healthproc_id" :reduce="service => service.id"></v-select>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="line-modal-form line-50-percent">
            <div class="description">
              {{ $t('Опис роботи') }}
            </div>
            <div class="input-wr">
              <input
                v-model="form.workdescription"
                type="text"
                class="custom-input"
              >
            </div>
          </div>
          <div class="line-modal-form line-50-percent">
            <div class="description">
              {{ $t('Примітка') }}
            </div>
            <div class="input-wr">
              <input
                v-model="form.comment"
                type="text"
                class="custom-input"
              >
            </div>
          </div>
          <div class="checkbox-order-bl">
            <div class="left-q">
              <ul class="list-filter-checkbox">
                <li>
                  <label class="checkbox-custom with-text ico-text-after"><input
                    v-model="form.noticed"
                    type="checkbox"
                  ><span>{{ $t('Попереджений про візит') }}</span></label>
                </li>
                <li>
                  <label class="checkbox-custom with-text ico-text-after"><input
                    v-model="form.factofvisit"
                    type="checkbox"
                  ><span>{{ $t('Факт візиту') }}</span></label>
                </li>
              </ul>
            </div>
            <div class="right-q">
              <ul class="radio-list">
                <li class="custom-long-radio">
                  <label>
                    <input
                      v-model="form.smsstatus"
                      type="checkbox"
                    >
                    <span>{{ $t('Увімкнути СМС') }}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer-modal flex-footer">
            <div class="btn-left-footer" />
            <div class="btn-right-footer">
              <div class="btn-tooltip-footer">
                <ul class="bottom-btn pay-box-btn big-btn">
                  <li class="tooltip-wrapper">
                    <router-link
                      to="/patients"
                      class="full-flex-center brand-hover with-border"
                    >
                      <i class="ico-21" />
                    </router-link>
                    <div class="tooltip">
                      {{ $t('Перейти до пацієнтів') }}
                    </div>
                  </li>
<!--                  <li class="tooltip-wrapper">-->
<!--                    <a-->
<!--                      href="#"-->
<!--                      class="full-flex-center brand-hover with-border"-->
<!--                    ><i class="ico-07" /></a>-->
<!--                    <div class="tooltip">-->
<!--                      {{ $t('В картку пацієнта') }}-->
<!--                    </div>-->
<!--                  </li>-->
                  <!--                  <li class="tooltip-wrapper">-->
                  <!--                    <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-62"></i></a>-->
                  <!--                    <div class="tooltip">Експорт картки</div>-->
                  <!--                  </li>-->
                  <!--                  <li class="tooltip-wrapper">-->
                  <!--                    <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-24"></i></a>-->
                  <!--                    <div class="tooltip">Друк</div>-->
                  <!--                  </li>-->
                </ul>
              </div>
              <button
                class="btn-green"
                @click.prevent="save"
              >
                {{ $t('Зберегти') }}
              </button>
              <button
                type="button"
                class="btn-red"
                @click="closeForm"
              >
                {{ $t('Скасувати') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  data() {
    return {
      url: '/tasks',
      tabContent: 1,
      form: {
        taskdate: this.date,
        startTime: '',
        endTime: '',
        patientid: '',
        doctorid: '',
        roomid: '',
        workplaceid: 0,
        noticed: false,
        workdescription: '',
        comment: '',
        smsstatus: 1,
        factofvisit: false,
        phone: '',
        healthproc_id: []
      },
      searchPatient: [],
      searchTimer: null,
      services: [],
      formMinimize: false,
      showModal: false,
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  watch: {
    formMinimize() {
      if (this.formMinimize) {
        document.getElementsByTagName('body')[0].classList.remove('overBody')
      } else {
        document.getElementsByTagName('body')[0].classList.add('overBody')
      }
    },
    date(newValue, oldValue) {
      this.form.taskdate = newValue
    }
  },
  props: {
    rooms: {
      default: Array
    },
    patients: {
      default: Array
    },
    doctors: {
      default: Array
    },
    date: {
      default: new Date()
    }
  },
  mounted() {
    this.emitter.on('newPatient', this.setSelectedPatient)
    this.emitter.on('showModalTask', this.showModalTask)
    this.emitter.on('setBeforeData', (data) => {
      this.form.roomid = data.room_id.id
      this.form.startTime = data.time
      this.form.doctorid = Number(data.room_id.doctor_id)
    })
  },
  async created() {
    await this.$http.get('/healthproc/all-list')
        .then(result => {
          this.services = result.data.healthproc
        })
        .catch(error => {
          console.log('[Log error]', error)
          this.$toastr.e("Помилка завантаження списку послуг");
        })
  },
  methods: {
    setSelectedPatient(data) {
      this.form.patientid = data
      this.searchPatient = [data];
      this.form.phone = data.mobilenumber
    },
    selectedPatient(value) {
      const user = this.searchPatient.find(patient => {
        return Number(patient.id) === Number(value)
      })
      this.form.phone = user.mobilenumber
    },
    counterTime(e, action, max, step) {
      if (action === 'plus' && Number(this[e]) < max) {
        let currentTime = Number(this[e])
        currentTime = currentTime + step
        if (currentTime < 10) {
          this[e] = '0' + currentTime
        } else {
          this[e] = currentTime
        }
      }
      if (action === 'minus') {
        let currentTime = Number(this[e])
        currentTime = currentTime - step
        if (currentTime >= 0) {
          if (currentTime < 10) {
            this[e] = '0' + currentTime
          } else {
            this[e] = currentTime
          }
        }
      }
    },
    save() {
      if (this.form.patientid === 0) {
        ElMessage({
          message: this.$t('Ви не вказали пацієнта'),
          type: 'error',
        })
        return false
      }
      if (this.form.doctorid === 0) {
        ElMessage({
          message: this.$t('Вкажіть всі обов\'язкові поля!'),
          type: 'error',
        })
        return false
      }
      if (this.form.startTime === '' || this.form.endTime === '') {
        ElMessage({
          message: this.$t('Ви не вказали години запису'),
          type: 'error',
        })
        return false
      }

      const data = {
        taskdate: this.formatDate(this.form.taskdate),
        beginoftheinterval: this.form.startTime + ':00',
        endoftheinterval: this.form.endTime + ':00',
        patientid: this.form.patientid,
        doctorid: this.form.doctorid,
        roomid: this.form.roomid,
        workplaceid: this.form.workplaceid,
        noticed: this.form.noticed ? 1 : 0,
        workdescription: this.form.workdescription,
        comment: this.form.comment,
        smsstatus: this.form.smsstatus ? 1 : 0,
        factofvisit: this.form.factofvisit ? 1 : 0,
        phone: this.form.phone,
        healthproc_id: this.form.healthproc_id
      }
      this.$http.post(this.url + '/save', data)
          .then(result => {
            ElMessage({
              message: this.$t('Запис додано'),
              type: 'success',
            })
            this.$emit('updateTask', result.data)
            this.clear()
          })
          .catch(error => {
            console.log('[Error]', error)
            ElMessage({
              message: this.$t('Помилка додавання запису'),
              type: 'error',
            })
          })
    },
    clear() {
      this.form = {
        taskdate: new Date(),
        startTime: '',
        endTime: '',
        patientid: 0,
        doctorid: 0,
        roomid: 0,
        workplaceid: 0,
        noticed: false,
        workdescription: '',
        comment: '',
        smsstatus: false,
        factofvisit: false,
        phone: '',
        healthproc_id: []
      }
    },
    cancel() {
      this.$modal.hide('orderManager')
      this.clear()
    },
    formatDate(date) {
      let dd = new Date(date)
      let y = dd.getFullYear()
      let m = dd.getMonth()
      let d = dd.getDate()
      m = Number(m) + 1
      if (String(m).length === 1) {
        m = '0' + m
      }
      if (String(d).length === 1) {
        d = '0' + d
      }
      return y + '-' + m + '-' + d
    },
    fetchOptions(search) {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        if (search === '') {
          this.searchPatient = []
        } else {
          this.$http.get('/patient/search', {
            params: {
              search: {
                firstname: search,
                lastname: search,
                middlename: search,
                fullname: search
              }
            }
          }).then(result => {
            this.searchPatient = result.data.patients
          }).catch(error => {
            console.log('[Error]', error)
          })
        }
      }, 1000)
    },
    modalClass() {
      let cls = []
      if (this.formMinimize) {
        cls.push('revert')
      }
      if (!this.showModal) {
        cls.push('out')
      }
      return cls
    },
    showModalTask() {
      this.showModal = true
    },
    closeForm() {
      this.showModal = false
      document.getElementsByTagName('body')[0].classList.remove('overBody')
    }
  }
}
</script>

<style scoped>

</style>
