<template>
  <div>
    <top-info></top-info>
    <div class="content">
      <div class="btn-above-table">
        <a href="#" class="btn-green" @click.prevent="show ()"><i class="ico-11"></i> Додати новий запис</a>
      </div>
      <div class="table-wrapper">
        <table class="body-bold">
          <thead>
          <tr>
            <th style="width: 46px;">
              <label class="checkbox-custom ico-text-after"><input type="checkbox" @click="checkedAll"><span></span></label>
            </th>
            <th style="width: 50%">
              <div class="caption">№</div>
            </th>
            <th style="width: 50%">
              <div class="caption">Опис</div>
              <input type="text" class="custom-input">
            </th>
            <th style="width: 40px;"></th>
            <th style="width: 40px;"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="person of articles" :key="person.idD">
            <td>
              <label class="checkbox-custom ico-text-after"><input type="checkbox" v-model="person.checked"><span></span></label>
            </td>
            <td>{{ person.sumD }}</td>
            <td>{{ person.interestD }}</td>
            <td><a href="#" class="btn-table-change"><i class="ico-19"></i></a></td>
            <td><a href="#" class="btn-table-change"><i class="ico-20"></i></a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal name="addOrder" class="modal-custom" :width="720" height="auto" :scrollable="true">
      <form>
        <div class="caption-modal">
          Додавання нового запису
          <a href="#" class="close-modal" @click.prevent="hide ()"><i class="ico-17"></i></a>
        </div>
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">Сума</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Відсоток</div>
            <div class="input-wr"><input type="text" class="custom-input"></div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">Зберегти</button>
            <button class="btn-red" @click.prevent="hide ()">Скасувати</button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'CumulativeDiscount',
  data () {
    return {
      articles: [
        {
          idD: 1,
          sumD: '100',
          interestD: '2',
          checked: false
        }
      ],
      checked: false
    }
  },
  methods: {
    checkedAll: function () {
      this.checked = !this.checked
      this.articles.forEach(e => {
        e.checked = this.checked
      })
    },
    show () {
      this.$modal.show('addOrder')
    },
    hide () {
      this.$modal.hide('addOrder')
    }
  }
}
</script>

<style scoped>

</style>
