<template>
  <div class="sidebar" v-if="isLogin">
    <a href="/" class="logo"><img src="../../assets/images/logo.svg" alt="Medical Standard logo"></a>
    <div class="center-sidebar">
      <ul>
        <li><router-link :class="{'active': currentMenu === '/manager'}" :to="'/manager'"><i class="ico-12"></i></router-link></li>
        <li
          v-show="$store.state.user.user.role === 1 || $store.state.user.user.role === 3 || $store.state.user.user.role === 4 || $store.state.user.user.role === 8"
        ><router-link :class="{'active': currentMenu === '/patients'}" to="/patients"><i class="ico-13"></i></router-link></li>
        <li v-show="$store.state.user.user.role === 3 || $store.state.user.user.role === 4"><router-link :class="{'active': currentMenu === '/director/sms'}" to="/director/sms"><i class="ico-14"></i></router-link></li>
      </ul>
    </div>
    <div class="bottom-btn">
      <ul>
        <li v-show="$store.state.user.user.role === 3">
          <router-link :class="{'active': currentMenu === '/dictonary'}" to="/info"><i class="ico-15"></i></router-link>
        </li>
        <li v-show="$store.state.user.user.role === 3">
          <router-link :class="{'active': currentMenu === '/info/index'}" to="/info/index"><i class="ico-16"></i></router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {store} from '../../store/index';

export default {
  name: 'NavbarComponent',
  data () {
    return {
      currentMenu: this.$router.currentRoute.path
    }
  },
  computed: {
    isLogin () {
      return store.getters.isLoggedIn
    }
  },
  watch: {
    $route (to, from) {
      let url = to.path.split('/')
      this.currentMenu = url[0] + '/' + url[1]
      if (url[2] !== undefined) {
        this.currentMenu = this.currentMenu + '/' + url[2]
      }
    }
  },
  methods: {
    getStartLink () {
      // return '/manager'
      if (this.$store.state.user.user.role === 1 || this.$store.state.user.user.role === 3 || this.$store.state.user.user.role === 4) {
        return '/manager'
      } else if (this.$store.state.user.user.role === 7) {
        return '/manager/kassa'
      } else if (this.$store.state.user.user.role === 8) {
        return '/patients'
      }
      return '/manager'
    }
  }
}
</script>

<style scoped>

</style>
