<template>
  <el-dialog
      v-model="$store.state.modal.add"
      width="720px"
      :title="title"
      class="modal-custom"
  >
    <form @submit.prevent="save">
      <div class="body-modal">
        <div class="line-modal-form">
          <div class="description">{{$t('Логін')}}*</div>
          <div class="input-wr">
            <input type="text" v-model="user.login" class="custom-input" :class="{'input-v-error':v$.user.login.$error.length}">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Пароль')}}*</div>
          <div class="input-wr">
            <input type="text" v-model="user.password" class="custom-input" :class="{'input-v-error':v$.user.password.$error.length}">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Номер ліцензії')}}*</div>
          <div class="input-wr">
            <input type="text" v-model="user.license" class="custom-input" :class="{'input-v-error':v$.user.license.$error.length}">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Юридична особа')}}*</div>
          <div class="input-wr">
            <input type="text" v-model="user.cashier_name" class="custom-input" :class="{'input-v-error':v$.user.cashier_name.$error.length}">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Назва закладу')}}*</div>
          <div class="input-wr">
            <input type="text" v-model="user.departament" class="custom-input" :class="{'input-v-error':v$.user.departament.$error.length}">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Юр. особа для акту')}}</div>
          <div class="input-wr">
            <textarea v-model="user.akt_ur_desc" rows="5" class="custom-input" style="height: auto"/>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('ПІБ для акту')}}</div>
          <div class="input-wr">
            <input type="text" v-model="user.akt_ur_name" class="custom-input">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Посада')}}</div>
          <div class="input-wr">
            <input type="text" v-model="user.position" class="custom-input">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Юр. особа для договору')}}</div>
          <div class="input-wr">
            <textarea v-model="user.contract_ur_desc" rows="5" class="custom-input" style="height: auto"/>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Реквізити оплати для договору')}}</div>
          <div class="input-wr">
            <textarea v-model="user.contract_ur_payment" rows="5" class="custom-input" style="height: auto"/>
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Merchant ID (для роботи терміналу)')}}</div>
          <div class="input-wr">
            <input type="text" v-model="user.merchant_id" class="custom-input">
          </div>
        </div>
        <div class="line-modal-form">
          <div class="description">{{$t('Використовувати по замовчуванню')}}</div>
          <div class="input-wr">
            <input type="checkbox" v-model="user.is_default">
          </div>
        </div>
      </div>
      <div class="footer-modal text-right">
        <div class="btn-left-footer"></div>
        <div class="btn-right-footer">
          <button class="btn-green">{{$t('Зберегти')}}</button>
          <button class="btn-red" @click.prevent="hide()">{{$t('Скасувати')}}</button>
        </div>
      </div>
    </form>
  </el-dialog>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {ElMessage} from "element-plus";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: 'CashierAdd',
  data () {
    return {
      user: {
        login: '',
        password: '',
        license: '',
        cashier_name: '',
        departament: '',
        akt_ur_desc: '',
        akt_ur_name: '',
        contract_ur_desc: '',
        contract_ur_payment: '',
        status: {id: 1, label: 'активна'},
        position: '',
        merchant_id: '',
        is_default: false,
        id: 0
      },
      title: this.$t('Додати нову юридичну особу'),
      cashiers: [],
    }
  },
  props: {
    cashiersProps: {
      default: Object
    }
  },
  created () {
    this.emitter.on('edit', this.edit)
  },
  methods: {
    hide () {
      this.$modal.hide('add')
      this.user = {
        login: '',
        password: '',
        license: '',
        cashier_name: '',
        departament: '',
        akt_ur_desc: '',
        akt_ur_name: '',
        contract_ur_desc: '',
        contract_ur_payment: '',
        status: {id: 1, label: 'активна'},
        position: '',
        merchant_id: '',
        is_default: false,
        id: 0
      }
    },
    save () {
      if (!this.v$.user.$invalid) {
        const msg = this.user.id === 0 ? this.$t('Юридичну особу створено') : this.$t('Юридичну особу оновлено')
        this.$http.post('/cashiers/add', this.user)
          .then(result => {
            this.user = {
              login: '',
              password: '',
              license: '',
              cashier_name: '',
              departament: '',
              akt_ur_desc: '',
              akt_ur_name: '',
              contract_ur_desc: '',
              contract_ur_payment: '',
              status: {id: 1, label: this.$t('активна')},
              position: '',
              merchant_id: '',
              is_default: false,
              id: 0
            }
            this.hide()
            this.emitter.emit('update', result.data)
            ElMessage({
              message: msg,
              type: 'success',
            })
          })
          .catch(error => {
            ElMessage({
              message: this.$t('Помилка створення'),
              type: 'error',
            })
            console.log('[Error]', error)
          })
      }
    },
    edit (data) {
      this.user = data
      this.user.is_default = this.user.is_default === 1
      this.$modal.show('add')
    }
  },
  validations () {
    return {
      user: {
        login: {
          required
        },
        password: {
          required
        },
        license: {
          required
        },
        cashier_name: {
          required
        },
        departament: {
          required
        }
      }
    }
  },
}
</script>
