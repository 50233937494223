<template>
  <div>
    <top-director></top-director>
  </div>
</template>
<script>
export default {
  name: 'FullAnalitics',
  data () {
    return {
      name: 'IndexPage'
    }
  }
}
</script>
