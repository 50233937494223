<template>
  <div>
    <TopManager />
    <div class="content">
      <div class="btn-above-table">
        <a href="#" @click.prevent="show()" class="btn-green"><i class="ico-11"></i> {{$t('Додати новий запис')}}</a>
      </div>
      <div class="table-wrapper p-20">
        <div id="table"/>
      </div>
    </div>
    <el-dialog
        v-model="$store.state.modal.addDoctor"
        :title="$t('Додавання нового працівника')"
        width="960px"
        class="modal-custom"
    >
      <form @submit.prevent="save">
        <div class="body-modal">
          <div class="line-modal-form">
            <div class="description">{{$t('ТІН')}}</div>
            <div class="input-wr"><input type="text" v-model="form.tin" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Прізвище')}}*</div>
            <div class="input-wr"><input type="text" v-model="form.lastname" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Ім\'я')}}*</div>
            <div class="input-wr"><input type="text" v-model="form.firstname" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('По батькові')}}*</div>
            <div class="input-wr"><input type="text" v-model="form.middlename" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Дата народження')}}</div>
            <div class="input-wr line-btn-right">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                format="DD.MM.YYYY"
                :placeholder="$t('Дата')"
                :picker-options="pickerOptions"
                size="large"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Стать')}}</div>
            <div class="input-wr">
              <el-select v-model="form.sex" @input="filterEvent">
                <el-option
                    v-for="item in [{value: 0, label: $t('чоловіча')}, {value: 1, label: $t('жіноча')}]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Дата початку роботи')}}</div>
            <div class="input-wr line-btn-right">
              <el-date-picker
                v-model="form.dateworkstart"
                type="date"
                format="DD.MM.YYYY"
                :placeholder="$t('Дата')"
                :picker-options="pickerOptions"
                size="large"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Дата завершення роботи')}}</div>
            <div class="input-wr line-btn-right">
              <el-date-picker
                v-model="form.dateworkend"
                type="date"
                format="DD.MM.YYYY"
                :placeholder="$t('Дата')"
                :picker-options="pickerOptions"
                size="large"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Мобільний номер')}}</div>
            <div class="input-wr"><input type="text" v-model="form.mobilephone" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Домашній номер')}}</div>
            <div class="input-wr"><input type="text" v-model="form.homephone" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Email</div>
            <div class="input-wr"><input type="text" v-model="form.email" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">Skype</div>
            <div class="input-wr"><input type="text" v-model="form.skype" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Домашня адреса')}}</div>
            <div class="input-wr"><input type="text" v-model="form.homeadress" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Індекс')}}</div>
            <div class="input-wr"><input type="text" v-model="form.postalcode" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Область')}}</div>
            <div class="input-wr"><input type="text" v-model="form.state" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Район')}}</div>
            <div class="input-wr"><input type="text" v-model="form.region" class="custom-input"></div>
          </div>
          <div class="line-modal-form">
            <div class="description">{{$t('Місто')}}</div>
            <div class="input-wr"><input type="text" v-model="form.city" class="custom-input"></div>
          </div>
        </div>
        <div class="footer-modal text-right">
          <div class="btn-left-footer"></div>
          <div class="btn-right-footer">
            <button class="btn-green">{{$t('Зберегти')}}</button>
            <button class="btn-red" @click.prevent="hide()">{{$t('Скасувати')}}</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import { Grid, html, h } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
export default {
  name: 'DoctorsPage',
  data () {
    return {
      users: [],
      checked: false,
      value: null,
      url: '/doctor',
      form: {
        id: 0,
        tin: '',
        firstname: '',
        middlename: '',
        lastname: '',
        birthday: '',
        sex: 0,
        speciality: '',
        dateworkstart: '',
        dateworkend: '',
        mobilephone: '',
        homephone: '',
        email: '',
        skype: '',
        homeadress: '',
        postalcode: '',
        state: '',
        region: '',
        city: ''
      },
      pickerOptions: {
        firstDayOfWeek: 1
      },
      grid: new Grid()
    }
  },
  created () {
    this.$http.get(this.url + '/all')
      .then(result => {
        this.setData(result.data)
      })
  },
  methods: {
    hide () {
      this.$modal.hide('addDoctor')
    },
    show () {
      this.$modal.show('addDoctor')
    },
    setData (data, update = false) {
      this.users = data.doctors
      const arr = []
      this.users.map((user, index) => {
        arr.push([user.lastname, user.firstname, user.middlename, user.birthday, user.sex === '1' ? this.$t('жіноча') : this.$t('чоловіча'), user.mobilephone, user.email, {id: user.id, index}])
      })
      if (update) {
        this.grid.updateConfig({
          data: arr
        }).forceRender();
      } else {
        this.grid.updateConfig({
          columns: [{
            id: 'lastname',
            name: this.$t('Прізвище')
          }, {
            id: 'name',
            name: this.$t('Ім’я')
          }, {
            id: 'middlename',
            name: this.$t('По батькові')
          }, {
            id: 'birthday',
            name: this.$t('Дата народження')
          }, {
            id: 'sex',
            name: this.$t('Стать')
          }, {
            id: 'phone',
            name: this.$t('Номер телефону')
          }, {
            id: 'email',
            name: 'Email'
          }, {
            id: 'action',
            name: '',
            width: '90px',
            formatter: (cell, row) => {
              const btn = []
              // btn.push(h('a', {
              //   className: 'btn-table-change',
              // }, html('<i class="ico-08"></i>')));
              // btn.push(h('a', {
              //   className: 'btn-table-change',
              // }, html('<i class="ico-26"></i>')));
              btn.push(h('a', {
                className: 'btn-table-change',
                onClick: () => {
                  this.modify(Number(row._cells[7].data.index))
                }
              }, html('<i class="ico-19"></i>')));
              btn.push(h('a', {
                className: 'btn-table-change',
                onClick: () => {
                  this.remove(row._cells[7].data.id)
                }
              }, html('<i class="ico-20"></i>')));
              return btn;
            }
          }],
          data: arr,
          pagination: {
            enabled: true,
            limit: 10,
            summary: false
          },
          search: true,
          language: {
            'search': {
              'placeholder': '🔍 ' + this.$t('Пошук')
            },
            'pagination': {
              'previous': '⬅️',
              'next': '➡️',
            }
          }
        });
        this.grid.render(document.getElementById("table"));
      }
    },
    remove (id) {
      if (confirm('Видали працівника?')) {
        this.$http.delete(this.url + '/delete', {
          params: {
            id: id
          }
        })
          .then(result => {
            this.setData(result.data, true)
          })
          .catch(() => {
            this.$root.alert('error', 'Помилка видалення')
          })
      }
    },
    save () {
      if (this.form.lastname === '' || this.form.firstname === '' || this.form.middlename === '') {
        this.$toastr.e("Вкажіть всі обов'язкові поля!");
        return
      }
      this.$http.post('/doctor/save', this.form)
        .then(result => {
          this.setData(result.data, true)
          this.hide()
          this.setFormData(true, 0)
          this.$toastr.s("Запис успішно збережено");
        })
        .catch(() => {
          this.$toastr.e("Помилка додавання запису");
        })
    },
    setFormData (clear = false, data) {
      if (clear) {
        for (let key in this.form) {
          if (key === 'id') {
            this.form[key] = 0
          } else {
            this.form[key] = ''
          }
        }
      } else {
        for (let key in this.form) {
          this.form[key] = data[key]
        }
      }
    },
    modify (id) {
      this.setFormData(false, this.users[id])
      this.show()
    }
  }
}
</script>

<style scoped>

</style>
